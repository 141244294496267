import {orange, red} from '@mui/material/colors'
import {blue} from 'finsys-webcomponent'

export const rejectedChipColor = {
  color: '#FFF',
  background: red['300'],
  fontSize: '0.75rem',
}

export const inProgressChipColor = {
  color: '#FFF',
  background: blue['secondary'],
  fontSize: '0.75rem',
}

export const clarityChipColor = {
  color: '#FFF',
  background: '#ef6bb8',
  fontSize: '0.75rem',
}

export const completedChipColor = {
  color: '#FFF',
  background: '#44AF69',
  fontSize: '0.75rem',
}

export const withdrawnChipColor = {
  color: '#FFF',
  background: orange['600'],
  fontSize: '0.75rem',
}
