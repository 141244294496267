import {encode} from 'html-entities'

import TSApi from 'api/TS/TSApi'
import type {paginationType} from 'api/TS/query/_CommonType'
import type {DefaultSelectType} from 'api/TS/query/_CommonQueries'

export type fetchPOASummaryType = {
  ...paginationType,
  results: Array<{
    approve_date_format: string,
    authorized_person: string,
    bank_approval_matrix_id: number,
    bank_code: string,
    bank_description: string,
    country_id: string,
    entity_id: string,
    entity_name: string,
    escrow_related_online: string,
    header_type: string,
    is_header: string,
    manual_payments: string,
    status: string,
    system_admin: string,
    trust_online: string,
    working_capital_online: string,
  }>,
}

/**
 * To retrieve the online banking matrix summary
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchPOASummary({country, segment, entity}): fetchPOASummaryType {
  let url = `/poa_summary/?entity_id=${entity}&country_id=${country}&segment_id=${segment}`

  const {data} = await TSApi.get(url)

  return data
}

/**
 * To retrieve the category for POA
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchCategory(): DefaultSelectType {
  const {data} = await TSApi.get(`/poa_category/`)

  return data
}

export type fetchPOAEditSummaryType = Array<{
  poa_request_id: string,
  current_order: string,
  editable: string,
  request_details: Array<{
    authorized_person: string,
    bank_approval_matrix_id: number,
    bank_code: string,
    bank_description: string,
    entity_id: string,
    entity_name: string,
    escrow_related_online: string,
    header_type: string,
    is_header: string,
    manual_payments: string,
    remarks: string,
    request_detail_id: number,
    request_type: string,
    status_update: string,
    system_admin: string,
    trust_online: string,
    working_capital_online: string,
  }>,
  request_id: number,
  summary: Array<{
    authorized_person: string,
    bank_approval_matrix_id: number,
    bank_code: string,
    bank_description: string,
    entity_id: string,
    entity_name: string,
    escrow_related_online: string,
    header_type: string,
    is_header: string,
    manual_payments: string,
    remarks: string,
    status_update: string,
    system_admin: string,
    trust_online: string,
    working_capital_online: string,
  }>,
}>

/**
 * To get POA payload
 * @param request
 * @param rejectComment
 * @return {{current_order: *, comments: string, approval_matrix: [], request_id: (number|string|*), country_id}}
 */
function getPOAPayload(request, rejectComment) {
  const getPOAObject = (entityId, data, type) => {
    const row = type ? data[type] : data

    return {
      country_id: request.country,
      segment_id: request.segment,
      entity_id: request.entity,
      poa_request_detail_id: row.request_detail_id ?? null,
      poa_id: row.poa_id,
      poa_category_id: row.poa_category_id,
      sub_category: row.sub_category,
      forms_to_be_signed: row.forms_to_be_signed,
      internal_approval: row.internal_approval,
      principal: row.principal,
      agent: row.agent,
      remarks: row.remarks,
      request_type: row.poa_id ? data.request_type ?? 'edit' : 'add',
    }
  }
  let poa = []

  Object.keys(request.requestDetails).forEach((keyName) => {
    const data = request.requestDetails[keyName]

    //next we will loop the data
    data.data.forEach((value) => {
      poa.push(getPOAObject(keyName, value, null))
    })
  })

  return {
    poa_request_id: request.requestId ?? null,
    country_id: request.country,
    segment_id: request.segment,
    entity_id: request.entity,
    current_order: request.currentOrder,
    comments: `<p> ${rejectComment ? encode(rejectComment) : 'Reject'} </p>`,
    approve_2: request.generalDetails.approver?.email ?? null,
    change_summary: request.generalDetails.changeSummary,
    poa_list: poa,
  }
}

/**
 * To retrieve the POA summary by request id
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchPOAEditSummary(
  request_id
): fetchBankApprovalMatrixEditSummaryType {
  const {data} = await TSApi.get(
    `/poa_edit_request/?tz=Asia/Singapore&poa_request_id=${request_id}`
  )

  return data[0]
}

/**
 * To save POA
 * @return {Promise<DefaultSelectType>}
 */
export async function savePOA(request) {
  const requestData = getPOAPayload(request)

  const {data} = await TSApi.put(`/save_poa/`, requestData)

  return data
}

/**
 * To submit POA
 * @return {Promise<DefaultSelectType>}
 */
export async function submitPOA(request) {
  const requestData = getPOAPayload(request)

  const {data} = await TSApi.put(`/update_poa/`, requestData)

  return data
}

/**
 * To reject POA
 * @return {Promise<DefaultSelectType>}
 */
export async function rejectPOA(request, rejectComment = 'reject') {
  const requestData = getPOAPayload(request, rejectComment)

  const {data} = await TSApi.put(`/reject_poa/`, requestData)

  return data
}

/**
 * To withdraw POA
 * @return {Promise<DefaultSelectType>}
 */
export async function withdrawPOA(request, rejectComment = 'withdraw') {
  const requestData = getPOAPayload(request, rejectComment)

  const {data} = await TSApi.put(`/withdraw_poa/`, requestData)

  return data
}
