import React, {useState} from 'react'
import {Grid, Stack, Typography} from '@mui/material'
import {
  Accordion,
  AttachmentIcon,
  FileUpload,
  IconButton,
  Label,
} from 'finsys-webcomponent'

import {useSnackBar} from 'hoc/SnackbarHandler'
import {maxFileSizeForServer} from '_helper/_constants'
import FileTable from 'components/InternalRequest/Forms/Attachments/FileTable'
import {useAuth} from 'hoc/AuthContext'

export const Attachments = ({
  formDetails,
  isCallingApi,
  setIsCallingApi,
  setFormDetails,
  disabled,
  //custom function
  handleFilePreview,
  handleFileUpload,
  handleFileDownload,
  handleFileDelete,
  uploadToServer = true,
}) => {
  const {user} = useAuth()
  const {setSnackBar} = useSnackBar()
  const [fileUpload, setFileUpload] = useState(false)

  return (
    <React.Fragment>
      <FileUpload
        maxFile={1}
        hideDeleteButtonValue={!disabled ? user.email : ''}
        hideDeleteButtonField={'created_by'}
        hideDeleteButton={!disabled}
        uploadFiles={!disabled}
        setSnackBar={setSnackBar}
        maxFileSizeForServer={maxFileSizeForServer}
        uploadToServer={uploadToServer}
        open={fileUpload}
        handleClose={() => setFileUpload(false)}
        columns={[
          {
            name: 'File Name',
            field: 'file',
            width: '25%',
            component: ({value}) => value.attachment_filename,
          },
          {
            name: 'Document Name*',
            width: '25%',
            field: 'docName',
          },
          {
            name: `Purpose`,
            field: 'purpose',
            width: '40%',
          },
        ]}
        handleFileUpload={(files) => handleFileUpload(files)}
        handleFileDownload={(index) => handleFileDownload(index)}
        handleFilePreview={(index) => handleFilePreview(index)}
        handleFileDelete={(index) => handleFileDelete(index)}
        fileData={formDetails.ticket_attachment}
        isFileUploading={isCallingApi.type === 'upload-file'}
        setIsFileUploading={() =>
          setIsCallingApi({
            loading: true,
            type: 'upload-file',
          })
        }
        resetFileUploading={() =>
          setIsCallingApi({
            loading: false,
            type: null,
          })
        }
      />

      <Accordion
        title={`Attachments (${formDetails.ticket_attachment.length} ${
          formDetails.ticket_attachment.length > 1 ? 'Files' : 'File'
        })`}
        expanded
      >
        <Grid container justifyContent={'flex-start'} columnSpacing={8} rowSpacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={6}>
                <Label label={`Attachments`} error={formDetails.error_ticketAttachment} />
              </Grid>
              <IconButton
                title={''}
                handleOnClick={() => setFileUpload(true)}
                sx={{float: 'left', padding: 0}}
              >
                <img src={AttachmentIcon} alt="attachments" />
                <Typography variant={'button'} sx={{pl: 1, fontSize: '12px!important'}}>
                  {!disabled && 'Upload Or '} Preview Attachments
                </Typography>
              </IconButton>
            </Stack>
            <FileTable
              handleFilePreview={(dataIndex) => handleFilePreview(dataIndex)}
              handleFileDownload={(dataIndex) => handleFileDownload(dataIndex)}
              handleFileDelete={(dataIndex) => handleFileDelete(dataIndex)}
              data={formDetails.ticket_attachment}
              setFileUpload={() => setFileUpload(true)}
              setFormDetails={setFormDetails}
              disabled={disabled}
              categoryType={formDetails.category_type}
              hideDeleteButtonValue={!disabled ? user.email : ''}
              hideDeleteButtonField={'created_by'}
              hideDeleteButton={true}
            />
          </Grid>
        </Grid>
      </Accordion>
    </React.Fragment>
  )
}
