import React from 'react'
import {TextField, Autocomplete, Accordion} from 'finsys-webcomponent'
import {Grid} from '@mui/material'

import {useFetchBankNames} from 'components/_Utils/UseFetchFunctions'

/**
 * Document Signing form details
 * @return {{bank: null, attachments: *[], purposeForSigningRequest: null}}
 */
function bankFormDetails() {
  return {
    doc_signing_bank: null,
    doc_signing_purpose: null,
  }
}

export type DocumentSigningFormType = {
  doc_signing_form_details: Array<{
    doc_signing_bank: string,
    doc_signing_bank_id: number,
    doc_signing_purpose: string,
  }>,
}

/**
 * To retrieve the document signing form fields
 * @param formData
 * @return {{bank: ({text: string, value: string}|null), attachments: ({docName: string, file: {name: string, id: number}, purpose: string}[]|null), purposeForSigningRequest: string}|{bank: null, attachments: *[], purposeForSigningRequest: null}}
 */
export function getDocumentSigningFormFields(formData: DocumentSigningFormType) {
  let details = []

  const getData = (data) => {
    return {
      doc_signing_bank: data.doc_signing_bank
        ? {value: data.doc_signing_bank_id, text: data.doc_signing_bank}
        : null,
      doc_signing_purpose: data.doc_signing_purpose,
    }
  }

  if (formData?.form_details?.doc_signing_bank) {
    details = formData.form_details
  } else if (formData?.doc_signing_form_details?.length > 0) {
    details = formData.doc_signing_form_details.map((data) => {
      return getData(data)
    })[0]
  } else {
    details = bankFormDetails()
  }

  return {
    form_details: details,
  }
}

type DocumentSigningInfoType = {
  documentSigningInfo: {
    doc_signing_bank: string,
    doc_signing_purpose: string,
  },
  setDocumentSigningRequestInfo: () => void,
}

/**
 * To validate the fields for document signing
 * @param documentSigningInfo
 * @param setDocumentSigningRequestInfo
 * @return {{type: string, message: string}|{type: string}}
 */
export function validateDocumentSigning({
  documentSigningInfo,
  setDocumentSigningRequestInfo,
}: DocumentSigningInfoType) {
  const requiredFields = {
    form: [
      {field: 'doc_signing_bank', label: 'bank'},
      {field: 'doc_signing_purpose', label: 'purpose of signing'},
    ],
  }

  let isFormValid = true
  //lets validate form details first
  for (const [key, value] of Object.entries(requiredFields.form)) {
    if (
      !documentSigningInfo[value.field] ||
      documentSigningInfo[value.field].length === 0
    ) {
      setDocumentSigningRequestInfo((prevState) => ({
        ...prevState,
        form_details: {
          ...prevState.form_details,
          [`error_${value.field}`]: 'This field is required',
        },
      }))

      isFormValid = false
    }
  }

  if (!isFormValid) {
    return {
      type: 'error',
    }
  }

  return {
    type: 'success',
  }
}

export default function DocumentSigningRequest({
  documentSigningRequestInfo,
  setDocumentSigningRequestInfo,
  isDisabled,
}) {
  const {form_details} = documentSigningRequestInfo
  const disabled = isDisabled
  const {loading: isBanksLoading, data: banks} = useFetchBankNames()

  if (isBanksLoading) {
    return 'Document Signing Request Form is loading..'
  }

  return (
    <>
      <Accordion title={'Document Signing Request Form'} expanded>
        <div style={{marginBottom: '10px'}}>
          <Grid container justifyContent={'flex-start'} columnSpacing={8} rowSpacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={banks}
                getOptionLabel={(option) => option.text || ''}
                handleOnChange={(value) =>
                  setDocumentSigningRequestInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      doc_signing_bank: value,
                      error_doc_signing_bank: undefined,
                    },
                  }))
                }
                value={form_details.doc_signing_bank}
                label={'Select Bank'}
                textFieldProps={{
                  required: true,
                  error: !!form_details.error_doc_signing_bank,
                  helperText: form_details.error_doc_signing_bank,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                multiline
                maxRows={10}
                label={'Purpose for Signing Request'}
                value={form_details.doc_signing_purpose}
                onChange={(value) => {
                  setDocumentSigningRequestInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      doc_signing_purpose: value.target.value,
                      error_doc_signing_purpose: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_doc_signing_purpose}
                helperText={
                  form_details.error_doc_signing_purpose
                    ? form_details.error_doc_signing_purpose
                    : !disabled
                    ? `Press 'enter' for new line`
                    : ''
                }
              />
            </Grid>
          </Grid>
        </div>
      </Accordion>
    </>
  )
}
