import React from 'react'

import Template from 'core/Template'

const NotFound = () => {
  return (
    <Template
      title="Error 404"
      content="Hmms, we couldnt find the resource."
      desc={<>The resource requested could not be found on this server!</>}
    />
  )
}

export default NotFound
