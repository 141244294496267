import React, {useEffect, useState} from 'react'
import {Accordion, Autocomplete, Loading} from 'finsys-webcomponent'
import {Grid} from '@mui/material'

import {useFetchCountry, useFetchEntities} from 'components/_Utils/UseFetchFunctions'
import {useFetchCategories} from 'pages/InternalRequest/Create'
import MultipleSelect from 'components/_Common/Checkmarks'
import {
  FORM_BANK_ACCOUNT_CLOSING,
  FORM_BANK_ACCOUNT_MAINTENANCE,
} from '_helper/_constants'
import {useSnackBar} from 'hoc/SnackbarHandler'

/**
 * To validate Initiator fields
 * @param formDetails
 * @param setFormDetails
 * @returns {boolean}
 */
export function validateDetails(formDetails, setFormDetails) {
  let isFormValid = true

  const requiredFields = ['country_id', 'entity_id', 'category_type']

  requiredFields.forEach((field) => {
    if (!formDetails[field] || formDetails[field].length === 0) {
      setFormDetails((prevState) => ({
        ...prevState,
        [`error_${field}`]: 'This is a required field',
      }))

      isFormValid = false
    }

    if (
      ['category_type'].includes(field) &&
      formDetails[field] !== 'Document Signing Request'
    ) {
      if (!formDetails.sub_category_1) {
        isFormValid = false
        setFormDetails((prevState) => ({
          ...prevState,
          [`error_sub_category_1`]: 'This is a required field',
        }))
      }
    }
  })

  if (!isFormValid) {
    return {
      type: 'error',
    }
  }

  return {
    type: 'success',
  }
}

export const Details = ({
  formDetails,
  setFormDetails,
  disabled,
  setRemoveEntityDialog,
  setChangeSubCategoryDialog,
}) => {
  const {setSnackBar} = useSnackBar()
  const [categories, setCategories] = useState([])
  const [subCategory, setSubCategory] = useState([])

  const {loading: isEntitiesLoading, data: entities = []} = useFetchEntities(
    formDetails.country_id
  )
  const {loading: isCategoryLoading, data: categoriesData} = useFetchCategories()
  const {loading: isCountryLoading, data: countries} = useFetchCountry()

  /**
   * To map category data into value, text format for the select dropdown
   */
  useEffect(() => {
    if (categoriesData) {
      const format = categoriesData.map((data) => {
        return {value: data.category_type, text: data.category_type}
      })
      setCategories([...new Map(format.map((item) => [item['value'], item])).values()])
    }
  }, [categoriesData])

  /**
   * To populate subcategory 1 value based on the category user selected
   */
  useEffect(() => {
    if (!categoriesData) {
      return
    }

    let format = []
    if (formDetails.category_type) {
      const filteredSubCategories = categoriesData?.filter(
        (data) => formDetails.category_type === data.category_type && data.sub_category_1
      )

      format =
        filteredSubCategories?.map((data) => {
          return {value: data.sub_category_1, text: data.sub_category_1}
        }) ?? []
    }

    setSubCategory(format)
    setFormDetails((prevState) => ({
      ...prevState,
      sub_category_1: format.length === 0 ? null : prevState.sub_category_1,
    }))
  }, [categoriesData, formDetails.category_type])

  if (isCountryLoading || isCategoryLoading) {
    return <Loading open={true} />
  }

  return (
    <>
      <Accordion title={'Details'} expanded>
        <Grid container justifyContent={'flex-start'} direction={'column'} rowSpacing={2}>
          <Grid item container direction={'row'} columnSpacing={8} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <MultipleSelect
                isAll={true}
                selections={[
                  {
                    country_id: 'All',
                    country_name: 'All Countries',
                    pending_approval_request_id: null,
                  },
                  ...countries,
                ]}
                label={'Select Country'}
                onChange={(event, value) => {
                  let country_id = value.map(({country_id}) => country_id)

                  //to filter out the entity that doesnt contain the coutry selected
                  let entity_list = formDetails.entity_id.filter((e) =>
                    country_id.includes(e.country_id)
                  )

                  if (value.length < formDetails.country_id.length) {
                    let countries = formDetails.country_id.filter(
                      (x) => !value.includes(x)
                    )

                    if (countries.length === 1 && formDetails.ticket_id) {
                      setRemoveEntityDialog({
                        open: true,
                        entities: entity_list,
                        countries: countries,
                        value: formDetails.country_id.filter((x) => value.includes(x)),
                        removeAll: false,
                        type: 'country',
                      })
                      return
                    }
                    if (countries.length > 0 && formDetails.ticket_id) {
                      setRemoveEntityDialog({
                        open: true,
                        entities: entity_list,
                        countries: formDetails.country_id,
                        value: [],
                        removeAll: true,
                        type: 'country',
                      })
                      return
                    }
                  }

                  let countryValue = value

                  if (value[value.length - 1]?.country_id === 'All') {
                    if (
                      formDetails.country_id.length === countries.length &&
                      formDetails.ticket_id
                    ) {
                      setRemoveEntityDialog({
                        open: true,
                        entities: entity_list,
                        countries: formDetails.country_id,
                        value: value,
                        removeAll: true,
                        type: 'country',
                      })
                      return
                    }

                    countryValue =
                      formDetails.country_id.length === countries.length ? [] : countries
                  }
                  setFormDetails((prevState) => ({
                    ...prevState,
                    country_id: countryValue,
                    entity_id: entity_list,
                    ...(prevState.error_country_id && {error_country_id: undefined}),
                  }))
                }}
                value={formDetails.country_id}
                getOptionLabel={(option) =>
                  `${option.country_name} (${option.country_id})` ?? option
                }
                keyValue={'country_id'}
                textFieldProps={{
                  required: true,
                  error: formDetails.error_country_id,
                  helperText: formDetails.error_country_id,
                }}
                loading={isCountryLoading}
                isOptionEqualToValue={(option, value) => {
                  return value?.country_id === option?.country_id
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MultipleSelect
                isAll={true}
                loading={isEntitiesLoading}
                selections={
                  formDetails?.country_id?.length > 0
                    ? [
                        {
                          entity_name: 'All Entity',
                          entity_id: 'All',
                          entity_code: 'All',
                          pending_poa_request_id: null,
                        },
                        ...entities,
                      ]
                    : []
                }
                label={'Select Entity'}
                onChange={(event, value) => {
                  if (value.length < formDetails.entity_id.length) {
                    let entities = formDetails.entity_id.filter((x) => !value.includes(x))

                    if (entities.length === 1 && formDetails.ticket_id) {
                      setRemoveEntityDialog({
                        open: true,
                        entities: entities,
                        value: formDetails.entity_id.filter((x) => value.includes(x)),
                        removeAll: false,
                        type: 'entity',
                      })
                      return
                    }
                    if (entities.length > 0 && formDetails.ticket_id) {
                      setRemoveEntityDialog({
                        open: true,
                        entities: formDetails.entity_id,
                        value: [],
                        removeAll: true,
                        type: 'entity',
                      })
                      return
                    }
                  }

                  let entityValue = value

                  if (value[value.length - 1]?.entity_code === 'All') {
                    if (
                      formDetails.entity_id.length === entities.length &&
                      formDetails.ticket_id
                    ) {
                      setRemoveEntityDialog({
                        open: true,
                        entities: formDetails.entities,
                        value: value,
                        removeAll: true,
                        type: 'entity',
                      })
                      return
                    }

                    entityValue =
                      formDetails.entity_id.length === entities.length ? [] : entities
                  }

                  setFormDetails((prevState) => ({
                    ...prevState,
                    entity_id: entityValue,
                    ...(prevState.error_entity_id && {error_entity_id: undefined}),
                  }))
                }}
                value={formDetails.entity_id}
                getOptionLabel={(option) => {
                  return `${option.entity_name} (${option.entity_code})` ?? option
                }}
                keyValue={'text'}
                textFieldProps={{
                  required: true,
                  error: formDetails.error_entity_id,
                  helperText:
                    formDetails.error_entity_id ?? 'Please select country first',
                }}
                isOptionEqualToValue={(option, value) => {
                  return option?.entity_id === value?.entity_id
                }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid item container direction={'row'} columnSpacing={8} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                label="Select Category"
                options={categories}
                getOptionLabel={(option) => option?.text ?? option}
                handleOnChange={(value) =>
                  setFormDetails((prevState) => ({
                    ...prevState,
                    category_type: value?.value,
                    sub_category_1: null,
                    ...(prevState.error_category_type && {
                      error_category_type: undefined,
                    }),
                    ...(prevState.error_sub_category_1 &&
                      value?.value === 'Document Signing Request' && {
                        error_sub_category_1: undefined,
                      }),
                  }))
                }
                value={formDetails.category_type}
                textFieldProps={{
                  required: true,
                  error: !!formDetails.error_category_type,
                  helperText: formDetails.error_category_type,
                }}
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value
                }}
                disabled={disabled || formDetails.ticket_id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                label="Select Sub-Category"
                options={subCategory}
                getOptionLabel={(option) => option?.text ?? option}
                handleOnChange={(value) => {
                  if (!value?.value) {
                    setSnackBar({
                      open: true,
                      type: 'error',
                      message: `Sub Category is mandatory`,
                    })
                    return
                  }

                  if (!formDetails.sub_category_1 || !formDetails.ticket_id) {
                    setFormDetails((prevState) => {
                      return {
                        ...prevState,
                        sub_category_1: value?.value,
                        ...(prevState.error_sub_category_1 && {
                          error_sub_category_1: undefined,
                        }),
                      }
                    })

                    return
                  }

                  // If we are changing it to combination
                  if (
                    value?.value === 'WC + ESCROW' ||
                    ![FORM_BANK_ACCOUNT_CLOSING, FORM_BANK_ACCOUNT_MAINTENANCE].includes(
                      formDetails.category_type?.replaceAll(' ', '')
                    )
                  ) {
                    setFormDetails((prevState) => {
                      return {
                        ...prevState,
                        sub_category_1: value?.value,
                        ...(prevState.error_sub_category_1 && {
                          error_sub_category_1: undefined,
                        }),
                      }
                    })

                    return
                  }

                  setChangeSubCategoryDialog({
                    open: true,
                    value: value?.value,
                    type: null,
                  })
                }}
                value={formDetails.sub_category_1}
                textFieldProps={{
                  required: subCategory?.length > 0,
                  error: !!formDetails.error_sub_category_1,
                  helperText: formDetails.error_sub_category_1,
                }}
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value
                }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </>
  )
}
