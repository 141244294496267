import TSApi from 'api/TS/TSApi'

interface UploadRequestAttachmentParams {
  requestId?: number;
  poaRequestId?: number;
  ticketId?: number;
  detailId?: number;
  attachmentType: string;
  attachment: any;
  user: string;
}

/**
 * To upload attachment
 * @return {Promise<any>}
 */
export async function uploadRequestAttachment({
  requestId,
  ticketId,
  detailId,
  poaRequestId,
  attachmentType,
  attachment,
  user,
}: UploadRequestAttachmentParams) {
  let formData = new FormData()

  if (detailId) {
    formData.append('detail_id', detailId)
  }

  if (ticketId) {
    formData.append('ticket_id', ticketId)
  }

  if (requestId) {
    formData.append('request_id', requestId)
  }

  if (poaRequestId) {
    formData.append('poa_request_id', poaRequestId)
  }

  formData.append('attachment_type', attachmentType)
  formData.append('attachment_file', attachment[0])
  formData.append('attachment_filename', attachment[0].name)
  formData.append('created_by', user)
  formData.append('last_updated_by', user)

  const {data} = await TSApi.post(`/attachment/?tz=Asia/Singapore`, formData)

  return data
}

export type AttachmentType = Array<{
  attachment_files: string,
  attachment_filename: string,
  attachment_id: number,
  attachment_purpose: string,
  attachment_type: string,
  created_by: string,
  creation_date: string,
  creation_date_format: string,
  detail_id: string,
  document_name: string,
  last_update_date: string,
  last_updated_by: string,
  request_id: number,
  ticket_id: string,
}>

/**
 * To retrieve BAM attachment
 * @return {Promise<any>}
 */
export async function getBankApprovalMatrixAttachments({
  requestId,
}: number): AttachmentType {
  if (!requestId) {
    return []
  }

  const {data} = await TSApi.get(`/attachment/?tz=Asia/Singapore&request_id=${requestId}`)

  return data
}

/**
 * To retrieve POA attachment
 * @return {Promise<any>}
 */
export async function getPOAAttachments({poaRequestId}: number): AttachmentType {
  if (!poaRequestId) {
    return []
  }

  const {data} = await TSApi.get(
    `/attachment/?tz=Asia/Singapore&poa_request_id=${poaRequestId}`
  )

  return data
}

/**
 * To download attachment
 * @return {Promise<any>}
 */
export async function downloadAttachment({id}: number) {
  const data = await TSApi.get(`/download/?attachment_id=${id}`, {
    responseType: 'blob',
  })

  const fileName = data.headers['content-disposition']
    .split('filename=')[1]
    .replaceAll('"', '')

  const url = window.URL.createObjectURL(new Blob([data.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName) //or any other extension
  document.body.appendChild(link)
  link.click()

  return true
}

/**
 * To preview attachment
 * @return {Promise<any>}
 */
export async function previewAttachment({id}: number) {
  const data = await TSApi.get(`/download/?attachment_id=${id}`, {
    responseType: 'blob',
  })

  return URL.createObjectURL(new Blob([data.data], {type: 'image/png'}))
}

/**
 * To delete attachment
 * @return {Promise<any>}
 */
export async function deleteAttachment({id}: number) {
  const {data} = await TSApi.delete(`/attachment_delete/${id}/`)

  return data
}
