import React, {useState} from 'react'
import {Button, ServerSideAutocomplete, TextField} from 'finsys-webcomponent'
import {
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'
import {useMutation} from 'react-query'

import {fetchEmployees} from 'api/TS/query/_CommonQueries'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {checkEmployeeExists} from 'api/TS/query/InternalRequestQueries'

export default function EmployeeFreeSoloCreateOptionDialogWithDesignation({
  disabled,
  data,
  onChange,
  error,
}) {
  const {setSnackBar} = useSnackBar()
  const [open, toggleOpen] = useState(false)

  const filter = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) =>
      `${option.full_name} ${option.email ? `(${option.email})` : ''}`,
  })

  const handleClose = () => {
    setDialogValue({
      email: '',
      fullName: '',
      companyDesignation: '',
    })

    toggleOpen(false)
  }

  const [dialogValue, setDialogValue] = React.useState({
    email: '',
    fullName: '',
    designation: '',
  })

  const doesEmployeeExists = useMutation(
    (employeeList) => checkEmployeeExists(employeeList),
    {
      onSuccess: (result) => {
        if (result.status === 'Success') {
          let trimDialogValue = {...dialogValue, name: dialogValue?.name.trim()}
          onChange(trimDialogValue)

          handleClose()
        } else {
          setSnackBar((prevState) => ({
            ...prevState,
            open: true,
            type: 'error',
            message: result.message,
          }))
        }
      },
      onError: (err) => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
    }
  )

  const handleSubmit = (event) => {
    event.preventDefault()
    doesEmployeeExists.mutate([dialogValue.email])
  }

  return (
    <React.Fragment>
      <ServerSideAutocomplete
        freeSolo
        clearOnBlur
        disabled={disabled}
        value={data}
        handleOnChange={(value) => {
          if (typeof value === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true)
              setDialogValue({
                email: null,
                name: value.full_name?.trim(),
              })
            })
          } else if (value && value.inputValue) {
            toggleOpen(true)
            setDialogValue({
              email: null,
              name: value.full_name,
            })
          } else {
            onChange({
              name: value?.full_name ?? null,
              email: value?.email ?? null,
              new: false,
            })
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          // Suggest the creation of a new value
          if (params.inputValue?.trim() !== '') {
            filtered.push({
              inputValue: `Add "${params.inputValue?.trim()}"`,
              full_name: params.inputValue?.trim(),
            })
          }

          return filtered
        }}
        fetchOptions={(value) => fetchEmployees(value?.trim())}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }

          return option.full_name
            ? `${option.full_name} ${option.email ? `(${option.email})` : ''}`
            : ''
        }}
        label={null}
        textFieldProps={{
          error: !!error,
          sx: {
            '& label': {display: 'none'},
            '& .MuiInput-root': {mt: 0},
          },
          placeholder: 'Search by name or email',
        }}
      />

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Missing Employee? Please, add it!
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              required={true}
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value?.replace(/\b\w/g, (l) => l.toUpperCase()),
                })
              }
              label="Name"
              type="text"
              helperText={'eg. John Lee'}
            />
            <TextField
              autoFocus
              required={true}
              id="email"
              value={dialogValue.email}
              onChange={(event) =>
                setDialogValue({...dialogValue, email: event.target.value?.trim()})
              }
              label="Email"
              type="text"
              helperText={'Please input the user email - eg. johnlee@domain.com'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" label={'Cancel'} type={'text'} />
          <Button onClick={handleSubmit} color="primary" label={'Add'} type={'primary'} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
