import {combineReducers} from 'redux'
import filter from './filter'
import breadcrumb from './breadcrumb'
import internalRequest from './internalRequest'

export default combineReducers({
  filter,
  breadcrumb,
  internalRequest,
})
