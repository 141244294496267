import React, {useRef} from 'react'
import {makeStyles} from '@mui/styles'

import {
  MemoizedTableFixHeader,
  MemoizedPOATableRow,
  NoRowToShow,
} from 'components/Tables/_helper'
import {Pagination} from 'components/Tables/Pagination'
import {Loading} from 'finsys-webcomponent'

const useStyles = makeStyles((theme) => ({
  dynamicWrapperRoot: {
    width: '100%',
    display: 'grid',
  },
  editDynamicWrapperRoot: {
    paddingTop: '10px',
    paddingRight: '15px',
    position: 'relative',
    height: 'calc(100vh - 30px - 50px - 120px)',
  },
}))

export function POATable({
  type = 'view',
  selectedCell,
  setSelectedCell,
  toEditCell,
  setToEditCell,
  columns,
  country,
  page,
  serverPaginationResults,
  onClickToNextPage,
  onClickToPreviousPage,
  onClickToFirstPage,
  onClickToLastPage,
  editable,
  style,
  handleEntityMenu,
  isLoading,
}) {
  const classes = useStyles()

  // used useRef to keep a mutable object with the current state.
  const selectedCellRef = useRef()
  selectedCellRef.current = selectedCell

  const toEditCellRef = useRef()
  toEditCellRef.current = toEditCell

  const memorizedHandleOnClick = React.useCallback(
    (event, targetId) => {
      // to detect if user is pressing ctrl / cmd and clicking
      if (event.ctrlKey || event.metaKey) {
        let mutateCell = [...selectedCellRef.current]

        if (event.detail >= 1) {
          if (!mutateCell.includes(targetId)) {
            mutateCell.push(targetId)
          }

          setSelectedCell(mutateCell)
        }
        return
      }

      // user is jus clicking the cell
      setSelectedCell([targetId])

      // we re click the same cell jus let it be
      if (selectedCellRef.current.includes(toEditCellRef.current)) {
        return
      }

      // detail 2 = double click
      if (event.detail >= 2) {
        setToEditCell(targetId)
      }

      if (event.detail < 2 && toEditCell) {
        setToEditCell(null)
      }
    },
    [selectedCell]
  )

  const handleOnMouseDown = (event, targetId) => {
    // we only wanna trigger cell select/ highlight during edit mode
    if (!type) {
      return
    }

    memorizedHandleOnClick(event, targetId)
  }

  return (
    <div
      className={
        type === 'edit' ? classes.editDynamicWrapperRoot : classes.dynamicWrapperRoot
      }
      {...(style && {style: style})}
    >
      <div className="wrapper ag-theme-material">
        {isLoading ? (
          <Loading open={true} fullScreen={false} />
        ) : (
          <>
            <div className={`bam-table ${type !== 'edit' && 'no-border'}`}>
              <table key={type ?? 'view'}>
                <MemoizedTableFixHeader columns={columns} />
                <MemoizedPOATableRow
                  columns={columns}
                  rows={serverPaginationResults.rowData}
                  type={type}
                  editable={editable}
                  toEditCell={toEditCell}
                  selectedCell={selectedCell}
                  handleOnMouseDown={handleOnMouseDown}
                  handleEntityMenu={handleEntityMenu}
                />
              </table>
            </div>

            {serverPaginationResults.rowData.length === 0 && (
              <NoRowToShow
                {...(!country && {
                  helperText: 'Please select a country, segment or entity to view',
                })}
              />
            )}

            <Pagination
              page={page}
              serverPaginationResult={serverPaginationResults}
              onClickToNextPage={onClickToNextPage}
              onClickToPreviousPage={onClickToPreviousPage}
              onClickToFirstPage={onClickToFirstPage}
              onClickToLastPage={onClickToLastPage}
            />
          </>
        )}
      </div>
    </div>
  )
}
