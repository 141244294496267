import TSApi from 'api/TS/TSApi'
import {supportingDocsType} from 'api/TS/query/BankApprovalMatrixQueries'
import type {paginationType} from 'api/TS/query/POAEditQueries'
import {DefaultSelectType, EntitiesType} from 'api/TS/query/_CommonQueries'

export type fetchPOASummaryType = {
  ...paginationType,
  results: Array<{
    approve_date_format: string,
    authorized_person: string,
    bank_approval_matrix_id: number,
    bank_code: string,
    bank_description: string,
    country_id: string,
    entity_id: string,
    entity_name: string,
    escrow_related_online: string,
    header_type: string,
    is_header: string,
    manual_payments: string,
    status: string,
    system_admin: string,
    trust_online: string,
    working_capital_online: string,
  }>,
}

/**
 * To retrieve the online banking matrix summary
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchPOASummary(filter): fetchPOASummaryType {
  let url = `/poa_summary/?tz=Asia/Singapore`

  if (filter.country) {
    url += `&country_id=${filter.country.country_id}`
  }

  if (filter.segment?.value) {
    url += `&segment_id=${filter.segment.value}`
  }

  if (filter.entity?.value) {
    url += `&entity_id=${filter.entity.value}`
  }

  if (filter.approvedDate) {
    url += `&approve_date=${filter.approvedDate}`
  }

  const {data} = await TSApi.get(url)

  return data
}

/**
 * To retrieve the POA supporting docs
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchSupportingDocs({entity, approvedDate}): supportingDocsType {
  if (!approvedDate || !entity) {
    return []
  }

  const {data} = await TSApi.get(
    `/get_poa_supporting_docs/?tz=Asia/Singapore&entity_id=${entity}&approved_date=${approvedDate}`
  )

  return data
}

/**
 * To send notification to user
 * @param emails
 * @return {Promise<any>}
 */
export async function notifyEmail({emails, filterQuery}) {
  const {data} = await TSApi.put(`poa_notify_email/`, {
    recipients: emails,
    country_id: filterQuery.country.country_id,
    approve_date: filterQuery.approvedDate ?? 'ALL',
    segment_id: filterQuery.segment?.value,
    entity: filterQuery.entity?.entity_id,
  })

  return data
}

/**
 * To retrieve POA approved date
 * @param emails
 * @return {Promise<any>}
 */
export async function fetchApprovedDate(entity): Array {
  if (!entity) {
    return []
  }

  const {data} = await TSApi.get(
    `poa_approve_date_list/?entity_id=${entity?.entity_id ?? entity}&tz=Asia/Singapore`
  )
  return data
}

/**
 * To retrieve all entities
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchEntitiesAndRequestId(country, segment): EntitiesType {
  if (!country) {
    return []
  }

  let url = `/entities/?country_id=${country.country_id}`

  if (segment?.value) {
    url += `&segment=${segment.value}`
  }

  const {data} = await TSApi.get(url)

  return data
}

/**
 * To retrieve the segment
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchSegmentsForPOA(country, entity): DefaultSelectType {
  let url = `/segments/`

  if (country) {
    url += `?country_id=${country.country_id}`
  }

  if (entity?.value) {
    url += `${country ? '&' : '?'}entity=${entity.value}`
  }
  const {data} = await TSApi.get(url)

  return data
}

/**
 * To retrieve POA summary
 * @return {Promise<DefaultSelectType>}
 */
export async function exportPOASummary(filter) {
  let url = `/export_poa/?tz=Asia/Singapore`

  if (filter.country) {
    url += `&country_id=${filter.country.country_id}`
  }

  if (filter.segment?.value) {
    url += `&segment_id=${filter.segment.value}`
  }

  if (filter.entity?.value) {
    url += `&entity_id=${filter.entity.value}`
  }

  if (filter.approvedDate) {
    url += `&approve_date=${filter.approvedDate}`
  }

  const response = await TSApi.get(url, {
    responseType: 'blob',
  })

  const fileName = response.headers['content-disposition']
    .split('filename=')[1]
    .replaceAll('"', '')

  const attachmentUrl = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = attachmentUrl
  link.setAttribute('download', fileName) //or any other extension
  document.body.appendChild(link)
  link.click()

  return true
}
