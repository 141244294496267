import {Dialog, TextField} from 'finsys-webcomponent'
import Grid from '@mui/material/Grid'

type WithdrawProps = {
  value: string | null,
  onChange: () => void,
  onClose: () => void,
  onSubmit: () => void,
  isDisabled: boolean,
  isLoading: boolean,
}

/**
 * To open Withdraw Dialog
 * @param value
 * @param onChange
 * @param onClose
 * @param onSubmit
 * @param isDisabled
 * @param isLoading
 * @return {JSX.Element}
 * @constructor
 */
export const Withdraw = ({
  value,
  onChange,
  onClose,
  onSubmit,
  isDisabled,
  isLoading,
}: WithdrawProps) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Dialog
          fullWidth
          type={'error'}
          title={'Reason for withdrawal'}
          content={
            <TextField
              required
              multiline
              rows={2}
              label={'Please specify the reason for withdrawal'}
              onChange={(value) => onChange(value.target.value)}
              value={value}
              disabled={isLoading}
            />
          }
          buttons={[
            {label: 'Cancel', type: 'text', onClick: onClose, disabled: isDisabled},
            {
              label: 'Withdraw',
              type: 'danger',
              onClick: onSubmit,
              disabled: isDisabled,
              loading: isLoading,
            },
          ]}
          handleOnClose={onClose}
        />
      </Grid>
    </Grid>
  )
}
