import {securedLocalStorage} from 'localstorage'

/**
 * To get user role
 */
export function getUserRole(): {access: string, role: string} {
  return {
    access: securedLocalStorage.get('access'),
    role: securedLocalStorage.get('role'),
  }
}

/**
 * To set user role
 * @param access
 * @param role
 */
export function setUserRole(access, role): void {
  securedLocalStorage.set('access', access)
  securedLocalStorage.set('role', role)
}

/**
 * To remove user role
 */
export function removeUserRole(): void {
  securedLocalStorage.remove('access')
  securedLocalStorage.remove('role')
}
