import React from 'react'
import {Chip} from '@mui/material'
import {orange, red} from '@mui/material/colors'
import {blue} from 'finsys-webcomponent'
import {
  STATUS_APPROVAL_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_PENDING_ACCOUNT_CLOSING,
  STATUS_PENDING_ACCOUNT_NUMBER,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_WITHDRAWN,
} from '_helper/_constants'

export const rejectedChipColor = {
  color: '#FFF',
  background: red['300'],
  fontSize: '0.75rem',
}

export const verifiedChipColor = {
  color: '#FFF',
  background: '#8964b4',
  fontSize: '0.75rem',
}

export const inProgressChipColor = {
  color: '#FFF',
  background: blue['secondary'],
  fontSize: '0.75rem',
}

export const notifiedChipColor = {
  color: '#ffffff',
  background: '#97aab0',
  fontSize: '0.75rem',
}

export const pendingChipColor = {
  color: '#FFF',
  background: '#3ba6a6',
  fontSize: '0.75rem',
}

export const approvedChipColor = {
  color: '#FFF',
  background: '#5a9a5c',
  fontSize: '0.75rem',
}

export const submittedChipColor = {
  color: '#6b6b6b',
  background: '#ffd587',
  fontSize: '0.75rem',
}

export const clarityChipColor = {
  color: '#FFF',
  background: '#ef6bb8',
  fontSize: '0.75rem',
}

export const pendingAccountNumberChipColor = {
  color: '#FFF',
  background: '#ad7cf1',
  fontSize: '0.75rem',
}

export const completedChipColor = {
  color: '#FFF',
  background: '#44AF69',
  fontSize: '0.75rem',
}

export const withdrawnChipColor = {
  color: '#FFF',
  background: orange['600'],
  fontSize: '0.75rem',
}

const StatusChip = (status: string, ticket_status?: string) => {
  return getStatus({status, ticket_status})
}

/**
 * Generic function to get the chip for ticket
 * @param status
 * @param boardView
 * @return {JSX.Element}
 */
export const getStatus = ({status, ticket_status}) => {
  let chipProps = {
    size: 'small',
    label: status ?? ticket_status,
  }

  switch (ticket_status ?? status) {
    case 'Draft':
      chipProps = {
        ...chipProps,
      }
      break

    case STATUS_REJECTED:
      chipProps = {
        ...chipProps,
        sx: {
          ...rejectedChipColor,
        },
      }
      break

    case STATUS_SUBMITTED:
      chipProps = {
        ...chipProps,
        sx: {
          ...submittedChipColor,
        },
      }
      break

    case 'Pending 2nd Approver':
    case STATUS_PENDING_ACCOUNT_NUMBER:
    case STATUS_PENDING_ACCOUNT_CLOSING:
      chipProps = {
        ...chipProps,
        sx: {
          ...pendingAccountNumberChipColor,
        },
      }
      break

    case STATUS_COMPLETED:
      chipProps = {
        ...chipProps,
        sx: {
          ...completedChipColor,
        },
      }
      break
    case STATUS_WITHDRAWN:
      chipProps = {
        ...chipProps,
        sx: {
          ...withdrawnChipColor,
        },
      }
      break

    case STATUS_APPROVAL_IN_PROGRESS:
    default:
      chipProps = {
        ...chipProps,
        sx: {
          ...inProgressChipColor,
        },
      }
      break
  }

  return <Chip {...chipProps} />
}

export default StatusChip
