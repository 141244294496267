import React from 'react'
import {Accordion, Autocomplete, FormTable, TextField} from 'finsys-webcomponent'
import Grid from '@mui/material/Grid'
import {Stack} from '@mui/material'

import {useFetchEmployeeTitle} from 'components/_Utils/UseFetchFunctions'

/**
 * Component for Bank - Detail Section
 * @param formDetails
 * @param setFormDetails
 * @param errors
 * @param isDisabled
 * @returns {JSX.Element}
 * @constructor
 */
export function Details({formDetails, setFormDetails, errors, isDisabled}) {
  const {loading: isEmployeeTitleLoading, data: title} = useFetchEmployeeTitle()

  return (
    <Accordion title={'OBM Employee Details'} expanded>
      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label={'Email'}
            onChange={(value) => {
              setFormDetails((prevState) => {
                return {
                  ...prevState,
                  email: value.target.value,
                  error_bank_code: null,
                }
              })
            }}
            value={formDetails.email}
            required
            error={!!formDetails.error_email || errors?.error_email}
            helperText={formDetails.error_email || errors?.error_email}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label={'Full name'}
            onChange={(value) => {
              setFormDetails((prevState) => {
                return {
                  ...prevState,
                  fullname: value.target.value,
                  error_fullname: null,
                }
              })
            }}
            value={formDetails.fullname}
            required
            error={!!formDetails.error_fullname || errors?.error_fullname}
            helperText={formDetails.error_fullname || errors?.error_fullname}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction={'column'} spacing={2}>
            <Autocomplete
              options={title}
              isLoading={isEmployeeTitleLoading}
              getOptionLabel={(option) => option.text || option}
              handleOnChange={(value) =>
                setFormDetails((prevState) => {
                  return {
                    ...prevState,
                    updated_designation: value,
                    error_designation: null,
                  }
                })
              }
              value={formDetails?.updated_designation}
              textFieldProps={{
                error: !!formDetails.error_designation,
                helperText:
                  'To update your designation, please select from here and save. This action will override any existing designations',
              }}
              label={'Select Designation'}
              disabled={isDisabled}
            />
            <FormTable
              tableKey={'obm_employee'}
              columns={[
                {
                  name: 'Current Designation',
                  field: 'designation',
                },
              ]}
              data={formDetails.designation}
            />
          </Stack>
        </Grid>
      </Grid>
    </Accordion>
  )
}
