import React, {useEffect} from 'react'

import Template from 'core/Template'
import {useAuth} from 'hoc/AuthContext'
import {useToken} from 'hoc/TokenHandler'
import {getWebEntryUrl, setWebEntryUrl} from 'localstorage/WebEntryUrl'

/**
 * To be shown upon logout / if user is not login
 * @returns {JSX.Element}
 * @constructor
 */
const Logout = () => {
  const {session, user} = useAuth()
  const {isTokenExpired} = useToken()

  useEffect(() => {
    if (window.gapi.auth2?.getAuthInstance() || !!user) {
      // sign out google auth
      window.gapi.auth2?.getAuthInstance().signOut()
      window.gapi.auth2?.getAuthInstance().disconnect()
    }
    //to clear all except web entry URL
    let entryUrl = getWebEntryUrl()
    localStorage.clear()
    if (entryUrl) {
      setWebEntryUrl(entryUrl)
    }
  }, [])

  return (
    <>
      <Template
        errorCode={null}
        desc={
          isTokenExpired
            ? `Your session has ended, please log in again`
            : session
            ? 'Gooooodbye! See you soon!'
            : 'You are not login, please login!'
        }
      />
    </>
  )
}

export default Logout
