import React from 'react'
import {Typography, Grid, Stack} from '@mui/material'
import {FullScreenDialog, Button} from 'finsys-webcomponent'

import EntityFilterView from 'components/Metadata/Entity/FilterView'
import OBMFilterView from 'components/Metadata/ObmEmployee/FilterView'

type FilterOptionsProps = {
  filterCondition: {
    temp: {
      currencies: [],
      segments: [],
      countries: [],
    },
    actual: {
      currency: [],
      segment: [],
      country: [],
    },
  },
  openFullScreenDialog: boolean,
  handleClose(): () => void,
  handleSetFilterCondition(): () => void,
  handleApplyFilter(): () => void,
  handleClearAllFilter(): () => void,
}
export const FilterOptions = ({
  filterCondition,
  filtersData,
  tab,
  openFullScreenDialog,
  handleClose,
  setFilterCondition,
  handleApplyFilter,
  handleClearAllFilter,
}: FilterOptionsProps) => {
  const getFilterView = () => {
    if (tab === 'obmeployee') {
      return (
        <OBMFilterView
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
      )
    }
    return (
      <EntityFilterView
        filterCondition={filterCondition}
        filtersData={filtersData}
        setFilterCondition={setFilterCondition}
      />
    )
  }
  return (
    <FullScreenDialog
      open={openFullScreenDialog}
      title={'Filters'}
      handleClose={handleClose}
      footer={
        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
          <Button
            type={'danger'}
            label={'Clear All Filters'}
            onClick={handleClearAllFilter}
          />
          <Button type={'primary'} label={'Apply'} onClick={handleApplyFilter} />
        </Stack>
      }
    >
      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'} gutterBottom>
            General
          </Typography>
          {getFilterView()}
        </Grid>
      </Grid>
    </FullScreenDialog>
  )
}

export default FilterOptions
