import {Dialog, FormTable, RadioButton, ServerSideAutocomplete} from 'finsys-webcomponent'
import {Stack, Grid} from '@mui/material'
import {fetchTickets, fetchUserAccess} from 'api/TS/query/_CommonQueries'
import {useState} from 'react'

type CopyProps = {
  value: string | null,
  onChange: () => void,
  onClose: () => void,
  onSubmit: () => void,
  isDisabled: boolean,
  isLoading: boolean,
}

/**
 * To open Copy Dialog
 * @param value
 * @param onChange
 * @param onClose
 * @param onSubmit
 * @param userList
 * @param isDisabled
 * @param isLoading
 * @return {JSX.Element}
 * @constructor
 */
export const Copy = ({
  value,
  onChange,
  onClose,
  onSubmit,
  isDisabled,
  isLoading,
}: CopyProps) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Dialog
          fullWidth
          maxWidth={'md'}
          type={'info'}
          title={'Copy Ticket Settings'}
          content={
            <Stack spacing={3}>
              <ServerSideAutocomplete
                textFieldProps={{
                  required: true,
                  placeholder: 'Search by ticket number',
                }}
                label={'Select Ticket'}
                value={value?.ticket}
                handleOnChange={(value) => {
                  onChange((prevState) => {
                    return {
                      ...prevState,
                      value: {...prevState.value, ticket: value},
                    }
                  })
                }}
                fetchOptions={(value) => fetchTickets(value)}
                getOptionLabel={(option) => {
                  return option?.ticket_number ?? option
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.ticket_number === value.ticket_number
                }}
              />
              <RadioButton
                label={'Options'}
                options={[
                  'All Fields',
                  ...(value?.ticket?.category_type === 'Bank Account Opening'
                    ? ['Operation Details']
                    : []),
                ]}
                handleOnChange={(value) => {
                  onChange((prevState) => {
                    return {
                      ...prevState,
                      value: {...prevState.value, type: value},
                    }
                  })
                }}
                value={value?.type}
              />
            </Stack>
          }
          buttons={[
            {label: 'Cancel', type: 'text', onClick: onClose, disabled: isDisabled},
            {
              label: 'Copy',
              type: 'primary',
              onClick: onSubmit,
              disabled: isDisabled,
              loading: isLoading,
            },
          ]}
          handleOnClose={onClose}
        />
      </Grid>
    </Grid>
  )
}
