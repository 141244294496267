import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Tabs} from 'finsys-webcomponent'
import {useNavigate} from 'react-router-dom'

import Pending from 'components/InternalRequest/Summary/Pending'
import MyRequest from 'components/InternalRequest/Summary/MyRequest'
import Participated from 'components/InternalRequest/Summary/Participated'
import AllRequest from 'components/InternalRequest/Summary/AllRequest'
import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {HQ_ROLE_ID, INTERNAL_REQUEST_PAGE_NAME, IT_ROLE_ID} from '_helper/_constants'

export const tabList = [
  {
    label: 'Pending',
    panel: <Pending />,
    count: 0,
  },
  {
    label: 'My Request',
    panel: <MyRequest />,
  },
  {
    label: 'Participated',
    panel: <Participated />,
  },
  {
    label: 'All Request',
    panel: <AllRequest />,
  },
]

export default function Metadata() {
  const navigate = useNavigate()
  const [dimensions, setDimensions] = useState([])
  const [functionAccess, setFunctionAccess] = useState([])
  const pendingCount = useSelector((state) => state.internalRequest)

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(INTERNAL_REQUEST_PAGE_NAME, setFunctionAccess)
    }

    let list = tabList

    if (functionAccess) {
      if (functionAccess === 'FORBIDDEN') {
        navigate('/Forbidden')
      }

      let dimensionList = []

      list = tabList.map(({label, ...props}) => {
        if (label === 'Pending') {
          return {
            ...props,
            label,
            count: pendingCount.totalCount,
          }
        }
        return {
          label,
          ...props,
        }
      })

      if ([HQ_ROLE_ID, IT_ROLE_ID].includes(functionAccess)) {
        dimensionList = list
      } else {
        dimensionList = list.filter((key) => key.label !== 'All Request')
      }

      setDimensions(dimensionList)
    }
  }, [functionAccess, pendingCount])

  return <Tabs tabList={dimensions} />
}
