import React from 'react'
import {makeStyles} from '@mui/styles'

import {Pagination} from 'components/Tables/Pagination'
import 'components/Tables/table.scss'

const useStyles = makeStyles((theme) => ({
  dynamicWrapperRoot: {
    width: '100%',
    display: 'grid',
  },
  editDynamicWrapperRoot: {
    paddingTop: '10px',
    paddingRight: '15px',
    position: 'relative',
    height: 'calc(100vh - 30px - 50px - 120px)',
    width: '100%',
    display: 'grid',
  },
}))

export function TableWrapper({
  type,
  page,
  serverPaginationResults,
  onClickToNextPage,
  onClickToPreviousPage,
  onClickToFirstPage,
  onClickToLastPage,
  children: table,
  ...props
}) {
  const classes = useStyles()

  return (
    <div
      className={
        type === 'edit' ? classes.editDynamicWrapperRoot : classes.dynamicWrapperRoot
      }
      {...(props.style && {style: props.style})}
    >
      <div className="wrapper ag-theme-material">
        {table}
        <Pagination
          page={page}
          serverPaginationResult={serverPaginationResults}
          onClickToNextPage={onClickToNextPage}
          onClickToPreviousPage={onClickToPreviousPage}
          onClickToFirstPage={onClickToFirstPage}
          onClickToLastPage={onClickToLastPage}
        />
      </div>
    </div>
  )
}
