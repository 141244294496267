import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import reduxThunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {QueryClientProvider} from 'react-query'
import {BrowserRouter} from 'react-router-dom'
import {ThemeProvider} from 'finsys-webcomponent'

import reducers from 'store/reducers'
import AuthApp from 'core/AuthApp'
import {queryClient} from 'hoc/ReactQuery'
import {TokenHandler} from 'hoc/TokenHandler'
import './index.scss'

const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(reduxThunk)))

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <ThemeProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <TokenHandler>
            <AuthApp />
          </TokenHandler>
        </BrowserRouter>
        {/*<ReactQueryDevtools />*/}
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
)
