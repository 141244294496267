import Login from 'core/Login'
import Logout from 'core/Template/Logout'
import ServerError from 'core/Template/ServerError'
import Forbidden from 'core/Template/Forbidden'
import NotFound from 'core/Template/NotFound'
import NoAccess from 'core/Template/NoAccess'
import NetworkError from 'core/Template/NetworkError'
import HomePage from 'pages/Home'

//custom pages
import InternalRequest from 'pages/InternalRequest'
import CreateInternalRequest from 'pages/InternalRequest/Create'
import EditInternalRequest from 'pages/InternalRequest/Edit'
import BankApprovalMatrix from 'pages/BankApprovalMatrix'
import EditBAM from 'pages/BankApprovalMatrix/Edit'
import StatusUpdateBankApprovalMatrix from 'pages/BankApprovalMatrix/StatusUpdate'
import OnlineBankingMatrix from 'pages/OnlineBankingMatrix'
import POA from 'pages/POA'
import EditPOA from 'pages/POA/Edit'
import Metadata from 'pages/Metadata'
import CreateBank from 'pages/Metadata/Bank/Create'
import Bank from 'pages/Metadata/Bank'
import CreateEntity from 'pages/Metadata/Entity/Create'
import Entity from 'pages/Metadata/Entity'
import ObmEmployee from 'pages/Metadata/ObmEmployee'
import RedirectOldTicketUrl from 'pages/RedirectOldTicketUrl'

export const unprotectedRouteList = [
  {path: '/Login', Component: <Login />},
  {path: '/Logout', Component: <Logout />},
  {path: '/NoAccess', Component: <NoAccess />},
  {path: '/InternalError', Component: <ServerError />},
]

/**
 * Path name must be the same as permission page!
 * @type {[{path: string, name: string, Component: ((function(): *)|*)}, {path: string, name: string, Component: ((function({tryAgain: *, loadingIndicator: *}): *)|*)}, {path: string, name: string, Component: ((function(): *)|*)}, {path: null, name: string, Component: ((function(): *)|*)}]}
 */
export const protectedRouteList = [
  {path: '/', Component: <HomePage />},
  {path: '/NetworkError', Component: <NetworkError />},
  {path: '/ServerError', Component: <ServerError />},
  {path: '/Forbidden', Component: <Forbidden />},
  //custom pages
  {path: '/InternalRequest', Component: <InternalRequest />},
  //@TODO TO BE REMOVED
  {path: '/InternalRequest/NewRequest', Component: <CreateInternalRequest />},
  {path: '/InternalRequest/Create', Component: <CreateInternalRequest />},
  {path: '/InternalRequest/:formType/:id', Component: <RedirectOldTicketUrl />},
  {path: '/InternalRequest/:id', Component: <EditInternalRequest />},
  {path: '/BankApprovalMatrix', Component: <BankApprovalMatrix />},
  {
    path: '/BankApprovalMatrix/ViewFromEmail/:country/:approvedDate',
    Component: <BankApprovalMatrix />,
  },
  {path: '/BankApprovalMatrix/Create/:country', Component: <EditBAM />},
  {path: '/BankApprovalMatrix/Edit/:country/:requestId', Component: <EditBAM />},
  {
    path: '/BankApprovalMatrix/StatusUpdate/:country/:approvedDate',
    Component: <StatusUpdateBankApprovalMatrix />,
  },
  {path: '/BankApprovalMatrix/OnlineBankingMatrix', Component: <OnlineBankingMatrix />},
  {path: '/POA', Component: <POA />},
  {
    path: '/POA/ViewFromEmail/:country/:segment/:entity/:approvedDate',
    Component: <POA />,
  },
  {
    path: '/POA/Create/:country/:segment/:entity',
    Component: <EditPOA />,
  },
  {
    path: '/POA/Edit/:country/:segment/:entity/:requestId',
    Component: <EditPOA />,
  },
  {path: '/Metadata', Component: <Metadata />},
  {
    path: '/Metadata/:metadata',
    Component: <Metadata />,
  },
  {
    path: '/Metadata/Bank/Create',
    Component: <CreateBank />,
  },
  {
    path: '/Metadata/Bank/:bankId',
    Component: <Bank />,
  },
  {path: '/Metadata/Entity/Create', Component: <CreateEntity />},
  {
    path: '/Metadata/Entity/:entityId',
    Component: <Entity />,
  },
  {
    path: '/Metadata/OBMEmployee/:email',
    Component: <ObmEmployee />,
  },
  {path: '*', Component: <NotFound />},
]
