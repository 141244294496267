import React from 'react'
import {Autocomplete} from '@mui/lab'
import {Checkbox, Chip} from '@mui/material'
import {TextField} from 'finsys-webcomponent'
import {CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material'

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

export default function MultipleSelect({
  label,
  selections,
  onChange,
  getOptionLabel,
  keyValue = null,
  textFieldProps,
  isAll = false,
  ...props
}) {
  const isAllSelected =
    selections.length > 0 &&
    props.value.length === (isAll ? selections.length - 1 : selections.length)

  return (
    <Autocomplete
      {...props}
      multiple
      options={selections}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      renderOption={(element, option, {selected, ...props}) => {
        const optionValue = getOptionLabel(option)

        return (
          <li {...element}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{marginRight: 8}}
              checked={selected || isAllSelected}
            />
            {optionValue}
          </li>
        )
      }}
      renderTags={(value, getTagProps) => {
        return props.value.map((option, index) => (
          <Chip
            label={getOptionLabel(option)}
            size="small"
            sx={{height: '26px'}}
            {...getTagProps({index})}
          />
        ))
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          label={label}
          InputLabelProps={{shrink: true}}
        />
      )}
    />
  )
}
