import React from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import {Stack} from '@mui/material'

type DesignationRendererProps = {
  contentField: string,
}

const DesignationRenderer = ({contentField, ...props}: DesignationRendererProps) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      {props.data['designation_count'] > 1 && <ReportProblemIcon color={'error'} />}
      {props.data[contentField]}
    </Stack>
  )
}

export default DesignationRenderer
