import TSApi from 'api/TS/TSApi'

/**
 * To save comment for internal request form
 * @param comment
 * @param request
 * @return {Promise<any>}
 */
export async function saveComments(
  comment: string,
  request: {ticket_id: string, ticket_status: string}
): {
  status: string,
} {
  const {data} = await TSApi.put('/comment_save/', {
    ticket_id: request.ticket_id,
    comments: comment,
    ticket_status: request.ticket_status,
  })

  return data
}

export type FetchCommentsType = {
  comment_id: string,
  comment_date_format: string,
  full_name: string,
  comment_date: string,
  comments: string,
  status: string,
  user_email: string,
  ticket_id: number,
}
/**
 * To retrieve internal request form comments
 * @param id
 * @return {Promise<FetchCommentsType>}
 */
export async function fetchComments(id: number): FetchCommentsType {
  const {data} = await TSApi.get(`/get_comments/?ticket_id=${id}&tz=Asia/Singapore`)

  return data
}
