import React from 'react'
import {OutlinedInput} from '@mui/material'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles({
  customInputLabel: {
    '& legend': {
      visibility: 'visible',
    },
  },
})

export default function OutlineInput(props) {
  const classes = useStyles()

  return (
    <OutlinedInput
      {...props}
      classes={{notchedOutline: classes.customInputLabel}}
      fullWidth
      notched
    />
  )
}
