import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const TokenContext = createContext()

export const TokenHandler = ({children}) => {
  const navigate = useNavigate()
  const [isTokenExpired, setIsTokenExpired] = useState(false)

  useEffect(() => {
    if (isTokenExpired) {
      navigate('/Logout')
    }
  }, [isTokenExpired])

  const contextPayload = useMemo(
    () => ({isTokenExpired, setIsTokenExpired}),
    [isTokenExpired]
  )

  return <TokenContext.Provider value={contextPayload}>{children}</TokenContext.Provider>
}

export const useToken = () => useContext(TokenContext)
