import React, {useEffect, useState} from 'react'
import {Badge, Grid, Stack, useMediaQuery} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {AgGridTable, IconButton, SearchInput} from 'finsys-webcomponent'
import styled from '@emotion/styled'
import {useTheme} from '@mui/styles'
import {useQuery} from 'react-query'
import moment from 'moment'

import FilterIcon from 'assets/icons/filter.png'
import CreateIcon from 'assets/icons/add.png'
import {
  columnDefs,
  frameworkComponents,
} from 'components/InternalRequest/Summary/ag-grid-constant'
import FilterOptions from 'components/InternalRequest/Summary/FilterOptions'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {setFilter, setPendingRequestTotalCount} from 'store/actions'
import {
  fetchInternalRequests,
  fetchInternalRequestsFilters,
} from 'api/TS/query/InternalRequestQueries'
import {getPagination} from 'localstorage/PaginationSettings'

/**
 * To override the default color for badge
 */
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    color: 'white',
  },
}))

interface TableWithFilterProps {
  retainFilter?: boolean;
  tab: string;
}

export const TableWithFilter = ({retainFilter, tab}: TableWithFilterProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {setSnackBar} = useSnackBar()
  const [openFilter, setOpenFilter] = useState(false)
  const filterState = useSelector((state) => state.filter.state)
  const [searchTF, setSearchTF] = useState('')
  //to cater filter
  const [filterCondition, setFilterCondition] = useState({
    temp: {
      submissionstartdate: null,
      submissionenddate: null,
      country: [],
      ticketstatus: [],
      entity: [],
      category: [],
      sub_category: [],
    },
    actual: {
      submissionstartdate: null,
      submissionenddate: null,
      country: [],
      ticketstatus: [],
      entity: [],
      category: [],
      sub_category: [],
    },
    searchText: null,
  })
  const [filterCounter, setFilterCounter] = useState(null)
  const [throttle, setThrottle] = useState(null)

  //to cater AG-GRID
  const [gridApi, setGridApi] = useState(null)
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    page: 1,
    rowsPerPage: getPagination().rowPerPage,
    toNextPage: null,
  })
  const theme = useTheme()
  const isIpad = useMediaQuery(theme.breakpoints.down('md'))

  /**
   * Apply filter state
   */
  useEffect(() => {
    if (!retainFilter) {
      return
    }

    //to retain filter value
    if (filterState) {
      setFilterCounter(filterState.filterCounter ?? filterCounter)
      setFilterCondition(filterState.filterCondition ?? filterCondition)
      setSearchTF(filterState.searchTF ?? searchTF)
    }
  }, [])

  /**
   * Set filter state
   */
  useEffect(() => {
    if (!retainFilter) {
      return
    }

    dispatch(
      setFilter('InternalRequest', tab, {
        filterCondition,
        filterCounter: filterCounter,
        searchTF,
      })
    )
  }, [filterCondition.actual])

  /**
   * To retrieve internal requests
   */
  const {isLoading, data} = useQuery(
    [
      'internal_requests',
      {
        filter: filterCondition.actual,
        searchText: filterCondition.searchText,
        tab,
        rowsPerPage: serverPaginationResult.rowsPerPage,
        page: serverPaginationResult.page,
      },
    ],
    () =>
      fetchInternalRequests(
        tab,
        filterCondition.actual,
        filterCondition.searchText,
        serverPaginationResult.rowsPerPage,
        serverPaginationResult.page
      ),
    {
      refetchOnWindowFocus: true,
    }
  )

  /**
   * To retrieve internal requests
   */
  const {data: filters_data, isLoading: isFilterDataLoading} = useQuery(
    [
      'internal_requests_filters',
      {
        tab,
      },
    ],
    () => fetchInternalRequestsFilters(tab),
    {
      refetchOnWindowFocus: true,
    }
  )

  /**
   * To update the AG-Grid table data
   */
  useEffect(() => {
    if (data?.results) {
      setServerPaginationResult((prevState) => ({
        ...prevState,
        rowData: data.results,
        totalPages: data.total_pages,
        totalCount: data.count,
        toNextPage: data.next,
      }))

      if (tab === 'pending') {
        dispatch(setPendingRequestTotalCount(data.count))
      }
    }
  }, [data, tab])

  /**
   * Default method for AG-Grid
   * @param params
   * @return {Promise<void>}
   */
  const onGridReady = async (params) => {
    setGridApi(params.api)
  }

  /**
   * To set the actual value for filter
   */
  const handleApplyFilter = () => {
    let counter = 0
    // only if user click on apply we will set the actual value
    setFilterCondition({...filterCondition, actual: filterCondition.temp})
    let filterObject = {}
    let submissionFlag = 0

    for (const [key, value] of Object.entries(filterCondition.temp)) {
      if (value !== null && value !== '' && value?.length !== 0) {
        if (key === 'submissionstartdate' || key === 'submissionenddate') {
          submissionFlag = submissionFlag === 0 ? 1 : submissionFlag
        } else {
          counter++
        }
      }
      filterObject[key] = value
    }

    setFilterCounter(counter + submissionFlag !== 0 ? counter + submissionFlag : null)
  }

  /**
   * To handle the temporary storage of data in the filter menu
   * To clear all the temporary value in the filter menu
   */
  const handleClearAllFilter = () => {
    setFilterCondition({
      ...filterCondition,
      temp: {
        submissionstartdate: null,
        submissionenddate: null,
        country: [],
        ticketstatus: [],
        entity: [],
        category: [],
        sub_category: [],
      },
    })
  }

  /**
   * To handle search function
   * @param event
   */
  const handleSearchTextFieldOnChange = (value) => {
    clearTimeout(throttle)

    setSearchTF(value)
    setThrottle(
      setTimeout(function () {
        setFilterCondition((prevState) => ({
          ...prevState,
          searchText: value,
        }))
      }, 500)
    )
  }

  return (
    <>
      <Grid container justifyContent={'flex-end'} spacing={2}>
        <Grid item xs={12} container direction="row">
          <Grid item xs={9} sm={8}>
            <SearchInput
              value={searchTF}
              handleOnChange={handleSearchTextFieldOnChange}
              placeholder={'Search for Request ID and Summary'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
              <IconButton title="Filters" handleOnClick={() => setOpenFilter(true)}>
                <StyledBadge color={'secondary'} badgeContent={filterCounter}>
                  <img src={FilterIcon} alt="filters" />
                </StyledBadge>
              </IconButton>
              <IconButton
                title="Create"
                handleOnClick={() => navigate('/InternalRequest/NewRequest')}
              >
                <img src={CreateIcon} alt="create" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      {openFilter && (
        <FilterOptions
          filtersData={filters_data}
          filterCondition={filterCondition.temp}
          openFullScreenDialog={openFilter}
          handleClose={() => {
            setFilterCondition({
              ...filterCondition,
              temp: {
                ...filterCondition.actual,
              },
            })
            setOpenFilter(false)
          }}
          setFilterCondition={setFilterCondition}
          handleSetFilterCondition={(key, value) => {
            setFilterCondition({
              ...filterCondition,
              temp: {
                ...filterCondition.temp,
                [key]: value,
              },
            })
          }}
          handleApplyFilter={() => {
            if (
              (filterCondition.temp.submissionstartdate &&
                !filterCondition.temp.submissionenddate) ||
              (!filterCondition.temp.submissionstartdate &&
                filterCondition.temp.submissionenddate)
            ) {
              setSnackBar((prevState) => ({
                ...prevState,
                message: 'Incomplete Start/ End Submission Date',
                open: true,
              }))
              return
            }

            if (
              filterCondition.temp.submissionstartdate &&
              filterCondition.temp.submissionenddate &&
              moment(filterCondition.temp.submissionstartdate).isAfter(
                moment(filterCondition.temp.submissionenddate)
              )
            ) {
              setSnackBar((prevState) => ({
                ...prevState,
                message: 'Start date should before End date',
                open: true,
              }))
              return
            }

            handleApplyFilter()
            setOpenFilter(false)
          }}
          handleClearAllFilter={handleClearAllFilter}
        />
      )}
      <div>
        <AgGridTable
          onGridReady={onGridReady}
          gridApi={gridApi}
          serverPaginationResult={serverPaginationResult}
          setServerPaginationResult={setServerPaginationResult}
          page={serverPaginationResult.page}
          isLoading={isLoading}
          pageInset={isIpad ? 230 : 215}
          columnDefs={columnDefs}
          frameworkComponents={frameworkComponents}
          suppressRowTransform={true}
          onColumnResized={null}
          onColumnVisible={null}
        />
      </div>
    </>
  )
}

export default TableWithFilter
