import {useState} from 'react'
import {useMutation, UseMutateFunction} from 'react-query'

import TSApi from 'api/TS/TSApi'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {queryClient} from 'hoc/ReactQuery'
import {queryKeys} from 'hoc/ReactQuery/_constants'
import {FormErrorPayload, FormSuccessPayload} from '_helper/_types'
import {_getError} from '_helper/_getError'
import type {Entity} from '_helper/_types'

export type EntityPayload = {
  ...FormSuccessPayload,
}

/**
 * To save Entity
 * @return {Promise<any>}
 */
export async function saveEntity(formDetails): EntityPayload | FormErrorPayload {
  const {data} = await TSApi.put(`entities/${formDetails.entity_id}/`, {
    entity_code: formDetails.entity_code,
    entity_name: formDetails.entity_name,
    currency: formDetails.currency,
    country_id: formDetails.country_id,
    segment_id: formDetails.segment_id,
  })

  return data
}

interface UseSaveEntity {
  mutate: UseMutateFunction;
  isLoading: boolean;
  errors: [];
}

export function useSaveEntity(): UseSaveEntity {
  const {setSnackBar} = useSnackBar()
  const [errors, setErrors] = useState(null)

  const {mutate, isLoading} = useMutation(
    (entityDetails: Entity) => saveEntity(entityDetails),
    {
      onSuccess: (result: EntityPayload | FormErrorPayload) => {
        setSnackBar({
          open: true,
          type: result.status === 'SUCCESS' ? 'success' : 'error',
          message:
            result.status === 'SUCCESS'
              ? result.message
              : 'Validation failed! Please check those fields that are in red.',
        })

        if (result.status === 'SUCCESS') {
          queryClient.invalidateQueries([queryKeys.entity, parseInt(result.entity_id)])

          if (errors) {
            setErrors(null)
          }
        } else {
          setErrors(_getError(result.errors))
        }
      },
    }
  )

  return {mutate, isLoading, errors}
}
