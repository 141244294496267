import React, {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import parse from 'html-react-parser'
import {
  AccessTime,
  AddBox,
  Approval as ApprovalIcon,
  BookOnline,
  Comment,
  Face,
  MoreVert as MoreVertIcon,
  Stop as StopIcon,
  Toc as TocIcon,
} from '@mui/icons-material'
import {Divider, Grid, Menu, MenuItem, Stack, Typography} from '@mui/material'
import {
  StringAvatar,
  Button,
  Dialog as AlertDialog,
  FullScreenDialog,
  CustomEditorContent,
  Editor,
  TextBox,
  Loading,
  TextField,
  Autocomplete,
  IconButton,
  DetailedLayout,
  FileDeleteDialog,
  FilePreviewDialog,
} from 'finsys-webcomponent'

import {setBreadcrumbRecordName} from 'store/actions'
import useDataFetching from 'hoc/UseDataFetching'
import MergeIcon from 'assets/icons/merge.png'
import SplitIcon from 'assets/icons/split.png'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {useAuth} from 'hoc/AuthContext'
import {
  deleteAttachment,
  downloadAttachment,
  getBankApprovalMatrixAttachments,
  previewAttachment,
  uploadRequestAttachment,
} from 'api/TS/query/AttachmentQueries'
import {
  saveComments,
  fetchComments,
  FetchCommentsType,
} from 'api/TS/query/BankApprovalMatrixCommentQueries'
import {
  fetchBankApprovalMatrixEditSummary,
  fetchBankApprovalMatrixSummary,
  rejectBankApprovalMatrix,
  saveBankApprovalMatrix,
  submitBankApprovalMatrix,
  withdrawBankApprovalMatrix,
} from 'api/TS/query/BankApprovalMatrixEditQueries'
import {BAMTable} from 'components/Tables/BAMTable'
import {formatCellValue, formatValueWithEmptyPToBr} from 'components/Tables/_helper'
import {useFetchBankNames, useFetchEntities} from 'components/_Utils/UseFetchFunctions'
import {Reject} from 'components/ActionButtonDialog/Reject'
import {FileUpload} from 'components/_Common/FileUpload'
import {
  APPROVAL_MATRIX_PAGE_NAME,
  dateTimeFormat,
  HQ_ROLE_ID,
  STATUS_APPROVED,
  STATUS_COMPLETED,
  STATUS_DRAFT,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_VERIFIED,
  STATUS_WITHDRAWN,
} from '_helper/_constants'
import {queryClient} from 'hoc/ReactQuery'
import {retrievePageFunctionAccess} from '_helper/_authHelper'
import StatusChip from 'components/InternalRequest/Forms/_Common/StatusChip'
import {getUserRole} from 'localstorage/UserRole'
import {Withdraw} from 'components/ActionButtonDialog/Withdraw'

/**
 * To retrieve request comments
 * @param id
 * @return {{isError: false | true, data: FetchCommentsType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
function useGetRequestComments(id: number): FetchCommentsType {
  return useDataFetching(`request-${id}-comments`, () => fetchComments({id}))
}

/**
 * To retrieve request attachment
 * @param id
 * @return {{isError: false | true, data: FetchCommentsType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
function useGetRequestAttachments(id: number) {
  return useDataFetching(`request-${id}-attachments`, () =>
    getBankApprovalMatrixAttachments({requestId: id})
  )
}
function Edit() {
  let {country, requestId} = useParams()
  const location = useLocation()
  const {user} = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [commentField, setCommentField] = useState(undefined)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [requestStatus, setRequestStatus] = useState('Draft')
  const [generalDetails, setGeneralInformation] = useState({
    requestedBy: null,
    lastUpdatedOn: null,
  })

  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    toNextPage: '',
  })
  const [editable, setEditable] = useState(true)
  const [currentOrder, setCurrentOrder] = useState(0)
  const [selectedCell, setSelectedCell] = useState([])
  const [toEditCell, setToEditCell] = useState(null)
  const [toMutateEntityRow, setToMutateEntityRow] = useState(null)
  const [fullScreenDialogConst, setFullScreenDialogConst] = useState({
    open: false,
    type: null,
  })
  const [toAddEntity, setToAddEntity] = useState(null)
  const [menuObject, setMenuObject] = useState(null)
  const [openAlertDialog, setOpenAlertDialog] = useState({
    open: false,
  })
  const [functionAccess, setFunctionAccess] = useState([])
  const [isCallingApi, setIsCallingApi] = useState({
    loading: false,
    type: null,
  })
  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState({
    open: false,
    index: null,
  })
  const [filePreviewDialog, setFilePreviewDialog] = useState({
    open: false,
    value: null,
  })
  const [actionDialog, setActionDialog] = useState({
    type: null,
    value: null,
    open: false,
    isDisabled: false,
    isLoading: false,
  })
  const [anchorEl, setAnchorEl] = useState(null)

  const {loading: isEntitiesLoading, data: entities} = useFetchEntities([
    {country_id: country},
  ])
  const {loading: isBanksLoading, data: banks} = useFetchBankNames()
  const {loading: isCommentsLoading, data: comments} = useGetRequestComments(requestId)
  const {loading: isAttachmentsLoading, data: attachments} =
    useGetRequestAttachments(requestId)
  /**
   * To retrieve bank approval matrix summary by request id else country
   */
  const {loading: isSummaryLoading, data: summaryData} = useDataFetching(
    [`bankApprovalMatrix-${country}`, country, requestId],
    () =>
      requestId
        ? fetchBankApprovalMatrixEditSummary(requestId)
        : fetchBankApprovalMatrixSummary(country),
    {refetchOnWindowFocus: false}
  )

  /**
   * To redirect non hq user to forbidden page
   */
  useEffect(() => {
    if (getUserRole().role !== HQ_ROLE_ID) {
      navigate('/Forbidden')
    }
  }, [])

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(APPROVAL_MATRIX_PAGE_NAME, setFunctionAccess)
    }
    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  const [cellMutation, setCellMutation] = useState(null)
  const toMergeField = [
    'bank_id',
    'bank_description',
    'entity_name',
    'authorized_person',
    'manual_payments',
    'system_admin',
  ]
  const editableHeaderColumns = [
    {
      name: 'Matrix',
      fieldName: 'matrix',
      menu: ({entityId}) => (
        <IconButton
          title="Modify Row"
          handleOnClick={(event) => handleMatrixThresholdMenuClick(event, entityId)}
        >
          <TocIcon fontSize={'small'} />
        </IconButton>
      ),
      component: ({value, entityId, fieldName, disabled}) => {
        if (disabled)
          return <div className={'wrap-text'} dangerouslySetInnerHTML={{__html: value}} />

        return (
          <CustomEditorContent
            disabled={disabled}
            customEditor={Editor}
            value={value}
            autoFocus={'end'}
            handleOnChange={(value) => {
              setServerPaginationResult((prevState) => ({
                ...prevState,
                rowData: {
                  ...prevState.rowData,
                  [entityId]: {
                    ...prevState.rowData[entityId],
                    matrix: {...prevState.rowData[entityId].matrix, [fieldName]: value},
                  },
                },
              }))
            }}
          />
        )
      },
    },
    {
      name: 'Threshold',
      fieldName: 'threshold',
      component: ({value, entityId, fieldName, disabled}) => {
        if (disabled)
          return <div className={'wrap-text'} dangerouslySetInnerHTML={{__html: value}} />

        return (
          <CustomEditorContent
            disabled={disabled}
            autoFocus={'end'}
            customEditor={Editor}
            value={value}
            handleOnChange={(value) => {
              setServerPaginationResult((prevState) => ({
                ...prevState,
                rowData: {
                  ...prevState.rowData,
                  [entityId]: {
                    ...prevState.rowData[entityId],
                    threshold: {
                      ...prevState.rowData[entityId].threshold,
                      [fieldName]: value,
                    },
                  },
                },
              }))
            }}
          />
        )
      },
    },
  ]

  /**
   * To return the common component
   * @param disabled
   * @param value
   * @param entityId
   * @param dataIndex
   * @param rowSpanName
   * @param fieldName
   * @return {JSX.Element}
   */
  const getComponent = ({
    disabled,
    value,
    entityId,
    dataIndex,
    rowSpanName,
    fieldName,
  }) => {
    if (disabled)
      return (
        <div
          className={'wrap-text'}
          dangerouslySetInnerHTML={{__html: formatValueWithEmptyPToBr(value)}}
        />
      )

    return (
      <TextBox
        customEditor={Editor}
        value={value}
        handleOnChange={(value) =>
          setCellMutation({
            value,
            entityId,
            dataIndex,
            rowSpanName,
            fieldName,
          })
        }
        autoFocus={'end'}
      />
    )
  }
  const columns = [
    {
      name: '',
      fieldName: 'tableMenu',
      component: ({entityId, dataIndex}) => (
        <IconButton
          title="Modify Row"
          handleOnClick={(event) => handleTableMenuClick(event, entityId, dataIndex)}
        >
          <TocIcon fontSize={'small'} />
        </IconButton>
      ),
    },
    {
      name: 'Bank',
      fieldName: 'bank_description',
      component: ({value, entityId, dataIndex, rowSpanName, fieldName, disabled}) => {
        if (disabled) {
          return value.bank_description
        }

        return (
          <Autocomplete
            label={''}
            options={banks}
            disableClearable
            handleOnChange={(value) => {
              handleOnSelectBankCellClick(
                value,
                entityId,
                dataIndex,
                rowSpanName,
                fieldName
              )
            }}
            value={value.bank_description}
            variant={'outlined'}
            getOptionLabel={(option) => {
              return option?.text ?? option
            }}
          />
        )
      },
    },
    {
      name: 'Entity',
      fieldName: 'entity_name',
    },
    {
      name: 'Authorised Person',
      fieldName: 'authorized_person',
      component: ({value, entityId, dataIndex, rowSpanName, fieldName, disabled}) => {
        return getComponent({
          disabled,
          value,
          entityId,
          dataIndex,
          rowSpanName,
          fieldName,
        })
      },
    },
    {
      name: 'Manual Payments',
      fieldName: 'manual_payments',
      component: ({value, entityId, dataIndex, rowSpanName, fieldName, disabled}) => {
        return getComponent({
          disabled,
          value,
          entityId,
          dataIndex,
          rowSpanName,
          fieldName,
        })
      },
    },
    {
      name: 'System Admin',
      fieldName: 'system_admin',
      component: ({value, entityId, dataIndex, rowSpanName, fieldName, disabled}) => {
        return getComponent({
          disabled,
          value,
          entityId,
          dataIndex,
          rowSpanName,
          fieldName,
        })
      },
    },
  ]

  /**
   * To set breadcrumb nav
   */
  useEffect(() => {
    let currentNav = ''

    if (requestId) {
      currentNav = `Edit ${country.toUpperCase()} - Request ${requestId ?? ''}`
    } else {
      currentNav = `Create Request for ${country.toUpperCase()}`
    }
    dispatch(setBreadcrumbRecordName({[location.pathname]: currentNav}))
  }, [])

  /**
   * To set the row value after cell mutation
   */
  useEffect(() => {
    if (cellMutation) {
      let isCellMutated = false
      const entityId = cellMutation.entityId
      const value = cellMutation.value
      const dataIndex = cellMutation.dataIndex
      const rowSpanName = cellMutation.rowSpanName
      const fieldName = cellMutation.fieldName

      let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]

      for (
        let i = dataIndex;
        i <
        (dataIndex === 0
          ? modifiedRowData[dataIndex][rowSpanName]
          : modifiedRowData[dataIndex][rowSpanName] + dataIndex);
        i++
      ) {
        if (cellMutation.isBank) {
          modifiedRowData[i].bank_id = value.value
          modifiedRowData[i][fieldName] = value.text
        } else {
          modifiedRowData[i][fieldName] = value
        }

        //we shall compare with previous value to see if value is being mutated
        if (
          !isCellMutated &&
          modifiedRowData[i][`previous_${fieldName}`] !== modifiedRowData[i][fieldName]
        ) {
          isCellMutated = true
        }
      }

      setServerPaginationResult((prevState) => ({
        ...prevState,
        rowData: {
          ...prevState.rowData,
          [entityId]: {
            ...prevState.rowData[entityId],
            data: modifiedRowData,
          },
        },
      }))
    }
  }, [cellMutation])

  /**
   * To mutate the cell value for select
   * @param value
   * @param entityId
   * @param dataIndex
   * @param rowSpanName
   */
  const handleOnSelectBankCellClick = (value, entityId, dataIndex) => {
    let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]
    let index = 0
    for (const details of modifiedRowData) {
      if (dataIndex !== index && details.bank_id === value?.value) {
        setSnackBar({
          open: true,
          type: 'error',
          message: `Bank exists!`,
        })
        return
      }
      index++
    }

    setCellMutation({
      value: value,
      entityId,
      dataIndex,
      rowSpanName: 'bank_descriptionRowSpan',
      fieldName: 'bank_description',
      isBank: true,
    })

    setToEditCell(null)
  }

  /**
   * To set requestStatus if currentOrder updates
   */
  useEffect(() => {
    if (currentOrder) {
      setRequestStatus(getRequestStatus(currentOrder))
    }
  }, [currentOrder])

  /**
   * To get request status by currentOrder
   * @param currentOrder
   * @return {string}
   */
  const getRequestStatus = (currentOrder) => {
    switch (currentOrder) {
      case 1:
        return STATUS_SUBMITTED
      case 2:
        return STATUS_VERIFIED
      case 3:
        return STATUS_APPROVED
      case 99:
        return STATUS_COMPLETED
      case 100:
        return STATUS_REJECTED
      case 101:
        return STATUS_WITHDRAWN
      default:
        return STATUS_DRAFT
    }
  }

  /**
   * To update the table data and format the data for the table to consume
   */
  useEffect(() => {
    if (summaryData) {
      // if we have requestId we need to take the data from summary
      // then compare it with requestID
      const data = requestId ? summaryData.summary : summaryData
      let modifiedData = []
      let newData = {}
      // if its request ID we need to concat it and replaced with details....
      if (requestId) {
        setEditable(summaryData.editable === 'Y')
        setCurrentOrder(parseInt(summaryData.current_order))
        setGeneralInformation({
          requestedBy: summaryData.created_by,
          lastUpdatedOn: summaryData.last_update_date_format,
        })

        modifiedData = summaryData.request_detail.reduce(
          (previousValue, currentValue, index) => {
            if (!currentValue.bank_approval_matrix_id) {
              //we gonna push the result to
              if (!newData[currentValue.entity_id]) {
                newData[currentValue.entity_id] = []
              }
              newData[currentValue.entity_id].push(currentValue)
              return previousValue
            }

            return {
              ...previousValue,
              [currentValue.bank_approval_matrix_id]: {
                index,
                ...currentValue,
              },
            }
          },
          {}
        )
      }
      // we need to loop one round to add the data in
      data.forEach((row, index) => {
        // we need to push new data in too..
        if (row.entity_id !== data[index + 1]?.entity_id) {
          const entityID = row.entity_id

          if (newData[entityID]) {
            for (const newRow of newData[entityID]) {
              data.splice(index + 1, 0, newRow)
            }
            //after that we shall delete it
            newData[entityID] = []
          }
        }
      })

      // next we need to loop the left over new data
      for (const [key, value] of Object.entries(newData)) {
        if (newData[key]) {
          for (const newRow of newData[key]) {
            data.push(newRow)
          }
        }
      }

      // if data is empty will jus push all new data in
      if (data.length === 0 && requestId) {
        summaryData.request_detail.forEach((row) => {
          data.push(row)
        })
      }

      // we need to extract threshold and matrix first
      let tempThresholdAndMatrix = []
      let currentEntity = null
      let currentEntityIndex = 0
      data.forEach((row) => {
        // lets set the row if its empty we jus set it to 'edit'
        row.request_type = row.request_type ?? 'edit'
        // whatever it is we need to group it based by entity
        let newThresholdAndMatrix = {...tempThresholdAndMatrix}

        if (!newThresholdAndMatrix[row.entity_id]) {
          newThresholdAndMatrix[row.entity_id] = {
            request_type: 'add',
            name: row.entity_name,
            matrix: {
              authorized_person: null,
              bank_approval_matrix_id: null,
              manual_payments: null,
              system_admin: null,
            },
            threshold: {
              authorized_person: null,
              bank_approval_matrix_id: null,
              manual_payments: null,
              system_admin: null,
            },
            data: [],
          }
        }

        // we check if it exist then replace...
        if (modifiedData[row.bank_approval_matrix_id]) {
          const modifiedCell = modifiedData[row.bank_approval_matrix_id]

          toMergeField.forEach((fieldName) => {
            if (fieldName === 'entity') {
              return
            }

            row[`previous_${fieldName}`] = row[fieldName] ?? ''
            row[fieldName] = modifiedCell[fieldName]
            row.request_type = modifiedCell.request_type
          })
        }

        // lets check if the data is a threshold and matrix header
        if (row.is_header === 'Y') {
          // lets retrieve entity name
          const thresholdAndMatrixObject = {
            request_detail_id: row.request_detail_id ?? undefined,
            bank_approval_matrix_id: row.bank_approval_matrix_id,
            previous_authorized_person:
              row.previous_authorized_person ?? row.authorized_person ?? '',
            authorized_person: row.authorized_person ?? '',
            previous_manual_payments:
              row.previous_manual_payments ?? row.manual_payments ?? '',
            manual_payments: row.manual_payments ?? '',
            previous_system_admin: row.previous_system_admin ?? row.system_admin ?? '',
            system_admin: row.system_admin ?? '',
          }

          newThresholdAndMatrix[row.entity_id] = {
            ...newThresholdAndMatrix[row.entity_id],
            request_type: row.request_type ?? 'edit',
            [row.header_type]: thresholdAndMatrixObject,
          }

          tempThresholdAndMatrix = newThresholdAndMatrix
          return
        }

        // the code below is executed if its not a header
        // we will merge it only if they got the same entity as the previous index
        let ableToMerge = undefined
        if (currentEntity !== row.entity_id) {
          currentEntity = row.entity_id
          currentEntityIndex = 0
          ableToMerge = false
        } else {
          ableToMerge = true
          currentEntityIndex++
        }

        // we will loop the field to set its rowSpan and index and compare if its merge-able
        toMergeField.forEach((fieldName) => {
          let tempArray = [...newThresholdAndMatrix[row.entity_id].data]
          let rowSpanIndex = currentEntityIndex
          let rowSpan = 1

          if (ableToMerge && fieldName !== 'bank_description') {
            const prevIndex = currentEntityIndex - 1
            // if previous is a add / delete we cannot merge
            // if current row is a edit or delete we cannot merge

            let row_value =
              typeof row[fieldName] === 'number'
                ? row[fieldName].toString()
                : row[fieldName]
            let current_value =
              typeof formatCellValue(tempArray[prevIndex][fieldName]) === 'number'
                ? formatCellValue(tempArray[prevIndex][fieldName]).toString()
                : formatCellValue(tempArray[prevIndex][fieldName])
            let previous_value =
              typeof formatCellValue(tempArray[prevIndex][`previous_${fieldName}`]) ===
              'number'
                ? formatCellValue(
                    tempArray[prevIndex][`previous_${fieldName}`]
                  ).toString()
                : formatCellValue(tempArray[prevIndex][`previous_${fieldName}`])
            let modified_value =
              typeof formatCellValue(
                row[`previous_${fieldName}`] ?? row[`${fieldName}`]
              ) === 'number'
                ? formatCellValue(
                    row[`previous_${fieldName}`] ?? row[`${fieldName}`]
                  ).toString()
                : formatCellValue(row[`previous_${fieldName}`] ?? row_value)

            if (
              tempArray[prevIndex].request_type === row.request_type &&
              current_value.localeCompare(row_value) === 0 &&
              previous_value.localeCompare(modified_value ?? row[`${fieldName}`]) === 0
            ) {
              // we will add +1 to the previous rowSpan based on the row span index
              tempArray[tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]][
                `${[fieldName]}RowSpan`
              ]++

              // update the current rowSpanIndex & rowSpan to the previous row index
              rowSpanIndex = tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]
              rowSpan = 0
            }
          }

          // if this is the first record we shall instantiate the rowSpan & rowIndex for the field
          if (!tempArray[currentEntityIndex]) {
            tempArray[currentEntityIndex] = {
              ...row,
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
              [`previous_${[fieldName]}`]:
                row[`previous_${[fieldName]}`] ?? row[fieldName],
            }
          } else {
            tempArray[currentEntityIndex] = {
              ...tempArray[currentEntityIndex],
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
              [`previous_${[fieldName]}`]:
                row[`previous_${[fieldName]}`] ?? row[fieldName],
            }
          }

          newThresholdAndMatrix[row.entity_id] = {
            ...newThresholdAndMatrix[row.entity_id],
            data: tempArray,
          }
          tempThresholdAndMatrix = newThresholdAndMatrix
        })
      })

      setServerPaginationResult({
        rowData: tempThresholdAndMatrix,
        totalPages: summaryData?.total_pages,
        totalCount: summaryData?.count,
        toNextPage: summaryData?.next,
      })
    }
  }, [summaryData])

  /**
   * Handle BAM save
   * @type {UseMutationResult<DefaultSelectType, unknown, void, unknown>}
   */
  const handleOnSave = useMutation(
    () =>
      saveBankApprovalMatrix({
        requestId,
        country,
        currentOrder,
        approvalMatrix: serverPaginationResult.rowData,
      }),
    {
      onSuccess: (result, variables) => {
        setSnackBar({
          open: true,
          type: 'success',
          message: result?.status,
        })

        if (result?.request_id) {
          navigate(`/BankApprovalMatrix/edit/${country}/${result?.request_id}`)
        }
      },
      onError: (err, variables, context) => {
        let message = null
        if (err?.data?.status) {
          message = err?.data?.status
        }
        setSnackBar((prevState) => ({
          ...prevState,
          open: true,
          message: message ?? prevState.message,
        }))
      },
      onSettled: () => {
        queryClient.invalidateQueries(`bankApprovalMatrix-${country}`)
        queryClient.invalidateQueries(`request-${requestId}-comments`)
        setActionDialog({
          type: null,
          value: null,
          open: false,
          isDisabled: false,
          isLoading: false,
        })
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * Handle BAM submission
   * @type {UseMutationResult<DefaultSelectType, unknown, void, unknown>}
   */
  const handleOnSubmit = useMutation(
    () =>
      submitBankApprovalMatrix({
        requestId,
        country,
        currentOrder,
        approvalMatrix: serverPaginationResult.rowData,
      }),
    {
      onSuccess: (result, variables) => {
        setSnackBar({
          open: true,
          type: 'success',
          message: result?.status,
        })

        if (!requestId) {
          navigate(`/BankApprovalMatrix/edit/${country}/${result?.request_id}`)
        }
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        queryClient.invalidateQueries(`bankApprovalMatrix-${country}`)
        queryClient.invalidateQueries(`request-${requestId}-comments`)
        setActionDialog({
          type: null,
          value: null,
          open: false,
          isDisabled: false,
          isLoading: false,
        })
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   *  Handle BAM rejection
   * @type {UseMutationResult<DefaultSelectType, unknown, void, unknown>}
   */
  const handleWithdrawTicket = useMutation(
    () =>
      withdrawBankApprovalMatrix(
        {
          requestId,
          country,
          currentOrder,
          approvalMatrix: serverPaginationResult.rowData,
        },
        actionDialog.value
      ),
    {
      onSuccess: (result, variables) => {
        setSnackBar({
          open: true,
          type: 'success',
          message: result?.status,
        })
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        queryClient.invalidateQueries(`bankApprovalMatrix-${country}`)
        queryClient.invalidateQueries(`request-${requestId}-comments`)
        setActionDialog({
          type: null,
          value: null,
          open: false,
          isDisabled: false,
          isLoading: false,
        })
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   *  Handle BAM rejection
   * @type {UseMutationResult<DefaultSelectType, unknown, void, unknown>}
   */
  const handleRejectTicket = useMutation(
    () =>
      rejectBankApprovalMatrix(
        {
          requestId,
          country,
          currentOrder,
          approvalMatrix: serverPaginationResult.rowData,
        },
        actionDialog.value
      ),
    {
      onSuccess: (result, variables) => {
        setSnackBar({
          open: true,
          type: 'success',
          message: result?.status,
        })
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        queryClient.invalidateQueries(`bankApprovalMatrix-${country}`)
        queryClient.invalidateQueries(`request-${requestId}-comments`)
        setActionDialog({
          type: null,
          value: null,
          open: false,
          isDisabled: false,
          isLoading: false,
        })
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To add new comment
   * @type {UseMutationResult<*, unknown, string, {previousComments: unknown}>}
   */
  const addNewCommentToRequest = useMutation(
    (comment) => saveComments(comment, {requestId, ticketStatus: requestStatus}),
    {
      onMutate: async (commentField: string) => {
        setCommentField(undefined)
        await queryClient.cancelQueries(`request-${requestId}-comments`)

        const previousComments = queryClient.getQueryData(`request-${requestId}-comments`)

        if (previousComments) {
          queryClient.setQueryData(`request-${requestId}-comments`, [
            {
              full_name: localStorage.getItem('username'),
              comment_date_format: moment(new Date()).format('YYYY-MM-DD HH:mm'),
              comments: commentField,
            },
            ...previousComments,
          ])
        }

        return {previousComments}
      },
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))

        if (context?.previousComments) {
          queryClient.setQueryData(
            `request-${requestId}-comments`,
            context.previousComments
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(`request-${requestId}-comments`)
      },
    }
  )

  /**
   * To toggle table menu open
   * @param event
   * @param entityId
   * @param dataIndex
   */
  const handleTableMenuClick = (event, entityId, dataIndex) => {
    setToMutateEntityRow({
      entityId,
      dataIndex,
    })
    setMenuObject({
      type: 'table-menu',
      event: event.currentTarget,
    })
  }

  /**
   * To toggle table menu close
   */
  const handleTableMenuClose = () => {
    setMenuObject(null)
    setToMutateEntityRow(null)
    setSelectedCell([])
    setToEditCell(null)
  }

  /**
   * To toggle matrix threshold menu open
   * @param event
   * @param entityId
   */
  const handleMatrixThresholdMenuClick = (event, entityId) => {
    setToMutateEntityRow({
      entityId,
    })
    setMenuObject({
      type: 'matrix-threshold-menu',
      event: event.currentTarget,
    })
  }

  /**
   * To merge cells
   */
  const mergeCells = () => {
    if (selectedCell.length <= 1) {
      return
    }

    let validated = true
    let smallestIndex = null
    let toBeMutateFieldName = null
    let mergedEntityCell = null
    let isSequential = []

    // // we will loop every selected cell to validate it and only break the loop upon failed validation
    selectedCell.every((value, index) => {
      const splitRow = value.split('-')

      // entity - index - fieldname - rowspan
      const entityId = splitRow[0]
      const dataIndex = parseInt(splitRow[1])
      const fieldName = splitRow[2]
      const rowSpan = parseInt(splitRow[3])

      if (isNaN(dataIndex)) {
        setSnackBar({
          open: true,
          type: 'error',
          message: 'Unable to merge fixed header - Matrix & Threshold',
        })
        validated = false
        return false
      }

      let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]

      // we instantiate the first selected cell selected entity & field name
      if (!toBeMutateFieldName) {
        toBeMutateFieldName = fieldName
        mergedEntityCell = modifiedRowData[dataIndex].entity_id
      }

      // cant merge if cell is not included in merge-able list
      if (!toMergeField.includes(fieldName) || fieldName === 'bank_description') {
        setSnackBar({
          open: true,
          type: 'error',
          message: 'Unable to merge Bank cell',
        })
        validated = false
        return false
      }

      //cant merge across diff field
      if (toBeMutateFieldName !== fieldName) {
        setSnackBar({
          open: true,
          type: 'error',
          message: 'Unable to merge across different field',
        })
        validated = false
        return false
      }

      //cant merge across entity
      if (mergedEntityCell !== modifiedRowData[dataIndex].entity_id) {
        setSnackBar({
          open: true,
          type: 'error',
          message: 'Cannot merge cell across entity',
        })
        validated = false
        return false
      }

      if (smallestIndex === null || smallestIndex > dataIndex) {
        smallestIndex = dataIndex
      }

      isSequential.push({dataIndex, rowSpan})
      if (selectedCell.length === index + 1) {
        // can’t merge value if sequence is wrong
        isSequential
          .sort(function (a, b) {
            return a.dataIndex - b.dataIndex
          })
          .every((value, valueIndex) => {
            if (isSequential.length === valueIndex + 1) {
              return false
            }

            if (
              value.dataIndex + value.rowSpan !==
              isSequential[valueIndex + 1].dataIndex
            ) {
              setSnackBar({
                open: true,
                type: 'error',
                message: 'Unable to merge across cell',
              })
              validated = false

              return false
            }
            return true
          })
      }
      return true
    })

    if (!validated) {
      return
    }

    let modifiedRowData = [...serverPaginationResult.rowData[mergedEntityCell].data]
    let firstValue = modifiedRowData[smallestIndex][toBeMutateFieldName]
    let toMutate = true

    for (const sequence of isSequential) {
      const {dataIndex} = sequence
      if (modifiedRowData[dataIndex][toBeMutateFieldName] !== null) {
        if (
          firstValue &&
          firstValue !== modifiedRowData[dataIndex][toBeMutateFieldName]
        ) {
          toMutate = false
          setOpenAlertDialog({
            toBeMutateFieldName,
            mergedEntityCell,
            isSequential,
            firstValue,
            type: 'merge-cell',
            open: true,
          })
          return
        }

        firstValue = modifiedRowData[dataIndex][toBeMutateFieldName]
      }
    }

    //we shall call mutate merge cell
    if (toMutate) {
      toMutateMergeCell({
        toBeMutateFieldName,
        mergedEntityCell,
        isSequential,
        firstValue,
      })
    }
  }

  /**
   * To modify the cell row span
   * @param toBeMutateFieldName
   * @param mergedEntityCell
   * @param isSequential
   * @param firstValue
   */
  const toMutateMergeCell = ({
    toBeMutateFieldName,
    mergedEntityCell,
    isSequential,
    firstValue,
  }) => {
    const rowSpanField = `${toBeMutateFieldName}RowSpan`
    const rowSpanFieldIndex = `${toBeMutateFieldName}RowSpanIndex`
    let modifiedRowData = [...serverPaginationResult.rowData[mergedEntityCell].data]

    for (let i = 0; i < isSequential.length; i++) {
      // because the cell by default are already merge together...
      // we jus check if their value is the same for the current dataIndex and previous dataIndex
      const dataIndex = isSequential[i].dataIndex
      const rowSpan = isSequential[i].rowSpan
      let newDataIndex = isSequential[i].dataIndex

      if (i !== 0) {
        // sub data
        const currentCellValue = formatCellValue(
          modifiedRowData[dataIndex][`previous_${toBeMutateFieldName}`]
        )
        const prevCellValue = formatCellValue(
          modifiedRowData[isSequential[i - 1].dataIndex][
            `previous_${toBeMutateFieldName}`
          ]
        )

        if (
          modifiedRowData[dataIndex].request_type ===
            modifiedRowData[isSequential[i - 1].dataIndex].request_type &&
          (currentCellValue.localeCompare(prevCellValue) === 0 ||
            modifiedRowData[dataIndex].request_type === 'add')
        ) {
          //same previous data can merge
          modifiedRowData[dataIndex][rowSpanField] = 0
          modifiedRowData[dataIndex][rowSpanFieldIndex] =
            modifiedRowData[isSequential[i - 1].dataIndex][rowSpanFieldIndex]
          modifiedRowData[dataIndex][toBeMutateFieldName] = firstValue
          // we add the rowSpan to the index rowspan
          modifiedRowData[
            modifiedRowData[isSequential[i - 1].dataIndex][rowSpanFieldIndex]
          ][rowSpanField]++
          // if its the same we will point it back for the subdata.
          newDataIndex = modifiedRowData[isSequential[i - 1].dataIndex][rowSpanFieldIndex]
        } else {
          // diff previous data cannot merge so we jus copy value over
          modifiedRowData[dataIndex][rowSpanField] = 1
          modifiedRowData[dataIndex][rowSpanFieldIndex] = dataIndex
          modifiedRowData[dataIndex][toBeMutateFieldName] = firstValue
        }
      } else {
        // first data
        modifiedRowData[dataIndex][rowSpanField] = 1
        modifiedRowData[dataIndex][rowSpanFieldIndex] = dataIndex
        modifiedRowData[dataIndex][toBeMutateFieldName] = firstValue
      }

      // this is for the sub data index row
      for (
        let i = dataIndex + 1;
        i < (dataIndex === 0 ? rowSpan : rowSpan + dataIndex);
        i++
      ) {
        modifiedRowData[i][rowSpanField] = 0
        modifiedRowData[i][rowSpanFieldIndex] = newDataIndex
        modifiedRowData[i][toBeMutateFieldName] = firstValue

        // add to the previous row
        modifiedRowData[newDataIndex][rowSpanField]++
      }
    }

    setServerPaginationResult((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        [mergedEntityCell]: {
          ...prevState.rowData[mergedEntityCell],
          data: modifiedRowData,
        },
      },
    }))

    setSelectedCell([])
    setOpenAlertDialog({open: false})
  }

  /**
   * To unmerge merged cell
   */
  const splitCells = () => {
    selectedCell.forEach((value) => {
      const splitRow = value.split('-')

      // entity - index - fieldname - rowspan
      const entityId = splitRow[0]
      const dataIndex = parseInt(splitRow[1])
      const fieldName = splitRow[2]
      const rowSpan = parseInt(splitRow[3])

      let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]

      for (
        let i = dataIndex;
        i < (dataIndex === 0 ? rowSpan : rowSpan + dataIndex);
        i++
      ) {
        modifiedRowData[i][`${fieldName}RowSpan`] = 1
        modifiedRowData[i][`${fieldName}RowSpanIndex`] = i
      }

      setServerPaginationResult((prevState) => ({
        ...prevState,
        rowData: {
          ...prevState.rowData,
          [entityId]: {
            ...prevState.rowData[entityId],
            data: modifiedRowData,
          },
        },
      }))
    })
    setSelectedCell([])
  }
  /**
   * To add new row to table
   */
  const addNewRow = () => {
    const entityId = toMutateEntityRow.entityId

    // we need to check if matrix and threshold is delete first
    if (serverPaginationResult.rowData[entityId].request_type === 'delete') {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Matrix and threshold is pending to be deleted - unable to add new rows',
      })
      handleTableMenuClose()

      return
    }

    const entityName = serverPaginationResult.rowData[entityId].name
    let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]
    modifiedRowData.push({
      request_type: 'add',
      approve_date_format: null,
      authorized_person: null,
      authorized_personRowSpan: 1,
      authorized_personRowSpanIndex: modifiedRowData.length,
      bank_approval_matrix_id: null,
      bank_id: null,
      bank_idRowSpan: 1,
      bank_idRowSpanIndex: modifiedRowData.length,
      bank_description: null,
      bank_descriptionRowSpan: 1,
      bank_descriptionRowSpanIndex: modifiedRowData.length,
      country_id: country,
      entity_id: entityId,
      entity_name: entityName,
      entity_nameRowSpan: 0,
      entity_nameRowSpanIndex: 0,
      header_type: null,
      is_header: 'N',
      manual_payments: null,
      manual_paymentsRowSpan: 1,
      manual_paymentsRowSpanIndex: modifiedRowData.length,
      status: null,
      system_admin: null,
      system_adminRowSpan: 1,
      system_adminRowSpanIndex: modifiedRowData.length,
    })

    setServerPaginationResult((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        [entityId]: {
          ...prevState.rowData[entityId],
          data: modifiedRowData,
        },
      },
    }))

    handleTableMenuClose()
  }

  /**
   * To delete row from table
   */
  const deleteRow = () => {
    // we get the entity ID of the row we re gonna delete
    const entityId = toMutateEntityRow.entityId

    // the entity row data that we will be modifying
    let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]
    const rowDataIndex = toMutateEntityRow.dataIndex

    // if row is already deleted, we will ignore
    if (modifiedRowData[rowDataIndex] === 'delete') {
      handleTableMenuClose()
      return
    }

    // looping all the field to mutate all their rowspan & rowspan index
    toMergeField.forEach((value) => {
      // we will ignore the below fields
      if (['entity_name', 'bank_description'].includes(value)) {
        return
      }

      //prefix the rowspan field name
      const fieldRowSpan = `${value}RowSpan`
      //prefix the rowspan index field name
      const fieldRowSpanIndex = `${value}RowSpanIndex`

      // if rowSpan === 1 && request_type !== add we can jus ignore it
      // because only for request_type === 'add' we need to splice it after so all the index will be shifted up
      if (
        modifiedRowData[rowDataIndex][fieldRowSpan] === 1 &&
        modifiedRowData[rowDataIndex].request_type !== 'add'
      ) {
        return
      }

      // below consists the code to mutate the follow field rowSpan & rowIndex
      // ---------START---------
      // we get the field rowSpanIndex first
      const rowSpanIndex = modifiedRowData[rowDataIndex][fieldRowSpanIndex]

      // we get the field rowSpan value next - this is needed to loop the relevant row
      const rowSpans = modifiedRowData[rowSpanIndex][fieldRowSpan]

      // we need to split the row span if we hit the deleted rowspan index
      let splitRowSpanIndex = rowSpanIndex

      // max row to loop to mutate
      const maxRowToLoop = rowSpans + rowSpanIndex

      for (let i = rowSpanIndex; i < maxRowToLoop; i++) {
        // if we hit the row data index to delete
        // theres 2 scenario
        // 1. request type === 'add'
        //    subsequent row rowSpanIndex needs to -1 as the current rowSpanIndex will be spliced.
        //    so the row will be shift up
        // 2. request type !== 'add'
        //    if rowSpanIndex !== rowDataIndex
        //      we can safely change the rowSpan to 1
        //      we can point the rowDataIndex back to itself
        //    if rowSpanIndex === rowDataIndex
        //      we need to push it to the next record to be the rowDataIndex
        if (i === rowDataIndex) {
          if (modifiedRowData[i].request_type === 'add') {
            if (i !== rowSpanIndex) {
              // we need to deduct the rowspan from the main index
              modifiedRowData[rowSpanIndex][fieldRowSpan]--
            }

            if (i === rowSpanIndex && modifiedRowData[i][fieldRowSpan] > 1) {
              // we need to pass the fieldRowSpan to the next row
              // the rowSpan index will be the same because it will be splice
              modifiedRowData[i + 1][fieldRowSpan] = modifiedRowData[i][fieldRowSpan] - 1
            }

            if (maxRowToLoop < modifiedRowData.length) {
              for (let x = maxRowToLoop; x < modifiedRowData.length; x++) {
                modifiedRowData[x][fieldRowSpanIndex] =
                  modifiedRowData[x][fieldRowSpanIndex] - 1
              }
            }
          } else if (rowDataIndex !== rowSpanIndex) {
            modifiedRowData[rowSpanIndex][fieldRowSpan]--

            modifiedRowData[i][fieldRowSpan] = 1
            modifiedRowData[i][fieldRowSpanIndex] = rowDataIndex

            //we set the new index to the next one..
            splitRowSpanIndex = i + 1
          }
        }

        if (i > rowDataIndex && modifiedRowData[rowDataIndex].request_type !== 'add') {
          // if record is not add we shall not split it!
          modifiedRowData[rowSpanIndex][fieldRowSpan]--

          if (splitRowSpanIndex !== rowSpanIndex) {
            modifiedRowData[splitRowSpanIndex][fieldRowSpan]++
            modifiedRowData[i][fieldRowSpanIndex] = splitRowSpanIndex
          } else {
            splitRowSpanIndex = i
            modifiedRowData[i][fieldRowSpan]++
            modifiedRowData[i][fieldRowSpanIndex] = i
          }
        }
      }
    })

    // lets remove it if type was 'add'
    if (modifiedRowData[rowDataIndex].request_type === 'add') {
      // because this is a new row so we can jus delete and pretend nothing happened.
      modifiedRowData.splice(rowDataIndex, 1)
    } else {
      modifiedRowData[rowDataIndex].request_type = 'delete'
    }

    setServerPaginationResult((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        [entityId]: {
          ...prevState.rowData[entityId],
          data: modifiedRowData,
        },
      },
    }))
    handleTableMenuClose()
  }

  /**
   * Undo row deletion from table
   */
  const undoDeleteRow = () => {
    const entityId = toMutateEntityRow.entityId

    // we need to check if matrix and threshold is delete first
    if (serverPaginationResult.rowData[entityId].request_type === 'delete') {
      setSnackBar({
        open: true,
        type: 'error',
        message:
          'Matrix and threshold is pending to be deleted - all rows will be deleted',
      })
      handleTableMenuClose()
      return
    }

    const dataIndex = toMutateEntityRow.dataIndex

    let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]
    if (
      modifiedRowData[dataIndex].request_type &&
      modifiedRowData[dataIndex].request_type === 'add'
    ) {
      handleTableMenuClose()
      return
    }

    modifiedRowData[dataIndex].request_type = 'edit'

    setServerPaginationResult((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        [entityId]: {
          ...prevState.rowData[entityId],
          data: modifiedRowData,
        },
      },
    }))
    handleTableMenuClose()
  }

  /**
   * Add new matrix and threshold for entity
   */
  const addNewMatrixAndThreshold = () => {
    // we need to do validation
    if (!toAddEntity?.entity) {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Missing Entity',
      })

      return
    }

    if (serverPaginationResult.rowData[toAddEntity.entity.entity_id]) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Matrix and Threshold Exists for ${toAddEntity.entity.entity_name} (${toAddEntity.entity.entity_code})`,
      })

      return
    }

    setServerPaginationResult((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        [toAddEntity.entity.entity_id]: {
          request_type: 'add',
          name: `${toAddEntity.entity.entity_name} (${toAddEntity.entity.entity_code})`,
          matrix: {
            bank_approval_matrix_id: null,
            authorized_person: toAddEntity.matrix?.authorized_person ?? null,
            manual_payments: toAddEntity.matrix?.manual_payments ?? null,
            system_admin: toAddEntity.matrix?.system_admin ?? null,
          },
          threshold: {
            bank_approval_matrix_id: null,
            authorized_person: toAddEntity.threshold?.authorized_person ?? null,
            manual_payments: toAddEntity.threshold?.manual_payments ?? null,
            system_admin: toAddEntity.threshold?.system_admin ?? null,
          },
          data: [
            {
              request_type: 'add',
              approve_date_format: null,
              authorized_person: null,
              authorized_personRowSpan: 1,
              authorized_personRowSpanIndex: 0,
              bank_approval_matrix_id: null,
              bank_id: null,
              bank_idRowSpan: 1,
              bank_idRowSpanIndex: 0,
              bank_description: null,
              bank_descriptionRowSpan: 1,
              bank_descriptionRowSpanIndex: 0,
              country_id: country,
              entity_id: toAddEntity.entity.entity_id,
              entity_name: `${toAddEntity.entity.entity_name} (${toAddEntity.entity.entity_code})`,
              entity_nameRowSpan: 1,
              entity_nameRowSpanIndex: 0,
              header_type: null,
              is_header: 'N',
              manual_payments: null,
              manual_paymentsRowSpan: 1,
              manual_paymentsRowSpanIndex: 0,
              status: null,
              system_admin: null,
              system_adminRowSpan: 1,
              system_adminRowSpanIndex: 0,
            },
          ],
        },
      },
    }))

    setFullScreenDialogConst({open: false, type: null})
    setToAddEntity(null)
  }

  /**
   * To toggle delete matrix and threshold alert
   */
  const handleDeleteMatrixAndThreshold = () => {
    // we need to check if matrix and threshold is delete first
    if (
      serverPaginationResult.rowData[toMutateEntityRow.entityId].request_type === 'delete'
    ) {
      handleTableMenuClose()
      return
    }

    setOpenAlertDialog({
      type: 'delete-matrix-and-threshold',
      open: true,
    })
  }

  /**
   * To delete matrix and threshold
   */
  const deleteMatrixAndThreshold = () => {
    setOpenAlertDialog({open: false})

    const entityId = toMutateEntityRow.entityId

    if (serverPaginationResult.rowData[entityId].request_type === 'add') {
      let modifiedRowData = serverPaginationResult.rowData
      const {[entityId]: removedProperty, ...rowDataRest} = modifiedRowData

      setServerPaginationResult((prevState) => ({
        ...prevState,
        rowData: rowDataRest,
      }))

      handleTableMenuClose()
      return
    }
    let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]

    // we need to loop all the rowData and modify the type to delete and remove those type = add
    serverPaginationResult.rowData[entityId].data.forEach((value, index) => {
      if (value.request_type && value.request_type === 'add') {
        modifiedRowData.splice(index, 1)
      }
      value.request_type = 'delete'
    })

    setServerPaginationResult((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        [entityId]: {
          ...prevState.rowData[entityId],
          request_type: 'delete',
          data: modifiedRowData,
        },
      },
    }))
    handleTableMenuClose()
  }

  /**
   * To undo deletion of matrix and threshold
   */
  const undoDeleteMatrixAndThreshold = () => {
    // we need to set alert to confirm if we want to delete matrix.. everything will be deleted.

    const entityId = toMutateEntityRow.entityId
    let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]

    // we need to loop all the rowData and modify the type delete and ignore type = add
    serverPaginationResult.rowData[entityId].data.forEach((value) => {
      value.request_type = value.request_type === 'add' ? 'add' : ''
    })

    setServerPaginationResult((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        [entityId]: {
          ...prevState.rowData[entityId],
          request_type:
            serverPaginationResult.rowData[entityId].request_type === 'add' ? 'add' : '',
          data: modifiedRowData,
        },
      },
    }))
    handleTableMenuClose()
  }

  /**
   * To render full screen dialog base on type
   * @return {JSX.Element}
   */
  const renderFullScreenDialogContent = () => {
    return (
      <>
        <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
              Entity
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              options={entities}
              getOptionLabel={(option) =>
                `${option.entity_name} (${option.entity_code})` || ''
              }
              handleOnChange={(value) =>
                setToAddEntity((prevState) => ({...prevState, entity: value}))
              }
              value={toAddEntity?.entity}
              label={'Select Entity'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
              Matrix
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={'Authorised Person'}
              value={toAddEntity?.matrix?.authorized_person}
              onChange={(event) => {
                setToAddEntity((prevState) => ({
                  ...prevState,
                  matrix: {
                    ...prevState.matrix,
                    authorized_person: event.target.value,
                  },
                }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={'Manual Payments'}
              value={toAddEntity?.matrix?.manual_payments}
              onChange={(event) => {
                setToAddEntity((prevState) => ({
                  ...prevState,
                  matrix: {
                    ...prevState.matrix,
                    manual_payments: event.target.value,
                  },
                }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={'System Admin'}
              value={toAddEntity?.matrix?.system_admin}
              onChange={(event) => {
                setToAddEntity((prevState) => ({
                  ...prevState,
                  matrix: {
                    ...prevState.matrix,
                    system_admin: event.target.value,
                  },
                }))
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
              Threshold
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={'Authorised Person'}
              value={toAddEntity?.threshold?.authorized_person}
              onChange={(event) => {
                setToAddEntity((prevState) => ({
                  ...prevState,
                  threshold: {
                    ...prevState.threshold,
                    authorized_person: event.target.value,
                  },
                }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={'Manual Payments'}
              value={toAddEntity?.threshold?.manual_payments}
              onChange={(event) => {
                setToAddEntity((prevState) => ({
                  ...prevState,
                  threshold: {
                    ...prevState.threshold,
                    manual_payments: event.target.value,
                  },
                }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={'System Admin'}
              value={toAddEntity?.threshold?.system_admin}
              onChange={(event) => {
                setToAddEntity((prevState) => ({
                  ...prevState,
                  threshold: {
                    ...prevState.threshold,
                    system_admin: event.target.value,
                  },
                }))
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
      </>
    )
  }

  /**
   * To upload file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleFileUpload = useMutation(
    (file) =>
      uploadRequestAttachment({
        requestId,
        attachmentType: 'bank_approval_matrix',
        attachment: file,
        user: user.email,
      }),
    {
      onSuccess: (result) => {
        setSnackBar((prevState) => ({
          ...prevState,
          open: true,
          type: 'success',
          message: 'Attachment uploaded successfully!',
        }))
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        queryClient.invalidateQueries(`request-${requestId}-attachments`)
        setIsFileUploading(false)
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To delete file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDeleteFile = useMutation(({id, index}) => deleteAttachment({id}), {
    onSuccess: () => {
      setSnackBar({
        open: true,
        type: 'success',
        message: 'Attachment deleted successfully',
      })
    },
    onError: (err, variables, context) => {
      setSnackBar((prevState) => ({...prevState, open: true}))
    },
    onSettled: () => {
      queryClient.invalidateQueries(`request-${requestId}-attachments`)
      setIsCallingApi({
        loading: false,
        type: null,
      })
    },
  })

  /**
   * To download file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDownloadAttachment = useMutation(({id}) => downloadAttachment({id}), {
    onError: (err) => {
      console.log(err)
      setSnackBar({
        open: true,
      })
    },
    onSettled: () => {
      setIsCallingApi({
        loading: false,
        type: null,
      })
    },
  })

  /**
   * To handle file preview
   * @type {UseMutationResult<unknown, unknown, void, unknown>}
   */
  const handlePreviewFile = useMutation(({id, name}) => previewAttachment({id}), {
    onSuccess: (result, {name}) => {
      setFilePreviewDialog({
        value: result,
        name: name,
        open: true,
      })
    },
  })

  /**
   * To validate call value before submitting
   */
  const validateCell = () => {
    if (serverPaginationResult.rowData.length === 0) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Unable to submit empty request!`,
      })
      setIsCallingApi({
        loading: false,
        type: null,
      })
      return
    }

    let isRequestModified = false
    // 1. to loop entity
    for (const [key, value] of Object.entries(serverPaginationResult.rowData)) {
      // 2. to loop data
      let cellIndex = 0
      for (const details of value.data) {
        cellIndex++
        // 3. to check for bank
        if (!details.bank_id && details.type !== 'delete') {
          setSnackBar({
            open: true,
            type: 'error',
            message: `Missing Bank value at Row ${cellIndex} for Entity: ${details.entity_name}!`,
          })
          setIsCallingApi({
            loading: false,
            type: null,
          })
          return
        }

        if (!isRequestModified) {
          for (const fieldName of toMergeField) {
            // lets check for
            // 1. previous & current value
            // 2. request type
            const currentCellValue =
              typeof formatCellValue(details[fieldName]) !== 'string'
                ? formatCellValue(details[fieldName]).toString()
                : formatCellValue(details[fieldName])
            const prevCellValue =
              typeof formatCellValue(details[`previous_${fieldName}`]) !== 'string'
                ? formatCellValue(details[`previous_${fieldName}`]).toString()
                : formatCellValue(details[`previous_${fieldName}`])

            if (
              prevCellValue.localeCompare(currentCellValue) !== 0 ||
              ['add', 'delete'].includes(details.request_type)
            ) {
              isRequestModified = true
              break
            }
          }
        }
      }

      // check for header
      if (!isRequestModified) {
        for (const fieldName of toMergeField) {
          // lets check for
          // 1. previous & current value
          const currentMatrixCellValue = formatCellValue(value.matrix[fieldName])
          const prevMatrixCellValue = formatCellValue(
            value.matrix[`previous_${fieldName}`]
          )
          const currentThresholdCellValue = formatCellValue(value.threshold[fieldName])
          const prevThresholdCellValue = formatCellValue(
            value.threshold[`previous_${fieldName}`]
          )

          if (
            currentMatrixCellValue.localeCompare(prevMatrixCellValue) !== 0 ||
            currentThresholdCellValue.localeCompare(prevThresholdCellValue) !== 0
          ) {
            isRequestModified = true
            break
          }
        }
      }
    }

    setSelectedCell([])
    if (requestId && !isRequestModified) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Unable to submit form, no changes detected!`,
      })
      setIsCallingApi({
        loading: false,
        type: null,
      })
      return
    }

    requestId ? handleOnSubmit.mutate() : handleOnSave.mutate()
  }

  /**
   * To render submit/ approve/ verify button based on current order
   * @return {JSX.Element|string}
   */
  const renderActionButton = () => {
    if (!editable) {
      return
    }
    // currentOrder = undefined || 0 means its draft user is able to save/ submit
    switch (currentOrder) {
      case 1: // verify
        return generalDetails.requestedBy !== user.email ? (
          <>
            <Button label={'Verify'} onClick={() => handleOnSubmit.mutate()} />
            <Button
              label={'Reject'}
              disabled={isCallingApi.loading}
              loading={isCallingApi.type === 'reject'}
              type={'danger'}
              onClick={() => {
                setIsCallingApi({
                  loading: true,
                  type: 'reject',
                })
                setActionDialog((prevState) => ({
                  ...prevState,
                  type: 'Reject',
                  value: null,
                  open: true,
                }))
              }}
            />
          </>
        ) : (
          <></>
        )
      case 2: // approve
        return generalDetails.requestedBy !== user.email ? (
          <>
            <Button
              label={'Approve'}
              disabled={isCallingApi.loading}
              loading={isCallingApi.type === 'approve'}
              onClick={() => {
                setIsCallingApi({
                  loading: true,
                  type: 'approve',
                })
                handleOnSubmit.mutate()
              }}
            />
            <Button
              label={'Reject'}
              disabled={isCallingApi.loading}
              loading={isCallingApi.type === 'reject'}
              type={'danger'}
              onClick={() => {
                setIsCallingApi({
                  loading: true,
                  type: 'reject',
                })
                setActionDialog((prevState) => ({
                  ...prevState,
                  type: 'Reject',
                  value: null,
                  open: true,
                }))
              }}
            />
          </>
        ) : (
          <></>
        )
      case 3: // apply changes
      case 99: // completed
      case 100: // rejected
        return ''
      default:
        // undefined & 0
        return (
          <>
            {requestId && (
              <Button
                label={'Save'}
                disabled={isCallingApi.loading}
                onClick={() => {
                  setIsCallingApi({
                    loading: true,
                    type: 'save',
                  })
                  handleOnSave.mutate()
                }}
                loading={isCallingApi.type === 'save'}
              />
            )}

            <Button
              label={`${requestId ? 'Submit' : 'Create'}`}
              disabled={isCallingApi.loading}
              onClick={() => {
                setIsCallingApi({
                  loading: true,
                  type: 'submit',
                })
                validateCell()
              }}
              loading={isCallingApi.type === 'submit'}
            />
          </>
        )
    }
  }

  /**
   * To render comments
   * @return {string|*}
   */
  const renderComments = () => {
    if (!requestId) {
      return
    }

    if (comments.length === 0) {
      return 'No comment'
    }

    return comments.map(({full_name, comment_date_format, comments, status}, key) => {
      const htmlComment = parse(comments)
      return (
        <>
          <Stack direction={'column'} spacing={0} key={key}>
            <Stack direction={'row'} spacing={1} alignItems={'start'}>
              <StringAvatar label={full_name} style={{width: 35, height: 35}} />
              <Stack direction={'column'}>
                <Typography align={'left'} sx={{fontWeight: '600'}} variant={'body2'}>
                  {full_name}
                </Typography>
                <Stack direction={'row'}>
                  <Typography align={'left'} variant={'caption'}>
                    {comment_date_format}{' '}
                  </Typography>

                  {status && (
                    <Typography align={'left'} variant={'caption'} color={'secondary'}>
                      · {status}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Typography
              className={'comment-wrap'}
              align={'left'}
              variant={'body2'}
              gutterBottom
            >
              {htmlComment}
            </Typography>
          </Stack>
        </>
      )
    })
  }

  /**
   * To render menu item
   * @param type
   * @return {JSX.Element}
   */
  const renderMenu = (type) => {
    switch (type) {
      case 'table-menu':
        return [
          {onClick: addNewRow, title: 'Insert Row'},
          {onClick: deleteRow, title: 'Delete Row'},
          {onClick: undoDeleteRow, title: 'Undo Delete Row'},
        ].map(({onClick, title}, index) => {
          return (
            <MenuItem key={`${index}-table-menu`} onClick={onClick}>
              {title}
            </MenuItem>
          )
        })

      case 'matrix-threshold-menu':
        return [
          {onClick: addNewRow, title: 'Insert Row'},
          {
            onClick: handleDeleteMatrixAndThreshold,
            title: 'Delete Matrix and Threshold',
          },
          {
            onClick: undoDeleteMatrixAndThreshold,
            title: 'Undo Delete Matrix and Threshold',
          },
        ].map(({onClick, title}, index) => {
          return (
            <MenuItem key={`${index}-matrix-menu`} onClick={onClick}>
              {title}
            </MenuItem>
          )
        })
      default:
        break
    }
  }

  if (
    isSummaryLoading ||
    isEntitiesLoading ||
    isBanksLoading ||
    isCommentsLoading ||
    isAttachmentsLoading
  ) {
    return <Loading open={true} />
  }

  return (
    <>
      <FileDeleteDialog
        open={openDeleteFileDialog.open}
        onClose={() => setOpenDeleteFileDialog({open: false, index: null})}
        onSubmit={() => {
          handleDeleteFile.mutate({
            index: openDeleteFileDialog.index,
            id: attachments[openDeleteFileDialog.index].attachment_id,
          })
          setOpenDeleteFileDialog({open: false, index: null, func: null})
        }}
      />
      <FilePreviewDialog
        open={filePreviewDialog.open}
        value={{preview: filePreviewDialog.value, name: filePreviewDialog.name}}
        onClose={() =>
          setFilePreviewDialog({
            value: null,
            open: false,
          })
        }
      />
      {actionDialog.open && actionDialog.type === 'Withdraw' && (
        <Withdraw
          value={actionDialog.value}
          onChange={(value) =>
            setActionDialog((prevState) => ({
              ...prevState,
              value: value,
            }))
          }
          onSubmit={() => {
            if (!actionDialog.value) {
              setSnackBar({
                open: true,
                type: 'error',
                message: `Missing reason to withdraw`,
              })

              setActionDialog((prevState) => ({
                ...prevState,
                isDisabled: false,
                isLoading: false,
              }))
              return
            }
            handleWithdrawTicket.mutate()
          }}
          onClose={() => {
            setIsCallingApi({
              loading: false,
              type: null,
            })
            setActionDialog((prevState) => ({
              type: null,
              value: null,
              open: false,
              isDisabled: false,
              isLoading: false,
            }))
          }}
          isDisabled={actionDialog.isDisabled}
          isLoading={actionDialog.isLoading}
        />
      )}
      {actionDialog.open && actionDialog.type === 'Reject' && (
        <Reject
          value={actionDialog.value}
          onChange={setActionDialog}
          onSubmit={() => {
            setActionDialog((prevState) => ({
              ...prevState,
              isDisabled: true,
              isLoading: true,
            }))
            if (!actionDialog.value) {
              setSnackBar({
                open: true,
                type: 'error',
                message: `Missing reason to reject`,
              })

              setActionDialog((prevState) => ({
                ...prevState,
                isDisabled: false,
                isLoading: false,
              }))
              return
            }
            handleRejectTicket.mutate()
          }}
          onClose={() => {
            setIsCallingApi({
              loading: false,
              type: null,
            })
            setActionDialog((prevState) => ({
              type: null,
              value: null,
              open: false,
              isDisabled: false,
              isLoading: false,
            }))
          }}
          isDisabled={actionDialog.isDisabled}
          isLoading={actionDialog.isLoading}
        />
      )}
      {openAlertDialog.open && (
        <AlertDialog
          type={'warning'}
          disableBackdropClick
          title={'Confirmation'}
          content={
            openAlertDialog.type === 'merge-cell'
              ? `Merging cells only keeps the first value and discards other values.`
              : `Deleting Matrix and Threshold will delete all the other rows linked to it, are you sure? `
          }
          buttons={[
            {
              label: 'Cancel',
              onClick: () => {
                if (openAlertDialog.type === 'delete-matrix-and-threshold') {
                  handleTableMenuClose()
                }
                setOpenAlertDialog({open: false})
              },
              type: 'text',
            },
            {
              label: 'Proceed',
              onClick: () =>
                openAlertDialog.type === 'merge-cell'
                  ? toMutateMergeCell({
                      toBeMutateFieldName: openAlertDialog.toBeMutateFieldName,
                      mergedEntityCell: openAlertDialog.mergedEntityCell,
                      isSequential: openAlertDialog.isSequential,
                      firstValue: openAlertDialog.firstValue,
                    })
                  : deleteMatrixAndThreshold(),
            },
          ]}
        />
      )}
      <Menu
        id="table-menu"
        anchorEl={menuObject?.event}
        keepMounted
        open={Boolean(menuObject)}
        onClose={handleTableMenuClose}
      >
        {renderMenu(menuObject?.type)}
      </Menu>
      <FullScreenDialog
        title={`Add New Matrix and Threshold for Entity - ${country.toUpperCase()}`}
        open={fullScreenDialogConst.open && fullScreenDialogConst.type === 'entity'}
        handleClose={() => {
          setFullScreenDialogConst({open: false, type: null})
          setToAddEntity(null)
        }}
        footer={
          <Stack spacing={2} direction={'row'} sx={{mt: 2}}>
            <Button
              label={'Cancel'}
              type={'text'}
              onClick={() => {
                setFullScreenDialogConst({open: false, type: null})
                setToAddEntity(null)
              }}
            />
            <Button label={'Add'} onClick={addNewMatrixAndThreshold} />
          </Stack>
        }
      >
        {renderFullScreenDialogContent()}
      </FullScreenDialog>
      <FileUpload
        formDetails={attachments}
        open={fullScreenDialogConst.open && fullScreenDialogConst.type !== 'entity'}
        isCallingApi={isCallingApi}
        setIsCallingApi={setIsCallingApi}
        handleClose={() => {
          setFullScreenDialogConst({open: false, type: null})
        }}
        columns={[
          {
            name: 'File Name',
            fieldName: 'attachment_filename',
          },
          {
            name: 'Upload By',
            fieldName: 'last_updated_by',
          },
          {
            name: 'Date',
            fieldName: 'creation_date_format',
          },
        ]}
        disabled={!(currentOrder === 0 && editable)}
        setIsFileUploading={setIsFileUploading}
        isFileUploading={isFileUploading}
        handleFileDownload={(index) => {
          const file = attachments[index]
          handleDownloadAttachment.mutate({
            id: file.attachment_id,
          })
        }}
        handleFileUpload={(file) => handleFileUpload.mutate(file)}
        handleFileDelete={(index) => {
          setOpenDeleteFileDialog({
            open: true,
            index: index,
          })
        }}
        handleFilePreview={(index) => {
          const file = attachments[index]
          handlePreviewFile.mutate({
            id: file.attachment_id,
            name: file.attachment_filename,
          })
        }}
        hideDeleteButton={editable && currentOrder === 0}
        hideDeleteButtonValue={editable && currentOrder === 0 ? user.email : ''}
        hideDeleteButtonField={'created_by'}
      />
      <DetailedLayout
        mainContent={
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{pr: '17px'}}
              >
                <Stack direction={'row'} spacing={1}>
                  {![
                    STATUS_COMPLETED,
                    STATUS_WITHDRAWN,
                    STATUS_REJECTED,
                    STATUS_VERIFIED,
                  ].includes(requestStatus) &&
                    requestId &&
                    generalDetails.requestedBy === user.email && (
                      <Button
                        label={'Withdraw'}
                        disabled={isCallingApi.loading}
                        loading={isCallingApi.type === 'withdraw'}
                        type={'danger'}
                        onClick={() => {
                          setIsCallingApi({
                            loading: true,
                            type: 'withdraw',
                          })
                          setActionDialog((prevState) => ({
                            ...prevState,
                            type: 'Withdraw',
                            value: null,
                            open: true,
                          }))
                        }}
                      />
                    )}
                  {renderActionButton()}
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  {editable && currentOrder === 0 && (
                    <Stack direction={'row'} spacing={0}>
                      <IconButton title="Merge Cell" handleOnClick={() => mergeCells()}>
                        <img
                          src={MergeIcon}
                          alt="Merge"
                          width={18}
                          height={18}
                          style={{margin: '3px'}}
                        />
                      </IconButton>
                      <IconButton title="Split Cell" handleOnClick={() => splitCells()}>
                        <img
                          src={SplitIcon}
                          alt="Merge"
                          width={18}
                          height={18}
                          style={{margin: '3px'}}
                        />
                      </IconButton>
                      <Divider orientation="vertical" flexItem />
                      <IconButton
                        title="Add Matrix & Threshold"
                        handleOnClick={() =>
                          setFullScreenDialogConst({open: true, type: 'entity'})
                        }
                      >
                        <AddBox />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BAMTable
                type={'edit'}
                selectedCell={selectedCell}
                setSelectedCell={setSelectedCell}
                toEditCell={toEditCell}
                setToEditCell={setToEditCell}
                editableHeaderColumns={editableHeaderColumns}
                columns={columns}
                serverPaginationResults={serverPaginationResult}
                country={country}
                editable={editable && currentOrder === 0}
                addMatrix={() => setFullScreenDialogConst({open: true, type: 'entity'})}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                bottom: 0,
                pb: 1,
                display: 'flex',
                position: 'absolute',
              }}
            >
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'body2'} sx={{fontWeight: 600}} align={'left'}>
                  Legend:
                </Typography>
                <Stack direction={'row'} spacing={0} alignItems={'center'}>
                  <StopIcon fontSize={'medium'} style={{color: '#ff7171'}} />
                  <Typography variant={'caption'}>Delete Row</Typography>
                </Stack>
                <Stack direction={'row'} spacing={0} alignItems={'center'}>
                  <StopIcon fontSize={'medium'} style={{color: '#6ee1a8'}} />
                  <Typography variant={'caption'}>Add Row</Typography>
                </Stack>
                <Stack direction={'row'} spacing={0} alignItems={'center'}>
                  <StopIcon fontSize={'medium'} style={{color: '#ffff70'}} />
                  <Typography variant={'caption'}>
                    Edit Cell - Hover to view previous value
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        }
        sideDrawerContent={
          <Grid container direction={'row'} rowSpacing={2}>
            {requestId && (
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={2}
                >
                  <Button
                    type={'text'}
                    label={'Supporting Docs'}
                    onClick={() =>
                      setFullScreenDialogConst({open: true, type: 'supportingDoc'})
                    }
                  />
                  {getUserRole().role === HQ_ROLE_ID &&
                    ![99, 100, 101].includes(currentOrder) && (
                      <>
                        <IconButton
                          title={'HQ Settings'}
                          handleOnClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="table-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null)
                              setIsCallingApi({
                                loading: true,
                                type: 'withdraw',
                              })
                              setActionDialog((prevState) => ({
                                ...prevState,
                                type: 'Withdraw',
                                value: null,
                                open: true,
                              }))
                            }}
                          >
                            Override and Withdraw Ticket
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
                General
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField label={'Request ID'} value={requestId} disabled={true} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={'Status'}
                InputProps={{
                  startAdornment: StatusChip(requestStatus),
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={'Requested By'}
                value={generalDetails.requestedBy}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={'Last updated'}
                value={generalDetails.lastUpdatedOn}
                disabled={true}
              />
            </Grid>

            {requestId && (
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
                    Comment
                  </Typography>
                  <Typography variant="caption" align={'left'} sx={{color: '#6B6B6B'}}>
                    Input comment below, hover text for styling
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextBox
                    style={{
                      border: '1px solid #c7c7c7',
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                    label={'Comments'}
                    customEditor={Editor}
                    value={commentField}
                    handleOnChange={(value) => setCommentField(value)}
                    placeholder={'Enter comment here'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack direction={'row'} spacing={2} sx={{mt: 1, mb: 3}}>
                    <Button
                      label={'Post'}
                      onClick={() => addNewCommentToRequest.mutate(commentField)}
                      disabled={!commentField}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {renderComments()}
                </Grid>
              </>
            )}
          </Grid>
        }
        collapseSideDrawer={[
          {
            icon: BookOnline,
            iconStyle: {transform: 'rotate(45deg)'},
            label: 'Request ID:',
            value: requestId,
          },
          {
            icon: ApprovalIcon,
            label: 'Status:',
            value: StatusChip(requestStatus),
          },
          {
            icon: Face,
            label: 'Requested By:',
            value: generalDetails.requestedBy,
          },
          {
            icon: AccessTime,
            label: 'Last Updated On:',
            value: generalDetails.lastUpdatedOn
              ? moment.utc(generalDetails.lastUpdatedOn).local().format(dateTimeFormat)
              : '',
          },
          {
            icon: Comment,
            label: 'Comment Count:',
            value: comments.length,
          },
        ]}
      />
    </>
  )
}

export default Edit
