import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Stack, Typography} from '@mui/material'
import {useMutation} from 'react-query'
import {Accordion, Button, FileDeleteDialog, FilePreviewDialog} from 'finsys-webcomponent'

import {useSnackBar} from 'hoc/SnackbarHandler'
import BankAccountOpening, {
  getBankAccountOpeningFormFields,
  validateBankAccountOpening,
} from 'components/InternalRequest/Forms/BankAccountOpening'
import BankAccountClosing, {
  getBankAccountClosingFormFields,
  validateBankAccountClosing,
} from 'components/InternalRequest/Forms/BankAccountClosing'
import BankAccountMaintenance, {
  getBankAccountMaintenanceFormFields,
  validateBankAccountMaintenance,
} from 'components/InternalRequest/Forms/BankAccountMaintanence'
import BankGuarantee, {
  getBankGuaranteeFormFields,
  validateBankGuarantee,
} from 'components/InternalRequest/Forms/BankGuarantee'
import DocumentSigningRequest, {
  getDocumentSigningFormFields,
  validateDocumentSigning,
} from 'components/InternalRequest/Forms/DocumentSigningRequest'
import useDataFetching from 'hoc/UseDataFetching'
import {
  fetchNewRequestCategories,
  fetchRequestForm,
  saveInternalRequest,
  submitInternalRequest,
} from 'api/TS/query/InternalRequestQueries'
import type {FetchRequestFormType} from 'api/TS/query/InternalRequestQueries'
import {deleteAttachment} from 'api/TS/query/AttachmentQueries'
import {useAuth} from 'hoc/AuthContext'
import {
  FORM_BANK_ACCOUNT_OPENING,
  FORM_BANK_ACCOUNT_CLOSING,
  FORM_BANK_ACCOUNT_MAINTENANCE,
  FORM_BANK_GUARANTEE,
  FORM_DOCUMENT_SIGNING_REQUEST,
  INTERNAL_REQUEST_PAGE_NAME,
} from '_helper/_constants'
import {
  Information,
  validateInformation,
} from 'components/InternalRequest/Forms/Information'
import {Details, validateDetails} from 'components/InternalRequest/Forms/Details'
import {Copy} from 'components/ActionButtonDialog/Copy'
import {OBMUserManagement} from 'components/ActionButtonDialog/OBMUserManagement'
import {useFetchUserOBMAccess} from 'components/_Utils/UseFetchFunctions'
import {retrievePageFunctionAccess} from '_helper/_authHelper'

export function removeDuplicateUser(deleteUser) {
  const uniqueUser = deleteUser.reduce((accumulator, current) => {
    if (
      !accumulator.find(
        (item) =>
          item.company_designation === current.company_designation &&
          item.email === current.email
      )
    ) {
      accumulator.push(current)
    }
    return accumulator
  }, [])

  return uniqueUser
}

export function removeDuplicateBank(deleteUserFromBankDetails) {
  const uniqueBank = deleteUserFromBankDetails.reduce((accumulator, current) => {
    if (
      !accumulator.find(
        (item) =>
          item.acc_managed_by === current.acc_managed_by &&
          item.account_number === current.account_number &&
          item.account_type === current.account_type &&
          item.bank_id?.value === current.bank_id?.value &&
          item.entity_id === current.entity_id &&
          item.currency_code === current.currency_code &&
          item.role === current.role
      )
    ) {
      accumulator.push(current)
    }
    return accumulator
  }, [])

  return uniqueBank
}

/**
 * To retrieve request form
 * @param id
 * @return {{isError: false | true, data: FetchRequestFormType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
function useRetrieveTicket(id): FetchRequestFormType {
  return useDataFetching(`ticket-${id}`, () => fetchRequestForm(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
  })
}

/**
 * To retrieve category
 * @return {{isError: false | true, data: CategoriesType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
export function useFetchCategories<TData = CategoriesType[]>() {
  return useDataFetching(`categories`, () => fetchNewRequestCategories())
}

function Create() {
  const {setSnackBar} = useSnackBar()
  const navigate = useNavigate()
  const {user} = useAuth()
  const {email: userEmail} = user

  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState({
    open: false,
    index: null,
  })
  const [filePreviewDialog, setFilePreviewDialog] = useState({
    open: false,
    value: null,
  })
  const [isCallingApi, setIsCallingApi] = useState({
    loading: false,
    type: null,
  })
  const [actionDialog, setActionDialog] = useState({
    type: null,
    value: null,
    open: false,
    isDisabled: false,
    isLoading: false,
  })
  const [formDetails, setFormDetails] = useState({
    request_by: '',
    current_order: null,
    ticket_id: null,
    entity_id: [],
    entity_code: '',
    country_id: [],
    category_type: null,
    sub_category_1: null,
    submission_date_format: '',
    last_update_date_format: '',
    ticket_number: '',
    ticket_status: '',
    request_by_fullname: '',
    request_summary: '',
    local_notify: [],
    local_approval: [],
    hq_approval: [],
    hq_verifier_1_fullname: '',
    hq_verifier_1_approve_date: '',
    hq_verifier_2: '',
    hq_verifier_2_approve_date: '',
    hq_final_approver: '',
    hq_final_approver_approve_date: '',
    reject_by: null,
    ticket_attachment: [],
    ticket_attachment_count: 0,
    form_details: [],
  })
  const [functionAccess, setFunctionAccess] = useState([])
  const [ticketToCopy, setTicketToCopy] = useState(null)

  const {loading: isRequestLoading, data: internalRequestData} = useRetrieveTicket(
    ticketToCopy?.ticket?.ticket_id
  )

  const {loading: isUserOBMAccessLoading, data: internalRequestOBMData} =
    useFetchUserOBMAccess(ticketToCopy?.emails)

  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(INTERNAL_REQUEST_PAGE_NAME, setFunctionAccess)
    }

    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  useEffect(() => {
    setFormDetails((prevState) => {
      const categoryType = prevState.category_type?.replaceAll(' ', '')
      const entityId = prevState.entity_id.map(({entity_id}) => entity_id)
      let mutatedFormDetails = prevState.form_details

      //Bank Accoount Closing
      if (categoryType === FORM_BANK_ACCOUNT_CLOSING) {
        // we need to remove relevant bank details as well
        mutatedFormDetails = mutatedFormDetails.filter((value) => {
          return entityId.includes(value.entity_id)
        })
      }
      //Bank Account Maintenance
      if (categoryType === FORM_BANK_ACCOUNT_MAINTENANCE) {
        // we need to remove relevant bank details as well
        mutatedFormDetails = mutatedFormDetails.map((value) => {
          //to remove irrelevant banks
          let bank_details = value.bank_details.filter(({entity_id}) => {
            return entityId.includes(entity_id)
          })

          return {
            ...value,
            bank_details: bank_details,
          }
        })
      }

      return {
        ...prevState,
        form_details: mutatedFormDetails,
      }
    })
  }, [formDetails.entity_id])

  useEffect(() => {
    if (!ticketToCopy) {
      return
    }

    setActionDialog({
      type: null,
      value: null,
      open: false,
      isDisabled: false,
      isLoading: false,
    })
  }, [ticketToCopy])

  useEffect(() => {
    if (
      !internalRequestData ||
      !['Operation Details', 'All Fields'].includes(ticketToCopy?.type)
    ) {
      return
    }

    let formsData = null

    if (ticketToCopy.type === 'Operation Details') {
      let mutatedInternalRequestData = {...internalRequestData}
      mutatedInternalRequestData.acc_opening_form_details = formDetails.form_details

      formsData = getBankAccountOpeningFormFields(mutatedInternalRequestData)

      setFormDetails((prevStats) => ({
        ...prevStats,
        category_type: mutatedInternalRequestData.category_type,
        ...formsData,
      }))
      return
    }

    switch (internalRequestData.category_type?.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        formsData = getBankAccountOpeningFormFields(internalRequestData)
        break

      case FORM_BANK_ACCOUNT_CLOSING:
        formsData = getBankAccountClosingFormFields(internalRequestData)
        break

      case FORM_BANK_ACCOUNT_MAINTENANCE:
        formsData = getBankAccountMaintenanceFormFields(internalRequestData)
        break

      case FORM_BANK_GUARANTEE:
        formsData = getBankGuaranteeFormFields(internalRequestData)
        break

      case FORM_DOCUMENT_SIGNING_REQUEST:
        formsData = getDocumentSigningFormFields(internalRequestData)
        break

      default:
        break
    }

    setFormDetails((prevStats) => ({
      ...prevStats,
      entity_id: internalRequestData.entity ?? [],
      country_id: internalRequestData.country ?? [],
      category_type: internalRequestData.category_type,
      sub_category_1: internalRequestData.sub_category_1,
      request_summary: internalRequestData.request_summary,
      ...(prevStats.error_entity_id && {
        error_entity_id: null,
      }),
      ...(prevStats.error_country_id && {
        error_country_id: null,
      }),
      ...(prevStats.error_category_type && {
        error_category_type: null,
      }),
      ...(prevStats.error_sub_category_1 && {
        error_sub_category_1: null,
      }),
      ...formsData,
    }))

    setTicketToCopy(null)
  }, [internalRequestData, ticketToCopy?.type])

  useEffect(() => {
    if (!internalRequestOBMData) {
      return
    }

    let formsData = null
    let creationForm = []
    let deletionForm = []

    switch (ticketToCopy.access_type) {
      case 'Replace User':
      case 'Mirror User':
      case 'Remove User':
        // this two variable will be universal
        let deleteUserFromBankDetails = []
        let deleteUser = []

        // we need to loop the data form first
        ticketToCopy.data.map((value) => {
          let internalRequestOBMDataIndex = internalRequestOBMData.users
            .map((e) => e.email)
            .indexOf(value.selected_user.email)

          let bankDetails = []
          let empDetails = []

          internalRequestOBMData.users[internalRequestOBMDataIndex].banks.forEach(
            (bankValue) => {
              let bankObj = {
                role: bankValue.approve_type,
                group: bankValue.group ?? 'N/A',
                bank_id: {
                  code: bankValue.bank_code,
                  text: bankValue.bank_desc,
                  value: bankValue.bank_id,
                },
                entity_id: bankValue.entity_id,
                entity_code: bankValue.entity_code,
                currency_code: bankValue.currency,
                account_number: bankValue.bank_account_number,
                acc_managed_by: bankValue.acc_managed_by,
                account_type: bankValue.account_type,
              }
              bankDetails.push(bankObj)

              //we will handle deletion here
              deleteUserFromBankDetails.push(bankObj)

              let deleteUserObj =
                internalRequestOBMData.users[internalRequestOBMDataIndex]
              deleteUser.push({
                company_designation: bankValue.designation,
                reason: 'Leaver',
                fullname: {
                  email: deleteUserObj.email,
                  full_name: deleteUserObj.fullname,
                },
                email: deleteUserObj.email,
                is_new: false,
                entity_id: bankValue.entity_id ?? null,
              })
            }
          )
          value.add_user
            .filter(({email}) => !!email)
            .forEach((userValue) => {
              empDetails.push({
                company_designation: null,
                reason: 'New Joiner',
                fullname: {
                  email: userValue.email,
                  full_name: userValue.full_name,
                },
                email: userValue.email,
                is_new: userValue?.is_new,
                entity_id: null,
              })
            })

          if (['Replace User', 'Mirror User'].includes(ticketToCopy.access_type)) {
            const uniqueUser = removeDuplicateUser(empDetails)
            const uniqueBank = removeDuplicateBank(bankDetails)

            creationForm.push({
              bank_details: uniqueBank,
              emp_details: uniqueUser,
              type_of_change: 'Creation',
              reason_for_change: '',
              new_system_admin_setup: null,
              role: null,
              reason: null,
              emp_name: null,
              designation: null,
              group: null,
              bank_id: null,
              currency_code: null,
              account_number: null,
              acc_managed_by: null,
              matrix_condition: [],
              approved_person: null,
              manual_payment_approvers_limit: null,
            })
          }
        })

        if (['Replace User', 'Remove User'].includes(ticketToCopy.access_type)) {
          const uniqueUser = removeDuplicateUser(deleteUser)
          const uniqueBank = removeDuplicateBank(deleteUserFromBankDetails)

          // we need 1 delete form
          deletionForm.push({
            bank_details: uniqueBank,
            emp_details: uniqueUser,
            type_of_change: 'Deletion',
            reason_for_change: '',
            new_system_admin_setup: null,
            role: null,
            reason: null,
            emp_name: null,
            designation: null,
            group: null,
            bank_id: null,
            currency_code: null,
            account_number: null,
            acc_managed_by: null,
            matrix_condition: [],
            approved_person: null,
            manual_payment_approvers_limit: null,
          })
        }

        break
      case 'Update Role':
        let updateUser = []

        ticketToCopy.data.map((value) => {
          let internalRequestOBMDataIndex = internalRequestOBMData.users
            .map((e) => e.email)
            .indexOf(value.selected_user.email)

          let bankDetails = []

          internalRequestOBMData.users[internalRequestOBMDataIndex].banks.forEach(
            (bankValue) => {
              let bankObj = {
                role: bankValue.approve_type,
                group: bankValue.group ?? 'N/A',
                bank_id: {
                  code: bankValue.bank_code,
                  text: bankValue.bank_desc,
                  value: bankValue.bank_id,
                },
                entity_id: bankValue.entity_id,
                entity_code: bankValue.entity_code,
                currency_code: bankValue.currency,
                account_number: bankValue.bank_account_number,
                acc_managed_by: bankValue.acc_managed_by,
                account_type: bankValue.account_type,
              }
              bankDetails.push(bankObj)

              let updatedUserObj =
                internalRequestOBMData.users[internalRequestOBMDataIndex]
              updateUser.push({
                company_designation: bankValue.designation,
                reason: 'Change of role',
                fullname: {
                  email: updatedUserObj.email,
                  full_name: updatedUserObj.fullname,
                },
                email: updatedUserObj.email,
                is_new: false,
                entity_id: bankValue.entity_id ?? null,
              })
            }
          )

          const uniqueUpdateUser = updateUser.reduce((accumulator, current) => {
            if (
              !accumulator.find(
                (item) =>
                  item.company_designation === current.company_designation &&
                  item.email === current.email
              )
            ) {
              accumulator.push(current)
            }
            return accumulator
          }, [])

          const uniqueUser = removeDuplicateUser(uniqueUpdateUser)
          const uniqueBank = removeDuplicateBank(bankDetails)

          creationForm.push({
            bank_details: uniqueBank,
            emp_details: uniqueUser,
            type_of_change: 'User Role Update (online)',
            reason_for_change: '',
            new_system_admin_setup: null,
            role: null,
            reason: null,
            emp_name: null,
            designation: null,
            group: null,
            bank_id: null,
            currency_code: null,
            account_number: null,
            acc_managed_by: null,
            matrix_condition: [],
            approved_person: null,
            manual_payment_approvers_limit: null,
          })
        })
        break

      default:
        break
    }

    formsData = getBankAccountMaintenanceFormFields({
      form_details: [...creationForm, ...deletionForm],
    })

    setFormDetails((prevStats) => ({
      ...prevStats,
      entity_id: internalRequestOBMData.entities ?? [],
      country_id: internalRequestOBMData.countries ?? [],
      category_type: 'Bank Account Maintenance',
      sub_category_1: internalRequestOBMData.sub_category,
      ...(prevStats.error_entity_id && {
        error_entity_id: null,
      }),
      ...(prevStats.error_country_id && {
        error_country_id: null,
      }),
      ...(prevStats.error_category_type && {
        error_category_type: null,
      }),
      ...(prevStats.error_sub_category_1 && {
        error_sub_category_1: null,
      }),
      ...formsData,
    }))
  }, [internalRequestOBMData, ticketToCopy?.access_type])

  /**
   * Initiate Form Input for Account after each update
   */
  useEffect(() => {
    if (!formDetails.category_type || ticketToCopy) {
      return
    }
    let formsData = null

    switch (formDetails.category_type?.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        formsData = getBankAccountOpeningFormFields(formDetails)
        break

      case FORM_BANK_ACCOUNT_CLOSING:
        formsData = getBankAccountClosingFormFields(formDetails)
        break

      case FORM_BANK_ACCOUNT_MAINTENANCE:
        formsData = getBankAccountMaintenanceFormFields(formDetails)
        break

      case FORM_BANK_GUARANTEE:
        formsData = getBankGuaranteeFormFields(formDetails)
        break

      case FORM_DOCUMENT_SIGNING_REQUEST:
        formsData = getDocumentSigningFormFields(formDetails)
        break

      default:
        break
    }

    setFormDetails((prevStats) => ({
      ...prevStats,
      ...formsData,
    }))
  }, [formDetails.category_type, formDetails.sub_category_1])

  /**
   * To create new request form
   * @type {UseMutationResult<unknown, unknown, void, unknown>}
   */
  const createNewRequest = useMutation(
    (newRequest) =>
      saveInternalRequest(newRequest, formDetails.category_type?.replaceAll(' ', '')),
    {
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
        navigate(
          `/InternalRequest/${formDetails.category_type?.replaceAll(' ', '')}/${
            result.ticket_id
          }`
        )
      },
      onError: (err, variables, context) => {
        setIsCallingApi({
          loading: false,
          type: null,
        })
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
    }
  )

  /**
   * To submit form
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const submitInternalRequestForm = useMutation(
    (newRequest) =>
      submitInternalRequest(newRequest, formDetails.category_type?.replaceAll(' ', '')),
    {
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
        navigate(
          `/InternalRequest/${formDetails.category_type?.replaceAll(' ', '')}/${
            result.ticket_id
          }`
        )
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        // invalidateTicket()
      },
    }
  )

  /**
   * To validate form input based on current orders
   */
  const validateForm = (type = 'create') => {
    let isGenericFormValid = true

    for (const value in formDetails.ticket_attachment) {
      if (!formDetails.ticket_attachment[value].docName) {
        setFormDetails((prevState) => {
          const newAttachments = [...prevState.ticket_attachment]
          newAttachments[value].error_docName = 'This field is required'

          return {
            ...prevState,
            ticket_attachment: newAttachments,
            error_ticketAttachment: 'Document name is required!',
          }
        })

        isGenericFormValid = false
      }
    }

    //validate default mandatory field
    let detailsValidation = null
    detailsValidation = validateDetails(formDetails, setFormDetails)

    if (type === 'create') {
      if (detailsValidation.type === 'error') {
        setSnackBar({
          open: true,
          type: 'error',
          message: 'Incomplete form! Please fill up all the required/ invalid fields',
        })

        return false
      }

      createNewRequest.mutate(formDetails)
      return true
    }

    //if user want to submit we have to validate all field
    let informationValidation = null
    informationValidation = validateInformation(formDetails, setFormDetails)

    let formValidation = null
    switch (formDetails.category_type?.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        formValidation = validateBankAccountOpening({
          bankAccountOpeningInfo: formDetails,
          setBankAccountOpeningInfo: setFormDetails,
          subCategory: formDetails.sub_category_1,
          currentOrder: formDetails.current_order,
        })
        break
      case FORM_BANK_ACCOUNT_CLOSING:
        formValidation = validateBankAccountClosing({
          bankAccountClosingInfo: formDetails,
          setBankAccountClosingInfo: setFormDetails,
          currentOrder: formDetails.current_order,
        })
        break
      case FORM_BANK_ACCOUNT_MAINTENANCE:
        formValidation = validateBankAccountMaintenance({
          bankAccountMaintenanceInfo: formDetails,
          setBankAccountMaintenanceInfo: setFormDetails,
        })
        break
      case FORM_BANK_GUARANTEE:
        formValidation = validateBankGuarantee({
          bankGuaranteeInfo: formDetails.form_details,
          setBankGuaranteeInfo: setFormDetails,
          subCategory: formDetails.sub_category_1,
        })
        break
      case FORM_DOCUMENT_SIGNING_REQUEST:
        formValidation = validateDocumentSigning({
          documentSigningInfo: formDetails.form_details,
          setDocumentSigningRequestInfo: setFormDetails,
        })

        let isDocNameMissing = false
        let isPurposeMissing = false
        for (const value in formDetails.ticket_attachment) {
          ;['docName', 'purpose'].forEach((key) => {
            if (!formDetails.ticket_attachment[value][key]) {
              setFormDetails((prevState) => {
                const newAttachments = [...prevState.ticket_attachment]
                if (key === 'docName') {
                  isDocNameMissing = true
                }

                if (key === 'purpose') {
                  isPurposeMissing = true
                }
                newAttachments[value][`error_${key}`] = 'This field is required'

                let errorMessage = `${isDocNameMissing ? 'Document Name, ' : ''}${
                  isPurposeMissing ? 'Purpose' : ''
                } is required!`

                return {
                  ...prevState,
                  ticket_attachment: newAttachments,
                  error_ticketAttachment: errorMessage,
                }
              })
              isGenericFormValid = false
            }
          })
        }

        break
      default:
        break
    }

    if (formValidation?.type === 'error' || detailsValidation.type === 'error') {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Incomplete form! Please fill up all the required/ invalid fields',
      })

      return false
    }

    if (informationValidation.type === 'error') {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Incomplete Approval Path/ Attachment  ! Please fill up the details`,
      })

      return false
    }

    submitInternalRequestForm.mutate(formDetails)
  }

  /**
   * To render the different form typef
   * @return {JSX.Element|string}
   */
  const renderFormTypeRequest = () => {
    switch (formDetails.category_type?.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        return (
          <BankAccountOpening
            bankAccountOpeningInfo={formDetails}
            setBankAccountOpeningInfo={setFormDetails}
            isDisabled={false}
          />
        )
      case FORM_BANK_ACCOUNT_CLOSING:
        return (
          <BankAccountClosing
            bankAccountClosingInfo={formDetails}
            setBankAccountClosingInfo={setFormDetails}
            isDisabled={false}
            invalidateTicket={() => console.log('hihi')}
            isCallingApi={isCallingApi}
            setIsCallingApi={setIsCallingApi}
            setOpenDeleteFileDialog={setOpenDeleteFileDialog}
            setFilePreviewDialog={setFilePreviewDialog}
          />
        )
      case FORM_BANK_ACCOUNT_MAINTENANCE:
        return (
          <BankAccountMaintenance
            bankAccountMaintenanceInfo={formDetails}
            setBankAccountMaintenanceInfo={setFormDetails}
            isDisabled={false}
          />
        )
      case FORM_BANK_GUARANTEE:
        return (
          <BankGuarantee
            bankGuaranteeInfo={formDetails}
            setBankGuaranteeInfo={setFormDetails}
            isDisabled={false}
            invalidateTicket={() => console.log('hihi')}
          />
        )
      case FORM_DOCUMENT_SIGNING_REQUEST:
        return (
          <DocumentSigningRequest
            documentSigningRequestInfo={formDetails}
            setDocumentSigningRequestInfo={setFormDetails}
            isDisabled={false}
            invalidateTicket={() => console.log('hihi')}
          />
        )
      default:
        return <></>
    }
  }

  /**
   * To delete attachment
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDeleteAttachment = useMutation(({id, index}) => deleteAttachment({id}), {
    onSuccess: (result, variables) => {
      setFormDetails((prevState) => {
        const removeAttachment = [...prevState.ticket_attachment]
        removeAttachment.splice(variables.index, 1)

        return {
          ...prevState,
          ticket_attachment: removeAttachment,
        }
      })

      setSnackBar({
        open: true,
        type: 'success',
        message: 'Attachment deleted successfully',
      })
    },
    onError: (err, variables, context) => {
      setSnackBar((prevState) => ({...prevState, open: true}))
    },
    onSettled: () => {
      // invalidateTicket()
    },
  })

  return (
    <>
      {actionDialog.open && actionDialog.type === 'OBMUserManagement' && (
        <OBMUserManagement
          isLoading={isUserOBMAccessLoading}
          onClose={() => {
            setActionDialog({
              type: null,
              value: null,
              open: false,
              isDisabled: false,
              isLoading: false,
            })
            setIsCallingApi({
              loading: false,
              type: null,
            })
          }}
          value={actionDialog.value}
          onChange={setActionDialog}
          onSubmit={() => {
            if (!actionDialog.value.access_type || actionDialog.value.data.length === 0) {
              setActionDialog((prevState) => {
                return {
                  ...prevState,
                  value: {
                    ...prevState.value,
                    ...(!actionDialog.value.access_type && {
                      error_access_type: 'This field is required',
                    }),
                    ...(actionDialog.value.data.length === 0 && {
                      error_data: 'Please select user',
                    }),
                  },
                }
              })
              setSnackBar((prevState) => ({
                ...prevState,
                open: true,
                type: 'error',
                message: 'Please fill up all required field!',
              }))

              return
            }
            if (actionDialog.value.data.length === 0) {
              setSnackBar((prevState) => ({
                ...prevState,
                open: true,
                type: 'error',
                message: 'Missing User email!',
              }))

              return
            }

            setTicketToCopy({
              ...actionDialog.value,
              emails: actionDialog.value.data.map(
                ({selected_user}) => selected_user.email
              ),
            })
          }}
        />
      )}
      {actionDialog.open && actionDialog.type === 'Copy' && (
        <Copy
          onClose={() => {
            setActionDialog({
              type: null,
              value: null,
              open: false,
              isDisabled: false,
              isLoading: false,
            })
            setIsCallingApi({
              loading: false,
              type: null,
            })
          }}
          value={actionDialog.value}
          onChange={setActionDialog}
          onSubmit={() => {
            if (!actionDialog.value.ticket) {
              setSnackBar((prevState) => ({
                ...prevState,
                open: true,
                type: 'error',
                message: 'Missing ticket number!',
              }))

              return
            }
            setTicketToCopy(actionDialog.value)
          }}
          isLoading={isRequestLoading}
        />
      )}
      <FileDeleteDialog
        open={openDeleteFileDialog.open}
        onClose={() => setOpenDeleteFileDialog({open: false, index: null})}
        onSubmit={() => {
          if (openDeleteFileDialog.func) {
            openDeleteFileDialog.func()
          } else {
            handleDeleteAttachment.mutate({
              index: openDeleteFileDialog.index,
              id: formDetails.ticket_attachment[openDeleteFileDialog.index].attachment_id,
            })
          }

          setOpenDeleteFileDialog({open: false, index: null, func: null})
        }}
      />
      <FilePreviewDialog
        open={filePreviewDialog.open}
        value={{preview: filePreviewDialog.value, name: filePreviewDialog.name}}
        onClose={() =>
          setFilePreviewDialog({
            value: null,
            open: false,
          })
        }
      />
      <>
        <Details formDetails={formDetails} setFormDetails={setFormDetails} />
        <Information
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          disabled={false}
          userEmail={userEmail}
          isLocalFieldDisabled={false}
          isHQFieldDisabled={true}
        />
        {renderFormTypeRequest()}

        <Accordion title={`Attachments (0 File)`} expanded>
          <Typography variant={'caption'}>
            File upload is only available after the creation of a request.
          </Typography>
        </Accordion>
        <Stack direction={'row'} spacing={2} sx={{pb: 2}}>
          <Button
            label={'Cancel'}
            type={'text'}
            onClick={() => {
              navigate('/InternalRequest')
            }}
          />
          <Button
            label={'Copy Ticket'}
            type={'secondary'}
            onClick={() => {
              setActionDialog((prevState) => {
                return {
                  ...prevState,
                  open: true,
                  type: 'Copy',
                  value: {
                    ticket: null,
                    type: 'All Fields',
                  },
                }
              })
            }}
          />
          <Button
            label={'OBM User Management'}
            type={'secondary'}
            onClick={() => {
              setActionDialog((prevState) => {
                return {
                  ...prevState,
                  open: true,
                  type: 'OBMUserManagement',
                  value: {access_type: null, data: []},
                }
              })
            }}
          />
          <Button
            type={'primary'}
            label={'Save'}
            onClick={() => {
              validateForm()
            }}
          />
          <Button
            type={'primary'}
            label={'Submit'}
            onClick={() => {
              validateForm('submit')
            }}
          />
        </Stack>
      </>
    </>
  )
}

export default Create
