import {useQuery} from 'react-query'

import type {Entity} from '_helper/_types'
import TSApi from 'api/TS/TSApi'
import {queryKeys} from 'hoc/ReactQuery/_constants'

/**
 * To retrieve obm employee
 * @return {Promise<any>}
 */
export async function getObmEmployee(email): Entity {
  const {data} = await TSApi.get(`obm_employee_details/?email=${email}`)

  return data
}

export function useObmEmployee(email): Entity {
  const {data: obmEmployee = null, isLoading} = useQuery(
    [queryKeys.obmEmployee, email],
    () => getObmEmployee(email)
  )

  return {obmEmployee, isLoading}
}
