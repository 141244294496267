import {useQuery} from 'react-query'

import TSApi from 'api/TS/TSApi'
import {queryKeys} from 'hoc/ReactQuery/_constants'
import {SummaryPagination, Entity} from '_helper/_types'

export type EntitySummaryType = {
  ...SummaryPagination,
  results: Entity[],
}

/**
 * To get the Entity summary
 * @return {Promise<any>}
 */
export async function getEntitySummary(
  rowsPerPage: number,
  page: number,
  searchText?: string,
  filterQuery?: any
): EntitySummaryType {
  let filterQueryString = '',
    pageQuery = ''

  for (let [filter, value] of Object.entries(filterQuery)) {
    if (value) {
      switch (filter) {
        case 'countries':
          if (value.length > 0) {
            value.map((arrayValue) => {
              filterQueryString = filterQueryString.concat(
                '&',
                'country_id',
                '=',
                arrayValue.country_id
              )
            })
          }
          break
        case 'segments':
          if (value.length > 0) {
            value.map((arrayValue) => {
              filterQueryString = filterQueryString.concat(
                '&',
                'segment_id',
                '=',
                arrayValue.segment_id
              )
            })
          }
          break
        case 'currencies':
          if (value.length > 0) {
            value.map((arrayValue) => {
              filterQueryString = filterQueryString.concat(
                '&',
                'currency_code',
                '=',
                arrayValue.currency_code
              )
            })
          }
          break
        default:
          filterQueryString = filterQueryString.concat('&', filter, '=', value)
      }
    }
  }

  if (searchText) {
    filterQueryString = filterQueryString.concat(
      '&search_text=',
      encodeURIComponent(searchText)
    )
  }

  if (page > 0) {
    pageQuery = '&page=' + page
  }

  const {data} = await TSApi.get(
    `/entity_summary/?limit=${rowsPerPage}${filterQueryString.trim()}${pageQuery}`
  )

  return data
}

interface UseEntitySummary {
  EntitySummary: EntitySummaryType;
  isLoading: boolean;
}

export function useEntitySummary({
  rowsPerPage,
  page,
  searchText,
  filterQuery,
}): UseEntitySummary {
  const {data: entitySummary = null, isLoading} = useQuery(
    [queryKeys.entitySummary, rowsPerPage, page, searchText, filterQuery],
    () => getEntitySummary(rowsPerPage, page, searchText, filterQuery),
    {
      refetchOnWindowFocus: true,
    }
  )

  return {entitySummary, isLoading}
}
