import TSApi from 'api/TS/TSApi'

export type DefaultSelectType = Array<{
  text: string,
  value: string,
}>

export type EntitiesType = Array<{
  text: string,
  value: string,
  pending_poa_request_id: number,
}>

/**
 * To retrieve all entities
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchEntities(countries): EntitiesType {
  let filterQueryString = ''

  countries.map((arrayValue) => {
    filterQueryString = filterQueryString.concat(
      `${filterQueryString !== '' ? '&' : '?'}`,
      'country_id',
      '=',
      arrayValue?.country_id ?? arrayValue
    )
  })

  const {data} = await TSApi.get(`/entities/${filterQueryString}`)

  return data
}

/**
 * To retrieve the account managed by
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchAccountManagedBy(): DefaultSelectType {
  const {data} = await TSApi.get(`/account_managed_by/`)

  return data
}

/**
 * To retrieve the currencies
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchCurrencies(): DefaultSelectType {
  const {data} = await TSApi.get(`/currencies/`)

  return data
}

/**
 * To retrieve the bank name
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchBankName(): DefaultSelectType {
  const {data} = await TSApi.get(`/banks/`)

  return data
}

/**
 * To retrieve the matrix condition
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchMatrixCondition(): DefaultSelectType {
  const {data} = await TSApi.get(`/matrix_condition/`)

  return data
}

/**
 * To retrieve the matrix Threshold
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchMatrixThreshold(): DefaultSelectType {
  const {data} = await TSApi.get(`/matrix_threshold/`)

  return data
}

/**
 * To retrieve the employee title
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchEmployeeTitle(): DefaultSelectType {
  const {data} = await TSApi.get(`/emp_title/`)

  return data
}

/**
 * To retrieve the employee group
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchEmployeeGroup(): DefaultSelectType {
  const {data} = await TSApi.get(`/emp_group/`)

  return data
}

export type FetchUserType = Array<{email: string, full_name: string}>

/**
 * To retrieve the users list - able to search for first name, last name and email
 * @return {Promise<FetchUserType>}
 */
export async function fetchUsers(search): FetchUserType {
  const {data} = await TSApi.get(`/user_list/?searchtext=${search}`)

  return data
}

/**
 * To retrieve the hq user list - able to search for first name, last name and email
 * @return {Promise<FetchUserType>}
 */
export async function fetchUsersHQ(search): FetchUserType {
  const {data} = await TSApi.get(`/user_list_hq/?searchtext=${search}`)

  return data
}

/**
 * To retrieve the master employee list - able to search for first name, last name and email
 * @return {Promise<FetchUserType>}
 */
export async function fetchEmployees(search): FetchUserType {
  const {data} = await TSApi.get(`/employee/?searchtext=${search}`)

  return data
}

/**
 * To retrieve previous tickets
 * @return {Promise<array>}
 */
export async function fetchPreviousTickets(): [] {
  const {data} = await TSApi.get(`/tickets`)

  return data
}

/**
 * To retrieve OBM banks
 * @return {Promise<array>}
 */
export async function fetchOBMBank(entity: [], subCategory: string): [] {
  let filterQueryString = ''
  entity.map((arrayValue) => {
    filterQueryString = filterQueryString.concat(`&entity_id=`, arrayValue)
  })

  const {data} = await TSApi.get(
    `/obm_banks/?sub_category=${subCategory}${filterQueryString}`
  )

  return data
}

/**
 * To retrieve the segment
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchSegments(): DefaultSelectType {
  const {data} = await TSApi.get(`/segments/`)

  return data
}

export type CountryType = Array<{
  country_id: string,
  country_name: string,
  pending_approval_request_id: number,
}>

/**
 * To retrieve all country
 * @return {Promise<any>}
 */
export async function fetchCountry(): CountryType {
  const {data} = await TSApi.get(`countries/`)

  return data
}

export type FetchTicketsType = Array<{
  ticket_id: number,
  ticket_number: string,
  category_type: string,
}>

/**
 * To retrieve the ticket list - able to search for ticket number
 * @return {Promise<FetchUserType>}
 */
export async function fetchTickets(search): FetchTicketsType {
  const {data} = await TSApi.get(`/to_copy_ticket_list/?searchtext=${search}`)

  return data
}

export type FetchUserAccess = Array<{
  fullname: number,
  user_email: string,
}>

/**
 * To retrieve the user access - able to search for user in OBM
 * @return {Promise<FetchUserType>}
 */
export async function fetchUserAccess(search): FetchUserAccess {
  const {data} = await TSApi.get(`/to_copy_user_access_list/?searchtext=${search}`)

  return data
}

/**
 * To retrieve the user OBM access - get the user access detail from OBM
 * @return {Promise<FetchUserType>}
 */
export async function fetchUserOBMAccess(emails): FetchUserAccess {
  let emailQueryString = ''
  emails.map((arrayValue) => {
    if (emailQueryString === '') {
      emailQueryString = emailQueryString.concat(`?emails=`, arrayValue)
      return
    }

    emailQueryString = emailQueryString.concat(`&emails=`, arrayValue)
  })

  const {data} = await TSApi.get(`/get_obm_user_access_list/${emailQueryString}`)

  return data
}
