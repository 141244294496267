import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {RenderErrorPage} from 'hoc/Error/RenderErrorPage'

const ErrorStatusContext = createContext()

export const ErrorHandler = ({children}) => {
  const location = useLocation()
  const [error, setError] = useState(undefined)
  const [retry, setRetry] = useState(undefined)
  useEffect(() => {
    if (location) {
      setError(undefined)
      setRetry(undefined)
    }
  }, [location])

  const renderContent = () => {
    if (error) {
      return (
        <>
          <RenderErrorPage error={error} retry={retry} />
        </>
      )
    }

    return children
  }

  const contextPayload = useMemo(() => ({setError, setRetry}), [])

  return (
    <ErrorStatusContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorStatusContext.Provider>
  )
}

export const useErrorStatus = () => useContext(ErrorStatusContext)
