import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {useAuth} from 'hoc/AuthContext'
import {isAppAccessSet, toRedirectToEntryPage} from '_helper/_authHelper'

const Login = () => {
  const {user} = useAuth()
  let navigate = useNavigate()

  useEffect(() => {
    // POP is to make sure google client is already initiated
    // So we will only redirect user to relogin if they choose to relogin from the web
    // and not from keying in /login in browser - that will be handled in google auth
    if (!user) {
      window.gapi.auth2.getAuthInstance().signIn({
        ux_mode: 'redirect',
        redirect_uri: `${process.env.REACT_APP_REDIRECT_TO_LOGIN_URL}`,
      })
    }

    if (user && isAppAccessSet()) {
      toRedirectToEntryPage(navigate)
    }
  }, [user])

  return <></>
}

export default Login
