import React from 'react'

import Template from 'core/Template'

const ApiError = ({loadingIndicator}) => {
  return (
    <>
      <Template
        title="Error 500"
        content="Hmms, something is broken."
        desc={
          <>
            The resource you are looking for is available but might be broken so we could
            not complete your request. Please contact us @{' '}
            <a href="mailto: finsys-epm@sea.com">finsys-epm@sea.com</a> to take a look at
            the issue.
          </>
        }
        loadingIndicator={loadingIndicator}
      />
    </>
  )
}

export default ApiError
