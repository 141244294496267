import {Dialog} from 'finsys-webcomponent'
import {Grid} from '@mui/material'

type DeleteProps = {
  onClose: () => void,
  onSubmit: () => void,
  isDisabled: boolean,
  isLoading: boolean,
}

/**
 * To open Delete Entity Dialog
 * @param value
 * @param onClose
 * @param onSubmit
 * @param isDisabled
 * @param isLoading
 * @return {JSX.Element}
 * @constructor
 */
export const DeleteDialog = ({
  onClose,
  onSubmit,
  isDisabled,
  isLoading,
  content,
}: DeleteProps) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Dialog
          fullWidth
          type={'error'}
          title={'Are you sure?'}
          content={content}
          buttons={[
            {label: 'Cancel', type: 'text', onClick: onClose, disabled: isDisabled},
            {
              label: 'Delete',
              type: 'danger',
              onClick: () => {
                onSubmit()
                onClose()
              },
              disabled: isDisabled,
              loading: isLoading,
            },
          ]}
          handleOnClose={onClose}
        />
      </Grid>
    </Grid>
  )
}
