import React from 'react'
import {
  NumberDatePicker,
  RadioButton,
  TextField,
  Autocomplete,
  Accordion,
} from 'finsys-webcomponent'
import {Grid, Typography} from '@mui/material'
import moment from 'moment'

import {
  useFetchBankNames,
  useFetchCurrencies,
  useFetchFormMetadata,
} from 'components/_Utils/UseFetchFunctions'
import useDataFetching from 'hoc/UseDataFetching'
import {fetchPreviousTickets} from 'api/TS/query/_CommonQueries'
import {formatThousandSeparator} from 'components/_Common'
import {NumericFormat} from 'react-number-format'

export const GUARANTEE_CASH_SECURITY_DEPOSIT = 'Cash Security Deposit'
export const NEW_BG_CATEGORY = 'New BG Application'

export type BankGuaranteeFormType = {
  bg_request_form_details: Array<{
    bg_request_id: number,
    bank_id: string,
    bank_description: string,
    type_of_facility: number,
    type_of_guarantee: string,
    previous_ticket: string,
    reason: string,
    beneficiary: string,
    beneficiary_address: string,
    beneficiary_contact: string,
    kyriba_bg_ref_no: string,
    kyriba_previous_ref_no: string,
    purpose_of_guarantee: string,
    format_of_guarantee: string,
    guarantee_amount: string,
    currency_code: string,
    start_effective_date: string,
    expiry_date: string,
    final_claim_date: string,
    guarantee_required_date: string,
    delivery_method: string,
    other_requirement: string,
    collateral_required: string,
    collateral_amount: string,
    commission_charge_amount: string,
    other_issuance_amount: string,
    auto_renewal_applicable: string,
  }>,
}

/**
 * Bank Account Maintenance form details
 * @type {{bank: null, openWithPreferredBank: null, numberOfAccountToOpen: null, chequeBookRequiredExplanation: null, applyingMT940Explanation: null, accountManagedByExplanation: null, applyingMT940: null, purposeOfBankAccount: null, openWithPreferredBankExplanation: null, chequeBookRequired: null, accountManagedBy: null}}
 */
function bankFormDetails() {
  return {
    bg_request_id: null,
    bank_id: null,
    type_of_facility: null,
    type_of_guarantee: null,
    reason: null,
    previous_ticket: null,
    beneficiary_name: null,
    beneficiary_contact: null,
    beneficiary_address: null,
    kyriba_bg_ref_no: null,
    kyriba_previous_ref_no: null,
    purpose_of_guarantee: null,
    guarantee_amount: null,
    currency_code: null,
    format_of_guarantee: null,
    start_effective_date: null,
    expiry_date: null,
    final_claim_date: null,
    guarantee_required_date: null,
    delivery_method: null,
    other_requirement: null,
    collateral_required: '',
    collateral_amount: null,
    commission_charge_amount: null,
    other_issuance_amount: null,
    auto_renewal_applicable: '',
  }
}

/**
 * To retrieve the document signing form fields
 * @param formData
 * @return {{attachments: ({docName: string, file: {name: string, id: number}, purpose: string}[]|null), purpose: ({text: string, value: string}|null), deliveryMethod: ({text: string, value: string}|null), otherIssuanceCosts: string, commissionCharge: string, guarantee: ({text: string, value: string}|null), kyribaPreviousRefNumber: string, previousTicketId: string, beneficiaryAddress: string, expiryDate: string, bank: ({text: string, value: string}|null), beneficiaryName, collateralRequired: string, beneficiaryContact, amountCurrency: ({text: string, value: string}|null), bgRequestId: number, amount: string, format: ({text: string, value: string}|null), autoRenewable: string, reasonForExceptionHandling: string, finalClaimDate: string, kyribaBGRefNumber: string, guaranteeRequiredDate: string, collateralAmount: string, otherRequirements: string, facility: ({text: number, value: number}|null), startDate: string}|{bank: null, openWithPreferredBank: null, numberOfAccountToOpen: null, chequeBookRequiredExplanation: null, applyingMT940Explanation: null, accountManagedByExplanation: null, applyingMT940: null, purposeOfBankAccount: null, openWithPreferredBankExplanation: null, chequeBookRequired: null, accountManagedBy: null}}
 */
export function getBankGuaranteeFormFields(formData: BankGuaranteeFormType) {
  let details = []

  const getData = (data) => {
    const {name: beneficiary_name, contact: beneficiary_contact} =
      data?.beneficiary_contact
        ? JSON.parse(data?.beneficiary_contact)
        : {
            name: null,
            contact: null,
          }

    return {
      bg_request_id: data.bg_request_id ?? null,
      bank_id: data.bank_id
        ? {value: data.bank_id.value ?? data.bank_id, text: data.bank_description}
        : null,
      type_of_facility: data.type_of_facility ?? null,
      type_of_guarantee: data.type_of_guarantee ?? null,
      reason: data.reason ?? null,
      previous_ticket: data.previous_ticket ?? null,
      beneficiary_name: beneficiary_name ?? '',
      beneficiary_contact: beneficiary_contact ?? '',
      beneficiary_address: data.beneficiary_address ?? null,
      kyriba_bg_ref_no: data.kyriba_bg_ref_no ?? null,
      kyriba_previous_ref_no: data.kyriba_previous_ref_no ?? null,
      purpose_of_guarantee: data.purpose_of_guarantee ?? null,
      guarantee_amount: data.guarantee_amount ?? null,
      currency_code: data.currency_code ?? null,
      format_of_guarantee: data.format_of_guarantee ?? null,
      start_effective_date: data.start_effective_date ?? null,
      expiry_date: data.expiry_date ?? null,
      final_claim_date: data.final_claim_date ?? null,
      guarantee_required_date: data.guarantee_required_date ?? null,
      delivery_method: data.delivery_method ?? null,
      other_requirement: data.other_requirement ?? null,
      collateral_required: data.collateral_required ?? '',
      collateral_amount: data.collateral_amount ?? null,
      commission_charge_amount: data.commission_charge_amount ?? null,
      other_issuance_amount: data.other_issuance_amount ?? null,
      auto_renewal_applicable: data.auto_renewal_applicable ?? '',
    }
  }

  if (formData?.form_details?.bg_request_id) {
    details = formData.form_details
  } else if (formData?.bg_request_form_details?.length > 0) {
    details = formData.bg_request_form_details.map((data) => {
      return getData(data)
    })[0]
  } else {
    details = bankFormDetails()
  }

  return {
    form_details: details,
  }
}

/**
 * To fetch previous tickets
 * @return {{isError: false | true, data: unknown, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
const useFetchPreviousTickets = () => {
  return useDataFetching('previousTickets', () => fetchPreviousTickets())
}

type BankGuaranteeInfoType = {
  bankGuaranteeInfo: {
    bg_request_id: number,
    bank_id: {
      text: string,
      value: string,
    },
    type_of_facility: {
      text: string,
      value: string,
    },
    type_of_guarantee: {
      text: string,
      value: string,
    },
    reason: string,
    previous_ticket: string,
    beneficiary_name: string,
    beneficiary_contact: string,
    beneficiary_address: string,
    kyriba_bg_ref_no: string,
    kyriba_previous_ref_no: string,
    purpose_of_guarantee: {
      text: string,
      value: string,
    },
    guarantee_amount: {
      text: string,
      value: string,
    },
    currency_code: {
      text: string,
      value: string,
    },
    start_effective_date: string,
    expiry_date: string,
    other_requirement: string,
    collateral_required: string,
    collateral_amount: string,
    commission_charge_amount: string,
    other_issuance_amount: string,
    auto_renewal_applicable: string,
    format_of_guarantee: {
      text: string,
      value: string,
    },
    finalClaimDate: string,
    guaranteeRequiredDate: string,
    deliveryMethod: {
      text: string,
      value: string,
    },
  },
  setBankGuaranteeInfo: () => void,
  sub_category: string,
}

/**
 * To validate the fields for bank guarantee
 * @param bankGuaranteeInfo
 * @param setBankGuaranteeInfo
 * @param subCategory
 */
export function validateBankGuarantee({
  bankGuaranteeInfo,
  setBankGuaranteeInfo,
  subCategory,
}: BankGuaranteeInfoType) {
  const requiredFields = {
    form: [
      {field: 'bank_id', label: 'bank'},
      {field: 'type_of_facility', label: 'facility'},
      {field: 'type_of_guarantee', label: 'guarantee'},
      {field: 'kyriba_bg_ref_no', label: 'BG Ref Number'},
      {field: 'beneficiary_name', label: 'beneficiary name'},
      {field: 'beneficiary_contact', label: 'beneficiary contact'},
      {field: 'beneficiary_address', label: 'beneficiary address'},
      {field: 'purpose_of_guarantee', label: 'purpose'},
      {field: 'currency_code', label: 'amount currency'},
      {field: 'start_effective_date', label: 'start date'},
      {field: 'expiry_date', label: 'expiry date'},
      {field: 'collateral_required', label: 'collateral required'},
      {field: 'collateral_amount', label: 'collateral amount'},
      {field: 'commission_charge_amount', label: 'commission charge'},
      {field: 'other_issuance_amount', label: 'other issuance costs'},
      {field: 'auto_renewal_applicable', label: 'auto renewable'},
    ],
    renewal: [
      {field: 'previous_ticket', label: 'previous ticket id'},
      {field: 'kyriba_previous_ref_no', label: 'previous BG Ref Number'},
    ],
    amount: [{field: 'guarantee_amount', label: 'amount'}],
    conditionalGuaranteeCash: [
      {field: 'format_of_guarantee', label: 'format'},
      {field: 'final_claim_date', label: 'final claim date'},
      {field: 'guarantee_required_date', label: 'guarantee required date'},
      {field: 'delivery_method', label: 'delivery method'},
      {field: 'other_requirement', label: 'other requirements'},
    ],
  }

  let isFormValid = true
  //lets validate form details first
  for (const [key, value] of Object.entries(requiredFields.form)) {
    if (!bankGuaranteeInfo[value.field] || bankGuaranteeInfo[value.field].length === 0) {
      setBankGuaranteeInfo((prevState) => ({
        ...prevState,
        form_details: {
          ...prevState.form_details,
          [`error_${value.field}`]: 'This field is required',
        },
      }))

      isFormValid = false
    }
  }

  //if its renewal we needa validate two more field
  if (subCategory !== NEW_BG_CATEGORY) {
    for (const [key, value] of Object.entries(requiredFields.renewal)) {
      if (
        !bankGuaranteeInfo[value.field] ||
        bankGuaranteeInfo[value.field].length === 0
      ) {
        setBankGuaranteeInfo((prevState) => ({
          ...prevState,
          form_details: {
            ...prevState.form_details,
            [`error_${value.field}`]: 'This field is required',
          },
        }))

        isFormValid = false
      }
    }
  }

  //lets validate amount value
  for (const [key, value] of Object.entries(requiredFields.amount)) {
    if (!bankGuaranteeInfo[value.field] && bankGuaranteeInfo[value.field] !== 0) {
      setBankGuaranteeInfo((prevState) => ({
        ...prevState,
        form_details: {
          ...prevState.form_details,
          [`error_${value.field}`]: 'This field is required',
        },
      }))
    }
    if (bankGuaranteeInfo[value.field] < 0) {
      setBankGuaranteeInfo((prevState) => ({
        ...prevState,
        form_details: {
          ...prevState.form_details,
          [`error_${value.field}`]: 'This field is invalid - cannot be negative',
        },
      }))

      isFormValid = false
    }
  }

  if (
    bankGuaranteeInfo.purpose_of_guarantee?.value === GUARANTEE_CASH_SECURITY_DEPOSIT &&
    !bankGuaranteeInfo.reason
  ) {
    setBankGuaranteeInfo((prevState) => ({
      ...prevState,
      form_details: {
        ...prevState.form_details,
        [`error_reason`]: 'This field is required',
      },
    }))

    isFormValid = false
  }

  if (bankGuaranteeInfo.purpose_of_guarantee?.value !== GUARANTEE_CASH_SECURITY_DEPOSIT) {
    for (const [key, value] of Object.entries(requiredFields.conditionalGuaranteeCash)) {
      if (
        !bankGuaranteeInfo[value.field] ||
        bankGuaranteeInfo[value.field].length === 0
      ) {
        setBankGuaranteeInfo((prevState) => ({
          ...prevState,
          form_details: {
            ...prevState.form_details,
            [`error_${value.field}`]: 'This field is required',
          },
        }))

        isFormValid = false
      }
    }
  }

  if (!isFormValid) {
    return {
      type: 'error',
    }
  }

  return {
    type: 'success',
  }
}

export default function BankGuarantee({
  bankGuaranteeInfo,
  setBankGuaranteeInfo,
  isDisabled,
}) {
  const {form_details} = bankGuaranteeInfo

  const disabled = isDisabled
  const {loading: isCurrenciesLoading, data: currencies} = useFetchCurrencies()
  const {loading: isTypeOfGuaranteeLoading, data: typeOfGuarantees} =
    useFetchFormMetadata('bg_request', 'type_of_guarantee')
  const {loading: isTypeOfFacilityLoading, data: typeOfFacilities} = useFetchFormMetadata(
    'bg_request',
    'type_of_facility'
  )
  const {loading: isPurposeOfGuaranteeLoading, data: purposeOfGuarantees} =
    useFetchFormMetadata('bg_request', 'purpose_of_guarantee')
  const {loading: isDeliveryMethodLoading, data: deliveryMethods} = useFetchFormMetadata(
    'bg_request',
    'delivery_method'
  )
  const {loading: isFormatOfGuaranteeLoading, data: formatOfGuarantees} =
    useFetchFormMetadata('bg_request', 'format_of_guarantee')
  const {loading: isBanksLoading, data: banks} = useFetchBankNames()
  const {loading: isPrevTicketLoading, data: previousTickets} = useFetchPreviousTickets()

  if (
    isTypeOfFacilityLoading ||
    isTypeOfGuaranteeLoading ||
    isPurposeOfGuaranteeLoading ||
    isDeliveryMethodLoading ||
    isFormatOfGuaranteeLoading ||
    isBanksLoading ||
    isPrevTicketLoading ||
    isCurrenciesLoading
  ) {
    return 'Bank Guarantee Form is loading..'
  }
  return (
    <>
      <Accordion title={'Bank Guarantee Form'} expanded>
        <div style={{marginBottom: '10px'}}>
          <Grid container justifyContent={'flex-start'} columnSpacing={8} rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
                General
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                options={banks}
                getOptionLabel={(option) => option.text || ''}
                handleOnChange={(value) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      bank_id: value,
                      error_bank_id: undefined,
                    },
                  }))
                }
                value={form_details.bank_id}
                label={'Select Bank'}
                textFieldProps={{
                  required: true,
                  error: !!form_details.error_bank_id,
                  helperText: form_details.error_bank_id,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                options={typeOfFacilities}
                getOptionLabel={(option) => option.value || option}
                handleOnChange={(value) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      type_of_facility: value?.value,
                      error_type_of_facility: undefined,
                    },
                  }))
                }
                value={form_details.type_of_facility}
                label={'Select Facility'}
                textFieldProps={{
                  required: true,
                  error: !!form_details.error_type_of_facility,
                  helperText: form_details.error_type_of_facility,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                options={typeOfGuarantees}
                getOptionLabel={(option) => option.value || option}
                handleOnChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      type_of_guarantee: value?.value,
                      error_guarantee: undefined,
                    },
                  }))
                  if (value?.value === GUARANTEE_CASH_SECURITY_DEPOSIT) {
                    setBankGuaranteeInfo((prevState) => ({
                      ...prevState,
                      form_details: {
                        ...prevState.form_details,
                        format_of_guarantee: null,
                        delivery_method: null,
                        other_requirement: null,
                        final_claim_date: null,
                        guarantee_required_date: null,
                      },
                    }))
                  }
                }}
                value={form_details.type_of_guarantee}
                label={'Select Guarantee'}
                textFieldProps={{
                  required: true,
                  error: !!form_details.error_type_of_guarantee,
                  helperText: form_details.error_type_of_guarantee,
                }}
                disabled={disabled}
              />
            </Grid>
            {bankGuaranteeInfo.sub_category_1 !== NEW_BG_CATEGORY && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  options={previousTickets}
                  getOptionLabel={(option) => option}
                  handleOnChange={(value) =>
                    setBankGuaranteeInfo((prevState) => ({
                      ...prevState,
                      form_details: {
                        ...prevState.form_details,
                        previous_ticket: value,
                        error_previous_ticket: undefined,
                      },
                    }))
                  }
                  value={form_details.previous_ticket}
                  label={'Select Previous Ticket ID'}
                  textFieldProps={{
                    required: true,
                    error: !!form_details.error_previous_ticket,
                    helperText: form_details.error_previous_ticket,
                  }}
                  disabled={disabled}
                />
              </Grid>
            )}
            {form_details.guarantee?.value === GUARANTEE_CASH_SECURITY_DEPOSIT && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  label={'Reason for exception handling'}
                  value={form_details.reason}
                  onChange={(value) => {
                    setBankGuaranteeInfo((prevState) => ({
                      ...prevState,
                      form_details: {
                        ...prevState.form_details,
                        reason: value.target.value,
                        error_reason: undefined,
                      },
                    }))
                  }}
                  required={true}
                  disabled={disabled}
                  error={!!form_details.error_reason}
                  helperText={form_details.error_reason}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
                Beneficiary
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <TextField
                label={'Name'}
                value={form_details.beneficiary_name}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      beneficiary_name: value.target.value,
                      error_beneficiary_name: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_beneficiary_name}
                helperText={form_details.error_beneficiary_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <TextField
                label={'Contact'}
                value={form_details.beneficiary_contact}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      beneficiary_contact: value.target.value,
                      error_beneficiary_contact: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_beneficiary_contact}
                helperText={form_details.error_beneficiary_contact}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                multiline
                label={'Address'}
                value={form_details.beneficiary_address}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      beneficiary_address: value.target.value,
                      error_beneficiary_address: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                maxRows={4}
                error={!!form_details.error_beneficiary_address}
                helperText={
                  form_details.error_beneficiary_address
                    ? form_details.error_beneficiary_address
                    : !disabled
                    ? `Press 'enter' for new line`
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
                Kyriba Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label={'BG Ref Number (for current application)'}
                value={form_details.kyriba_bg_ref_no}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      kyriba_bg_ref_no: value.target.value,
                      error_kyriba_bg_ref_no: undefined,
                    },
                  }))
                }}
                disabled={disabled}
                required
                error={!!form_details.error_kyriba_bg_ref_no}
                helperText={form_details.error_kyriba_bg_ref_no}
              />
            </Grid>
            {bankGuaranteeInfo.sub_category_1 !== NEW_BG_CATEGORY && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label={'Previous Ref Number (For renewal)'}
                  value={form_details.kyriba_previous_ref_no}
                  onChange={(value) => {
                    setBankGuaranteeInfo((prevState) => ({
                      ...prevState,
                      form_details: {
                        ...prevState.form_details,
                        kyriba_previous_ref_no: value.target.value,
                        error_kyriba_previous_ref_no: undefined,
                      },
                    }))
                  }}
                  disabled={disabled}
                  required
                  error={!!form_details.error_kyriba_previous_ref_no}
                  helperText={form_details.error_kyriba_previous_ref_no}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
                Bank Guarantee Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                options={purposeOfGuarantees}
                getOptionLabel={(option) => option.text || option}
                handleOnChange={(value) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      purpose_of_guarantee: value?.value,
                      error_purpose_of_guarantee: undefined,
                    },
                  }))
                }
                value={form_details.purpose_of_guarantee}
                label={'Select Purpose'}
                textFieldProps={{
                  required: true,
                  error: !!form_details.error_purpose_of_guarantee,
                  helperText: form_details.error_purpose_of_guarantee,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <NumericFormat
                value={form_details.guarantee_amount}
                thousandSeparator=","
                decimalSeparator={'.'}
                decimalScale={2}
                fixedDecimalScale
                onValueChange={({value}) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      guarantee_amount: value === '' ? null : parseFloat(value),
                      error_guarantee_amount: undefined,
                    },
                  }))
                }}
                customInput={TextField}
                label={'Amount'}
                required={true}
                disabled={disabled}
                error={!!form_details.error_guarantee_amount}
                helperText={form_details.error_guarantee_amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                options={currencies}
                getOptionLabel={(option) => option.text || option}
                handleOnChange={(value) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      currency_code: value?.value,
                      error_currency_code: undefined,
                    },
                  }))
                }
                value={form_details.currency_code}
                label={'Select Currency'}
                textFieldProps={{
                  required: true,
                  error: !!form_details.error_currency_code,
                  helperText: form_details.error_currency_code,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <NumberDatePicker
                onOpen={() => {
                  const todayDate = new Date()
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      start_effective_date: moment(todayDate, 'yyyy-MM-DD'),
                      error_start_effective_date: undefined,
                    },
                  }))
                }}
                label={'Start/Effective Date'}
                value={form_details.start_effective_date}
                setSelectedDate={(date) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      start_effective_date: date ? date : null,
                      error_start_effective_date: undefined,
                    },
                  }))
                }
                required={true}
                disabled={disabled}
                error={!!form_details.error_start_effective_date}
                helperText={form_details.error_start_effective_date}
                handleOnError={(error) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      error_start_effective_date: error,
                    },
                  }))
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <NumberDatePicker
                onOpen={() => {
                  const todayDate = new Date()
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      expiry_date: moment(todayDate, 'yyyy-MM-DD'),
                      error_expiry_date: undefined,
                    },
                  }))
                }}
                label={'Expiry Date'}
                value={form_details.expiry_date}
                setSelectedDate={(date) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      expiry_date: date ? date : null,
                      error_expiry_date: undefined,
                    },
                  }))
                }
                required={true}
                disabled={disabled}
                error={!!form_details.error_expiry_date}
                helperText={form_details.error_expiry_date}
                handleOnError={(error) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      error_expiry_date: error,
                    },
                  }))
                }}
                {...(form_details.start_effective_date !== null && {
                  minDate: moment(form_details.start_effective_date, 'yyyy-MM-DD'),
                })}
                minDateMessage={'Date should not be before start/effective date'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <NumberDatePicker
                onOpen={() => {
                  const todayDate = new Date()
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      final_claim_date: moment(todayDate, 'yyyy-MM-DD'),
                      error_final_claim_date: undefined,
                    },
                  }))
                }}
                label={'Final Claim Date'}
                value={form_details.final_claim_date}
                setSelectedDate={(date) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      final_claim_date: date ? date : null,
                      error_final_claim_date: undefined,
                    },
                  }))
                }
                required={
                  form_details.purpose_of_guarantee?.value !==
                  GUARANTEE_CASH_SECURITY_DEPOSIT
                }
                disabled={
                  form_details.purpose_of_guarantee?.value ===
                    GUARANTEE_CASH_SECURITY_DEPOSIT || disabled
                }
                error={!!form_details.error_final_claim_date}
                helperText={form_details.error_final_claim_date}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <NumberDatePicker
                onOpen={() => {
                  const todayDate = new Date()
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      guarantee_required_date: moment(todayDate, 'yyyy-MM-DD'),
                      error_guarantee_required_date: undefined,
                    },
                  }))
                }}
                label={'Date Guarantee Required'}
                value={form_details.guarantee_required_date}
                setSelectedDate={(date) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      guarantee_required_date: date ? date : null,
                      error_guarantee_required_date: undefined,
                    },
                  }))
                }
                required={
                  form_details.purpose_of_guarantee?.value !==
                  GUARANTEE_CASH_SECURITY_DEPOSIT
                }
                disabled={
                  form_details.purpose_of_guarantee?.value ===
                    GUARANTEE_CASH_SECURITY_DEPOSIT || disabled
                }
                error={!!form_details.error_guarantee_required_date}
                helperText={form_details.error_guarantee_required_date}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                options={formatOfGuarantees}
                getOptionLabel={(option) => option.text || option}
                handleOnChange={(value) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      format_of_guarantee: value?.value,
                      error_format_of_guarantee: undefined,
                    },
                  }))
                }
                value={form_details.format_of_guarantee}
                label={'Select Format'}
                textFieldProps={{
                  required:
                    form_details.purpose_of_guarantee?.value !==
                    GUARANTEE_CASH_SECURITY_DEPOSIT,
                  error: !!form_details.error_format_of_guarantee,
                  helperText: form_details.error_format_of_guarantee,
                }}
                disabled={
                  form_details.purpose_of_guarantee?.value ===
                    GUARANTEE_CASH_SECURITY_DEPOSIT || disabled
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                options={deliveryMethods}
                getOptionLabel={(option) => option.text || option}
                handleOnChange={(value) =>
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      delivery_method: value?.value,
                      error_delivery_method: undefined,
                    },
                  }))
                }
                value={form_details.delivery_method}
                label={'Select Delivery Method'}
                textFieldProps={{
                  required:
                    form_details.purpose_of_guarantee?.value !==
                    GUARANTEE_CASH_SECURITY_DEPOSIT,
                  error: !!form_details.error_delivery_method,
                  helperText: form_details.error_delivery_method,
                }}
                disabled={
                  form_details.purpose_of_guarantee?.value ===
                    GUARANTEE_CASH_SECURITY_DEPOSIT || disabled
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                multiline
                label={'Other requirements'}
                value={form_details.other_requirement}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      other_requirement: value.target.value,
                      error_other_requirement: undefined,
                    },
                  }))
                }}
                required={
                  form_details.purpose_of_guarantee?.value !==
                  GUARANTEE_CASH_SECURITY_DEPOSIT
                }
                disabled={
                  form_details.purpose_of_guarantee?.value ===
                    GUARANTEE_CASH_SECURITY_DEPOSIT || disabled
                }
                error={!!form_details.error_other_requirement}
                helperText={
                  form_details.error_other_requirement
                    ? form_details.error_other_requirement
                    : !disabled
                    ? `Press 'enter' for new line`
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RadioButton
                label="Collateral Required"
                value={form_details.collateral_required?.toUpperCase()}
                options={['Y', 'N']}
                handleOnChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      collateral_required: value,
                      error_collateral_required: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_collateral_required}
                helperText={form_details.error_collateral_required}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TextField
                label={'Collateral Amount'}
                value={form_details.collateral_amount}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      collateral_amount: formatThousandSeparator(value.target.value),
                      error_collateral_amount: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_collateral_amount}
                helperText={form_details.error_collateral_amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <RadioButton
                label="Is Auto-renewable applicable"
                value={form_details.auto_renewal_applicable?.toUpperCase()}
                options={['Y', 'N']}
                handleOnChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      auto_renewal_applicable: value,
                      error_auto_renewal_applicable: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_auto_renewal_applicable}
                helperText={form_details.error_auto_renewal_applicable}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label={'Commission Charge'}
                value={form_details.commission_charge_amount}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      commission_charge_amount: formatThousandSeparator(
                        value.target.value
                      ),
                      error_commission_charge_amount: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_commission_charge_amount}
                helperText={form_details.error_commission_charge_amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label={'Other issuance costs'}
                value={form_details.other_issuance_amount}
                onChange={(value) => {
                  setBankGuaranteeInfo((prevState) => ({
                    ...prevState,
                    form_details: {
                      ...prevState.form_details,
                      other_issuance_amount: formatThousandSeparator(value.target.value),
                      error_other_issuance_amount: undefined,
                    },
                  }))
                }}
                required={true}
                disabled={disabled}
                error={!!form_details.error_other_issuance_amount}
                helperText={form_details.error_other_issuance_amount}
              />
            </Grid>
          </Grid>
        </div>
      </Accordion>
    </>
  )
}
