import {useQuery} from 'react-query'

import TSApi from 'api/TS/TSApi'
import {queryKeys} from 'hoc/ReactQuery/_constants'
import {SummaryPagination} from '_helper/_types'
import type {Bank} from '_helper/_types'

export type BankSummaryType = {
  ...SummaryPagination,
  results: Bank[],
}

/**
 * To get the OBM employee summary
 * @return {Promise<any>}
 */
export async function getOBMEmployeeSummary(
  rowsPerPage: number,
  page: number,
  searchText?: string,
  filterQuery?: any
): BankSummaryType {
  let filterQueryString = '',
    pageQuery = ''

  for (let [filter, value] of Object.entries(filterQuery)) {
    if (value) {
      switch (filter) {
        default:
          filterQueryString = filterQueryString.concat('&', filter, '=', value)
      }
    }
  }

  if (searchText) {
    filterQueryString = filterQueryString.concat(
      '&search_text=',
      encodeURIComponent(searchText)
    )
  }

  if (page > 0) {
    pageQuery = '&page=' + page
  }

  const {data} = await TSApi.get(
    `/obm_employee_details_summary/?limit=${rowsPerPage}${filterQueryString.trim()}${pageQuery}`
  )

  return data
}

interface UseBankSummary {
  bankSummary: BankSummaryType;
  isLoading: boolean;
}

export function useOBMEmployeeSummary({
  rowsPerPage,
  page,
  searchText,
  filterQuery,
}): UseBankSummary {
  const {data: obmEmployee = null, isLoading} = useQuery(
    [queryKeys.obmEmployee, rowsPerPage, page, searchText, filterQuery],
    () => getOBMEmployeeSummary(rowsPerPage, page, searchText, filterQuery),
    {
      refetchOnWindowFocus: true,
    }
  )

  return {obmEmployee, isLoading}
}
