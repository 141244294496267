import {Dialog} from 'finsys-webcomponent'
import {Grid} from '@mui/material'

type DeleteDraftProps = {
  value: string | null,
  onChange: () => void,
  onClose: () => void,
  onSubmit: () => void,
  isDisabled: boolean,
  isLoading: boolean,
}

/**
 * To open Delete Dialog
 * @param value
 * @param onClose
 * @param onSubmit
 * @param isDisabled
 * @param isLoading
 * @return {JSX.Element}
 * @constructor
 */
export const DeleteDraft = ({
  onClose,
  onSubmit,
  isDisabled,
  isLoading,
}: DeleteDraftProps) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Dialog
          fullWidth
          type={'error'}
          title={'Are you sure'}
          buttons={[
            {label: 'Cancel', type: 'text', onClick: onClose, disabled: isDisabled},
            {
              label: 'Delete',
              type: 'danger',
              onClick: onSubmit,
              disabled: isDisabled,
              loading: isLoading,
            },
          ]}
          content={'Do you want to delete this draft?'}
          handleOnClose={onClose}
        />
      </Grid>
    </Grid>
  )
}
