import {sessionTTL} from '_helper/_constants'
import {securedLocalStorage} from 'localstorage'

/**
 * To get user session information
 * @returns {{session: any, expiry: string}}
 */
export function getUserSessionToken(): {session: string, expiry: number} {
  return {
    session: securedLocalStorage.get('session'),
    expiry: securedLocalStorage.get('expiry'),
  }
}

/**
 * To get user session information
 * @returns {boolean}
 */
export function isUserTokenExpired(): Promise {
  let expired = false

  if (!localStorage.getItem('expiry')) {
    return new Promise((resolve) => resolve(expired))
  }

  const expiry = securedLocalStorage.get('expiry')
  const now = new Date()

  // compare the expiry time of the item with the current time
  if (expiry && now.getTime() > expiry) {
    // If the items are expired, clear the storage and prompt user to re-login
    localStorage.clear()
    expired = true
  }
  return new Promise((resolve) => resolve(expired))
}

/**
 * To set user session information
 */
export function setUserSessionToken(token): void {
  const now = new Date()

  securedLocalStorage.set('session', token)
  securedLocalStorage.set('expiry', now.getTime() + sessionTTL)
}

/**
 * To remove user session information
 */
export function removeUserSessionToken(): void {
  securedLocalStorage.remove('expiry')
  securedLocalStorage.remove('session')
}
