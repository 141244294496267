/**
 * To get the landing web url of the user
 * @returns {string}
 */
export function getWebEntryUrl(): string {
  return localStorage.getItem('entryUrl')
}

/**
 * To set the landing web url of the user
 * @param token
 */
export function setWebEntryUrl(token): void {
  localStorage.setItem('entryUrl', token)
}

/**
 * To remove the landing web url of the user
 */
export function removeWebEntryUrl(): void {
  localStorage.removeItem('entryUrl')
}
