import React, {useEffect, useState} from 'react'
import {Badge, Grid, Menu, MenuItem, Stack, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {IconButton, SearchInput} from 'finsys-webcomponent'
import styled from '@emotion/styled'

import FilterIcon from 'assets/icons/filter.png'
import CreateIcon from 'assets/icons/add.png'
import {setFilter} from 'store/actions'
import FilterOptions from 'components/Metadata/Summary/Entity/FilterOptions'
import {getUserRole} from 'localstorage/UserRole'
import {HQ_ROLE_ID} from '_helper/_constants'

/**
 * To override the default color for badge
 */
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    color: 'white',
  },
}))

const CREATE_METADATA = [
  {label: 'Bank', description: 'To create new bank'},
  {label: 'Entity', description: 'To create new entity'},
]

export const Layout = ({
  retainFilter,
  searchPlaceholder,
  children,
  searchTF,
  setSearchTF,
  filterQuery,
  setFilterQuery,
  tab,
  filterData = null,
  handleClearAllFilter,
  handleApplyFilter,
  filterCounter = null,
  openFilter = false,
  setOpenFilter,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const filterState = useSelector((state) => state.filter.state)

  const [throttle, setThrottle] = useState(null)
  const [openCreate, setOpenCreate] = useState(null)

  /**
   * Apply filter state
   */
  useEffect(() => {
    if (!retainFilter) {
      return
    }

    //to retain filter value
    if (filterState) {
      setFilterQuery(filterState.filterQuery ?? filterQuery)
      setSearchTF(filterState.searchTF ?? searchTF)
    }
  }, [])

  /**
   * Set filter state
   */
  useEffect(() => {
    if (!retainFilter) {
      return
    }

    dispatch(
      setFilter({tab}, tab, {
        filterQuery,
        searchTF,
      })
    )
  }, [filterQuery])

  /**
   * To handle search function
   * @param event
   */
  const handleSearchTextFieldOnChange = (value) => {
    clearTimeout(throttle)
    setSearchTF(value)
    setThrottle(
      setTimeout(function () {
        setFilterQuery({
          ...filterQuery,
          searchText: value,
        })
      }, 500)
    )
  }

  return (
    <>
      {openFilter && (
        <FilterOptions
          filtersData={filterData}
          tab={tab}
          filterCondition={filterQuery.temp}
          openFullScreenDialog={openFilter}
          handleClose={() => {
            setFilterQuery({
              ...filterQuery,
              temp: {
                ...filterQuery.actual,
              },
            })
            setOpenFilter(false)
          }}
          setFilterCondition={setFilterQuery}
          handleApplyFilter={() => {
            handleApplyFilter()
            setOpenFilter(false)
          }}
          handleClearAllFilter={handleClearAllFilter}
        />
      )}
      <Grid container justifyContent={'flex-end'} spacing={2}>
        <Grid item xs={12} container direction="row">
          <Grid item xs={9} sm={8}>
            <SearchInput
              value={searchTF}
              handleOnChange={handleSearchTextFieldOnChange}
              placeholder={searchPlaceholder}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
              {['entities', 'obmeployee'].includes(tab) && (
                <IconButton title="Filters" handleOnClick={() => setOpenFilter(true)}>
                  <StyledBadge color={'secondary'} badgeContent={filterCounter}>
                    <img src={FilterIcon} alt="filters" />
                  </StyledBadge>
                </IconButton>
              )}
              {getUserRole().role === HQ_ROLE_ID && (
                <IconButton
                  title="Create"
                  handleOnClick={(event) => setOpenCreate(event.currentTarget)}
                  disabled={false}
                >
                  <img src={CreateIcon} alt="create" />
                </IconButton>
              )}
              <Menu
                id="setting-menu"
                aria-labelledby="setting-menu-button"
                anchorEl={openCreate}
                open={!!openCreate}
                onClose={() => setOpenCreate(null)}
              >
                {CREATE_METADATA.map(({label, description}) => {
                  return (
                    <MenuItem
                      key={`create-${label}`}
                      onClick={() => {
                        navigate(`Create${label}`)
                      }}
                    >
                      <Typography variant="body">
                        {label} <br />
                        <Typography variant="overline">{description}</Typography>
                      </Typography>
                    </MenuItem>
                  )
                })}
              </Menu>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <div>{children}</div>
    </>
  )
}

export default Layout
