import TSApi from 'api/TS/TSApi'

/**
 * To save comment for internal request form
 * @param comment
 * @param request
 * @return {Promise<any>}
 */
export async function saveComments(
  comment: string,
  request: {requestId: string, ticketStatus: string}
): {
  status: string,
} {
  const {data} = await TSApi.put('/comment_save/', {
    request_id: request.requestId,
    comments: comment,
    ticket_status: request.ticketStatus,
  })

  return data
}

export type FetchCommentsType = {
  comment_id: string,
  comment_date_format: string,
  full_name: string,
  comment_date: string,
  comments: string,
  status: string,
  user_email: string,
  ticket_id: number,
  request_id: number,
}
/**
 * To retrieve internal request form comments
 * @param id
 * @return {Promise<FetchCommentsType>}
 */
export async function fetchComments({id}: number): FetchCommentsType {
  if (!id) {
    return []
  }

  const {data} = await TSApi.get(`/get_comments/?request_id=${id}&tz=Asia/Singapore`)

  return data
}
