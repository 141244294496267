import {useQuery} from 'react-query'

import TSApi from 'api/TS/TSApi'
import {queryKeys} from 'hoc/ReactQuery/_constants'

export type EntitySummaryFilterType = {
  countries: {
    country_id: string,
    country_name: string,
  }[],
  segments: {
    segment_id: string,
    segment_name: string,
  }[],
  currencies: {
    currency_code: string,
    currency_description: string,
  }[],
}

/**
 * To get the Entity summary filter data
 * @return {Promise<any>}
 */
export async function getEntitySummaryFilter(): EntitySummaryFilterType {
  const {data} = await TSApi.get(`/entity_summary_filters/`)

  return data
}

interface UseEntitySummaryFilter {
  entitySummaryFilter: EntitySummaryFilterType;
  isLoading: boolean;
}

export function useEntitySummaryFilter(): UseEntitySummaryFilter {
  const {data: entitySummaryFilter = {}, isLoading} = useQuery(
    [queryKeys.entitySummaryFilter],
    () => getEntitySummaryFilter()
  )

  return {entitySummaryFilter: entitySummaryFilter[0], isLoading}
}
