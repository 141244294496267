import React from 'react'
import {Link} from 'react-router-dom'
import {blue} from 'finsys-webcomponent'

type NewTabRendererProps = {
  to: string,
  endToIdentification: string,
  linkIdentification: string,
  contentField: string,
}

const NewTabLinkRenderer = ({
  to,
  endToIdentification,
  linkIdentification,
  contentField,
  ...props
}: NewTabRendererProps) => {
  const link = `${to}/${
    props.data[endToIdentification]
      ? props.data[endToIdentification].replaceAll(' ', '') + '/'
      : ''
  }${props.data[linkIdentification]}`
  return (
    <>
      <Link
        to={link}
        style={{color: blue.secondary, textDecoration: 'underline', cursor: 'pointer'}}
      >
        {props.data[contentField]}
      </Link>
    </>
  )
}

export default NewTabLinkRenderer
