import useBreadcrumbs from 'use-react-router-breadcrumbs'
import {Breadcrumbs as Nav} from 'finsys-webcomponent'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {resetFilter} from '../../store/actions'

/**
 * @url https://www.npmjs.com/package/use-react-router-breadcrumbs
 * @returns {JSX.Element}
 * @constructor
 */
export const Breadcrumbs = () => {
  const dispatch = useDispatch()

  // if we need to override any id with their name
  const {crumbs} = useSelector((state) => state.breadcrumb)
  const filterPage = useSelector((state) => state.filter.page)

  const breadcrumbs = useBreadcrumbs().map((value, key) => {
    const getPath = () => {
      switch (value.key.toLowerCase()) {
        //to add own custom routing rules here
        default:
          return value.key
      }
    }

    const getName = () => {
      switch (value.key.toLowerCase()) {
        //to add own custom routing rules here
        default:
          if (crumbs && crumbs[value.key]) {
            return crumbs[value.key] ?? 'Retrieving..'
          }
          return value.breadcrumb.props.children.replace(/([A-Z])/g, ' $1').trim()
      }
    }

    //Override default function for FLOW
    return {
      path: getPath(),
      name: getName(),
    }
  })

  /**
   * To reset retained filter if we proceed to other pages
   */
  useEffect(() => {
    let toResetFilter = true
    //if the filter page does not exists in breadcrumb, we reset filter else retain it
    for (const [key, value] of Object.entries(breadcrumbs)) {
      if (value.path.includes(filterPage)) {
        toResetFilter = false
      }
    }
    if (toResetFilter) {
      dispatch(resetFilter())
    }
  }, [breadcrumbs])

  if (
    breadcrumbs.length === 4 &&
    [
      'bank account opening',
      'bank account closing',
      'bank account maintenance',
      'document signing request',
      'bank guarantee',
      'create',
    ].includes(breadcrumbs[2].name.toLowerCase())
  ) {
    breadcrumbs.splice(2, 1)
  }

  if (
    breadcrumbs.length === 5 &&
    ['edit', 'status update', 'viewfromemail'].includes(breadcrumbs[2].name.toLowerCase())
  ) {
    breadcrumbs.splice(2, 2)
  }

  if (
    breadcrumbs.length === 6 &&
    ['create', 'edit'].includes(breadcrumbs[2].name.toLowerCase())
  ) {
    breadcrumbs.splice(2, 3)
  }

  if (
    breadcrumbs.length === 7 &&
    ['edit', 'viewfromemail'].includes(breadcrumbs[2].name.toLowerCase())
  ) {
    breadcrumbs.splice(2, 4)
  }

  return <Nav crumbs={breadcrumbs} />
}
