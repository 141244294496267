import React, {useEffect, useState} from 'react'
import {Route, Routes, useNavigate} from 'react-router-dom'
import {protectedRouteList, unprotectedRouteList} from 'route'
import {ApplicationBar, ApplicationBox, IdleTimer} from 'finsys-webcomponent'

import {useAuth} from 'hoc/AuthContext'
import {PrivateRoutes} from 'hoc/PrivateRoutes'
import {SnackbarHandler} from 'hoc/SnackbarHandler'
import {DialogHandler} from 'hoc/DialogHandler'
import {isAppAccessSet} from '_helper/_authHelper'
import {APP_NAME, menuList} from '_helper/_constants'

// welcome message to all fellow developers
console.log(
  '%c 🥂 Built by FinSys Team 🍻',
  'background: #000; color: #FFDE00; font-size:10px; padding: 8px'
)

function BaseApp() {
  let navigate = useNavigate()
  const {user} = useAuth()

  const [pages, setPages] = useState([])
  const [permission, setPermission] = useState(isAppAccessSet())

  useEffect(() => {
    if (!user) {
      setPermission(false)
    }
    if (!permission) {
      setTimeout(() => {
        //to retrieve the permission from storage because its not set yet
        setPermission(isAppAccessSet)
      }, 1000)
    }
    if (permission) {
      setPages(menuList)
    }
  }, [permission, user])

  const handleSignOut = () => {
    navigate('/Logout', {replace: true})
  }

  return (
    <>
      <ApplicationBar
        applicationName={APP_NAME}
        authenticated={!!user}
        username={user?.name}
        email={user?.email}
        redirectToWebPortal={`${process.env.REACT_APP_WEB_PORTAL_URL}`}
        userGuideUrl={`${process.env.REACT_APP_USER_GUIDE_URL}`}
        handleLogout={handleSignOut}
        menuList={pages}
      />
      {!!user && <IdleTimer handleSignOut={handleSignOut} />}

      <SnackbarHandler>
        <DialogHandler>
          <ApplicationBox>
            <Routes>
              {unprotectedRouteList.map(({path, Component}, key) => (
                <Route exact path={path} key={key} element={Component} />
              ))}
              <Route element={<PrivateRoutes authenticated={!!user} />}>
                {protectedRouteList.map(({path, Component, params}, key) => (
                  <Route exact path={path} key={key} element={Component} />
                ))}
              </Route>
            </Routes>
          </ApplicationBox>
        </DialogHandler>
      </SnackbarHandler>
    </>
  )
}

export default BaseApp
