import React, {useEffect, useState} from 'react'
import {Grid, Stack} from '@mui/material'
import {Loading, Button, ServerSideAutocomplete, Autocomplete} from 'finsys-webcomponent'
import {useNavigate} from 'react-router-dom'

import {rowsPerPage} from 'components/AgGrid/constant'
import {OBMTable} from 'components/Tables/OBMTable'
import {fetchOnlineBankingMatrixSummary} from 'api/TS/query/OnlineBankingMatrixQueries'
import {exportOnlineBankingMatrixSummary} from 'api/TS/query/OnlineBankingMatrixQueries'
import {useSnackBar} from 'hoc/SnackbarHandler'
import useDataFetching from 'hoc/UseDataFetching'
import {useFetchCountry, useFetchEntities} from 'components/_Utils/UseFetchFunctions'
import {fetchEmployees} from 'api/TS/query/_CommonQueries'
import MultipleSelect from 'components/InternalRequest/Summary/FilterOptions/MultipleSelect'
import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {APPROVAL_MATRIX_PAGE_NAME} from '_helper/_constants'

function OnlineBankingMatrix() {
  const navigate = useNavigate()

  const [functionAccess, setFunctionAccess] = useState([])
  const [filters, setFilters] = useState({
    country: null,
    accountType: null,
    employeeName: null,
    entity: [],
  })
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    toNextPage: '',
  })
  const [page, setPage] = useState(1)
  const accountTypes = ['WC', 'ESCROW']
  const {setSnackBar} = useSnackBar()

  /**
   * To retrieve countries list
   */
  const {loading: isCountryLoading, data: countries} = useFetchCountry()

  /**
   * To retrieve bank approval matrix summary
   */
  const {
    loading: isSummaryLoading,
    data: summaryData,
    isPreviousData,
  } = useDataFetching(
    ['OBM', filters, page],
    () => fetchOnlineBankingMatrixSummary(filters, rowsPerPage, page),
    {
      refetchOnWindowFocus: true,
    }
  )

  /**
   * To retrieve entity list
   */
  const {loading: isEntityLoading, data: entities = []} = useFetchEntities([
    filters.country,
  ])

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(APPROVAL_MATRIX_PAGE_NAME, setFunctionAccess)
    }
    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  useEffect(() => {
    if (filters) {
      setPage(1)
    }
  }, [filters])

  /**
   * To export excel file
   */
  const handleExportRecords = () => {
    if (serverPaginationResult.rowData.length === 0) {
      setSnackBar((prevState) => ({
        ...prevState,
        open: true,
        message: 'No data to export!',
      }))
      return
    }
    exportOnlineBankingMatrixSummary(filters).then((response) => {
      setSnackBar((prevState) => ({
        ...prevState,
        open: true,
        type: 'success',
        message: 'Excel download successfully!',
      }))
    })
  }

  /**
   * To update the table data and format the data for the table to consume
   */
  useEffect(() => {
    let mutatedSummaryData = []

    if (summaryData && !isPreviousData) {
      mutatedSummaryData = summaryData.results

      summaryData.results.forEach(({condition_threshold}, index) => {
        if (index === 0) {
          mutatedSummaryData[index].condition_thresholdRowSpan = 1
          mutatedSummaryData[index].condition_thresholdRowSpanIndex = 0
        } else {
          if (
            JSON.stringify(summaryData.results[index - 1].condition_threshold) ===
            JSON.stringify(condition_threshold)
          ) {
            mutatedSummaryData[index].condition_thresholdRowSpan = 0
            mutatedSummaryData[index].condition_thresholdRowSpanIndex =
              mutatedSummaryData[index - 1].condition_thresholdRowSpanIndex
            mutatedSummaryData[
              mutatedSummaryData[index - 1].condition_thresholdRowSpanIndex
            ].condition_thresholdRowSpan++
          } else {
            mutatedSummaryData[index].condition_thresholdRowSpan = 1
            mutatedSummaryData[index].condition_thresholdRowSpanIndex = index
          }
        }
      })
    }

    setServerPaginationResult({
      rowData: mutatedSummaryData,
      totalPages: summaryData?.total_pages,
      totalCount: summaryData?.count,
      toNextPage: summaryData?.next,
    })
  }, [summaryData])

  const columns = [
    {
      name: 'Entity',
      fieldName: 'entity_desc',
    },
    {
      name: 'Bank',
      fieldName: 'bank_desc',
    },
    {
      name: 'Bank Acc No',
      fieldName: 'bank_account_number',
    },
    {
      name: 'Currency',
      fieldName: 'currency_code',
    },
    {
      name: 'Account Type',
      fieldName: 'account_type',
    },
    {
      name: 'Managed By',
      fieldName: 'acc_managed_by',
    },
    {
      name: 'Status',
      fieldName: 'status',
    },
    {
      name: 'Threshold',
      fieldName: 'condition_threshold',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({threshold}, index) => {
              return <p key={`${keyIndex}-${index}`}>{threshold}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Condition',
      fieldName: 'condition_threshold',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({condition}, index) => {
              return <p key={`${keyIndex}-${index}`}>{condition}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Employee Name',
      fieldName: 'payment_maker',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({fullname}, index) => {
              return <p key={`${keyIndex}-${index}`}>{fullname}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Company Designation',
      fieldName: 'payment_maker',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({designation}, index) => {
              return <p key={`${keyIndex}-${index}`}>{designation}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Employee Name',
      fieldName: 'payment_approver',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({fullname}, index) => {
              return <p key={`${keyIndex}-${index}`}>{fullname}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Company Designation',
      fieldName: 'payment_approver',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({designation}, index) => {
              return <p key={`${keyIndex}-${index}`}>{designation}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Grouping',
      fieldName: 'payment_approver',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({grouping}, index) => {
              return <p key={`${keyIndex}-${index}`}>{grouping}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Employee Name',
      fieldName: 'payment_verifier',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({fullname}, index) => {
              return <p key={`${keyIndex}-${index}`}>{fullname}</p>
            })}
          </>
        )
      },
    },
    {
      name: 'Company Designation',
      fieldName: 'payment_verifier',
      component: ({value, keyIndex}) => {
        return (
          <>
            {value.map(({designation}, index) => {
              return <p key={`${keyIndex}-${index}`}>{designation}</p>
            })}
          </>
        )
      },
    },
  ]

  if (isCountryLoading || isEntityLoading) {
    return <Loading open={true} />
  }
  return (
    <>
      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid
            container
            direction={'row'}
            columnSpacing={5}
            justifyContent={'space-between'}
            rowSpacing={2}
          >
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.country_id || ''}
                handleOnChange={(value) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    country: value,
                  }))
                }}
                value={filters.country}
                label={'Country'}
                textFieldProps={{
                  required: true,
                  placeholder: 'Please select country',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MultipleSelect
                isAll={true}
                selections={[
                  {
                    entity_name: 'All',
                    entity_code: 'All',
                    value: 'All',
                  },
                  ...entities,
                ]}
                limitTags={1}
                label="Select Entity"
                onChange={(event, value) => {
                  if (value[value.length - 1]?.value === 'All') {
                    setFilters((prevState) => ({
                      ...prevState,
                      entity: filters.entity.length === entities.length ? [] : entities,
                    }))
                  } else {
                    setFilters((prevState) => ({
                      ...prevState,
                      entity: value,
                    }))
                  }
                }}
                value={filters.entity}
                getOptionLabel={(option) => {
                  return `${option.entity_name} (${option.entity_code})`
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Autocomplete
                options={accountTypes}
                getOptionLabel={(option) => option || ''}
                handleOnChange={(value) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    accountType: value,
                  }))
                }}
                value={filters.accountType}
                label={'Select Account Type'}
                textFieldProps={{
                  required: true,
                  placeholder: 'Please select account type',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <ServerSideAutocomplete
                textFieldProps={{
                  placeholder: 'Search by name or email',
                }}
                fullWidth
                label={'Employee Name'}
                value={filters.employeeName}
                handleOnChange={(value) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    employeeName: value,
                  }))
                }}
                fetchOptions={(value) => fetchEmployees(value)}
                getOptionLabel={(option) =>
                  option.full_name ? `${option.full_name} (${option.email})` : ''
                }
                isOptionEqualToValue={(option, value) => option.email === value.email}
              />
            </Grid>

            <Grid item xs={1}>
              <Stack direction={'row'} spacing={2} sx={{width: '73px'}}>
                <Button
                  label="export"
                  onClick={() => handleExportRecords()}
                  sx={{margin: '8px 0'}}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <OBMTable
            isLoading={isSummaryLoading}
            columns={columns}
            serverPaginationResults={serverPaginationResult}
            page={page}
            // below is all the custom function
            onClickToNextPage={() => {
              if (!isPreviousData && serverPaginationResult.toNextPage !== '') {
                setPage((prevPage) => prevPage + 1)
              }
            }}
            onClickToPreviousPage={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
            onClickToFirstPage={() => setPage(1)}
            onClickToLastPage={() => {
              if (!isPreviousData && serverPaginationResult.toNextPage !== '') {
                setPage(serverPaginationResult.totalPages)
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OnlineBankingMatrix
