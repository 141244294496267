import React from 'react'
import {Dialog, FormTable} from 'finsys-webcomponent'
import {Typography} from '@mui/material'

type TicketHistoryProps = {
  history: Array<{
    action_date: string,
    date_format: string,
    status: string,
    ticket_id: number,
    full_name: string,
    user_email: string,
  }>,
  onClose: () => void,
}

/**
 * To open Ticket History Dialog
 * @param value
 * @param onClose
 * @return {JSX.Element}
 * @constructor
 */
export const TicketHistory = ({onClose, history}: TicketHistoryProps) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      type={'info'}
      title={'Ticket History'}
      content={
        <FormTable
          columns={[
            {
              name: 'Status',
              field: 'status',
            },
            {
              name: 'Action By',
              field: 'user_email',
              component: ({data}) => {
                return (
                  <>
                    {data.full_name} ({data.user_email}){' '}
                  </>
                )
              },
            },
            {
              name: 'Action Date',
              field: 'date_format',
            },
          ]}
          data={history}
          noDataLabel={<Typography color={'secondary'}>No History</Typography>}
          style={{
            minHeight: '75px',
            maxHeight: '550px',
            overflow: 'auto',
          }}
        />
      }
      buttons={[{label: 'Close', type: 'text', onClick: onClose}]}
      handleOnClose={onClose}
    />
  )
}
