import TSApi from 'api/TS/TSApi'

/**
 * To retrieve role page access for current user
 * @return {Promise<any>}
 */
export async function fetchRolePageAccess() {
  const {data} = await TSApi.get(`/my_role_page_access/`)

  return data
}
