import {encode} from 'html-entities'

import TSApi from 'api/TS/TSApi'
import {fetchBankApprovalMatrixSummaryType} from 'api/TS/query/BankApprovalMatrixQueries'

/**
 * To retrieve the bank approval matrix summary by country
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchBankApprovalMatrixSummary(
  country,
  approvedDate,
  pageName = 'summary'
): fetchBankApprovalMatrixSummaryType {
  let url = `/approval_matrix_summary/?tz=Asia/Singapore&country_id=${country}&page=${pageName}`

  if (approvedDate) {
    url += `&approved_date=${approvedDate}`
  }

  const {data} = await TSApi.get(url)

  return data
}

export type fetchBankApprovalMatrixEditSummaryType = Array<{
  country_id: string,
  current_order: string,
  editable: string,
  request_details: Array<{
    authorized_person: string,
    bank_approval_matrix_id: number,
    bank_code: string,
    bank_description: string,
    entity_id: string,
    entity_name: string,
    escrow_related_online: string,
    header_type: string,
    is_header: string,
    manual_payments: string,
    remarks: string,
    request_detail_id: number,
    request_type: string,
    status_update: string,
    system_admin: string,
    trust_online: string,
    working_capital_online: string,
  }>,
  request_id: number,
  summary: Array<{
    authorized_person: string,
    bank_approval_matrix_id: number,
    bank_code: string,
    bank_description: string,
    entity_id: string,
    entity_name: string,
    escrow_related_online: string,
    header_type: string,
    is_header: string,
    manual_payments: string,
    remarks: string,
    status_update: string,
    system_admin: string,
    trust_online: string,
    working_capital_online: string,
  }>,
}>

/**
 * To retrieve the bank approval matrix summary by request id
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchBankApprovalMatrixEditSummary(
  request_id
): fetchBankApprovalMatrixEditSummaryType {
  const {data} = await TSApi.get(
    `/matrix_edit_request/?tz=Asia/Singapore&request_id=${request_id}`
  )

  return data[0]
}

/**
 * To get the bank approval matrix payload
 * @param request
 * @param rejectComment
 * @return {{current_order: *, comments: string, approval_matrix: [], request_id: (number|string|*), country_id}}
 */
function getBankApprovalMatrixPayload(request, rejectComment) {
  const getApprovalMatrixObject = (entityId, data, type, header = 'N') => {
    const row = type ? data[type] : data

    return {
      request_detail_id: row.request_detail_id ?? null,
      bank_approval_matrix_id: row.bank_approval_matrix_id,
      entity_id: entityId,
      bank: row.bank_id ?? null,
      authorized_person: row.authorized_person,
      manual_payments: row.manual_payments,
      escrow_related_online: row.escrow_related_online,
      working_capital_online: row.working_capital_online,
      trust_online: row.trust_online,
      system_admin: row.system_admin,
      status_update: null,
      remarks: null,
      is_header: header,
      header_type: type,
      request_type: row.bank_approval_matrix_id ? data.request_type ?? 'edit' : 'add',
    }
  }
  let approvalMatrix = []

  Object.keys(request.approvalMatrix).forEach((keyName) => {
    const data = request.approvalMatrix[keyName]

    //lets add matrix & threshold first
    ;['matrix', 'threshold'].forEach((value) => {
      approvalMatrix.push(getApprovalMatrixObject(keyName, data, value, 'Y'))
    })

    //next we will loop the data
    data.data.forEach((value) => {
      approvalMatrix.push(getApprovalMatrixObject(keyName, value, null, 'N'))
    })
  })

  return {
    request_id: request.requestId,
    country_id: request.country,
    comments: `<p> ${rejectComment ? encode(rejectComment) : 'Reject'} </p>`,
    current_order: request.currentOrder,
    approval_matrix: approvalMatrix,
  }
}

/**
 * To save bank approval matrix
 * @return {Promise<DefaultSelectType>}
 */
export async function saveBankApprovalMatrix(request) {
  const requestData = getBankApprovalMatrixPayload(request)

  const {data} = await TSApi.put(`/save_matrix/`, requestData)

  return data
}

/**
 * To submit bank approval matrix
 * @return {Promise<DefaultSelectType>}
 */
export async function submitBankApprovalMatrix(request) {
  const requestData = getBankApprovalMatrixPayload(request)

  const {data} = await TSApi.put(`/update_matrix/`, requestData)

  return data
}

/**
 * To withdraw bank approval matrix
 * @return {Promise<DefaultSelectType>}
 */
export async function withdrawBankApprovalMatrix(request, withdrawComment = 'withdraw') {
  const requestData = getBankApprovalMatrixPayload(request, withdrawComment)

  const {data} = await TSApi.put(`/withdraw_matrix/`, requestData)

  return data
}

/**
 * To reject bank approval matrix
 * @return {Promise<DefaultSelectType>}
 */
export async function rejectBankApprovalMatrix(request, rejectComment = 'reject') {
  const requestData = getBankApprovalMatrixPayload(request, rejectComment)

  const {data} = await TSApi.put(`/reject_matrix/`, requestData)

  return data
}

/**
 * To save status update for bank approval matrix
 * @return {Promise<any>}
 */
export async function saveStatusUpdate(request) {
  let requestData = []
  Object.keys(request).forEach((keyName) => {
    request[keyName].data.forEach(({request_detail_id, status_update, remarks}) => {
      requestData.push({request_detail_id, status_update, remarks})
    })
  })

  const {data} = await TSApi.put(`save_status_update/`, {
    approval_request_detail: requestData,
  })

  return data
}
