import React from 'react'
import {Accordion, TextField, Autocomplete} from 'finsys-webcomponent'
import Grid from '@mui/material/Grid'

import {
  useFetchCountry,
  useFetchSegment,
  useFetchCurrencies,
} from 'components/_Utils/UseFetchFunctions'

/**
 * Component for Entity - Detail Section
 * @param formDetails
 * @param setFormDetails
 * @param errors
 * @param isDisabled
 * @returns {JSX.Element}
 * @constructor
 */

export function Details({formDetails, setFormDetails, errors, isDisabled}) {
  const {loading: isCurrenciesLoading, data: currencies} = useFetchCurrencies()
  const {loading: isCountryLoading, data: countries} = useFetchCountry()
  const {loading: isSegmentsLoading, data: segments} = useFetchSegment()
  if (isCurrenciesLoading || isCountryLoading || isSegmentsLoading) {
    return 'Entity Creation Page is loading..'
  }

  return (
    <Accordion title={'Entity Details'} expanded>
      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label={'Entity Code'}
            onChange={(value) => {
              setFormDetails((prevState) => {
                return {
                  ...prevState,
                  entity_code: value.target.value?.toUpperCase(),
                  error_entity_code: null,
                }
              })
            }}
            value={formDetails.entity_code}
            required
            error={!!formDetails.error_entity_code || errors?.entity_code}
            helperText={formDetails.error_entity_code || errors?.entity_code}
            disabled={isDisabled}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label={'Entity Name'}
            onChange={(value) => {
              setFormDetails((prevState) => {
                return {
                  ...prevState,
                  entity_name: value.target.value,
                  error_entity_name: null,
                }
              })
            }}
            value={formDetails.entity_name}
            required
            error={!!formDetails.error_entity_name || errors?.entity_name}
            helperText={formDetails.error_entity_name || errors?.entity_name}
            disabled={isDisabled}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            label={'Currency'}
            noOptionsText="Select Currency*"
            options={currencies.filter((val) => val.text !== 'ALL')}
            getOptionLabel={(option) => option.text || option}
            handleOnChange={(value) =>
              setFormDetails((prevState) => ({
                ...prevState,
                currency: value?.value ?? null,
                error_currency: null,
              }))
            }
            value={formDetails.currency}
            error={!!formDetails.error_currency || errors?.error_currency}
            disabled={isDisabled}
            textFieldProps={{
              required: true,
              error: !!formDetails.error_currency || errors?.error_currency,
              helperText: formDetails.error_currency ?? errors?.error_currency,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            label={'Country'}
            noOptionsText="Select Country*"
            options={countries}
            getOptionLabel={(option) => option.country_id || option}
            handleOnChange={(value) =>
              setFormDetails((prevState) => ({
                ...prevState,
                country_id: value?.country_id ?? null,
                error_country_id: null,
              }))
            }
            value={formDetails.country_id}
            disabled={isDisabled}
            textFieldProps={{
              required: true,
              error: !!formDetails.error_country_id || errors?.error_country_id,
              helperText: formDetails.error_country_id ?? errors?.error_country_id,
            }}
          />
        </Grid>
      </Grid>
    </Accordion>
  )
}
