import React, {useContext, useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {Loading} from 'finsys-webcomponent'

import {getPermission, isAppAccessSet} from '_helper/_authHelper'
import {isUserTokenExpired, setUserSessionToken} from 'localstorage/UserSessionToken'
import {setUserDetails} from 'localstorage/UserDetails'
import {useToken} from 'hoc/TokenHandler'

export const AuthContext = React.createContext({})

export function AuthProvider({children}) {
  const [user, setUser] = useState(undefined)
  const [session, setSession] = useState(false)
  const {setIsTokenExpired} = useToken()

  const [isLoading, setIsLoading] = useState(true)

  let location = useLocation()
  let navigate = useNavigate()

  useEffect(() => {
    // to save the entry url if we haven login
    if (!['/login', '/logout'].includes(location.pathname.toLowerCase())) {
      localStorage.setItem('entryUrl', location.pathname)
    }

    // clean up controller
    let isLogin = true

    window.gapi.load('client:auth2', () => {
      window.gapi.auth2
        .init({
          client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
          cookie_policy: 'none',
        })
        .then(async () => {
          const sessionExpire = await isUserTokenExpired()

          if (!sessionExpire) {
            await onAuthChange()

            // // listen for changes to sign in
            window.gapi.auth2.getAuthInstance().isSignedIn.listen(onAuthChange)

            // // Listener method for when the user changes.
            // // It will invoke 5 minutes before the access token expire
            window.gapi.auth2.getAuthInstance().currentUser.listen(onUserChanged)
          } else {
            setIsLoading(false)
            navigate('/Login')
          }
        })
        .catch((error) => {
          console.log(error)
          navigate('/InternalError')
          setIsLoading(false)
        })
    })

    // cancel subscription to useEffect
    return () => (isLogin = false)
  }, [])

  /**
   * Listener method for sign-out live value.
   *
   * @param {boolean} isSignedIn the updated signed out state.
   */
  const onAuthChange = async () => {
    try {
      const res = await window.gapi.auth2.getAuthInstance().isSignedIn.get()
      if (!res) {
        setIsLoading(false)
        setUser(undefined)

        // to prevent it from redirecting to logout
        if (
          ['/login', '/no-access', '/internalerror'].includes(
            location.pathname.toLowerCase()
          )
        ) {
          return
        }

        navigate('/Logout')
        return
      }

      //lets reload auth response first whenever we load page
      const reloadAuthResponse = await window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .reloadAuthResponse()

      if (reloadAuthResponse) {
        saveUserDetail(
          window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()
        )

        saveAccessToken(
          window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true)
            .access_token
        )
      }

      setSession(true)
      setIsLoading(false)
    } catch (error) {
      console.error(error)

      if (error?.type === 'tokenFailed') {
        setIsTokenExpired(true)
        setIsLoading(false)
      }
    }
  }

  /**
   * Listener method for when the user changes.
   * It will invoke 5 minutes before the access token expire
   *
   * @param user the updated user.
   */
  const onUserChanged = (user) => {
    if (!window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      return
    }

    saveAccessToken(user.getAuthResponse(true).access_token)
  }

  /**
   * To save access token
   * @param token
   */
  const saveAccessToken = (token) => {
    setUserSessionToken(token)

    //we need to check if we have access to this app
    if (!isAppAccessSet()) {
      // we need to call permission API
      getPermission(navigate)
    }
  }

  /**
   * To save user details in localstorage
   * @param user
   */
  const saveUserDetail = (user) => {
    setUserDetails(user)

    setUser({
      name: user.getName(),
      email: user.getEmail(),
    })
  }

  if (isLoading) {
    return <Loading open={isLoading} />
  }

  return <AuthContext.Provider value={{user, session}}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
