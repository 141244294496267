import React, {useEffect, useState} from 'react'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Avatar,
  Tooltip,
} from '@mui/material'
import {green, red} from '@mui/material/colors'
import {
  Done,
  RotateLeft as RotateLeftIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@mui/icons-material'
import {blue, grey} from 'finsys-webcomponent'
import moment from 'moment'

import {dateTimeFormat} from '_helper/_constants.js'

function ColorlibStepIcon(props) {
  const {active, completed, error} = props

  if (error) {
    return (
      <>
        <Avatar
          sx={{
            background: red['300'],
            width: 25,
            height: 25,
            padding: 2,
          }}
          ownerState={{completed, active}}
        >
          <RotateLeftIcon sx={{color: 'white'}} height={20} width={20} />
        </Avatar>
      </>
    )
  }
  if (completed) {
    return (
      <>
        <Avatar
          sx={{
            background: green['300'],
            width: 25,
            height: 25,
            padding: 2,
          }}
          ownerState={{completed, active}}
        >
          <Done sx={{color: 'white'}} height={20} width={20} />
        </Avatar>
      </>
    )
  }

  if (active) {
    return (
      <Avatar
        sx={{
          background: blue.secondary,

          width: 25,
          height: 25,
          padding: 2,
        }}
        ownerState={{completed, active}}
      >
        <HourglassEmptyIcon sx={{color: 'white'}} height={20} width={20} />
      </Avatar>
    )
  }
  return (
    <Avatar
      sx={{
        width: 25,
        height: 25,
        padding: 2.2,
        background: error
          ? red['300']
          : completed
          ? green['300']
          : active
          ? blue.secondary
          : grey.secondary,
      }}
      ownerState={{completed, active}}
    />
  )
}

export default function ProgressIndicator({
  isWithdrawn = false,
  summary,
  currentOrder = 0,
  rejectFromOrder = null,
  status = '',
}: {
  summary: Array<{
    approve_date: null | string,
    approve_date_format: null | string,
    created_by: string,
    creation_date: string,
    flow_ticket_id: number,
    full_name: string,
    last_update_date: string,
    last_updated_by: string,
    order: number,
    step_name: string,
    step_type: string,
    ticket_id: number,
    user_email: string,
  }>,
}) {
  const [formattedSummaryData, setFormattedSummaryData] = useState([])
  const [formattedCurrentOrder, setFormattedCurrentOrder] = useState(null)

  useEffect(() => {
    if (!summary) {
      return
    }

    let isRejectedShown = false
    const mutatedSummaryData = summary.filter((value, index) => {
      if (
        rejectFromOrder &&
        value.order == rejectFromOrder &&
        !status.includes('Verifier')
      ) {
        if (!isRejectedShown) {
          isRejectedShown = true
          return true
        } else {
          return false
        }
      }

      //draft state
      if (currentOrder === 0 && index !== 0) {
        if (summary[index - 1].step_name === value.step_name) {
          return false
        }
      }

      return !(value.skipped === 'Y' && value.approve_date === null)
    })

    setFormattedSummaryData(mutatedSummaryData)
    //we need to loop and see which order the current order is at and mutate it
    let mutatedCurrentOrder = 0
    let isSet = false
    mutatedSummaryData.map((value) => {
      if (value.step_no === parseInt(currentOrder)) {
        setFormattedCurrentOrder(mutatedCurrentOrder)
        isSet = true
      }
      mutatedCurrentOrder++
    })

    if (!isSet) {
      setFormattedCurrentOrder(parseInt(currentOrder))
    }
  }, [summary, rejectFromOrder])

  if (formattedCurrentOrder === null) {
    return 'Loading..'
  }

  return (
    <Box sx={{maxWidth: 400}}>
      <Stepper
        activeStep={isWithdrawn ? -1 : formattedCurrentOrder}
        orientation="vertical"
      >
        {formattedSummaryData.map(
          (
            {
              step_name,
              approve_date,
              user_email,
              full_name,
              step_who,
              last_update_date,
              order,
            },
            index
          ) => {
            const labelProps: {
              optional?: React.ReactNode,
              completed?: boolean,
              active?: boolean,
            } = {}

            let hidePending = false
            labelProps.active = false
            labelProps.completed = false

            let isVerifier1Step = false
            //we need to check for verifier step because verifier 1 and verifier 2 got same step order
            if (
              currentOrder === order &&
              step_name === 'Verifier 2' &&
              !formattedSummaryData[index - 1].approve_date
            ) {
              isVerifier1Step = true
            }

            if (!isWithdrawn) {
              if (isVerifier1Step) {
                labelProps.active = false
                labelProps.completed = false
                hidePending = true
              } else if (currentOrder === order && !approve_date) {
                labelProps.active = true
                labelProps.completed = false
              } else if (currentOrder >= order && approve_date) {
                labelProps.active = false
                labelProps.completed = true
              }
            }

            return (
              <Step key={`${step_name}-${index}`} expanded={true}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  StepIconProps={labelProps}
                  error={rejectFromOrder == order && status.includes(step_name)}
                >
                  {step_name}
                  {rejectFromOrder == order && status.includes(step_name) && ' Rejected'}
                </StepLabel>
                <StepContent>
                  {parseInt(currentOrder) === order && !approve_date && !hidePending && (
                    <>
                      <Typography align={'left'} variant={'caption'}>
                        Pending from
                      </Typography>
                      <br />
                      {full_name ? (
                        <>
                          <Typography align={'left'} variant={'caption'}>
                            {full_name}
                          </Typography>
                          <br />
                          <Typography align={'left'} variant={'caption'}>
                            {user_email}
                          </Typography>
                        </>
                      ) : (
                        <Tooltip title={'HQ Treasury'}>
                          <Typography
                            align={'left'}
                            variant={'caption'}
                            sx={{cursor: 'help'}}
                          >
                            HQ Treasury
                          </Typography>
                        </Tooltip>
                      )}
                    </>
                  )}
                  {!isWithdrawn && approve_date && (
                    <>
                      <Typography align={'left'} variant={'caption'}>
                        {moment.utc(approve_date).local().format(dateTimeFormat)}
                      </Typography>
                      <br />
                      <>
                        <Typography align={'left'} variant={'caption'}>
                          {full_name}
                          <br />
                          {user_email}
                        </Typography>
                      </>
                    </>
                  )}
                </StepContent>
              </Step>
            )
          }
        )}
      </Stepper>
    </Box>
  )
}
