import React from 'react'
import {Grid, Typography} from '@mui/material'

import ApprovedPersonAndManualPaymentApprovers from 'components/InternalRequest/Forms/_Common/ApprovedPersonAndManualPaymentApprovers'
import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'

/**
 * To validate Authorized Person Update field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @returns {boolean}
 */
export const validateAuthorizedPersonUpdateField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo
) => {
  const requiredFields = {
    mandatory: [
      {field: 'reason', label: 'reason'},
      {field: 'approved_person', label: 'Approved Person'},
    ],
  }

  let isFormValid = true

  for (const [key, value] of Object.entries(requiredFields.mandatory)) {
    if (!formDetail[value.field] || formDetail[value.field].length === 0) {
      setFormArrayErrorValue(
        setBankAccountMaintenanceInfo,
        index,
        'form_details',
        value.field
      )
      isFormValid = false
    }
  }

  return isFormValid
}

/**
 * Authorized Person Update form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export const AuthorizedPersonUpdate = ({
  detail,
  formIndex,
  setFormArrayValue,
  disabled,
}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
          Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ApprovedPersonAndManualPaymentApprovers
          details={detail}
          setApprovers={(value) => {
            setFormArrayValue(formIndex, 'form_details', 'approved_person', value)
          }}
          approvedPersonKey={'approved_person'}
          disabled={disabled}
          showApprovedPerson={true}
          showManualApproval={false}
        />
      </Grid>
    </Grid>
  )
}
