import React, {useEffect, useState} from 'react'
import {useMediaQuery} from '@mui/material'
import {useTheme} from '@mui/styles'
import {AgGridTable} from 'finsys-webcomponent'

import {
  columnDefs,
  frameworkComponents,
} from 'components/Metadata/Summary/ObmEmployee/ag-grid-constant'
import Layout from 'components/Metadata/Summary/Layout'
import {useOBMEmployeeSummary} from 'components/Metadata/_hooks/useObmEmployeeSummary'
import {getPagination} from 'localstorage/PaginationSettings'

export default function ObmEmployee() {
  //table
  const theme = useTheme()
  const isIpad = useMediaQuery(theme.breakpoints.down('md'))

  // filter
  const [searchTF, setSearchTF] = useState('')
  //to cater filter
  const [filterQuery, setFilterQuery] = useState({
    searchText: '',
    temp: {
      conflict: false,
    },
    actual: {
      conflict: false,
    },
  })
  const [openFilter, setOpenFilter] = useState(false)
  const [filterCounter, setFilterCounter] = useState(null)

  //to cater AG-GRID
  const [gridApi, setGridApi] = useState(null)
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    page: 1,
    rowsPerPage: getPagination().rowPerPage,
    toNextPage: null,
  })

  const {obmEmployee, isLoading} = useOBMEmployeeSummary({
    rowsPerPage: serverPaginationResult.rowsPerPage,
    page: serverPaginationResult.page,
    searchText: filterQuery.searchText,
    filterQuery: filterQuery.actual,
  })

  /**
   * To update the AG-Grid table data
   */
  useEffect(() => {
    if (obmEmployee?.results) {
      setServerPaginationResult((prevState) => ({
        ...prevState,
        rowData: obmEmployee.results,
        totalPages: obmEmployee.total_pages,
        totalCount: obmEmployee.count,
        toNextPage: obmEmployee.next,
      }))
    }
  }, [obmEmployee])

  /**
   * To set the actual value for filter
   */
  const handleApplyFilter = () => {
    let counter = 0
    // only if user click on apply we will set the actual value
    setFilterQuery((prevState) => {
      return {...prevState, actual: prevState.temp}
    })
    let filterObject = {}

    for (const [key, value] of Object.entries(filterQuery.temp)) {
      if (value !== null && value !== '' && value?.length !== 0) {
        counter++
      }
      filterObject[key] = value
    }

    setFilterCounter(counter !== 0 ? counter : null)
  }

  /**
   * To handle the temporary storage of data in the filter menu
   * To clear all the temporary value in the filter menu
   */
  const handleClearAllFilter = () => {
    setFilterQuery((prevState) => {
      return {
        ...prevState,
        temp: {
          conflict: false,
        },
      }
    })
  }

  /**
   * Default method for AG-Grid
   * @param params
   * @return {Promise<void>}
   */
  const onGridReady = async (params) => {
    setGridApi(params.api)
  }

  return (
    <Layout
      tab={'obmeployee'}
      pageInset={205}
      retainFilter={true}
      searchPlaceholder={'Search for name, email'}
      searchTF={searchTF}
      setSearchTF={setSearchTF}
      setFilterQuery={setFilterQuery}
      filterQuery={filterQuery}
      handleClearAllFilter={handleClearAllFilter}
      handleApplyFilter={handleApplyFilter}
      filterCounter={filterCounter}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
    >
      <AgGridTable
        onGridReady={onGridReady}
        gridApi={gridApi}
        serverPaginationResult={serverPaginationResult}
        setServerPaginationResult={setServerPaginationResult}
        page={serverPaginationResult.page}
        isLoading={isLoading}
        pageInset={isIpad ? 230 : 215}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        suppressRowTransform={true}
        onColumnResized={null}
        onColumnVisible={null}
      />
    </Layout>
  )
}
