import React from 'react'
import {FileUpload as WebFileUpload} from 'finsys-webcomponent'

import {useSnackBar} from 'hoc/SnackbarHandler'
import {maxFileSizeForServer} from '_helper/_constants'

export const FileUpload = ({
  formDetails,
  isCallingApi,
  setIsCallingApi,
  disabled,
  open,
  handleClose,
  hideDeleteButton = false,
  hideDeleteButtonValue,
  hideDeleteButtonField,
  //custom function
  handleFilePreview,
  handleFileUpload,
  handleFileDownload,
  handleFileDelete,
  uploadToServer = true,
}) => {
  const {setSnackBar} = useSnackBar()
  return (
    <WebFileUpload
      maxFile={1}
      uploadFiles={!disabled}
      setSnackBar={setSnackBar}
      maxFileSizeForServer={maxFileSizeForServer}
      uploadToServer={uploadToServer}
      open={open}
      handleClose={handleClose}
      columns={[
        {
          name: 'File Name',
          field: 'attachment_filename',
          width: '80%',
        },
      ]}
      handleFileUpload={(files) => handleFileUpload(files)}
      handleFileDownload={(index) => handleFileDownload(index)}
      handleFilePreview={(index) => handleFilePreview(index)}
      handleFileDelete={(index) => handleFileDelete(index)}
      fileData={formDetails}
      isFileUploading={isCallingApi?.type === 'upload-file'}
      setIsFileUploading={() =>
        setIsCallingApi({
          loading: true,
          type: 'upload-file',
        })
      }
      resetFileUploading={() =>
        setIsCallingApi({
          loading: false,
          type: null,
        })
      }
      hideDeleteButtonValue={hideDeleteButtonValue}
      hideDeleteButtonField={hideDeleteButtonField}
      hideDeleteButton={hideDeleteButton}
    />
  )
}
