import React from 'react'
import {Grid, Stack} from '@mui/material'
import {Button, Autocomplete, FormTable, IconButton, Label} from 'finsys-webcomponent'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  GroupingAutocomplete,
  ReasonAutocomplete,
  RoleAutocomplete,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/constant'
import EmployeeFreeSoloCreateOptionDialogWithDesignation from 'components/InternalRequest/Forms/_Common/EmployeeFreeSoloCreateOptionDialogWithDesignation'
import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'

/**
 * To validate creation field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @param setFormErrorDetail
 * @returns {boolean}
 */
export const validateCreationField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo,
  setFormErrorDetail
) => {
  const requiredFields = {
    employeeDetails: [
      {field: 'reason', label: 'Reason'},
      {field: 'fullname', label: 'Full Name'},
      {field: 'company_designation', label: 'Company Designation'},
    ],
    bankAccounts: [
      {field: 'bank_id', label: 'Bank Code'},
      {field: 'account_number', label: 'Account Number'},
      {field: 'acc_managed_by', label: 'Account Managed By'},
      {field: 'role', label: 'Role'},
      {field: 'group', label: 'Group'},
    ],
  }

  let isFormValid = true
  let isEmpValid = true
  let isBankValid = true
  //lets validate form details

  if (formDetail.emp_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'emp_details',
      'Please add at least 1 Employee Detail'
    )
    isFormValid = false
  } else {
    for (const empIndex in formDetail.emp_details) {
      for (const [key, value] of Object.entries(requiredFields.employeeDetails)) {
        if (
          !formDetail.emp_details[empIndex][value.field] ||
          formDetail.emp_details[empIndex][value.field].length === 0
        ) {
          if (isEmpValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'emp_details',
              'Please fill up all the required fields'
            )
            isEmpValid = false
          }
          isFormValid = false
          setFormErrorDetail('emp_details', index, empIndex, value.field)
        }
      }
    }
  }

  if (formDetail.bank_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'bank_details',
      'Please add at least 1 Bank Acccount Detail'
    )
    isFormValid = false
  } else {
    for (const empIndex in formDetail.bank_details) {
      for (const [key, value] of Object.entries(requiredFields.bankAccounts)) {
        if (
          !formDetail.bank_details[empIndex][value.field] ||
          formDetail.bank_details[empIndex][value.field].length === 0
        ) {
          if (isBankValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'bank_details',
              'Please fill up all the required fields'
            )
            isBankValid = false
          }
          isFormValid = false
          setFormErrorDetail('bank_details', index, empIndex, value.field)
        }
      }
    }
  }

  return isFormValid
}

/**
 * Creation form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param setArrayValue
 * @param disabled
 * @param reason
 * @param grouping
 * @param roles
 * @param OBMbanks
 * @param bankAccount
 * @param designation
 * @param accountMangedBy
 * @returns {JSX.Element}
 * @constructor
 */
export const Creation = ({
  detail,
  formIndex,
  setFormArrayValue,
  setArrayValue,
  disabled,
  reason,
  grouping,
  roles,
  OBMbanks,
  bankAccount,
  designation,
  accountMangedBy,
}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label label={`Bank Account`} required={true} error={detail.error_bank_details} />
        <FormTable
          tableKey={'creation_bank_account'}
          columns={[
            {
              name: 'Bank',
              width: '30%',
              field: 'bank_id',
              component: ({value, dataIndex, error}) => (
                <Autocomplete
                  options={OBMbanks}
                  getOptionLabel={(option) => option?.text || ''}
                  handleOnChange={(value) => {
                    ;['account_number', 'acc_managed_by', 'currency_code'].forEach(
                      (fieldName) => {
                        setArrayValue(
                          formIndex,
                          'bank_details',
                          dataIndex,
                          fieldName,
                          null
                        )
                      }
                    )

                    setArrayValue(formIndex, 'bank_details', dataIndex, 'bank_id', value)
                  }}
                  value={value}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Bank Account Number',
              field: 'account_number',
              width: '20%',
              component: ({value, dataIndex, error, data}) => {
                return (
                  <Autocomplete
                    options={
                      bankAccount[detail.bank_details[dataIndex].bank_id?.value]
                        ? [
                            {
                              acc_managed_by: null,
                              account_number: 'All Account',
                              currency_code: 'ALL',
                              account_type: null,
                              entity_id: 'ALL',
                              entity_name: '',
                            },
                            ...(bankAccount[
                              detail.bank_details[dataIndex].bank_id?.value
                            ] ?? []),
                          ]
                        : detail.bank_details[dataIndex].bank_id?.text ===
                          'All Banks (ALL)'
                        ? [
                            {
                              acc_managed_by: null,
                              account_number: 'All Account',
                              currency_code: 'ALL',
                              account_type: null,
                              entity_id: null,
                              entity_name: '',
                            },
                          ]
                        : []
                    }
                    getOptionLabel={(option) => {
                      // console.log(option)
                      return option.account_number &&
                        option.account_number !== 'All Account'
                        ? `${option.account_number} - ${option.entity_code}`
                        : option.account_number === 'All Account'
                        ? option.account_number
                        : ''
                    }}
                    handleOnChange={(value) => {
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'account_number',
                        value?.account_number
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'entity_code',
                        value?.entity_code
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'entity_name',
                        value?.entity_name
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'entity_id',
                        value?.entity_id
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'currency_code',
                        value?.currency_code
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'account_type',
                        value?.account_type
                      )
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'acc_managed_by',
                        value?.acc_managed_by
                      )
                    }}
                    value={data}
                    textFieldProps={{
                      error: !!error,
                      sx: {
                        '& label': {display: 'none'},
                        '& .MuiInput-root': {mt: 0},
                      },
                    }}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              name: 'Account Type',
              width: '5%',
              field: 'account_type',
            },
            {
              name: 'Currency',
              width: '5%',
              field: 'currency_code',
            },
            {
              name: 'Managed By',
              field: 'acc_managed_by',
              width: '12%',
              dependentValue: 'account_number',
              component: ({value, dataIndex, data, error}) => {
                const selectManagedBy = data?.account_number === 'All Account'

                if (selectManagedBy) {
                  return (
                    <Autocomplete
                      options={accountMangedBy}
                      getOptionLabel={(option) => option.text ?? option}
                      handleOnChange={(value) => {
                        setArrayValue(
                          formIndex,
                          'bank_details',
                          dataIndex,
                          'acc_managed_by',
                          value.value
                        )
                      }}
                      value={value ?? ''}
                      textFieldProps={{
                        error: !!error,
                        sx: {
                          '& label': {display: 'none'},
                          '& .MuiInput-root': {mt: 0},
                        },
                      }}
                      disabled={!selectManagedBy || disabled}
                    />
                  )
                } else {
                  return value?.text === null ? '' : value?.text ?? value
                }
              },
            },
            {
              name: 'New Role(To Be)',
              width: '12%',
              field: 'role',
              component: ({value, dataIndex, error}) => {
                return (
                  <RoleAutocomplete
                    options={roles}
                    table={true}
                    value={value}
                    onChange={(value) =>
                      setArrayValue(formIndex, 'bank_details', dataIndex, 'role', value)
                    }
                    error={error}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              name: 'New Grouping(To Be)',
              width: '12%',
              field: 'group',
              component: ({value, dataIndex, error}) => {
                return (
                  <GroupingAutocomplete
                    options={grouping}
                    table={true}
                    value={value}
                    onChange={(value) =>
                      setArrayValue(
                        formIndex,
                        'bank_details',
                        dataIndex,
                        'group',
                        value?.value
                      )
                    }
                    error={error}
                    disabled={disabled}
                  />
                )
              },
            },
            {
              ...(!disabled && {
                name: '',
                field: '',
                width: '1%',
                component: ({dataIndex}) => {
                  return (
                    <IconButton
                      title="Delete"
                      handleOnClick={() => {
                        let newBankDetails = detail.bank_details
                        newBankDetails.splice(dataIndex, 1)
                        setFormArrayValue(
                          formIndex,
                          'form_details',
                          'bank_details',
                          newBankDetails
                        )
                      }}
                    >
                      <DeleteIcon style={{color: '#CE6B72'}} />
                    </IconButton>
                  )
                },
              }),
            },
          ]}
          data={detail.bank_details}
        />
        {!disabled && (
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button
              label={'Add Bank Details'}
              type={'secondary'}
              size={'small'}
              onClick={() => {
                const newBankDetails = [
                  ...detail.bank_details,
                  {
                    role: '',
                    group: '',
                    bank_id: '',
                    entity_id: null,
                    currency_code: '',
                    account_number: '',
                    acc_managed_by: '',
                    account_type: '',
                  },
                ]
                setFormArrayValue(
                  formIndex,
                  'form_details',
                  'bank_details',
                  newBankDetails
                )
              }}
            />
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label
          label={`Employee Details`}
          required={true}
          error={detail.error_emp_details}
        />
        <FormTable
          tableKey={'creation_employee'}
          columns={[
            {
              name: 'Reason',
              width: '25%',
              field: 'reason',
              component: ({value, dataIndex, error}) => {
                return (
                  <ReasonAutocomplete
                    options={reason}
                    disabled={disabled}
                    table={true}
                    value={value}
                    error={error}
                    onChange={(value) =>
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'reason',
                        value?.text
                      )
                    }
                  />
                )
              },
            },
            {
              name: 'Name',
              field: 'fullname',
              width: '40%',
              component: ({value, dataIndex, error}) => {
                return (
                  <EmployeeFreeSoloCreateOptionDialogWithDesignation
                    disabled={disabled}
                    data={value}
                    index={dataIndex}
                    onChange={(value) => {
                      setArrayValue(formIndex, 'emp_details', dataIndex, 'fullname', {
                        full_name: value?.name,
                        email: value?.email,
                      })

                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'email',
                        value?.email
                      )
                    }}
                    keyName={'emp_details'}
                    subKeyName={'full_name'}
                    table={true}
                    titles={designation}
                    error={error}
                  />
                )
              },
            },
            {
              name: 'Company Designation',
              field: 'company_designation',
              component: ({value, dataIndex, error}) => {
                return (
                  <Autocomplete
                    options={designation}
                    getOptionLabel={(option) => option.text || option}
                    handleOnChange={(value) =>
                      setArrayValue(
                        formIndex,
                        'emp_details',
                        dataIndex,
                        'company_designation',
                        value?.value
                      )
                    }
                    value={value}
                    textFieldProps={{
                      error: !!error,
                      sx: {
                        '& label': {display: 'none'},
                        '& .MuiInput-root': {mt: 0},
                      },
                    }}
                    disabled={disabled}
                  />
                )
              },
            },
            ...(!disabled
              ? [
                  {
                    name: '',
                    field: '',
                    width: '1%',
                    component: ({dataIndex}) => {
                      return (
                        <IconButton
                          title={'Delete'}
                          handleOnClick={() => {
                            let newEmpDetails = detail.emp_details
                            newEmpDetails.splice(dataIndex, 1)
                            setFormArrayValue(
                              formIndex,
                              'form_details',
                              'emp_details',
                              newEmpDetails
                            )
                          }}
                        >
                          <DeleteIcon style={{color: '#CE6B72'}} />
                        </IconButton>
                      )
                    },
                  },
                ]
              : []),
          ]}
          data={detail.emp_details}
        />

        {!disabled && (
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button
              label={'Add Employee Details'}
              type={'secondary'}
              size={'small'}
              onClick={() => {
                const newEmpDetails = [
                  ...detail.emp_details,
                  {
                    company_designation: '',
                    reason: '',
                    fullname: '',
                    email: '',
                    is_new: '',
                  },
                ]
                setFormArrayValue(formIndex, 'form_details', 'emp_details', newEmpDetails)
              }}
            />
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
