import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import API from 'api/TS/TSApi'
import {setUserSessionToken} from 'localstorage/UserSessionToken'
import {useToken} from 'hoc/TokenHandler'
import {useErrorStatus} from 'hoc/Error/ErrorHandler'

const NETWORK_ERROR = 999
const SERVER_ERROR = 1000

async function fulfillWithTimeLimit(timeLimit, task, failureValue) {
  let timeout
  const timeoutPromise = new Promise((resolve, reject) => {
    timeout = setTimeout(() => {
      resolve(failureValue)
    }, timeLimit)
  })

  const response = await Promise.race([task, timeoutPromise])

  if (timeout) {
    clearTimeout(timeout)
  }

  return response
}

const AxiosInterceptor = ({children}) => {
  const navigate = useNavigate()
  const {setIsTokenExpired} = useToken()
  const {setError, setRetry} = useErrorStatus()

  useEffect(() => {
    const resInterceptor = (response) => {
      return response
    }

    //ERR_NAME_NOT_RESOLVED
    const errInterceptor = async (error) => {
      const errorResponseData = error?.response?.data
      const originalRequest = error?.config

      // NETWORK ERROR
      if (!errorResponseData) {
        // IF API GOT Network error we try to call is_alive
        if (error.message === 'Network Error') {
          if (!originalRequest._retry) {
            originalRequest._retry = true
            originalRequest.url = '/is_alive/'
            originalRequest.method = 'get'
            originalRequest.data = null

            return API(originalRequest)
          } else {
            setError(NETWORK_ERROR)
          }
        }
      }

      // to catch token expire
      if (
        errorResponseData?.detail === "Your credentials aren't allowed" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true

        try {
          //we will wait avg 1s for Google response - considering country with slower internet
          let isRefreshed = await fulfillWithTimeLimit(
            1000,
            window.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse(),
            true
          )
          if (isRefreshed) {
            let token = window.gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getAuthResponse(true).access_token
            setUserSessionToken(token)
            originalRequest.headers.authorization = 'Bearer' + token

            return API(originalRequest)
          }
        } catch (error) {
          console.error(error)
          if (error?.type === 'tokenFailed') {
            setIsTokenExpired(true)
          }
        }
      }

      if ([500, 401].includes(error.response.status) && !originalRequest._isAlive) {
        originalRequest._isAlive = true

        API.get('is_alive/')
          .then(({data}) => {
            if (data?.is_alive) {
              setRetry('refresh')
            }
          })
          .catch(function (error) {
            if (error?.data?.detail === "Your credentials aren't allowed") {
              setRetry('refresh')
            } else {
              setError(SERVER_ERROR)
            }
          })
      } else {
        setError(error.response.status)
      }

      return Promise.reject(error.response)
    }

    const interceptor = API.interceptors.response.use(resInterceptor, errInterceptor)

    return () => API.interceptors.response.eject(interceptor)
  }, [navigate])

  return children
}

export {AxiosInterceptor}
