import React, {useEffect, useState} from 'react'
import {
  Accordion,
  Autocomplete,
  FormTable,
  TextField,
  IconButton,
  Label,
} from 'finsys-webcomponent'
import {useMutation} from 'react-query'
import {makeStyles} from '@mui/styles'
import {
  Badge,
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {Description as DescriptionIcon} from '@mui/icons-material'
import {NumericFormat} from 'react-number-format'

import {
  deleteAttachment,
  downloadAttachment,
  previewAttachment,
  uploadRequestAttachment,
} from 'api/TS/query/AttachmentQueries'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {AddButton, RemoveButton} from 'components/_Common'
import OutlineInput from 'components/OutlineInput'
import {FileUpload} from 'components/_Common/FileUpload'
import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'
import {useFetchOBMBankAccount} from 'components/_Utils/UseFetchFunctions'
import {SectionLoading} from 'components/InternalRequest/Forms/_Common/SectionLoading'
import {useAuth} from 'hoc/AuthContext'

export const useStyles = makeStyles((theme) => ({
  badgeColorPrimary: {
    fontSize: '9px',
    height: '18px',
    width: '18px',
    minWidth: 'unset',
    color: 'white',
    background: '#68b6ef',
  },
}))

type BankAccountClosingFormType = {
  acc_closing_form_details: Array<{
    acc_closing_id: number,
    bank_id: string,
    bank_description: string,
    currency_code: string,
    account_number: string,
    purpose_of_bank_acc: string,
    reason_closure: string,
    acc_no_to_transfer: string,
    entity_to_transfer: string,
    amount_to_transfer: string,
    status: string,
    local_to_confirm: string,
    remarks: string,
    attachment: Array<{
      attachment_id: string,
      attachment_filename: string,
    }>,
    attachment_count: number,
    notify: string,
  }>,
}

/**
 * Bank Account Closing form details
 * @type {{bank: null, openWithPreferredBank: null, numberOfAccountToOpen: null, chequeBookRequiredExplanation: null, applyingMT940Explanation: null, accountManagedByExplanation: null, applyingMT940: null, purposeOfBankAccount: null, openWithPreferredBankExplanation: null, chequeBookRequired: null, accountManagedBy: null}}
 */
export function getBankFormDetails(subCategory) {
  return {
    account_number: null,
    currency_code: null,
    bank_id: null,
    purpose_of_bank_acc: null,
    reason_closure: null,
    sub_category: subCategory,
    entity_id: null,
    entity_name: null,
    attachment: [],
    acc_no_to_transfer: null,
    entity_to_transfer: null,
    amount_to_transfer: null,
    account_status: null,
    local_to_confirm: null,
    remarks: '',
    notify: null,
  }
}

/**
 * To return all the fields for Bank Account closing
 * @return {{formDetails: [{accountNumber: {text: string, value: string}, localToConfirm: undefined, notify: string, accountStatus: undefined, bank: undefined, amountToTransfer: undefined, attachment: *[], reasonOfClosure: undefined, purposeOfAccount: undefined, transferToEntityName: undefined, currency: {text: string, value: string}, transferToAccountNumber: {text: string, value: string}, remarks: undefined}]}}
 * @param formData
 */
export function getBankAccountClosingFormFields(formData: BankAccountClosingFormType) {
  let details = []

  const getData = (data) => {
    return {
      account_number: data.account_number,
      currency_code: data.currency_code
        ? {
            value: data.currency_code?.value ?? data.currency_code,
            text: data.currency_code.text ?? data.currency_code,
          }
        : null,
      bank_id: data.bank_description
        ? {bank_id: data.bank_id, bank_description: data.bank_description}
        : data.bank_id,
      entity_id: data.entity_id,
      entity_name: data.entity_name,
      purpose_of_bank_acc: data.purpose_of_bank_acc ?? null,
      reason_closure: data.reason_closure ?? null,
      sub_category:
        formData.sub_category_1 === data.sub_category
          ? data.sub_category
          : formData.sub_category_1 === 'WC + ESCROW'
          ? data.sub_category ?? null
          : formData.sub_category_1,
      acc_no_to_transfer: data.acc_no_to_transfer
        ? {
            bank_account_number:
              data.acc_no_to_transfer?.bank_account_number ?? data.acc_no_to_transfer,
            entity_to_transfer:
              data.entity_to_transfer?.entity_name ?? data.entity_to_transfer,
          }
        : null,
      entity_to_transfer: data.entity_to_transfer
        ? {
            entity_name: data.entity_to_transfer?.entity_name ?? data.entity_to_transfer,
          }
        : null,
      amount_to_transfer: data.amount_to_transfer ?? null,
      account_status:
        data.account_status ??
        data.local_to_confirm ??
        `Pending closing, requested on ${formData.submission_date_format}`,
      local_to_confirm: data.local_to_confirm ?? null,
      remarks: data.remarks ?? '',
      attachment: data.attachment
        ? data.attachment.map((file, key) => {
            return {
              attachment_filename: file.attachment_filename,
              attachment_id: file.attachment_id,
            }
          })
        : [],
      acc_closing_id: data.acc_closing_id,
      notify: data.notify,
    }
  }

  if (formData?.form_details?.length > 0) {
    details = formData.form_details.map((data) => {
      return getData(data)
    })
  } else if (formData?.acc_closing_form_details?.length > 0) {
    details = formData.acc_closing_form_details.map((data) => {
      return getData(data)
    })
  } else {
    details = [
      getBankFormDetails(
        formData.sub_category_1 === 'WC + ESCROW' ? null : formData.sub_category_1
      ),
    ]
  }

  return {
    form_details: details,
  }
}

type BankAccountClosingInfoType = {
  bankAccountClosingInfo: Array<{
    account_number: string,
    currency_code: {text: string, value: string},
    bank_id: {bank_id: string, bank_description: string},
    entity_id: string,
    entity_name: string,
    purpose_of_bank_acc: string,
    reason_closure: string,
    sub_category: string,
    acc_no_to_transfer: {bank_account_number: string, entity_to_transfer: string},
    entity_to_transfer: {entity_name: string},
    amount_to_transfer: string,
    account_status: string,
    local_to_confirm: string,
    remarks: string,
    attachment: Array<{
      attachment_id: string,
      attachment_filename: string,
    }>,
    attachment_count: number,
    notify: string,
    acc_closing_id: number,
  }>,
  setBankAccountClosingInfo: () => void,
  current_order: number,
}

/**
 * To validate the fields for Bank Account Closing
 * @param bankAccountClosingInfo
 * @param subCategory
 */
export function validateBankAccountClosing({
  bankAccountClosingInfo,
  setBankAccountClosingInfo,
  currentOrder,
}: BankAccountClosingInfoType) {
  const requiredFields = {
    form: [
      {field: 'account_number', label: 'bank account number'},
      {field: 'currency_code', label: 'currency'},
      {field: 'purpose_of_bank_acc', label: 'purpose of account'},
      {field: 'reason_closure', label: 'reason of closure'},
      {field: 'acc_no_to_transfer', label: 'transfer to account number'},
      {field: 'sub_category', label: 'sub_category'},
    ],
    amount: [{field: 'amount_to_transfer', label: 'amount to transfer'}],
    accountStatus: [{field: 'local_to_confirm', label: 'local to confirm'}],
  }

  if (bankAccountClosingInfo.form_details.length === 0) {
    return {
      type: 'error',
      message: `Need to have at least 1 bank account closing form`,
    }
  }

  let isFormValid = true
  //lets validate form details first
  for (const [key, value] of Object.entries(requiredFields.form)) {
    let formIndex = 0
    for (const details of bankAccountClosingInfo.form_details) {
      formIndex++
      if (!details[value.field] || details[value.field].length === 0) {
        setFormArrayErrorValue(
          setBankAccountClosingInfo,
          formIndex,
          'form_details',
          value.field
        )
        isFormValid = false
      }
    }
  }

  let formIndex = 0

  let bankAccountToXferTo = []
  for (const details of bankAccountClosingInfo.form_details) {
    formIndex++
    // lets check if bank account is the same as bank acc to transfer to
    if (details.account_number === details.acc_no_to_transfer?.bank_account_number) {
      setFormArrayErrorValue(
        setBankAccountClosingInfo,
        formIndex,
        'form_details',
        'acc_no_to_transfer',
        `Cannot be the same as bank account number`
      )
      isFormValid = false
    }

    // duplicate account number - bank account number - transfer to account number - bank id
    if (
      bankAccountToXferTo[
        `${details.account_number}-${details.acc_no_to_transfer?.bank_account_number}-${details.bank_id?.bank_id}`
      ]
    ) {
      const duplicateIndex =
        bankAccountToXferTo[
          `${details.account_number}-${details.acc_no_to_transfer?.bank_account_number}-${details.bank_id?.bank_id}`
        ]

      setFormArrayErrorValue(
        setBankAccountClosingInfo,
        formIndex,
        'form_details',
        'account_number',
        `Duplicate bank account closure! - Form ${duplicateIndex}`
      )
      setFormArrayErrorValue(
        setBankAccountClosingInfo,
        duplicateIndex,
        'form_details',
        'account_number',
        `Duplicate bank account closure! - Form ${formIndex} `
      )
      isFormValid = false
    }

    // duplicate account number - bank account number - transfer to account number - bank id
    if (
      bankAccountToXferTo[
        `${details.acc_no_to_transfer?.bank_account_number}-${details.account_number}-${details.bank_id?.bank_id}`
      ]
    ) {
      const recursiveIndex =
        bankAccountToXferTo[
          `${details.acc_no_to_transfer?.bank_account_number}-${details.account_number}-${details.bank_id?.bank_id}`
        ]

      ;['account_number', 'acc_no_to_transfer'].forEach((field) => {
        setFormArrayErrorValue(
          setBankAccountClosingInfo,
          formIndex,
          'form_details',
          field,
          `Recursive bank account closure! - Form ${recursiveIndex} `
        )

        setFormArrayErrorValue(
          setBankAccountClosingInfo,
          recursiveIndex,
          'form_details',
          field,
          `Recursive bank account closure! - Form ${formIndex} `
        )
      })

      isFormValid = false
    }

    bankAccountToXferTo[
      `${details.account_number}-${details.acc_no_to_transfer?.bank_account_number}-${details.bank_id?.bank_id}`
    ] = formIndex
    if (
      details.amount_to_transfer === '' ||
      (details.acc_no_to_transfer?.bank_account_number !== 'N/A' &&
        details.amount_to_transfer === 0) ||
      details.amount_to_transfer < 0
    ) {
      // recursive bank transfer - transfer to account number - bank account number

      // we need to check if they re recurisve
      setFormArrayErrorValue(
        setBankAccountClosingInfo,
        formIndex,
        'form_details',
        'amount_to_transfer',
        details.amount_to_transfer
          ? `Invalid amount - cannot be negative`
          : details.amount_to_transfer === 0
          ? 'Amount to transfer have to be more than 0'
          : 'This field is required'
      )
      isFormValid = false
    }

    if (
      details.acc_no_to_transfer?.bank_account_number === 'N/A' &&
      details.amount_to_transfer > 0
    ) {
      // recursive bank transfer - transfer to account number - bank account number

      // we need to check if they re recurisve
      setFormArrayErrorValue(
        setBankAccountClosingInfo,
        formIndex,
        'form_details',
        'amount_to_transfer',
        'Amount have to be 0'
      )
      isFormValid = false
    }
  }

  // if step 6
  if (currentOrder === 7) {
    //lets validate form details first
    for (const [key, value] of Object.entries(requiredFields.accountStatus)) {
      let accountStatusIndex = 0

      for (const details of bankAccountClosingInfo.form_details) {
        accountStatusIndex++
        if (!details[value.field] || details[value.field].length === 0) {
          setFormArrayErrorValue(
            setBankAccountClosingInfo,
            accountStatusIndex,
            'form_details',
            value.field
          )

          isFormValid = false
        }

        // we need to check for remarks if local to confirm is withdrawn
        if (details.local_to_confirm === 'Withdrawn' && !details.remarks) {
          setFormArrayErrorValue(
            setBankAccountClosingInfo,
            accountStatusIndex,
            'form_details',
            'remarks'
          )

          isFormValid = false
        }
      }
    }
  }

  if (!isFormValid) {
    return {
      type: 'error',
    }
  }

  return {
    type: 'success',
  }
}

export default function BankAccountClosing({
  bankAccountClosingInfo,
  setBankAccountClosingInfo,
  isDisabled,
  invalidateTicket,
  isCallingApi,
  setIsCallingApi,
  setOpenDeleteFileDialog,
  setFilePreviewDialog,
}) {
  const {user} = useAuth()
  const {setSnackBar} = useSnackBar()

  const classes = useStyles()
  const {form_details} = bankAccountClosingInfo
  const disabled = isDisabled
  const localToConfirmStatus = [null, 'Completed', 'Withdrawn']
  const [bankAccount, setBankAccount] = useState([])
  const [bankAccountToXfer, setBankAccountToXfer] = useState([])

  const [openViewAttachmentDialog, setOpenViewAttachmentDialog] = useState(false)
  const [openUploadAttachmentAccount, setOpenUploadAttachmentAccount] = useState(null)
  const {loading: isOBMAccLoading, data: obmBankAccount} = useFetchOBMBankAccount(
    bankAccountClosingInfo?.entity_id?.map(({entity_id}) => entity_id),
    bankAccountClosingInfo.sub_category_1,
    false
  )
  const {loading: isOBMAccToXferLoading, data: obmBankAccountToXfer} =
    useFetchOBMBankAccount(
      bankAccountClosingInfo?.entity_id?.map(({entity_id}) => entity_id),
      bankAccountClosingInfo.sub_category_1,
      true
    )

  /**
   * Map account code to the diff entity
   */
  useEffect(() => {
    if (obmBankAccount?.length > 0) {
      const setValue = (value) => ({
        value: value.currency_code,
        text: value.currency_code,
        bankId: value.bank_id,
        bankDesc: value.bank_desc,
        entity_id: value.entity_id,
        entity_name: value.entity_name,
        account_type: value.account_type,
      })

      let data = obmBankAccount.reduce((initialD, subsequentD) => {
        let keyValue = {[subsequentD.bank_account_number]: [setValue(subsequentD)]}

        if (!initialD.bankAccount) {
          initialD.bankAccount = {}
        }

        if (!initialD.bankAccount[subsequentD.account_type]) {
          initialD.bankAccount = {
            ...initialD.bankAccount,
            [subsequentD.account_type]: {},
          }
        }

        if (
          initialD.bankAccount[subsequentD.account_type][subsequentD.bank_account_number]
        ) {
          initialD.bankAccount[subsequentD.account_type][
            subsequentD.bank_account_number
          ].push(setValue(subsequentD))
        } else {
          initialD.bankAccount[subsequentD.account_type] = {
            ...initialD.bankAccount[subsequentD.account_type],
            ...keyValue,
          }
        }

        return initialD
      }, [])

      setBankAccount(data.bankAccount)
    }
  }, [obmBankAccount])

  /**
   * Get unique bank account number
   */
  useEffect(() => {
    if (obmBankAccountToXfer) {
      const uniqueBankAccount = [
        ...new Map(
          obmBankAccountToXfer.map((item) => [
            item.bank_account_number,
            {
              bank_account_number: item.bank_account_number,
              entity_id: item.entity_id,
              entity_name: item.entity_name,
              account_type: item.account_type,
            },
          ])
        ).values(),
      ]
      setBankAccountToXfer(uniqueBankAccount)
    }
  }, [obmBankAccountToXfer])

  /**
   * To set form value
   *
   * @param index
   * @param key
   * @param subKey
   * @param value
   */
  const setFormArrayValue = (index, key, subKey, value, error = true) => {
    setBankAccountClosingInfo((prevState) => {
      const handleChangeDetails = [...prevState[key]]
      handleChangeDetails[index][subKey] = value
      if (handleChangeDetails[index][`error_${subKey}`]) {
        handleChangeDetails[index][`error_${subKey}`] = undefined
      }

      return {
        ...prevState,
        [key]: handleChangeDetails,
      }
    })
  }

  /**
   * To upload attachment
   * @type {UseMutationResult<unknown, unknown, void, unknown>}
   */
  const handleFileUpload = useMutation(
    (file) =>
      uploadRequestAttachment({
        ticketId: bankAccountClosingInfo.ticket_id,
        detailId: openUploadAttachmentAccount?.id,
        attachmentType: 'acc_closing',
        user: user.email,
        attachment: file,
      }),
    {
      onSuccess: (result) => {
        setOpenUploadAttachmentAccount((prevState) => {
          const addAttachment = [...prevState.data]
          addAttachment.push({
            attachment_filename: result.attachment_filename,
            attachment_id: result.attachment_id,
          })

          return {
            ...prevState,
            data: addAttachment,
          }
        })

        //To update the attachment value without rerendering
        const dataIndex = form_details.findIndex(
          (value) => value.account_number === result.detail_id
        )

        let newAttachment = form_details[dataIndex].attachment

        newAttachment = [
          ...newAttachment,
          {
            attachment_filename: result.attachment_filename,
            attachment_id: result.attachment_id,
          },
        ]
        setFormArrayValue(dataIndex, 'form_details', 'attachment', newAttachment)

        setSnackBar((prevState) => ({
          ...prevState,
          open: true,
          type: 'success',
          message: 'Attachment uploaded successfully!',
        }))
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To download file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDownloadAttachment = useMutation(({id}) => downloadAttachment({id}), {
    onError: (err) => {
      console.log(err)
      setSnackBar({
        open: true,
      })
    },
    onSettled: () => {
      setIsCallingApi({
        loading: false,
        type: null,
      })
    },
  })

  /**
   * To handle file preview
   * @type {UseMutationResult<unknown, unknown, void, unknown>}
   */
  const handlePreviewFile = useMutation(({id, name}) => previewAttachment({id}), {
    onSuccess: (result, {name}) => {
      setFilePreviewDialog({
        value: result,
        name: name,
        open: true,
      })
    },
  })

  /**
   * To delete attachment
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDeleteFile = useMutation(({id, index}) => deleteAttachment({id}), {
    onSuccess: (result, variables) => {
      setOpenUploadAttachmentAccount((prevState) => {
        const removeAttachment = [...prevState.data]
        removeAttachment.splice(variables.index, 1)

        return {
          ...prevState,
          data: removeAttachment,
        }
      })

      //To update the attachment value without rerendering
      const dataIndex = form_details.findIndex(
        (value) => value.account_number === openUploadAttachmentAccount?.id
      )
      const attachmentDataIndex = form_details[dataIndex].attachment.findIndex(
        (value) => value.attachment_filename === result.attachment_filename
      )
      let newAttachment = form_details[dataIndex].attachment
      newAttachment.splice(attachmentDataIndex, 1)
      setFormArrayValue(dataIndex, 'form_details', 'attachment', newAttachment)

      setSnackBar({
        open: true,
        type: 'success',
        message: 'Attachment deleted successfully',
      })
    },
    onError: (err, variables, context) => {
      setSnackBar((prevState) => ({...prevState, open: true}))
    },
    onSettled: () => {
      invalidateTicket()
    },
  })

  if (isOBMAccLoading || isOBMAccToXferLoading) {
    return <SectionLoading label={'Bank Account Closing'} />
  }

  return (
    <>
      <FileUpload
        formDetails={openUploadAttachmentAccount?.data}
        disabled={
          bankAccountClosingInfo.current_order !== 7 ||
          bankAccountClosingInfo.editable !== 'Y'
        }
        isCallingApi={isCallingApi}
        setIsCallingApi={setIsCallingApi}
        open={openViewAttachmentDialog}
        handleClose={() => setOpenViewAttachmentDialog(false)}
        handleFileDownload={(index) => {
          const file = openUploadAttachmentAccount.data[index]
          handleDownloadAttachment.mutate({
            id: file.attachment_id,
          })
        }}
        handleFileDelete={(index) => {
          const file = openUploadAttachmentAccount.data[index]
          setOpenDeleteFileDialog({
            open: true,
            index: index,
            func: () => handleDeleteFile.mutate({id: file.attachment_id, index}),
          })
        }}
        handleFileUpload={(file) => handleFileUpload.mutate(file)}
        handleFilePreview={(index) => {
          const file = openUploadAttachmentAccount.data[index]
          handlePreviewFile.mutate({
            id: file.attachment_id,
            name: file.attachment_filename,
          })
        }}
        uploadToServer={
          bankAccountClosingInfo.current_order === 7 ||
          bankAccountClosingInfo.editable === 'Y'
        }
        hideDeleteButtonValue={
          bankAccountClosingInfo.current_order !== 7 ||
          bankAccountClosingInfo.editable !== 'Y'
            ? user.email
            : ''
        }
        hideDeleteButtonField={'created_by'}
        hideDeleteButton={
          bankAccountClosingInfo.current_order === 7 ||
          bankAccountClosingInfo.editable === 'Y'
        }
      />

      <Accordion title={'Bank Account Closing Form'} expanded>
        <div style={{marginBottom: '10px'}}>
          {Array.isArray(form_details) &&
            form_details?.map((detail, index) => {
              return (
                <Accordion
                  title={`Form ${index + 1}`}
                  secondaryTitle={true}
                  expanded
                  key={index}
                  id={`form-${index}`}
                >
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Autocomplete
                            options={
                              bankAccountClosingInfo.sub_category_1 === 'WC + ESCROW'
                                ? ['WC', 'ESCROW']
                                : []
                            }
                            handleOnChange={(value) => {
                              setFormArrayValue(
                                index,
                                'form_details',
                                'sub_category',
                                value
                              )
                            }}
                            value={detail.sub_category}
                            textFieldProps={{
                              required: true,
                              error: !!detail.error_sub_category,
                              helperText: detail.error_sub_category,
                            }}
                            label="Select Sub Category"
                            disabled={
                              disabled ||
                              bankAccountClosingInfo.sub_category_1 !== 'WC + ESCROW'
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Autocomplete
                            options={
                              detail.sub_category
                                ? bankAccount[detail.sub_category]
                                  ? Object.keys(bankAccount[detail.sub_category])
                                      .map((k) => k)
                                      .filter((value) => {
                                        return (
                                          value !==
                                          detail.acc_no_to_transfer?.acc_no_to_transfer
                                        )
                                      })
                                  : []
                                : []
                            }
                            handleOnChange={(value) => {
                              setFormArrayValue(
                                index,
                                'form_details',
                                'account_number',
                                value
                              )
                              setFormArrayValue(
                                index,
                                'form_details',
                                'currency_code',
                                null,
                                false
                              )
                              setFormArrayValue(index, 'form_details', 'bank_id', null)
                              setFormArrayValue(
                                index,
                                'form_details',
                                'entity_id',
                                value
                                  ? bankAccount[detail.sub_category][value][0].entity_id
                                  : null
                              )
                              setFormArrayValue(
                                index,
                                'form_details',
                                'entity_name',
                                value
                                  ? bankAccount[detail.sub_category][value][0].entity_name
                                  : null
                              )
                            }}
                            value={detail.account_number}
                            textFieldProps={{
                              required: true,
                              error: !!detail.error_account_number,
                              helperText:
                                detail.error_account_number ??
                                (bankAccountClosingInfo.sub_category_1 ===
                                  'WC + ESCROW' && !detail.sub_category
                                  ? 'Please select sub category first'
                                  : ''),
                            }}
                            label={'Select Bank Account'}
                            disabled={
                              disabled ||
                              (bankAccountClosingInfo.sub_category_1 === 'WC + ESCROW' &&
                                !detail.sub_category)
                            }
                            isOptionEqualToValue={(option, value) => {
                              return option?.bank_account_number ?? option === value
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            label={'Entity Name'}
                            value={detail.entity_name}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Autocomplete
                            options={
                              detail.sub_category
                                ? bankAccount[detail.sub_category]
                                  ? bankAccount[detail.sub_category][
                                      detail.account_number
                                    ] ?? []
                                  : []
                                : []
                            }
                            getOptionLabel={(option) => option.text || ''}
                            handleOnChange={(value) => {
                              setFormArrayValue(
                                index,
                                'form_details',
                                'currency_code',
                                value
                              )
                              if (value) {
                                setFormArrayValue(index, 'form_details', 'bank_id', {
                                  bank_id: value.bankId,
                                  bank_description: value.bankDesc,
                                })
                              } else {
                                setFormArrayValue(index, 'form_details', 'bank_id', null)
                              }
                            }}
                            value={detail.currency_code}
                            label={'Select Currency'}
                            textFieldProps={{
                              required: true,
                              error: !!detail.error_currency_code,
                              helperText: detail.error_currency_code,
                            }}
                            disabled={
                              disabled ||
                              (bankAccountClosingInfo.sub_category_1 === 'WC + ESCROW' &&
                                !detail.sub_category) ||
                              !detail.account_number
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            label={'Bank'}
                            value={detail.bank_id?.bank_description}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            multiline
                            maxRows={3}
                            label={'Purpose of bank account'}
                            value={detail.purpose_of_bank_acc}
                            onChange={(value) => {
                              setFormArrayValue(
                                index,
                                'form_details',
                                'purpose_of_bank_acc',
                                value.target.value
                              )
                            }}
                            required={true}
                            disabled={disabled}
                            error={!!detail.error_purpose_of_bank_acc}
                            helperText={
                              detail.error_purpose_of_bank_acc
                                ? form_details.error_purpose_of_bank_acc
                                : !disabled
                                ? `Press 'enter' for new line`
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            multiline
                            maxRows={3}
                            label={'Reason for closure'}
                            value={detail.reason_closure}
                            onChange={(value) => {
                              setFormArrayValue(
                                index,
                                'form_details',
                                'reason_closure',
                                value.target.value
                              )
                            }}
                            required={true}
                            disabled={disabled}
                            error={!!detail.error_reason_closure}
                            helperText={
                              detail.error_reason_closure
                                ? form_details.error_reason_closure
                                : !disabled
                                ? `Press 'enter' for new line`
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant={'subtitle2'}
                            sx={{fontWeight: 600}}
                            align={'left'}
                          >
                            Bank Account details to transfer remaining cash balance
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Autocomplete
                            options={[
                              {
                                bank_account_number: 'N/A',
                                entity_id: null,
                                entity_name: 'N/A',
                              },
                              ...(bankAccountToXfer.filter(
                                (value) =>
                                  value.account_number !== detail.account_number &&
                                  value.account_type === detail.sub_category
                              ) ?? []),
                            ]}
                            getOptionLabel={(option) =>
                              option.bank_account_number || option
                            }
                            handleOnChange={(value) => {
                              setBankAccountClosingInfo((prevState) => {
                                const handleChangeDetails = [...prevState.form_details]
                                handleChangeDetails[index].acc_no_to_transfer = value
                                handleChangeDetails[index].entity_to_transfer = {
                                  entity_name: value ? value.entity_name : null,
                                }
                                handleChangeDetails[index].amount_to_transfer =
                                  value?.bank_account_number === 'N/A' ? 0 : ''

                                if (handleChangeDetails[index].error_acc_no_to_transfer) {
                                  handleChangeDetails[index].error_acc_no_to_transfer =
                                    null
                                }
                                if (handleChangeDetails[index].error_entity_to_transfer) {
                                  handleChangeDetails[index].error_entity_to_transfer =
                                    null
                                }
                                if (handleChangeDetails[index].error_amount_to_transfer) {
                                  handleChangeDetails[index].error_amount_to_transfer =
                                    null
                                }

                                return {
                                  ...prevState,
                                  form_details: handleChangeDetails,
                                }
                              })
                            }}
                            value={detail.acc_no_to_transfer}
                            label={'Select Bank Account To Transfer'}
                            textFieldProps={{
                              required: true,
                              error: !!detail.error_acc_no_to_transfer,
                              helperText: detail.error_acc_no_to_transfer,
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            label={'Entity Name'}
                            value={detail.entity_to_transfer?.entity_name}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <NumericFormat
                            value={detail.amount_to_transfer}
                            thousandSeparator=","
                            decimalSeparator={'.'}
                            onValueChange={({value}) => {
                              setFormArrayValue(
                                index,
                                'form_details',
                                'amount_to_transfer',
                                value === '' ? null : parseFloat(value)
                              )
                            }}
                            customInput={TextField}
                            label={'Amount to transfer'}
                            required={true}
                            disabled={
                              disabled ||
                              detail.acc_no_to_transfer?.bank_account_number === 'N/A'
                            }
                            error={!!detail.error_amount_to_transfer}
                            helperText={detail.error_amount_to_transfer}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    {!disabled && (
                      <CardActions style={{padding: '0 23px 16px'}}>
                        <RemoveButton
                          onClick={() => {
                            setBankAccountClosingInfo((prevState) => {
                              const addFormDetails = [...prevState.form_details]
                              addFormDetails.splice(index, 1)

                              return {
                                ...prevState,
                                form_details: addFormDetails,
                              }
                            })
                          }}
                        />
                      </CardActions>
                    )}
                  </Card>
                </Accordion>
              )
            })}
        </div>
        {!disabled && (
          <AddButton
            onClick={() =>
              setBankAccountClosingInfo((prevState) => {
                const addFormDetails = [...prevState.form_details]
                addFormDetails.push(
                  getBankFormDetails(
                    bankAccountClosingInfo.sub_category_1 === 'WC + ESCROW'
                      ? null
                      : bankAccountClosingInfo.sub_category_1
                  )
                )

                return {
                  ...prevState,
                  form_details: addFormDetails,
                }
              })
            }
          />
        )}
      </Accordion>
      {bankAccountClosingInfo.ticket_id &&
        !['Draft', 'Withdrawn'].includes(bankAccountClosingInfo.ticket_status) && (
          <Accordion
            title={
              <>
                Account Status
                <span
                  aria-hidden="true"
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                >
                  &thinsp;*
                </span>
              </>
            }
            expanded={bankAccountClosingInfo.current_order >= 6}
          >
            {form_details?.error_account_status && (
              <Label label={''} error={form_details?.error_account_status} />
            )}
            {bankAccountClosingInfo.current_order === 7 && (
              <Typography variant={'caption'}>
                Please click on Submit button to close the ticket after you filled up all
                the details
              </Typography>
            )}
            <FormTable
              tableKey={'form-account-closing'}
              columns={[
                {
                  name: 'Account Number',
                  width: '12%',
                  field: 'account_number',
                },
                {
                  name: 'Entity',
                  width: '8%',
                  field: 'entity_name',
                },
                {
                  name: 'Account Type',
                  width: '12%',
                  field: 'sub_category',
                },
                {
                  name: 'Status',
                  width: '10%',
                  field: 'account_status',
                },
                {
                  name: 'Local To Confirm',
                  width: '10%',
                  field: 'local_to_confirm',
                  component: ({value, dataIndex, data, ...props}) => (
                    <Select
                      disabled={
                        bankAccountClosingInfo.current_order !== 7 ||
                        bankAccountClosingInfo.editable !== 'Y'
                      }
                      error={data?.error_local_to_confirm}
                      onChange={(value) => {
                        setFormArrayValue(
                          dataIndex,
                          'form_details',
                          'local_to_confirm',
                          value.target.value
                        )
                      }}
                      value={value ?? null}
                      displayEmpty
                      variant={'outlined'}
                      style={{width: '100%', fontSize: '0.9em', paddingLeft: '8px'}}
                    >
                      {localToConfirmStatus.map((value, index) => {
                        return (
                          <MenuItem style={{fontSize: '0.9em'}} key={index} value={value}>
                            {value}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  ),
                },
                {
                  name: 'Remarks',
                  field: 'remarks',
                  component: ({value, dataIndex, error}) => (
                    <OutlineInput
                      disabled={
                        bankAccountClosingInfo.current_order !== 7 ||
                        bankAccountClosingInfo.editable !== 'Y'
                      }
                      style={{fontSize: '0.9em', paddingLeft: '8px'}}
                      multiline
                      minRows={1}
                      maxRows={2}
                      error={!!error}
                      value={value ?? ''}
                      onChange={(value) => {
                        setFormArrayValue(
                          dataIndex,
                          'form_details',
                          'remarks',
                          value.target.value
                        )
                      }}
                    />
                  ),
                },
                {
                  name: 'Attachment',
                  width: '80px',
                  field: 'attachment',
                  component: ({data}) => (
                    <IconButton
                      title={'Open Attachment'}
                      handleOnClick={() => {
                        setOpenViewAttachmentDialog(true)
                        setOpenUploadAttachmentAccount({
                          id: data.account_number,
                          data: data.attachment,
                        })
                      }}
                    >
                      <Badge
                        badgeContent={`${data.attachment.length}`}
                        classes={{badge: classes.badgeColorPrimary}}
                      >
                        <DescriptionIcon />
                      </Badge>
                    </IconButton>
                  ),
                },
                {
                  name: 'Notify',
                  field: 'notify',
                  width: '130px',
                },
              ]}
              data={form_details}
            />
          </Accordion>
        )}
    </>
  )
}
