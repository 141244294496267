import {useQuery} from 'react-query'

import TSApi from 'api/TS/TSApi'
import {queryKeys} from 'hoc/ReactQuery/_constants'
import {SummaryPagination} from '_helper/_types'
import type {Bank} from '_helper/_types'

export type BankSummaryType = {
  ...SummaryPagination,
  results: Bank[],
}

/**
 * To get the bank summary
 * @return {Promise<any>}
 */
export async function getBankSummary(
  rowsPerPage: number,
  page: number,
  searchText?: string
): BankSummaryType {
  let filterQueryString = '',
    pageQuery = ''

  if (searchText) {
    filterQueryString = filterQueryString.concat(
      '&search_text=',
      encodeURIComponent(searchText)
    )
  }

  if (page > 0) {
    pageQuery = '&page=' + page
  }

  const {data} = await TSApi.get(
    `/banks_summary/?limit=${rowsPerPage}${filterQueryString.trim()}${pageQuery}`
  )

  return data
}

interface UseBankSummary {
  bankSummary: BankSummaryType;
  isLoading: boolean;
}

export function useBankSummary({rowsPerPage, page, searchText}): UseBankSummary {
  const {data: bankSummary = null, isLoading} = useQuery(
    [queryKeys.bankSummary, rowsPerPage, page, searchText],
    () => getBankSummary(rowsPerPage, page, searchText),
    {
      refetchOnWindowFocus: true,
    }
  )

  return {bankSummary, isLoading}
}
