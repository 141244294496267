import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

/**
 * To redirect to /:id whenever we hit '/InternalRequest/:Category/:id (old url)'
 * @return {JSX.Element}
 * @constructor
 */
const RedirectOldTicketUrl = () => {
  const navigate = useNavigate()
  let {id} = useParams()

  useEffect(() => {
    navigate(`/InternalRequest/${id}`)
  }, [])

  return <></>
}

export default RedirectOldTicketUrl
