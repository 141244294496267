/**
 * To get user basic information like name and username from google auth
 * @returns {{email: string, username: string}}
 */
export function getUserDetails(): {email: string, username: string} {
  return {
    email: localStorage.getItem('email'),
    username: localStorage.getItem('username'),
  }
}

/**
 * To set user basic information like name and username from google auth
 * @param user
 */
export function setUserDetails(user): void {
  localStorage.setItem('email', user.getEmail())
  localStorage.setItem('username', user.getName())
}

/**
 * To remove user basic information like name and username from google auth
 */
export function removeUserDetails(): void {
  localStorage.removeItem('email')
  localStorage.removeItem('username')
}
