import React, {useEffect, useState} from 'react'
import {Stack} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {Button} from 'finsys-webcomponent'

import {useSnackBar} from 'hoc/SnackbarHandler'
import {Details} from 'components/Metadata/Entity/Details'
import {useCreateEntity} from 'components/Metadata/_hooks/useCreateEntity'
import {HQ_ROLE_ID, METADATA_PAGE_NAME} from '_helper/_constants'
import {getUserRole} from 'localstorage/UserRole'
import {retrievePageFunctionAccess} from '_helper/_authHelper'

/**
 * Create page for Entity
 * @return {JSX.Element}
 * @constructor
 */
const Create = () => {
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()
  const [formDetails, setFormDetails] = useState({
    entity_code: null,
    entity_name: null,
    currency: null,
    country_id: null,
    segment_id: null,
  })
  const [functionAccess, setFunctionAccess] = useState([])

  const createEntity = useCreateEntity()

  /**
   * To redirect non hq user to forbidden page
   */
  useEffect(() => {
    if (getUserRole().role !== HQ_ROLE_ID) {
      navigate('/Forbidden')
    }
  }, [])

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(METADATA_PAGE_NAME, setFunctionAccess)
    }

    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * To validate form
   */
  const validateForm = () => {
    let isGenericFieldsValid = true

    const requiredFields = [
      'entity_code',
      'entity_name',
      'currency',
      'country_id',
      'segment_id',
    ]

    requiredFields.forEach((field) => {
      if (!formDetails[field] || formDetails[field].length === 0) {
        setFormDetails((prevState) => ({
          ...prevState,
          [`error_${field}`]: 'This is a required field',
        }))

        isGenericFieldsValid = false
      }
    })

    if (!isGenericFieldsValid) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Missing input! Please fill up all the required input`,
      })
    } else {
      createEntity.mutate(formDetails)
    }
  }

  return (
    <>
      <Details
        isDisabled={false}
        errors={createEntity.errors}
        setFormDetails={setFormDetails}
        formDetails={formDetails}
      ></Details>
      <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
        <Button
          disabled={createEntity.isLoading}
          label={'Cancel'}
          type={'text'}
          onClick={() => navigate('/Metadata')}
        ></Button>
        {getUserRole().role === HQ_ROLE_ID && (
          <Button
            label={'Create'}
            onClick={() => validateForm()}
            disabled={createEntity.isLoading}
            loading={createEntity.isLoading}
          ></Button>
        )}
      </Stack>
    </>
  )
}

export default Create
