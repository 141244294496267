import {useState} from 'react'
import {useMutation, UseMutateFunction} from 'react-query'
import {useNavigate} from 'react-router-dom'

import AIApi from 'api/TS/TSApi'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {FormErrorPayload, FormSuccessPayload} from '_helper/_types'
import {_getError} from '_helper/_getError'
import type {Bank} from '_helper/_types'

export type BankPayload = {
  ...FormSuccessPayload,
  bank_id: number,
}

/**
 * To create risk event
 * @return {Promise<any>}
 */
export async function createBank(formDetails): BankPayload | FormErrorPayload {
  const {data} = await AIApi.post(`banks/`, {
    bank_code: formDetails.bank_code,
    bank_description: formDetails.bank_description,
  })

  return data
}

interface UseCreateBank {
  mutate: UseMutateFunction;
  isLoading: boolean;
  errors: [];
}

export function useCreateBank(): UseCreateBank {
  const {setSnackBar} = useSnackBar()
  const navigate = useNavigate()
  const [errors, setErrors] = useState(null)

  const {mutate, isLoading} = useMutation(
    (bankDetails: Bank) => createBank(bankDetails),
    {
      onSuccess: (result: BankPayload | FormErrorPayload) => {
        setSnackBar({
          open: true,
          type: result.status === 'SUCCESS' ? 'success' : 'error',
          message:
            result.status === 'SUCCESS'
              ? result.message
              : 'Validation failed! Please check those fields that are in red.',
        })

        if (result.status === 'SUCCESS') {
          navigate(`/Metadata/Bank/${result.bank_id}`)
        } else {
          setErrors(_getError(result.errors))
        }
      },
    }
  )

  return {mutate, isLoading, errors}
}
