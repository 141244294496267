/**
 * To set error value during validation
 * @param setValue
 * @param index
 * @param key
 * @param subKey
 * @param value
 */
export const setFormArrayErrorValue = (
  setValue,
  index,
  key,
  subKey,
  value = 'This field is required'
) => {
  setValue((prevState) => {
    const handleChangeDetails = prevState[key] ? [...prevState[key]] : []

    handleChangeDetails[index - 1][`error_${subKey}`] = value
    return {
      ...prevState,
      [key]: handleChangeDetails,
    }
  })
}

/**
 * Check if user is able to edit form
 * User is only able to edit at step 0 - draft and step 3 - verifier 1 and verifier 2
 * @param currentOrder
 * @param editable
 * @param hqVerifierApprovalDate
 * @param hqVerifier2ApprovalDate
 * @return {boolean}
 */
export function isDisabled(
  currentOrder,
  editable,
  hqVerifierApprovalDate,
  hqVerifier2ApprovalDate
) {
  return !(
    [0, 3].includes(currentOrder) &&
    editable === 'Y' &&
    (hqVerifierApprovalDate === null || hqVerifier2ApprovalDate === null)
  )
}

/**
 * To display error validation message for IRF forms
 * @param label
 * @param formIndex
 * @return {{type: string, message: string}}
 */
export function errorMessage(label, formIndex) {
  let msg = `Missing ${label.charAt(0).toUpperCase() + label.slice(1)}`

  if (formIndex) {
    msg += `  for Form ${formIndex}!`
  }

  return {
    type: 'error',
    message: msg,
  }
}
