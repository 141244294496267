import React, {useState, useEffect} from 'react'
import {Grid, Stack, Typography} from '@mui/material'
import {blue, Button, DetailedLayout, TextField} from 'finsys-webcomponent'
import moment from 'moment'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {Info} from '@mui/icons-material'

import {useSnackBar} from 'hoc/SnackbarHandler'
import {useSaveBank} from 'components/Metadata/_hooks/useSaveBank'
import {useBank} from 'components/Metadata/_hooks/useBank'
import {setBreadcrumbRecordName} from 'store/actions'
import {Details} from 'components/Metadata/Bank/Details'
import {dateTimeFormat, HQ_ROLE_ID, METADATA_PAGE_NAME} from '_helper/_constants'
import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {getUserRole} from 'localstorage/UserRole'
import {SIDEBAR_DEFAULT, SIDEBAR_INFORMATION} from 'pages/Metadata'

/**
 * Bank Detail
 * @return {JSX.Element}
 * @constructor
 */
const Bank = () => {
  const {setSnackBar} = useSnackBar()
  let {bankId} = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [sidebarDetails, setSidebarDetails] = useState(SIDEBAR_INFORMATION)
  const [functionAccess, setFunctionAccess] = useState([])
  const [formDetails, setFormDetails] = useState({
    bank_id: null,
    bank_code: null,
    bank_description: null,
  })

  const {bank} = useBank(parseInt(bankId))
  const saveBank = useSaveBank()

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(METADATA_PAGE_NAME, setFunctionAccess)
    }
    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * To validate form
   */
  const validateForm = () => {
    let isGenericFieldsValid = true

    const requiredFields = ['bank_code', 'bank_description']

    requiredFields.forEach((field) => {
      if (!formDetails[field] || formDetails[field].length === 0) {
        setFormDetails((prevState) => ({
          ...prevState,
          [`error_${field}`]: 'This is a required field',
        }))

        isGenericFieldsValid = false
      }
    })

    if (!isGenericFieldsValid) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Missing input! Please fill up all the required input`,
      })
    } else {
      saveBank.mutate(formDetails)
    }
  }

  /**
   * To initialize data
   */
  useEffect(() => {
    if (!bank) {
      return
    }

    //To set breadcrumb
    dispatch(
      setBreadcrumbRecordName({
        [location.pathname]: `${bank.bank_description}`,
      })
    )

    setFormDetails(bank)
  }, [bank])

  return (
    <DetailedLayout
      mainContent={
        <>
          <Details
            isDisabled={getUserRole().role !== HQ_ROLE_ID}
            errors={saveBank.errors}
            setFormDetails={setFormDetails}
            formDetails={formDetails}
          />
          {getUserRole().role === HQ_ROLE_ID && (
            <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
              <Button
                label={'Save'}
                onClick={() => validateForm()}
                disabled={saveBank.isLoading}
                loading={saveBank.isLoading}
              />
            </Stack>
          )}
        </>
      }
      sideDrawerContent={
        <Grid container direction={'row'} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
              General
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField label={'Bank ID'} value={formDetails.bank_id} disabled={true} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Last Updated On'}
              value={moment
                .utc(formDetails.last_update_date)
                .local()
                .format(dateTimeFormat)}
              disabled={true}
            />
          </Grid>
        </Grid>
      }
      collapseSideDrawer={[
        {
          icon: Info,
          label: 'Information',
          iconStyle: {
            color:
              sidebarDetails.type === SIDEBAR_INFORMATION.type
                ? blue.secondary
                : 'inherit',
          },
          onClick: () => setSidebarDetails(SIDEBAR_INFORMATION),
        },
      ]}
      defaultOnToggleSideMenu={(open) => {
        setSidebarDetails(open ? SIDEBAR_DEFAULT : SIDEBAR_INFORMATION)
      }}
    />
  )
}

export default Bank
