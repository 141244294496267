import React, {useRef} from 'react'
import {TableWrapper} from 'components/Tables/TableWrapper'
import {
  MemoizedTableFixHeader,
  MemoizedTableRow,
  NoRowToShow,
} from 'components/Tables/_helper'
import {Stack, Typography} from '@mui/material'
import {Loading} from 'finsys-webcomponent'

export function BAMTable({
  type = 'view',
  selectedCell,
  setSelectedCell,
  toEditCell,
  setToEditCell,
  editableHeaderColumns,
  columns,
  country,
  page,
  serverPaginationResults,
  onClickToNextPage,
  onClickToPreviousPage,
  onClickToFirstPage,
  onClickToLastPage,
  editable,
  addMatrix,
  isLoading,
  ...props
}) {
  // used useRef to keep a mutable object with the current state.
  const selectedCellRef = useRef()
  selectedCellRef.current = selectedCell

  const toEditCellRef = useRef()
  toEditCellRef.current = toEditCell

  const memorizedHandleOnClick = React.useCallback(
    (event, targetId) => {
      // to detect if user is pressing ctrl / cmd and clicking
      if (event.ctrlKey || event.metaKey) {
        let mutateCell = [...selectedCellRef.current]

        if (event.detail >= 1) {
          if (!mutateCell.includes(targetId)) {
            mutateCell.push(targetId)
          }

          setSelectedCell(mutateCell)
        }
        return
      }

      // user is jus clicking the cell
      setSelectedCell([targetId])

      // we re click the same cell jus let it be
      if (selectedCellRef.current.includes(toEditCellRef.current)) {
        return
      }

      // detail 2 = double click
      if (event.detail >= 2) {
        setToEditCell(targetId)
      }

      if (event.detail < 2 && toEditCell) {
        setToEditCell(null)
      }
    },
    [selectedCell]
  )

  const handleOnMouseDown = (event, targetId) => {
    // we only wanna trigger cell select/ highlight during edit mode
    if (!type) {
      return
    }

    memorizedHandleOnClick(event, targetId)
  }

  return (
    <TableWrapper
      type={type}
      serverPaginationResults={serverPaginationResults}
      page={page}
      onClickToNextPage={onClickToNextPage}
      onClickToPreviousPage={onClickToPreviousPage}
      onClickToFirstPage={onClickToFirstPage}
      onClickToLastPage={onClickToLastPage}
      {...(props.style && {style: props.style})}
    >
      {isLoading ? (
        <Loading open={true} fullScreen={false} />
      ) : (
        <>
          <div className={`bam-table ${type !== 'edit' && 'no-border'}`}>
            <table key={type ?? 'view'}>
              <MemoizedTableFixHeader columns={columns} />
              <MemoizedTableRow
                columns={columns}
                editableHeaderColumns={editableHeaderColumns}
                rows={serverPaginationResults.rowData}
                type={type}
                editable={editable}
                toEditCell={toEditCell}
                selectedCell={selectedCell}
                handleOnMouseDown={handleOnMouseDown}
              />
            </table>
          </div>
          {serverPaginationResults?.rowData?.length === 0 &&
            (type === 'view' ? (
              <NoRowToShow
                {...(!country && {helperText: 'Please select a country to view'})}
              />
            ) : (
              <Stack
                sx={{top: '-55%', margin: '0 auto', position: 'relative'}}
                alignItems={'center'}
              >
                <Typography
                  color={'secondary'}
                  sx={{cursor: 'pointer', p: 2}}
                  // @ts-ignore
                  onClick={addMatrix}
                >
                  No Entity, Click here to add new Entity!
                </Typography>
              </Stack>
            ))}
        </>
      )}
    </TableWrapper>
  )
}
