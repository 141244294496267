import {Card, CardActions, CardContent, Grid, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Accordion, Autocomplete} from 'finsys-webcomponent'

import {AddButton, DuplicateButton, RemoveButton} from 'components/_Common'
import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'
import {
  useFetchAccountManagedBy,
  useFetchEmployeeGroup,
  useFetchEmployeeTitle,
  useFetchFormMetadata,
  useFetchMatrixConditions,
  useFetchMatrixThresholds,
  useFetchOBMBankAccount,
  useFetchOBMBankNames,
} from 'components/_Utils/UseFetchFunctions'
import {
  Creation,
  validateCreationField,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/TypeOfChangeForm/Creation'
import {
  Deletion,
  validateDeletionField,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/TypeOfChangeForm/Deletion'
import {
  UserRoleUpdate,
  validateUserRoleUpdateField,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/TypeOfChangeForm/UserRoleUpdate'
import {
  SystemAdminUpdate,
  validateSystemAdminUpdateField,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/TypeOfChangeForm/SystemAdminUpdate'
import {
  AuthorizedPersonUpdate,
  validateAuthorizedPersonUpdateField,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/TypeOfChangeForm/AuthorizedPersonUpdate'
import {
  ManualPaymentUpdate,
  validateManualPaymentUpdateField,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/TypeOfChangeForm/ManualPaymentUpdate'
import {
  ApprovalThresholdUpdate,
  validateApprovalThresholdUpdateField,
} from 'components/InternalRequest/Forms/BankAccountMaintanence/TypeOfChangeForm/ApprovalThresholdUpdate'
import {SectionLoading} from 'components/InternalRequest/Forms/_Common/SectionLoading'
import {removeDuplicateBank, removeDuplicateUser} from 'pages/InternalRequest/Create'

export const TYPE_OF_CHANGE_CREATION = 'Creation'
export const TYPE_OF_CHANGE_DELETION = 'Deletion'
export const TYPE_OF_CHANGE_USER_ROLE_UPDATE = 'User Role Update (online)'
export const TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE = 'System Admin Update'
export const TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE =
  'Approval Threshold Update (online)'
export const TYPE_OF_CHANGE_AUTHORIZED_PERSON_UPDATE = 'Authorized Person Update'
export const TYPE_OF_CHANGE_MANUAL_PAYMENTS_UPDATE = 'Manual Payments Update'

export type BankAccountMaintenanceFormType = {
  acc_maintain_form_details: Array<{
    type_of_change: number,
    role: string,
    reason: string,
    emp_name: string,
    designation: string,
    group: string,
    bank_code: string,
    bank_description: string,
    currency_code: string,
    account_number: string,
    acc_managed_by: string,
    approved_person: string,
    manual_payment_approvers_limit: string,
    matrix_condition: Array<{
      matrix_condition: string,
      matrix_threshold: string,
    }>,
  }>,
}

/**
 * Bank Account Maintenance form details
 * @type {{bank: null, openWithPreferredBank: null, numberOfAccountToOpen: null, chequeBookRequiredExplanation: null, applyingMT940Explanation: null, accountManagedByExplanation: null, applyingMT940: null, purposeOfBankAccount: null, openWithPreferredBankExplanation: null, chequeBookRequired: null, accountManagedBy: null}}
 */
function bankFormDetails() {
  return {
    type_of_change: null,
    role: null,
    reason: null,
    emp_name: null,
    designation: null,
    group: null,
    bank_id: null,
    currency_code: null,
    account_number: null,
    acc_managed_by: null,
    matrix_condition: [],
    approved_person: null,
    manual_payment_approvers_limit: null,
    new_system_admin_setup: null,
    reason_for_change: null,
    bank_details: [],
    emp_details: [],
  }
}

function formBankDetails() {
  return {
    role: {text: '', value: ''},
    group: '',
    bank_id: '',
    currency_code: '',
    account_number: '',
    acc_managed_by: '',
    account_type: '',
  }
}

function formEmployeeDetails() {
  return {
    company_designation: '',
    reason: '',
    fullname: '',
    email: '',
    approve_type: {text: '', value: ''},
    is_new: '',
  }
}

function formMatrixCondition() {
  return {
    matrix_threshold: null,
    matrix_condition: null,
  }
}

/**
 * To return all the fields for Bank Account Maintenance
 * @param formData
 * @return {{formDetails: ({reason: {text: string, value: string}|null, employeeName: string, role: {text: string, value: string}|null, threshold: string, typeOfChange: {text: number, value: number}|null, accountNumber: {bankAccountNumber: string}|null, grouping: {text: string, value: string}|null, accountManagedBy: {text: string, value: string}|null, manualPaymentApproversAndLimits: string, approvedPerson: string, bank: {text: string, value: string}|null, companyDesignation: string, currency: {text: string, value: string}|null, conditions: string}[]|{bank: null, openWithPreferredBank: null, numberOfAccountToOpen: null, chequeBookRequiredExplanation: null, applyingMT940Explanation: null, accountManagedByExplanation: null, applyingMT940: null, purposeOfBankAccount: null, openWithPreferredBankExplanation: null, chequeBookRequired: null, accountManagedBy: null}[])}}
 */
export function getBankAccountMaintenanceFormFields(
  formData: BankAccountMaintenanceFormType
) {
  let details = []

  const getData = (data) => {
    const bankDetails =
      data?.bank_details?.map((value) => {
        return {
          role: value.role ?? {text: '', value: ''},
          group: value.group ?? null,
          bank_id: value.bank_id
            ? {
                code: value.bank_id.code ?? value.bank_code,
                text: value.bank_id.text ?? value.bank_desc,
                value: value.bank_id.value ?? value.bank_id,
              }
            : null,
          entity_id: value.entity_id ?? null,
          entity_code: value.entity_code ?? null,
          currency_code: value.currency_code,
          account_number: value.account_number ?? null,
          acc_managed_by: value.acc_managed_by ?? null,
          account_type: value.account_type,
        }
      }) ?? []

    const empDetails =
      data?.emp_details?.map((value) => {
        return {
          company_designation: value.company_designation ?? null,
          reason: value.reason ?? null,
          fullname: value.fullname
            ? {
                email: value.email,
                full_name: value.fullname.full_name ?? value.fullname,
              }
            : null,
          email: value.email,
          is_new: value.is_new,
          entity_id: value.entity_id ?? null,
          approve_type: value.approve_type ?? {text: '', value: ''},
        }
      }) ?? []

    const uniqueUser = removeDuplicateUser(empDetails)
    const uniqueBank = removeDuplicateBank(bankDetails)

    return {
      bank_details: uniqueBank,
      emp_details: uniqueUser,
      type_of_change: data.type_of_change ?? null,
      reason_for_change: data.reason_for_change,
      new_system_admin_setup: data.new_system_admin_setup,
      role: data.role ?? null,
      reason: data.reason ?? null,
      emp_name: data?.emp_name
        ? data?.emp_name instanceof String
          ? JSON.parse(data?.emp_name)
          : data?.emp_name
        : null,
      designation: data.designation ?? null,
      group: data.group ?? null,
      bank_id: data.bank_id ? data.bank_id.value : data.bank_id,
      currency_code: data.currency_code,
      account_number: data.account_number ?? null,
      acc_managed_by: data.acc_managed_by,
      matrix_condition:
        data?.matrix_condition?.map(({matrix_condition, matrix_threshold}) => {
          if (!matrix_condition && !matrix_threshold) {
            return {
              matrix_condition: null,
              matrix_threshold: null,
            }
          } else {
            return {
              matrix_condition: matrix_condition,
              matrix_threshold: matrix_threshold,
            }
          }
        }) ?? [],
      approved_person: data.approved_person,
      manual_payment_approvers_limit: data.manual_payment_approvers_limit,
    }
  }

  if (formData?.form_details?.length > 0) {
    details = formData.form_details.map((data) => {
      return getData(data)
    })
  } else if (formData?.acc_maintain_form_details?.length > 0) {
    details = formData.acc_maintain_form_details.map((data) => {
      return getData(data)
    })
  } else {
    details = [bankFormDetails()]
  }

  return {
    form_details: details,
  }
}

type BankAccountMaintenanceType = {
  bankAccountMaintenanceInfo: Array<{
    type_of_change: string | null,
    role: string | null,
    reason: string | null,
    emp_name: {full_name: string, email: string, new?: false},
    designation: string,
    group: string | null,
    bank_id: string | null,
    currency_code: string,
    account_number: string,
    acc_managed_by: string,
    matrix_condition: Array<{
      matrix_threshold: number,
      matrix_condition: string,
    }>,
    approved_person: string,
    manual_payment_approvers_limit: string,
    new_system_admin_setup: string,
    reason_for_change: string,
    bank_details: Array<{
      role: string,
      group: string,
      bank_id: string,
      entity_id: string,
      entity_code: string,
      currency_code: string,
      account_number: string,
      acc_managed_by: string | null,
      account_type: string,
    }>,
    emp_details: Array<{
      company_designation: string,
      reason: string,
      fullname: string,
      email: string,
      is_new: string,
      entity_id: string,
    }>,
  }>,
  setBankAccountMaintenanceInfo: () => void,
  current_order: number,
}

/**
 * To validate the fields for Bank Account Maintenance
 * @param bankAccountMaintenance
 */
export function validateBankAccountMaintenance({
  bankAccountMaintenanceInfo,
  setBankAccountMaintenanceInfo,
}: BankAccountMaintenanceType) {
  const setErrorDetailsForFormDetailsArray = (key, formIndex, subFormIndex, subKey) => {
    setBankAccountMaintenanceInfo((prevState) => {
      const handleChangeDetails = prevState.form_details
      handleChangeDetails[formIndex - 1][key][subFormIndex][`error_${subKey}`] =
        'This field is required'
      return {
        ...prevState,
        form_details: handleChangeDetails,
      }
    })
  }

  if (bankAccountMaintenanceInfo.form_details.length === 0) {
    return {
      type: 'error',
      message: `Need to have at least 1 bank maintenance form`,
    }
  }

  let isFormValid = true
  //lets validate form details
  let formIndex = 0
  for (const details of bankAccountMaintenanceInfo.form_details) {
    formIndex++

    // we always need to fill up type of change first
    if (!details.type_of_change) {
      setFormArrayErrorValue(
        setBankAccountMaintenanceInfo,
        formIndex,
        'form_details',
        'type_of_change'
      )
      isFormValid = false
    }

    let isSubFormValid = true

    switch (details.type_of_change) {
      case TYPE_OF_CHANGE_CREATION:
        isSubFormValid = validateCreationField(
          details,
          formIndex,
          setBankAccountMaintenanceInfo,
          setErrorDetailsForFormDetailsArray
        )

        if (!isSubFormValid) {
          isFormValid = false
        }

        break
      case TYPE_OF_CHANGE_DELETION:
        isSubFormValid = validateDeletionField(
          details,
          formIndex,
          setBankAccountMaintenanceInfo,
          setErrorDetailsForFormDetailsArray
        )

        if (!isSubFormValid) {
          isFormValid = false
        }

        break
      case TYPE_OF_CHANGE_USER_ROLE_UPDATE:
        isSubFormValid = validateUserRoleUpdateField(
          details,
          formIndex,
          setBankAccountMaintenanceInfo,
          setErrorDetailsForFormDetailsArray
        )
        if (!isSubFormValid) {
          isFormValid = false
        }
        break
      case TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE:
        isSubFormValid = validateSystemAdminUpdateField(
          details,
          formIndex,
          setBankAccountMaintenanceInfo,
          setErrorDetailsForFormDetailsArray
        )
        if (!isSubFormValid) {
          isFormValid = false
        }
        break
      case TYPE_OF_CHANGE_AUTHORIZED_PERSON_UPDATE:
        isSubFormValid = validateAuthorizedPersonUpdateField(
          details,
          formIndex,
          setBankAccountMaintenanceInfo,
          setErrorDetailsForFormDetailsArray
        )
        if (!isSubFormValid) {
          isFormValid = false
        }
        break
      case TYPE_OF_CHANGE_MANUAL_PAYMENTS_UPDATE:
        isSubFormValid = validateManualPaymentUpdateField(
          details,
          formIndex,
          setBankAccountMaintenanceInfo,
          setErrorDetailsForFormDetailsArray
        )
        if (!isSubFormValid) {
          isFormValid = false
        }
        break
      case TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE:
        isSubFormValid = validateApprovalThresholdUpdateField(
          details,
          formIndex,
          setBankAccountMaintenanceInfo,
          setErrorDetailsForFormDetailsArray
        )
        if (!isSubFormValid) {
          isFormValid = false
        }
        break
      default:
        break
    }
  }

  if (!isFormValid) {
    return {
      type: 'error',
    }
  }

  return {
    type: 'success',
  }
}

export default function BankAccountMaintenance({
  bankAccountMaintenanceInfo,
  setBankAccountMaintenanceInfo,
  isDisabled,
}) {
  const {form_details} = bankAccountMaintenanceInfo
  const disabled = isDisabled
  const [bankAccount, setBankAccount] = useState([])

  const {loading: isOBMBanksLoading, data: OBMbanks = []} = useFetchOBMBankNames(
    bankAccountMaintenanceInfo.entity_id.map(({entity_id}) => entity_id),
    bankAccountMaintenanceInfo.sub_category_1
  )

  const {loading: isOBMAccToXferLoading, data: obmBankAccountToXfer} =
    useFetchOBMBankAccount(
      bankAccountMaintenanceInfo.entity_id.map(({entity_id}) => entity_id),
      bankAccountMaintenanceInfo.sub_category_1,
      false
    )
  const {loading: isMatrixConditionsLoading, data: matrixConditions} =
    useFetchMatrixConditions()
  const {loading: isMatrixThresholdsLoading, data: matrixThresholds} =
    useFetchMatrixThresholds()
  const {loading: isEmployeeTitleLoading, data: title} = useFetchEmployeeTitle()
  const {loading: isEmployeeGroupLoading, data: group} = useFetchEmployeeGroup()
  const {loading: isTypeOfChangeLoading, data: typeOfChanges} = useFetchFormMetadata(
    'acc_maintain',
    'type_of_change'
  )
  const {loading: isReasonOfChangeLoading, data: reasonOfChanges} = useFetchFormMetadata(
    'acc_maintain',
    'reason_of_change'
  )
  const {loading: isRoleLoading, data: roles} = useFetchFormMetadata(
    'acc_maintain',
    'role'
  )
  const {loading: isAccountMangedByLoading, data: accountMangedBy} =
    useFetchAccountManagedBy()

  /**
   * To map the bank account according to entity
   */
  useEffect(() => {
    if (obmBankAccountToXfer) {
      let bankAccount = []

      obmBankAccountToXfer.forEach((item) => {
        if (!bankAccount[item.bank_id]) {
          bankAccount[item.bank_id] = [
            {
              account_number: item.bank_account_number,
              entity_id: item.entity_id,
              entity_name: item.entity_name,
              entity_code: item.entity_code,
              currency_code: item.currency_code,
              acc_managed_by: item.acc_managed_by,
              account_type: item.account_type,
            },
          ]
        } else {
          bankAccount[item.bank_id].push({
            account_number: item.bank_account_number,
            entity_id: item.entity_id,
            entity_name: item.entity_name,
            entity_code: item.entity_code,
            currency_code: item.currency_code,
            acc_managed_by: item.acc_managed_by,
            account_type: item.account_type,
          })
        }
      })
      setBankAccount(bankAccount)
    }
  }, [obmBankAccountToXfer])

  /**
   * To set sub form value
   *
   * @param index
   * @param key
   * @param subKey
   * @param value
   */
  const setSubFormArrayValue = (formIndex, key, subFormIndex, subKey, value) => {
    setBankAccountMaintenanceInfo((prevState) => {
      const handleChangeDetails = prevState.form_details
      handleChangeDetails[formIndex][key][subFormIndex][subKey] = value

      if (handleChangeDetails[formIndex][key][subFormIndex][`error_${subKey}`]) {
        handleChangeDetails[formIndex][key][subFormIndex][`error_${subKey}`] = undefined
      }
      return {
        ...prevState,
        form_details: handleChangeDetails,
      }
    })
  }

  /**
   * To set form value
   *
   * @param index
   * @param key
   * @param subKey
   * @param value
   */
  const setFormArrayValue = (index, key, subKey, value) => {
    setBankAccountMaintenanceInfo((prevState) => {
      const handleChangeDetails = [...prevState[key]]
      handleChangeDetails[index][subKey] = value
      if (handleChangeDetails[index][`error_${subKey}`]) {
        handleChangeDetails[index][`error_${subKey}`] = undefined
      }
      return {
        ...prevState,
        [key]: handleChangeDetails,
      }
    })
  }

  /**
   * To render the diff Maintenance form
   */
  const renderTypeOfChangeInput = (detail: Array, index: number) => {
    const typeOfChangeValue = detail.type_of_change
    switch (typeOfChangeValue) {
      case TYPE_OF_CHANGE_CREATION:
        return (
          <Creation
            disabled={disabled}
            detail={detail}
            formIndex={index}
            reason={reasonOfChanges}
            grouping={group}
            roles={roles}
            setFormArrayValue={setFormArrayValue}
            setArrayValue={setSubFormArrayValue}
            OBMbanks={OBMbanks}
            bankAccount={bankAccount}
            designation={title}
            accountMangedBy={accountMangedBy}
          />
        )
      case TYPE_OF_CHANGE_DELETION:
        return (
          <Deletion
            disabled={disabled}
            detail={detail}
            formIndex={index}
            reason={reasonOfChanges}
            setFormArrayValue={setFormArrayValue}
            setArrayValue={setSubFormArrayValue}
            OBMbanks={OBMbanks}
            bankAccount={bankAccount}
            accountMangedBy={accountMangedBy}
            formDetails={bankAccountMaintenanceInfo}
          />
        )
      case TYPE_OF_CHANGE_USER_ROLE_UPDATE:
        return (
          <UserRoleUpdate
            disabled={disabled}
            detail={detail}
            formIndex={index}
            reason={reasonOfChanges}
            grouping={group}
            roles={roles}
            setFormArrayValue={setFormArrayValue}
            setArrayValue={setSubFormArrayValue}
            OBMbanks={OBMbanks}
            bankAccount={bankAccount}
            accountMangedBy={accountMangedBy}
            formDetails={bankAccountMaintenanceInfo}
          />
        )
      case TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE:
        return (
          <SystemAdminUpdate
            disabled={disabled}
            detail={detail}
            formIndex={index}
            setFormArrayValue={setFormArrayValue}
            setArrayValue={setSubFormArrayValue}
            OBMbanks={OBMbanks}
            bankAccount={bankAccount}
            accountMangedBy={accountMangedBy}
          />
        )
      case TYPE_OF_CHANGE_AUTHORIZED_PERSON_UPDATE:
        return (
          <AuthorizedPersonUpdate
            disabled={disabled}
            detail={detail}
            formIndex={index}
            setFormArrayValue={setFormArrayValue}
          />
        )
      case TYPE_OF_CHANGE_MANUAL_PAYMENTS_UPDATE:
        return (
          <ManualPaymentUpdate
            disabled={disabled}
            detail={detail}
            formIndex={index}
            setFormArrayValue={setFormArrayValue}
          />
        )

      case TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE:
        return (
          <ApprovalThresholdUpdate
            disabled={disabled}
            detail={detail}
            formIndex={index}
            setFormArrayValue={setFormArrayValue}
            setArrayValue={setSubFormArrayValue}
            OBMbanks={OBMbanks}
            bankAccount={bankAccount}
            matrixThresholds={matrixThresholds}
            matrixConditions={matrixConditions}
            accountMangedBy={accountMangedBy}
          />
        )

      default:
        return <></>
    }
  }

  const resetForm = (index, value) => {
    setBankAccountMaintenanceInfo((prevState) => {
      let handleChangeDetails = [...prevState.form_details]
      handleChangeDetails[index] = bankFormDetails()

      switch (value) {
        case TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE:
          handleChangeDetails[index].matrix_condition.push(formMatrixCondition())
        case TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE:
          handleChangeDetails[index].bank_details.push(formBankDetails())
          break
        case TYPE_OF_CHANGE_CREATION:
        case TYPE_OF_CHANGE_DELETION:
        case TYPE_OF_CHANGE_USER_ROLE_UPDATE:
          handleChangeDetails[index].bank_details.push(formBankDetails())
          handleChangeDetails[index].emp_details.push(formEmployeeDetails())
          break
        default:
          break
      }
      handleChangeDetails[index].type_of_change = value

      return {
        ...prevState,
        form_details: handleChangeDetails,
      }
    })
  }

  if (
    isTypeOfChangeLoading ||
    isReasonOfChangeLoading ||
    isRoleLoading ||
    isMatrixConditionsLoading ||
    isMatrixThresholdsLoading ||
    isEmployeeTitleLoading ||
    isEmployeeGroupLoading ||
    isOBMAccToXferLoading ||
    isOBMBanksLoading ||
    isAccountMangedByLoading
  ) {
    return <SectionLoading label={'Bank Account Maintenance'} />
  }

  return (
    <React.Fragment>
      <Accordion title={'Bank Account Maintenance Form'} expanded>
        <div style={{marginBottom: '10px'}}>
          {Array.isArray(form_details) &&
            form_details?.map((detail, index) => {
              return (
                <Accordion
                  title={`Form ${index + 1}`}
                  secondaryTitle={true}
                  expanded
                  key={index}
                >
                  <Card variant="outlined" id={`form-card-${index}`}>
                    <CardContent>
                      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant={'subtitle2'}
                            sx={{fontWeight: 600}}
                            align={'left'}
                          >
                            General
                          </Typography>
                          <Typography variant={'caption'} align={'left'}>
                            Select the Type Of Change and relevant input will appear
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Autocomplete
                            options={typeOfChanges}
                            getOptionLabel={(option) => option.text || option}
                            handleOnChange={(value) => {
                              resetForm(index, value?.value)
                            }}
                            value={detail.type_of_change}
                            label={'Select Type Of Change'}
                            textFieldProps={{
                              required: true,
                              error: !!detail.error_type_of_change,
                              helperText: detail.error_type_of_change,
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                        {[
                          TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE,
                          TYPE_OF_CHANGE_AUTHORIZED_PERSON_UPDATE,
                          TYPE_OF_CHANGE_MANUAL_PAYMENTS_UPDATE,
                        ].includes(detail.type_of_change) && (
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Autocomplete
                              options={reasonOfChanges}
                              getOptionLabel={(option) => option.text || option}
                              handleOnChange={(value) =>
                                setFormArrayValue(
                                  index,
                                  'form_details',
                                  'reason',
                                  value?.value
                                )
                              }
                              value={detail.reason}
                              label={'Select Reason'}
                              textFieldProps={{
                                required: true,
                                error: !!detail.error_reason,
                                helperText: detail.error_reason,
                              }}
                              disabled={disabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                      {renderTypeOfChangeInput(detail, index)}
                    </CardContent>
                    {!disabled && (
                      <CardActions style={{padding: '0 23px 16px'}}>
                        <DuplicateButton
                          onClick={() => {
                            setBankAccountMaintenanceInfo((prevState) => {
                              let duplicateFormDetails = [...prevState.form_details]
                              let duplicateCopy = JSON.parse(JSON.stringify(detail))
                              duplicateFormDetails.push(duplicateCopy)

                              return {
                                ...prevState,
                                form_details: duplicateFormDetails,
                              }
                            })
                          }}
                        />
                        <RemoveButton
                          onClick={() => {
                            setBankAccountMaintenanceInfo((prevState) => {
                              const addFormDetails = [...prevState.form_details]
                              addFormDetails.splice(index, 1)

                              return {
                                ...prevState,
                                form_details: addFormDetails,
                              }
                            })
                          }}
                        />
                      </CardActions>
                    )}
                  </Card>
                </Accordion>
              )
            })}
        </div>
        {!disabled && (
          <AddButton
            onClick={() =>
              setBankAccountMaintenanceInfo((prevState) => {
                const addFormDetails = [...prevState.form_details]
                addFormDetails.push(bankFormDetails())

                return {
                  ...prevState,
                  form_details: addFormDetails,
                }
              })
            }
          />
        )}
      </Accordion>
    </React.Fragment>
  )
}
