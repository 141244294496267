import React, {useEffect, useState} from 'react'
import {Grid, Menu, MenuItem, Stack, Typography} from '@mui/material'
import {
  Loading,
  Button,
  FullScreenDialog,
  Autocomplete,
  IconButton,
  FilePreviewDialog,
} from 'finsys-webcomponent'
import {
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useMutation} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'

import {
  exportBankApprovalMatrixSummary,
  fetchBankApprovalMatrixSummary,
  fetchSupportingDocs,
  notifyEmail,
} from 'api/TS/query/BankApprovalMatrixQueries'
import useDataFetching from 'hoc/UseDataFetching'

import {FileUpload} from 'components/_Common/FileUpload'
import {downloadAttachment, previewAttachment} from 'api/TS/query/AttachmentQueries'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {BAMTable} from 'components/Tables/BAMTable'
import {setBreadcrumbRecordName, setFilter} from 'store/actions'
import {formatCellValue, formatValueWithEmptyPToBr} from 'components/Tables/_helper'
import {APPROVAL_MATRIX_PAGE_NAME, HQ_ROLE_ID} from '_helper/_constants'
import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {getDate} from 'components/_Common'
import {useFetchCountry, useFetchEntities} from 'components/_Utils/UseFetchFunctions'
import {fetchApprovedDate} from 'api/TS/query/BankApprovalMatrixQueries'
import {EmailNotify} from 'components/_Common/EmailNotify'
import {ApprovedDateTimePicker} from 'components/_Common/ApprovedDateTimePicker'
import MultipleSelect from 'components/InternalRequest/Summary/FilterOptions/MultipleSelect'

function BankApprovalMatrix() {
  const dispatch = useDispatch()
  const {country, approvedDate} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const {setSnackBar} = useSnackBar()
  const filterState = useSelector((state) => state.filter.state)
  const [openSettings, setOpenSettings] = useState(null)
  const [openMore, setOpenMore] = useState(null)
  const [filterQuery, setFilterQuery] = useState({
    country: country
      ? {country_id: country.toUpperCase(), pending_approval_request_id: null}
      : null,
    approvedDate: getDate(approvedDate),
    entity: [],
  })
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    toNextPage: '',
  })

  const [page, setPage] = useState(1)
  const [pendingApprovalRequestId, setPendingApprovalRequestId] = useState(null)
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState({
    open: false,
    type: null,
  })
  const [functionAccess, setFunctionAccess] = useState([])
  const [emailNotifyUserList, setEmailNotifyUserList] = useState({
    toNotify: [],
    notified: [],
    isNotified: false,
  })
  const [filePreviewDialog, setFilePreviewDialog] = useState({
    open: false,
    value: null,
  })

  const [isCallingApi, setIsCallingApi] = useState({
    loading: false,
    type: null,
  })

  /**
   * To retrieve approved dates
   */
  const {loading: isApprovedDateLoading, data: approvedDates = []} = useDataFetching(
    ['bam-approved-dates', filterQuery.country],
    () => fetchApprovedDate(filterQuery.country)
  )

  /**
   * To set breadcrumb nav
   */
  useEffect(() => {
    if (country) {
      dispatch(
        setBreadcrumbRecordName({
          [location.pathname]: `View From Email ${country.toUpperCase()}`,
        })
      )
    }
  }, [])

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(APPROVAL_MATRIX_PAGE_NAME, setFunctionAccess)
    }
    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * Apply filter state
   */
  useEffect(() => {
    //to retain filter value
    if (filterState) {
      setFilterQuery(filterState.filterQuery ?? filterQuery)
      if (filterState.filterQuery.country) {
        setPendingApprovalRequestId(
          filterState.filterQuery.country.pending_approval_request_id
        )
      }
    }
  }, [])

  /**
   * Set filter state
   */
  useEffect(() => {
    dispatch(
      setFilter('BankApprovalMatrix', 'index', {
        filterQuery,
      })
    )
  }, [filterQuery])

  useEffect(() => {
    const approvedDateLength = approvedDates.length
    if (approvedDateLength > 0) {
      setFilterQuery((prevState) => ({
        ...prevState,
        approvedDate: getDate(approvedDates[approvedDateLength - 1]),
      }))
    } else {
      setFilterQuery((prevState) => ({
        ...prevState,
        approvedDate: null,
      }))
    }
  }, [approvedDates])

  const editableHeaderColumns = [
    {
      name: 'Matrix',
      fieldName: 'matrix',
      component: ({value}) => renderCellComponent(value),
    },
    {
      name: 'Threshold',
      fieldName: 'threshold',
      component: ({value}) => renderCellComponent(value),
    },
  ]
  const columns = [
    {
      name: 'Bank',
      fieldName: 'bank_description',
      width: 100,
    },
    {
      name: 'Entity',
      fieldName: 'entity_name',
      width: 120,
    },
    {
      name: 'Authorised Person',
      fieldName: 'authorized_person',
      component: ({value}) => renderCellComponent(value),
      width: 150,
    },
    {
      name: 'Manual Payments',
      fieldName: 'manual_payments',
      component: ({value}) => renderCellComponent(value),
      width: 150,
    },
    {
      name: 'System Admin',
      fieldName: 'system_admin',
      component: ({value}) => renderCellComponent(value),
      width: 150,
    },
  ]

  /**
   * To retrieve countries list
   */
  const {loading: isCountryLoading, data: countries} = useFetchCountry()

  /**
   * To retrieve entity list
   */
  const {loading: isEntityLoading, data: entities = []} = useFetchEntities([
    filterQuery.country,
  ])

  /**
   * To retrieve bank approval matrix summary
   */
  const {
    loading: isSummaryLoading,
    data: summaryData,
    isPreviousData,
  } = useDataFetching(
    ['bankApprovalMatrix', filterQuery],
    () => fetchBankApprovalMatrixSummary(filterQuery),
    {
      refetchOnWindowFocus: true,
    }
  )

  /**
   * To retrieve supporting doc for bank approval matrix
   */
  const {loading: isSupportDocLoading, data: supportDocs} = useDataFetching(
    ['supporting-doc', filterQuery.country, filterQuery.approvedDate],
    () =>
      fetchSupportingDocs({
        country: filterQuery.country?.country_id,
        approvedDate: filterQuery.approvedDate,
      })
  )

  /**
   * To render cell component
   * @param value
   */
  const renderCellComponent = (value) => {
    return (
      <div
        className={'wrap-text'}
        dangerouslySetInnerHTML={{__html: formatValueWithEmptyPToBr(value)}}
      />
    )
  }

  //we need to update country
  useEffect(() => {
    if (countries && filterState?.filterQuery?.country) {
      countries.forEach(({country_id, pending_approval_request_id}) => {
        if (country_id === filterState.filterQuery.country.country_id) {
          setPendingApprovalRequestId(pending_approval_request_id)
        }
      })
    }
  }, [countries])

  /**
   * To update the table data and format the data for the table to consume
   */
  useEffect(() => {
    if (summaryData && !isPreviousData) {
      // we need to extract threshold and matrix first
      let tempThresholdAndMatrix = []
      let currentEntity = null
      let currentEntityIndex = 0
      summaryData.forEach((row) => {
        // whatever it is we need to group it based by entity
        let newThresholdAndMatrix = {...tempThresholdAndMatrix}

        if (!newThresholdAndMatrix[row.entity_id]) {
          newThresholdAndMatrix[row.entity_id] = {
            data: [],
            type: null,
            name: row.entity_name,
            matrix: {
              authorized_person: null,
              bank_approval_matrix_id: null,
              manual_payments: null,
              system_admin: null,
            },
            threshold: {
              authorized_person: null,
              bank_approval_matrix_id: null,
              manual_payments: null,
              system_admin: null,
            },
          }
        }

        // lets check if the data is a threshold and matrix header
        if (row.is_header === 'Y') {
          // lets retrieve entity name
          const thresholdAndMatrixObject = {
            bank_approval_matrix_id: row.bank_approval_matrix_id,
            authorized_person: row.authorized_person ?? '',
            manual_payments: row.manual_payments ?? '',
            system_admin: row.system_admin ?? '',
          }

          newThresholdAndMatrix[row.entity_id] = {
            ...newThresholdAndMatrix[row.entity_id],
            [row.header_type]: thresholdAndMatrixObject,
          }

          tempThresholdAndMatrix = newThresholdAndMatrix
          return
        }

        // the code below is executed if its not a header
        // we will merge it only if they got the same entity as the previous index
        let ableToMerge = undefined
        if (currentEntity !== row.entity_id) {
          currentEntity = row.entity_id
          currentEntityIndex = 0
          ableToMerge = false
        } else {
          ableToMerge = true
          currentEntityIndex++
        }

        // we will loop the field to set its rowSpan and index and compare if its merge-able
        ;[
          'bank_description',
          'entity_name',
          'authorized_person',
          'manual_payments',
          'system_admin',
        ].forEach((fieldName) => {
          let tempArray = [...newThresholdAndMatrix[row.entity_id].data]
          let rowSpanIndex = currentEntityIndex
          let rowSpan = 1

          if (ableToMerge) {
            const prevIndex = currentEntityIndex - 1

            if (
              formatCellValue(tempArray[prevIndex][fieldName]).localeCompare(
                formatCellValue(row[fieldName])
              ) === 0
            ) {
              // we will add +1 to the previous rowSpan based on the row span index
              tempArray[tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]][
                `${[fieldName]}RowSpan`
              ]++

              // update the current rowSpanIndex & rowSpan to the previous row index
              rowSpanIndex = tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]
              rowSpan = 0
            }
          }

          // if this is the first record we shall instantiate the rowSpan & rowIndex for the field
          if (!tempArray[currentEntityIndex]) {
            tempArray[currentEntityIndex] = {
              ...row,
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
            }
          } else {
            tempArray[currentEntityIndex] = {
              ...tempArray[currentEntityIndex],
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
            }
          }

          newThresholdAndMatrix[row.entity_id] = {
            ...newThresholdAndMatrix[row.entity_id],
            data: tempArray,
          }
          tempThresholdAndMatrix = newThresholdAndMatrix
        })
      })

      setServerPaginationResult({
        rowData: tempThresholdAndMatrix,
        totalPages: summaryData?.total_pages,
        totalCount: summaryData?.count,
        toNextPage: summaryData?.next,
      })
    }
  }, [summaryData])

  /**
   * To download file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDownloadFile = useMutation((id) => downloadAttachment({id}), {
    onSuccess: () => {
      setSnackBar({
        open: true,
        type: 'success',
        message: 'Attachment downloaded successfully',
      })
    },
    onError: (err, variables, context) => {
      setSnackBar((prevState) => ({...prevState, open: true}))
    },
  })

  /**
   * To handle file preview
   * @type {UseMutationResult<unknown, unknown, void, unknown>}
   */
  const handlePreviewFile = useMutation(({id, name}) => previewAttachment({id}), {
    onSuccess: (result, {name}) => {
      setFilePreviewDialog({
        value: result,
        name: name,
        open: true,
      })
    },
  })

  const handleNotifyUserEmail = useMutation(
    (emails) => notifyEmail({emails, filterQuery}),
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          type: 'success',
          message: 'Sent email notification to user successfully',
        })
        setEmailNotifyUserList({
          toNotify: [],
          isNotified: true,
          notified: [...emailNotifyUserList.notified, ...emailNotifyUserList.toNotify],
        })
      },
      onError: () => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To export excel file
   */
  const handleExportRecords = () => {
    if (serverPaginationResult.rowData.length === 0) {
      setSnackBar((prevState) => ({
        ...prevState,
        open: true,
        message: 'No data to export!',
      }))
      return
    }
    exportBankApprovalMatrixSummary(filterQuery).then((response) => {
      setSnackBar((prevState) => ({
        ...prevState,
        open: true,
        type: 'success',
        message: 'Excel download successfully!',
      }))
    })
  }

  if (isSupportDocLoading || isCountryLoading) {
    return <Loading open={true} />
  }
  return (
    <>
      <FilePreviewDialog
        open={filePreviewDialog.open}
        value={{preview: filePreviewDialog.value, name: filePreviewDialog.name}}
        onClose={() =>
          setFilePreviewDialog({
            value: null,
            open: false,
          })
        }
      />
      <FullScreenDialog
        title={
          openFullScreenDialog.type === 'supportingDocs'
            ? `Supporting docs`
            : `Email and Notify User to view Country[${
                filterQuery.country?.country_id
              }] BAM ${filterQuery.approvedDate ? `for ${filterQuery.approvedDate}` : ''}`
        }
        open={openFullScreenDialog.open}
        handleClose={() => {
          if (isCallingApi.loading && isCallingApi.type === 'notify') {
            setSnackBar({
              open: true,
              type: 'info',
              message: 'Notifying user in progress, unable to close',
            })
            return
          }

          setOpenFullScreenDialog({open: false, type: null})
          setEmailNotifyUserList({
            toNotify: [],
            notified: [],
            isNotified: false,
          })
        }}
      >
        <FileUpload
          formDetails={supportDocs}
          open={
            openFullScreenDialog.open && openFullScreenDialog.type === 'supportingDocs'
          }
          handleClose={() => {
            setOpenFullScreenDialog({open: false, type: null})
          }}
          uploadFiles={false}
          columns={[
            {
              name: 'File Name',
              fieldName: 'attachment_filename',
            },
            {
              name: 'Upload By',
              fieldName: 'created_by',
            },
            {
              name: 'Date',
              fieldName: 'creation_date_format',
            },
          ]}
          disabled={true}
          hideDeleteButton={false}
          handleFilePreview={(index) => {
            const file = supportDocs[index]
            handlePreviewFile.mutate({
              id: file.attachment_id,
              name: file.attachment_filename,
            })
          }}
          handleFileDownload={(index) =>
            handleDownloadFile.mutate(supportDocs[index].attachment_id)
          }
        />

        {openFullScreenDialog.type === 'notify' && (
          <EmailNotify
            emailNotifyUserList={emailNotifyUserList}
            setEmailNotifyUserList={setEmailNotifyUserList}
            handleNotifyUserEmail={(emails) => {
              setIsCallingApi({
                loading: true,
                type: 'notify',
              })
              handleNotifyUserEmail.mutate(emails)
            }}
            isCallingApi={isCallingApi.type === 'notify'}
          />
        )}
      </FullScreenDialog>
      <Grid
        container
        direction={'row'}
        columnSpacing={5}
        justifyContent={'space-between'}
        rowSpacing={2}
      >
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Autocomplete
            disabled={!!country}
            options={countries}
            getOptionLabel={(option) => option.country_id || ''}
            handleOnChange={(value) => {
              setFilterQuery((prevState) => ({
                ...prevState,
                country: value,
                entity: [],
                approvedDate: null,
              }))
              setPendingApprovalRequestId(value?.pending_approval_request_id)
            }}
            value={filterQuery.country}
            label={'Country'}
            textFieldProps={{
              placeholder: 'Please select Country',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <MultipleSelect
            isAll={true}
            selections={[
              {
                entity_name: 'All',
                entity_code: 'All',
                value: 'All',
              },
              ...entities,
            ]}
            limitTags={1}
            label="Select Entity"
            onChange={(event, value) => {
              if (value[value.length - 1]?.value === 'All') {
                setFilterQuery((prevState) => ({
                  ...prevState,
                  entity: filterQuery.entity.length === entities.length ? [] : entities,
                }))
              } else {
                setFilterQuery((prevState) => ({
                  ...prevState,
                  entity: value,
                }))
              }
            }}
            value={filterQuery.entity}
            getOptionLabel={(option) => {
              return `${option.entity_name} (${option.entity_code})`
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <ApprovedDateTimePicker
            loading={isApprovedDateLoading}
            approvedDates={approvedDates}
            approvedDate={filterQuery.approvedDate}
            setApprovedDate={(date) => {
              setFilterQuery((prevState) => ({
                ...prevState,
                approvedDate: date,
              }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={3}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={{xl: 'end', md: 'start'}}
          >
            {functionAccess === HQ_ROLE_ID && (
              <>
                <Button
                  sx={{mt: '8px', mb: '8px'}}
                  label={'Request in progress'}
                  type={'danger'}
                  disabled={!filterQuery.country || !pendingApprovalRequestId}
                  onClick={() =>
                    navigate(
                      `/BankApprovalMatrix/Edit/${filterQuery.country?.country_id}/${pendingApprovalRequestId}`
                    )
                  }
                />
                <IconButton
                  title={`${
                    !filterQuery.country ? 'Please select country to edit' : 'Edit'
                  }`}
                  disabled={!filterQuery.country || !!pendingApprovalRequestId}
                  handleOnClick={() =>
                    navigate(
                      `/BankApprovalMatrix/Create/${filterQuery.country?.country_id}`
                    )
                  }
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </>
            )}
            <IconButton
              title={'More'}
              handleOnClick={(event) => setOpenMore(event.currentTarget)}
            >
              <MoreHorizIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={'Settings'}
              handleOnClick={(event) => setOpenSettings(event.currentTarget)}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
            <Menu
              style={{marginTop: '40px'}}
              anchorEl={openSettings}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={!!openSettings}
              onClose={() => setOpenSettings(null)}
            >
              <MenuItem
                onClick={() => {
                  handleExportRecords()
                }}
              >
                <Typography variant="overline">Export</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (!filterQuery.country) {
                    setSnackBar((prevState) => ({
                      ...prevState,
                      open: true,
                      message: 'Please select a country',
                    }))

                    return
                  }

                  setOpenFullScreenDialog({
                    open: true,
                    type: 'notify',
                  })
                }}
              >
                <Typography variant="overline">Email / Notify</Typography>
              </MenuItem>
            </Menu>
            <Menu
              style={{marginTop: '40px'}}
              anchorEl={openMore}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={!!openMore}
              onClose={() => setOpenMore(null)}
            >
              <MenuItem
                onClick={() => {
                  if (!filterQuery.country || !filterQuery.approvedDate) {
                    setSnackBar((prevState) => ({
                      ...prevState,
                      open: true,
                      message: 'Please select country & approved date first',
                    }))

                    return
                  }
                  setOpenFullScreenDialog({
                    open: true,
                    type: 'supportingDocs',
                  })
                }}
              >
                <Typography variant="overline">Supporting Doc</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (!filterQuery.country || !filterQuery.approvedDate) {
                    setSnackBar((prevState) => ({
                      ...prevState,
                      open: true,
                      message: 'Please select country & approved date first',
                    }))

                    return
                  }

                  navigate(
                    `/BankApprovalMatrix/StatusUpdate/${
                      filterQuery.country.country_id
                    }/${filterQuery.approvedDate.replaceAll('/', '-')}`
                  )
                }}
              >
                <Typography variant="overline">Status Update</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(`/BankApprovalMatrix/OnlineBankingMatrix`)
                }}
              >
                <Typography variant="overline">Online Banking Matrix</Typography>
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BAMTable
            columns={columns}
            editableHeaderColumns={editableHeaderColumns}
            serverPaginationResults={serverPaginationResult}
            country={filterQuery.country}
            page={page}
            // below is paginated function
            onClickToNextPage={() => {
              if (!isPreviousData && serverPaginationResult.toNextPage !== '')
                setPage((prevPage) => prevPage + 1)
            }}
            onClickToPreviousPage={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
            onClickToFirstPage={() => setPage(1)}
            onClickToLastPage={() => {
              if (!isPreviousData && serverPaginationResult.toNextPage !== '') {
                setPage(serverPaginationResult.totalPages)
              }
            }}
            isLoading={isSummaryLoading}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default BankApprovalMatrix
