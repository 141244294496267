import React from 'react'
import moment from 'moment'
import {Button} from 'finsys-webcomponent'

/**
 * Add Button Component for forms
 * @param onClick
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AddButton({onClick}, props) {
  return <Button {...props} label={'Add Form'} type={'secondary'} onClick={onClick} />
}

/**
 * Remove Button Component for forms
 * @param onClick
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RemoveButton({onClick}, props) {
  return <Button {...props} onClick={onClick} type={'danger'} label={'Remove'} />
}

/**
 * Duplicate Button Component for forms
 * @param onClick
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function DuplicateButton({onClick}, props) {
  return <Button {...props} onClick={onClick} type={'text'} label={'Duplicate'} />
}

/**
 * To retrieve date from variable
 * @param date
 * @return {string|null}
 */
export function getDate(date: string) {
  return !isNaN(new Date(date)) ? moment(`${date}`).format('YYYY-MM-DD HH:mm:ss') : null
}

/**
 * Use this is user wanna save free text thousand separator
 * @param value
 * @return {string}
 */
export function formatThousandSeparator(value: string) {
  //remove commas
  let newValue = value.replace(/,/g, '')

  //apply formatting
  return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
