import {Autocomplete, Dialog, TextField} from 'finsys-webcomponent'
import {Stack, Grid} from '@mui/material'

type RejectProps = {
  value: string | null,
  onChange: () => void,
  onClose: () => void,
  onSubmit: () => void,
  isDisabled: boolean,
  isLoading: boolean,
}

/**
 * To open Reject Dialog
 * @param value
 * @param onChange
 * @param onClose
 * @param onSubmit
 * @param userList
 * @param isDisabled
 * @param isLoading
 * @return {JSX.Element}
 * @constructor
 */
export const Reject = ({
  value,
  onChange,
  onClose,
  onSubmit,
  userList = null,
  isDisabled,
  isLoading,
}: RejectProps) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Dialog
          fullWidth
          type={'error'}
          title={'Reason for rejection'}
          content={
            <Stack spacing={3}>
              {userList && (
                <Autocomplete
                  getOptionLabel={(option) => {
                    return `${option.full_name} (${option.user_email}) - ${option.step_name}`
                  }}
                  label={'Select Name (Email) - Steps to reject'}
                  options={userList}
                  handleOnChange={(value) => {
                    onChange((prevState) => ({
                      ...prevState,
                      user_email: value?.user_email,
                      full_name: value?.full_name,
                      order: value?.order,
                      step_name: value?.step_name,
                    }))
                  }}
                  value={value?.user_email}
                  textFieldProps={{
                    required: true,
                  }}
                  disabled={isLoading}
                />
              )}
              <TextField
                multiline
                rows={2}
                label={'Please specify the reason for rejection'}
                onChange={(value) => {
                  onChange((prevState) => ({
                    ...prevState,
                    value: value.target.value?.trimStart() ?? null,
                  }))
                }}
                value={value}
                required
                disabled={isLoading}
              />
            </Stack>
          }
          buttons={[
            {label: 'Cancel', type: 'text', onClick: onClose, disabled: isDisabled},
            {
              label: 'Reject',
              type: 'danger',
              onClick: onSubmit,
              disabled: isDisabled,
              loading: isLoading,
            },
          ]}
          handleOnClose={onClose}
        />
      </Grid>
    </Grid>
  )
}
