import {
  RESET_BREADCRUMB_RECORD_NAME,
  RESET_FILTER_STATE,
  SET_BREADCRUMB_RECORD_NAME,
  SET_FILTER_STATE,
  SET_PENDING_REQUEST_TOTAL_COUNT,
} from './types'

/**
 * To persists table filter
 * @param page, data
 * @return {{payload, type: string, page: string}}
 */
export const setFilter = (page, metadata, data) => {
  return {
    type: SET_FILTER_STATE,
    page: page,
    metadata: metadata,
    payload: data,
  }
}

/**
 * To reset table filter
 * @return {{type: string}}
 */
export const resetFilter = () => {
  return {
    type: RESET_FILTER_STATE,
  }
}

/**
 * To set breadcrumb record name
 * @return {{type: string}}
 */
export const setBreadcrumbRecordName = (crumbs) => {
  return {
    type: SET_BREADCRUMB_RECORD_NAME,
    crumbs: crumbs,
  }
}

/**
 * To reset breadcrumb record name
 * @return {{type: string}}
 */
export const resetBreadcrumbRecordName = () => {
  return {
    type: RESET_BREADCRUMB_RECORD_NAME,
  }
}

/**
 * To set pending total
 * @return {{type: string}}
 */
export const setPendingRequestTotalCount = (total) => {
  return {
    type: SET_PENDING_REQUEST_TOTAL_COUNT,
    total,
  }
}
