import React from 'react'
import {Grid, Stack, Typography} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {Button, FormTable, IconButton, ServerSideAutocomplete} from 'finsys-webcomponent'

import {fetchEmployees} from 'api/TS/query/_CommonQueries'
import {useSnackBar} from 'hoc/SnackbarHandler'

type EmailNotifyProps = {
  emailNotifyUserList: {
    toNotify: [],
    notified: [],
    isNotified: boolean,
  },
  setEmailNotifyUserList: () => void,
  handleNotifyUserEmail: () => void,
}

export function EmailNotify({
  emailNotifyUserList,
  setEmailNotifyUserList,
  handleNotifyUserEmail,
  isCallingApi,
}: EmailNotifyProps) {
  const {setSnackBar} = useSnackBar()

  return (
    <Grid container justifyContent={'flex-start'} columnSpacing={8} rowSpacing={2}>
      <Grid xs={12} sm={12} md={12} lg={12} item>
        <ServerSideAutocomplete
          textFieldProps={{
            required: true,
            placeholder: 'Search by name or email',
          }}
          label={'Notifier'}
          value={{email: '', full_name: ''}}
          handleOnChange={(value) => {
            if (!value) {
              return
            }

            if (emailNotifyUserList.toNotify.some((user) => user.email === value.email)) {
              setSnackBar({
                type: 'error',
                open: 'true',
                message: 'User exists in notified list!',
              })

              return
            }

            if (emailNotifyUserList.notified.some((user) => user.email === value.email)) {
              setSnackBar({
                type: 'error',
                open: 'true',
                message: 'User already notified!',
              })

              return
            }

            setEmailNotifyUserList((prevState) => ({
              ...prevState,
              toNotify: [
                ...prevState.toNotify,
                {
                  full_name: value?.full_name,
                  email: value?.email,
                  approve_date: null,
                },
              ],
            }))
          }}
          fetchOptions={(value) => fetchEmployees(value)}
          getOptionLabel={(option) =>
            option.full_name ? `${option.full_name} (${option.email})` : ''
          }
          isOptionEqualToValue={(option, value) => option.email === value.email}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'row'} spacing={2}>
          <Button
            loading={isCallingApi}
            label={'Notify'}
            onClick={() => {
              if (emailNotifyUserList.toNotify.length === 0) {
                setSnackBar((prevState) => ({
                  ...prevState,
                  open: true,
                  message: 'Please select a user!',
                }))
                return
              }
              handleNotifyUserEmail(
                emailNotifyUserList.toNotify.map((user) => user.email)
              )
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} item>
        <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
          Pending to be notified - {emailNotifyUserList.toNotify.length} user
          {emailNotifyUserList.toNotify.length > 1 ? 's' : ''}
        </Typography>
        {emailNotifyUserList.toNotify.length === 0 && (
          <Typography variant={'caption'}>
            Get started by searching for a name/ email in notifier!
          </Typography>
        )}
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} item>
        <FormTable
          columns={[
            {name: 'Full Name', field: 'full_name', width: '50%'},
            {name: 'Email', field: 'email'},
            {
              name: 'Action',
              field: '',
              width: '1%',
              component: ({dataIndex}) => (
                <IconButton
                  title={'Delete'}
                  handleOnClick={() => {
                    setEmailNotifyUserList((prevState) => {
                      const toNotify = [...prevState.toNotify]
                      toNotify.splice(dataIndex, 1)

                      return {
                        ...prevState,
                        toNotify: toNotify,
                      }
                    })
                  }}
                >
                  <DeleteIcon style={{color: '#CE6B72'}} />
                </IconButton>
              ),
            },
          ]}
          data={emailNotifyUserList.toNotify}
        />
      </Grid>

      <Grid xs={12} sm={12} md={12} lg={12} item>
        <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
          Notified - {emailNotifyUserList.notified.length} user
          {emailNotifyUserList.notified.length > 1 ? 's' : ''}
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} item>
        <FormTable
          columns={[
            {name: 'Full Name', field: 'full_name', width: '50%'},
            {name: 'Email', field: 'email'},
          ]}
          data={emailNotifyUserList.notified}
        />
      </Grid>
    </Grid>
  )
}
