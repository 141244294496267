import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Tabs} from 'finsys-webcomponent'
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom'

import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {METADATA_PAGE_NAME} from '_helper/_constants'
import Banks from 'components/Metadata/Summary/Banks'
import Entity from 'components/Metadata/Summary/Entity'
import ObmEmployee from 'components/Metadata/Summary/ObmEmployee'

export const SIDEBAR_INFORMATION = {
  type: 'INFORMATION',
  title: 'Basic Information',
}

export const SIDEBAR_DEFAULT = {
  type: null,
  title: '',
}

export const tabList = [
  {
    label: 'Banks',
    panel: <Banks />,
  },
  {
    label: 'Entities',
    panel: <Entity />,
  },
  {
    label: 'OBM Employee',
    panel: <ObmEmployee />,
  },
]

export default function Metadata() {
  const {metadata} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [dimensions, setDimensions] = useState([])
  const [functionAccess, setFunctionAccess] = useState([])
  const pendingCount = useSelector((state) => state.internalRequest)

  let index = 0

  switch (location?.search.replace('?', '').toLowerCase()) {
    case 'obmemployee':
      index = 2
      break
    case 'entity':
      index = 1
      break
    case 'bank':
      index = 0
      break
    default:
      index = 0
      break
  }

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(METADATA_PAGE_NAME, setFunctionAccess)
    }

    if (functionAccess) {
      if (functionAccess === 'FORBIDDEN') {
        navigate('/Forbidden')
      }
      setDimensions(tabList)
    }
  }, [functionAccess, pendingCount])

  if (metadata) {
    return <Navigate to={`/Metadata?${metadata}`} />
  }

  return <Tabs tabList={dimensions} tabIndex={index} />
}
