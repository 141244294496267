import axios from 'axios'

import {getUserSessionToken} from 'localstorage/UserSessionToken'

const TSApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})

TSApi.interceptors.request.use((config) => {
  const token = getUserSessionToken().session

  if (token && !config.headers.authorization) {
    config.headers.authorization = `Bearer google-oauth2 ${token}`
  }
  return config
})

export default TSApi
