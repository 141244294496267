import {SET_FILTER_STATE, RESET_FILTER_STATE} from '../actions/types'

const INITIAL_STATE = {
  table: null,
  page: null,
  state: null,
}

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILTER_STATE:
      return {...state, table: action.metadata, page: action.page, state: action.payload}
    case RESET_FILTER_STATE:
      return {...state, table: null, page: null, state: null}
    default:
      return state
  }
}

export default filterReducer
