import {useState} from 'react'
import {useMutation, UseMutateFunction} from 'react-query'

import AIApi from 'api/TS/TSApi'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {queryClient} from 'hoc/ReactQuery'
import {queryKeys} from 'hoc/ReactQuery/_constants'
import {FormErrorPayload, FormSuccessPayload} from '_helper/_types'
import {_getError} from '_helper/_getError'
import type {Bank} from '_helper/_types'

export type BankPayload = {
  ...FormSuccessPayload,
}

/**
 * To save bank
 * @return {Promise<any>}
 */
export async function saveBank(formDetails): BankPayload | FormErrorPayload {
  const {data} = await AIApi.put(`banks/${formDetails.bank_id}/`, {
    bank_code: formDetails.bank_code,
    bank_description: formDetails.bank_description,
  })

  return data
}

interface UseSaveBank {
  mutate: UseMutateFunction;
  isLoading: boolean;
  errors: [];
}

export function useSaveBank(): UseSaveBank {
  const {setSnackBar} = useSnackBar()
  const [errors, setErrors] = useState(null)

  const {mutate, isLoading} = useMutation((bankDetails: Bank) => saveBank(bankDetails), {
    onSuccess: (result: BankPayload | FormErrorPayload) => {
      setSnackBar({
        open: true,
        type: result.status === 'SUCCESS' ? 'success' : 'error',
        message:
          result.status === 'SUCCESS'
            ? result.message
            : 'Validation failed! Please check those fields that are in red.',
      })

      if (result.status === 'SUCCESS') {
        queryClient.invalidateQueries([queryKeys.bank, parseInt(result.bank_id)])

        if (errors) {
          setErrors(null)
        }
      } else {
        setErrors(_getError(result.errors))
      }
    },
  })

  return {mutate, isLoading, errors}
}
