import React from 'react'
import {Grid, Paper} from '@mui/material'

interface ApprovedPersonAndManualPaymentApproversParams {
  details: {
    approvedPerson: string,
    manualPaymentApproversAndLimits: string,
  };
  showApprovedPerson: boolean;
  showManualApproval: boolean;
}

export default function ApprovedPersonAndManualPaymentApprovers({
  details,
  renderMockTextArea,
  acc_opening_approved_person = null,
  showApprovedPerson = true,
  showManualApproval = true,
  showManualOfflineApproval = false,
}: ApprovedPersonAndManualPaymentApproversParams) {
  return (
    <>
      {showApprovedPerson && (
        <React.Fragment>
          <Grid
            container
            direction={'row'}
            columnSpacing={8}
            rowSpacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                {renderMockTextArea(
                  'Approved Person',
                  acc_opening_approved_person
                    ? details[acc_opening_approved_person]
                    : details.approved_person
                )}
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{fontSize: '0.75em'}} elevation={0}>
                  <b>example</b>: <br />
                  <u>Group A</u>: Forrest Li Xiaodong (Group CEO) <br />
                  <u>Group B</u>: Tony Hou Tianyu (Group CFO), David Chen Jingye (Chief
                  Product Officer) <br />
                  <br />
                  Condition: Any 2 out of 3
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{p: 2, m: 2, fontSize: '0.75em'}}>
                <b>To note for Approved Person:</b>
                <br />
                - Authorised by Local Regulation/ Board Resolution/ Power of Attorney to
                sign-off account opening, closing and maintenance. Including signing-off
                on bank forms for operational activities <br />
                <br /> - To ensure dual approval <br />
                <br />
                - Please input based on following format: <br />
                1) Grouping & User <br /> 2) Condition for approval
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {showManualApproval && (
        <React.Fragment>
          <Grid
            container
            direction={'row'}
            columnSpacing={8}
            rowSpacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                {renderMockTextArea(
                  'Manual Payment Approvers and Limits',
                  details.acc_opening_manual_payment_approvers_limit
                )}
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{fontSize: '0.75em'}} elevation={0}>
                  <b>example</b>: <br />
                  <u>Group A</u>: Forrest Li Xiaodong (Group CEO)
                  <br />
                  <u>Group B</u>: Tony Hou Tianyu (Group CFO), David Chen Jingye (Chief
                  Product Officer)
                  <br />
                  <br />
                  Condition: Unlimited - 1A+ 1B, {`<`} USD20mil - 1A + 1B/2B
                  <br />
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{p: 2, m: 2, fontSize: '0.75em'}}>
                <b>To note for Manual Payment Approvers and Limits:</b>
                <br />
                - To ensure dual approval. For unlimited amount, there must be at least 1
                HQ approver
                <br />
                <br />
                - Please state persons' name and title
                <br />
                <br />
                - User input based on following format:
                <br />
                <br />
                1) Grouping & User & title
                <br />
                <br />
                2) Condition for approval
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {showManualOfflineApproval && (
        <React.Fragment>
          <Grid
            container
            direction={'row'}
            columnSpacing={8}
            rowSpacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                {renderMockTextArea(
                  'Manual Payment/ Offline Users and Limits',
                  details.manual_payment_approvers_limit
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper sx={{fontSize: '0.75em'}} elevation={0}>
                  <b>example</b>: <br />
                  <u>Group A</u>: Forrest Li Xiaodong (Group CEO)
                  <br />
                  <u>Group B</u>: Tony Hou Tianyu (Group CFO), David Chen Jingye (Chief
                  Product Officer)
                  <br />
                  <br />
                  Offline verifier: XXX (designation)
                  <br />
                  Offline approver: YYY (designation)
                  <br />
                  <br />
                  Condition: Unlimited - 1A+ 1B, {`<`} USD20mil - 1A + 1B/2B
                  <br />
                  Process: maker > offline verifier > offline approver > online approver
                  <br />
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{p: 2, m: 2, fontSize: '0.75em'}}>
                <b>To note for Manual Payment Approvers and Limits:</b>
                <br />
                - To ensure dual approval. For unlimited amount, there must be at least 1
                HQ approver
                <br />
                <br />
                - Please state persons' name and title
                <br />
                <br />
                - User input based on following format:
                <br />
                <br />
                1) Grouping & User & title
                <br />
                2) Condition for approval
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </>
  )
}
