import {
  Dialog,
  FormTable,
  IconButton,
  RadioButton,
  ServerSideAutocomplete,
} from 'finsys-webcomponent'
import {Stack, Grid} from '@mui/material'
import {useState} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

import {fetchUserAccess} from 'api/TS/query/_CommonQueries'
import EmployeeFreeSoloCreateOptionDialogMultiple from 'components/InternalRequest/Forms/_Common/EmployeeFreeSoloCreateOptionDialogMultiple'

type CopyProps = {
  value: string | null,
  onChange: () => void,
  onClose: () => void,
  onSubmit: () => void,
  isDisabled: boolean,
  isLoading: boolean,
}

/**
 * To open OBM User Management Dialog
 * @param value
 * @param onChange
 * @param onClose
 * @param onSubmit
 * @param userList
 * @param isDisabled
 * @param isLoading
 * @return {JSX.Element}
 * @constructor
 */
export const OBMUserManagement = ({
  value,
  onChange,
  onClose,
  onSubmit,
  isDisabled,
  isLoading,
}: CopyProps) => {
  const USER_ACCESS_TYPE_LIST = [
    'Replace User',
    'Mirror User',
    'Remove User',
    'Update Role',
  ]
  const [userAccessType, setUserAccessType] = useState(null)

  const copyUserTable = () => {
    return (
      <FormTable
        tableKey={'copyUser'}
        columns={[
          {
            name: 'Selected User',
            field: 'selected_user',
            component: ({value}) => `${value?.fullname} (${value?.email})`,
          },
          {
            name: 'Mirror User Access',
            field: 'add_user',
            width: '40%',
            component: ({value, dataIndex, error, data, ...props}) => (
              <EmployeeFreeSoloCreateOptionDialogMultiple
                data={value}
                index={dataIndex}
                setFormArrayValue={(employeeValue) => {
                  onChange((prevState) => {
                    let mutatedData = prevState.value.data
                    mutatedData[dataIndex].add_user = employeeValue
                    return {
                      ...prevState,
                      value: {
                        ...prevState.value,
                        data: mutatedData,
                      },
                    }
                  })
                }}
                keyName={'obmUserManagement'}
                subKeyName={'name'}
                selectedUser={data.selected_user}
              />
            ),
          },
          {
            name: '',
            field: '',
            width: '1%',
            component: ({value, dataIndex, ...props}) => {
              return (
                <IconButton
                  title={'Delete'}
                  handleOnClick={() => {
                    onChange((prevState) => {
                      let mutatedData = prevState.value.data
                      mutatedData.splice(dataIndex, 1)
                      return {
                        ...prevState,
                        value: {
                          ...prevState.value,
                          data: mutatedData,
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon style={{color: '#CE6B72'}} />
                </IconButton>
              )
            },
          },
        ]}
        data={value.data}
      />
    )
  }

  const updateUserTable = () => {
    return (
      <FormTable
        tableKey={'updateUser'}
        columns={[
          {
            name: 'Selected User',
            field: 'selected_user',
            component: ({value}) => `${value?.fullname} (${value?.email})`,
          },
          {
            name: '',
            field: '',
            width: '1%',
            component: ({value, dataIndex, ...props}) => {
              return (
                <IconButton
                  title={'Delete'}
                  handleOnClick={() => {
                    onChange((prevState) => {
                      let mutatedData = prevState.value.data
                      mutatedData.splice(dataIndex, 1)
                      return {
                        ...prevState,
                        value: {
                          ...prevState.value,
                          data: mutatedData,
                        },
                      }
                    })
                  }}
                >
                  <DeleteIcon style={{color: '#CE6B72'}} />
                </IconButton>
              )
            },
          },
        ]}
        data={value.data}
      />
    )
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Dialog
          fullWidth
          maxWidth={'md'}
          type={'info'}
          title={'OBM User Management Option'}
          content={
            <Stack spacing={3}>
              <RadioButton
                label={'Select User Access Type'}
                options={USER_ACCESS_TYPE_LIST}
                handleOnChange={(value) => {
                  onChange((prevState) => {
                    return {
                      ...prevState,
                      value: {
                        ...prevState.value,
                        access_type: value,
                        ...(prevState.value.error_access_type && {
                          error_access_type: undefined,
                        }),
                      },
                    }
                  })
                  setUserAccessType(value)
                }}
                required={true}
                value={value.access_type}
                error={value.error_access_type}
              />
              <ServerSideAutocomplete
                disabled={!value.access_type}
                textFieldProps={{
                  disabled: !value.access_type,
                  required: true,
                  placeholder: 'Search user',
                  error: !!value.error_data,
                  helperText: !value.access_type
                    ? 'Please select User Access Type first'
                    : value.error_data,
                }}
                label={'Select Users'}
                value={{email: '', fullname: ''}}
                handleOnChange={(value) => {
                  if (!value) {
                    return
                  }
                  onChange((prevState) => {
                    return {
                      ...prevState,
                      value: {
                        ...prevState.value,
                        data: [
                          ...prevState.value.data,
                          {selected_user: value, add_user: []},
                        ],
                        ...(prevState.value.error_data && {
                          error_data: undefined,
                        }),
                      },
                    }
                  })
                }}
                fetchOptions={(value) => fetchUserAccess(value)}
                getOptionLabel={(option) => {
                  return option.fullname ? `${option.fullname} (${option.email})` : ''
                }}
                isOptionEqualToValue={(option, value) => {
                  return option?.email === value?.email
                }}
              />
              {['Replace User', 'Mirror User'].includes(userAccessType) &&
                copyUserTable()}
              {['Remove User', 'Update Role'].includes(userAccessType) &&
                updateUserTable()}
            </Stack>
          }
          buttons={[
            {label: 'Cancel', type: 'text', onClick: onClose, disabled: isDisabled},
            {
              label: 'Proceed',
              type: 'primary',
              onClick: onSubmit,
              disabled: isDisabled,
              loading: isLoading,
            },
          ]}
          handleOnClose={onClose}
        />
      </Grid>
    </Grid>
  )
}
