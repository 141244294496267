import React, {useEffect, useState} from 'react'
import {useMediaQuery} from '@mui/material'
import {useTheme} from '@mui/styles'
import {AgGridTable} from 'finsys-webcomponent'

import {columnDefs} from 'components/Metadata/Summary/Banks/ag-grid-constant'
import Layout from 'components/Metadata/Summary/Layout'
import {frameworkComponents} from 'components/InternalRequest/Summary/ag-grid-constant'
import {useBankSummary} from 'components/Metadata/_hooks/useBankSummary'
import {getPagination} from 'localstorage/PaginationSettings'

export default function Banks() {
  //table
  const theme = useTheme()
  const isIpad = useMediaQuery(theme.breakpoints.down('md'))

  // filter
  const [searchTF, setSearchTF] = useState('')
  //to cater filter
  const [filterQuery, setFilterQuery] = useState({
    searchText: '',
  })

  //to cater AG-GRID
  const [gridApi, setGridApi] = useState(null)
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    page: 1,
    rowsPerPage: getPagination().rowPerPage,
    toNextPage: null,
  })

  const {bankSummary, isLoading} = useBankSummary({
    rowsPerPage: serverPaginationResult.rowsPerPage,
    page: serverPaginationResult.page,
    searchText: filterQuery.searchText,
  })

  /**
   * To update the AG-Grid table data
   */
  useEffect(() => {
    if (bankSummary?.results) {
      setServerPaginationResult((prevState) => ({
        ...prevState,
        rowData: bankSummary.results,
        totalPages: bankSummary.total_pages,
        totalCount: bankSummary.count,
        toNextPage: bankSummary.next,
      }))
    }
  }, [bankSummary])

  /**
   * Default method for AG-Grid
   * @param params
   * @return {Promise<void>}
   */
  const onGridReady = async (params) => {
    setGridApi(params.api)
  }

  return (
    <Layout
      tab={'banks'}
      pageInset={205}
      retainFilter={true}
      searchPlaceholder={'Search for bank code, name'}
      searchTF={searchTF}
      setSearchTF={setSearchTF}
      setFilterQuery={setFilterQuery}
      filterQuery={filterQuery}
    >
      <AgGridTable
        onGridReady={onGridReady}
        gridApi={gridApi}
        serverPaginationResult={serverPaginationResult}
        setServerPaginationResult={setServerPaginationResult}
        page={serverPaginationResult.page}
        isLoading={isLoading}
        pageInset={isIpad ? 230 : 215}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        suppressRowTransform={true}
        onColumnResized={null}
        onColumnVisible={null}
      />
    </Layout>
  )
}
