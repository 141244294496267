import React, {useEffect, useState} from 'react'
import {Grid, Stack, Typography} from '@mui/material'
import {blue, Button, DetailedLayout, Loading, TextField} from 'finsys-webcomponent'
import moment from 'moment'
import {Info} from '@mui/icons-material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {dateTimeFormat, HQ_ROLE_ID, METADATA_PAGE_NAME} from '_helper/_constants'
import {setBreadcrumbRecordName} from 'store/actions'
import {Details} from 'components/Metadata/ObmEmployee/Details'
import {useObmEmployee} from 'components/Metadata/_hooks/useObmEmployee'
import {useSaveObmEmployee} from 'components/Metadata/_hooks/useSaveObmEmployee'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {getUserRole} from 'localstorage/UserRole'
import {SIDEBAR_DEFAULT, SIDEBAR_INFORMATION} from 'pages/Metadata'

/**
 * Obm Employee Detail
 * @return {JSX.Element}
 * @constructor
 */
const ObmEmployee = () => {
  const {setSnackBar} = useSnackBar()
  let {email} = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [functionAccess, setFunctionAccess] = useState([])
  const [formDetails, setFormDetails] = useState({
    fullname: null,
    email: null,
    designation: null,
  })
  const [sidebarDetails, setSidebarDetails] = useState(SIDEBAR_INFORMATION)
  const {obmEmployee, isLoading} = useObmEmployee(email)
  const saveObmEmployee = useSaveObmEmployee()

  useEffect(() => {
    if (!saveObmEmployee.updatedEmail) {
      return
    }

    navigate(`/Metadata/OBMEmployee/${saveObmEmployee.updatedEmail}`)
  }, [saveObmEmployee.updatedEmail])

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(METADATA_PAGE_NAME, setFunctionAccess)
    }

    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * To validate form
   */
  const validateForm = () => {
    let isGenericFieldsValid = true

    const requiredFields = ['email', 'fullname']

    requiredFields.forEach((field) => {
      if (!formDetails[field] || formDetails[field].length === 0) {
        setFormDetails((prevState) => ({
          ...prevState,
          [`error_${field}`]: 'This is a required field',
        }))

        isGenericFieldsValid = false
      }
    })

    if (!isGenericFieldsValid) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Missing input! Please fill up all the required input`,
      })
    } else {
      saveObmEmployee.mutate({obmEmployee, formDetails})
    }
  }

  /**
   * To initialize data
   */
  useEffect(() => {
    if (!obmEmployee) {
      return
    }

    //To set breadcrumb
    dispatch(
      setBreadcrumbRecordName({
        [location.pathname]: `${obmEmployee.fullname} (${obmEmployee.email})`,
      })
    )

    setFormDetails(obmEmployee)
  }, [obmEmployee])

  if (isLoading) {
    return <Loading open={true} />
  }

  return (
    <DetailedLayout
      mainContent={
        <>
          <Details
            isDisabled={getUserRole().role !== HQ_ROLE_ID}
            errors={saveObmEmployee.errors}
            setFormDetails={setFormDetails}
            formDetails={formDetails}
          />
          {getUserRole().role === HQ_ROLE_ID && (
            <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
              <Button
                label={'Save'}
                onClick={() => validateForm()}
                disabled={saveObmEmployee.isLoading}
                loading={saveObmEmployee.isLoading}
              />
            </Stack>
          )}
        </>
      }
      sideDrawerContent={
        <Grid container direction={'row'} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
              General
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Last Updated On'}
              value={moment
                .utc(formDetails.last_update_date)
                .local()
                .format(dateTimeFormat)}
              disabled={true}
            />
          </Grid>
        </Grid>
      }
      collapseSideDrawer={[
        {
          icon: Info,
          label: 'Information',
          iconStyle: {
            color:
              sidebarDetails.type === SIDEBAR_INFORMATION.type
                ? blue.secondary
                : 'inherit',
          },
          onClick: () => setSidebarDetails(SIDEBAR_INFORMATION),
        },
      ]}
      defaultOnToggleSideMenu={(open) => {
        setSidebarDetails(open ? SIDEBAR_DEFAULT : SIDEBAR_INFORMATION)
      }}
    />
  )
}

export default ObmEmployee
