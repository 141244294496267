import React from 'react'
import {Chip} from '@mui/material'

import {
  clarityChipColor,
  completedChipColor,
  inProgressChipColor,
  rejectedChipColor,
  withdrawnChipColor,
} from 'components/AgGrid/StatusChip/Colors'

type TooltipRendererProps = {
  contentField: string,
}

const StatusChip = ({contentField, dependentField, ...props}: TooltipRendererProps) => {
  let status = props.data[dependentField] ? 'Pending Clarity' : props.data[contentField]

  return status !== ''
    ? getStatus({
        status,
      })
    : ''
}

/**
 * Generic function to get the chip for ticket
 * @param status
 * @param boardView
 * @return {JSX.Element}
 */
const getStatus = ({status}) => {
  let chipProps = {
    size: 'small',
    label: status,
  }

  switch (status) {
    case 'Pending Clarity':
      chipProps = {
        ...chipProps,
        sx: {
          ...clarityChipColor,
        },
      }
      break
    case 'Draft':
      chipProps = {
        ...chipProps,
      }
      break
    case 'Rejected':
      chipProps = {
        ...chipProps,
        sx: {
          ...rejectedChipColor,
        },
      }
      break
    case 'Completed':
      chipProps = {
        ...chipProps,
        sx: {
          ...completedChipColor,
        },
      }
      break
    case 'Withdrawn':
      chipProps = {
        ...chipProps,
        sx: {
          ...withdrawnChipColor,
        },
      }
      break
    default:
      if (status?.includes('Rejected')) {
        chipProps = {
          ...chipProps,
          sx: {
            ...rejectedChipColor,
          },
        }
      } else {
        chipProps = {
          ...chipProps,
          sx: {
            ...inProgressChipColor,
          },
        }
      }

      break
  }

  return <Chip {...chipProps} />
}

export default StatusChip
