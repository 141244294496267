import React, {useState} from 'react'

/**
 * To render table header
 * @return {JSX.Element}
 */
export const TableFixHeader = ({columns}) => {
  return (
    <thead style={{position: 'sticky', top: '0', zIndex: 98, display: 'content'}}>
      <tr>
        {columns?.map(({name, width}, index) => {
          return (
            <th key={`${name}-header${index}`} {...(width && {width: width})}>
              {name}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

/**
 * Helper function to prevent re-rendering
 * @param prevProps
 * @param nextProps
 * @returns {boolean}
 */
function areEqual(prevProps, nextProps) {
  const keys1 = Object.keys(prevProps)
  const keys2 = Object.keys(nextProps)
  if (keys1.length !== keys2.length) {
    return false
  }
  return true
}

/**
 * To prevent header from being re-rendered
 * Header will always be the same - the columns wont change
 * @type {React.NamedExoticComponent<{readonly columns?: *}>}
 */
export const MemoizedTableFixHeader = React.memo(TableFixHeader, areEqual)

export const BodyRow = ({data, columns}) => {
  const [active, setActive] = useState(null)
  return (
    <tbody>
      {data?.map((row, index) => {
        return (
          <tr key={`${index}-${Math.random() + 1}`}>
            {columns?.map(({name, component: Component, field, dependent_field}) => {
              let props = {
                key: `${name}-component-${index}`,
                dataIndex: index,
                editing: active === `${name}-data-${index}`,
              }

              if (field) {
                props = {
                  ...props,
                  value: row[field],
                  error: row[`error_${field}`],
                  data: row,
                }
              }

              if (dependent_field) {
                props = {...props, dependent_field: row[dependent_field]}
              }

              return (
                <td
                  key={`${name}-data-${index}`}
                  onClick={() => setActive(`${name}-data-${index}`)}
                >
                  {Component ? Component({...props}) : row[field]}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

export const MemoizedBodyRow = React.memo(BodyRow, () => false)
