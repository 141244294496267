import TSApi from 'api/TS/TSApi'

export type fetchBankApprovalMatrixSummaryType = Array<{
  approve_date_format: string,
  authorized_person: string,
  bank_approval_matrix_id: number,
  bank_code: string,
  bank_description: string,
  country_id: string,
  entity_id: string,
  entity_name: string,
  escrow_related_online: string,
  header_type: string,
  is_header: string,
  manual_payments: string,
  status: string,
  system_admin: string,
  trust_online: string,
  working_capital_online: string,
}>

/**
 * To retrieve the online banking matrix summary
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchOnlineBankingMatrixSummary(
  filter,
  limit,
  page
): fetchBankApprovalMatrixSummaryType {
  let filterQueryString = ''

  if (filter.country) {
    filterQueryString = filterQueryString.concat(
      '&country_id',
      '=',
      filter.country.country_id
    )
  }

  if (filter.accountType) {
    filterQueryString = filterQueryString.concat('&account_type', '=', filter.accountType)
  }

  if (filter.employeeName) {
    filterQueryString = filterQueryString.concat(
      '&employee_email',
      '=',
      filter.employeeName.email
    )
  }

  if (filter.entity) {
    filter.entity.map((arrayValue) => {
      filterQueryString = filterQueryString.concat(
        '&entity_id',
        '=',
        arrayValue?.entity_id
      )
    })
  }

  const {data} = await TSApi.get(
    `/obm_account_summary/?limit=${limit}&page=${page}${filterQueryString}`
  )

  return data
}

/**
 * To retrieve the bank approval matrix summary
 * @return {Promise<DefaultSelectType>}
 */
export async function exportOnlineBankingMatrixSummary(filter) {
  let filterQueryString = ''

  if (filter.country) {
    filterQueryString = filterQueryString.concat(
      '&country_id',
      '=',
      filter.country.country_id
    )
  }

  if (filter.accountType) {
    filterQueryString = filterQueryString.concat('&account_type', '=', filter.accountType)
  }

  if (filter.employeeName) {
    filterQueryString = filterQueryString.concat(
      '&employee_email',
      '=',
      filter.employeeName.email
    )
  }

  if (filter.entity) {
    filter.entity.map((arrayValue) => {
      filterQueryString = filterQueryString.concat(
        '&entity_id',
        '=',
        arrayValue?.entity_id
      )
    })
  }

  const response = await TSApi.get(`/export_obm/?tz=Asia/Singapore${filterQueryString}`, {
    responseType: 'blob',
  })

  const fileName = response.headers['content-disposition']
    .split('filename=')[1]
    .replaceAll('"', '')

  const attachmentUrl = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = attachmentUrl
  link.setAttribute('download', fileName) //or any other extension
  document.body.appendChild(link)
  link.click()

  return true
}
