import {FORMATTED_APP_NAME} from '_helper/_constants'
import {getWebEntryUrl, removeWebEntryUrl, setWebEntryUrl} from 'localstorage/WebEntryUrl'
import {getUserRole, setUserRole} from 'localstorage/UserRole'
import {fetchRolePageAccess} from 'api/TS/query/AccessRoleQueries'

/**
 * To redirect to entry page if any
 */
export const toRedirectToEntryPage = (navigate) => {
  let entryUrl = getWebEntryUrl()
  if (entryUrl) {
    navigate(entryUrl, {replace: true})
    removeWebEntryUrl()
  } else {
    navigate('/', {replace: true})
  }
}

/**
 * To get permission
 */
export const getPermission = (navigate) => {
  let permissions = {}

  fetchRolePageAccess()
    .then((data) => {
      if (data.length === 0) {
        navigate('/Forbidden')
        return
      }

      data.forEach(({page_name, role_id}) => {
        permissions[page_name] = role_id
      })
      const newPermission = {[FORMATTED_APP_NAME]: permissions}
      setUserRole(
        JSON.stringify(newPermission),
        data.length > 0 ? data[0]['role_id'] : null
      )
      toRedirectToEntryPage(navigate)
    })
    .catch((e) => {
      console.log('error-no-access')
      // if theres error we will redirect to no access page straight
      navigate('/NoAccess')
    })
}

/**
 * To retrieve page access function for user
 */
export const retrievePageFunctionAccess = (pageName, setFunctionAccess) => {
  // we need to get the access for the page
  const access = getAccess()

  if (access[FORMATTED_APP_NAME] && access[FORMATTED_APP_NAME][pageName]) {
    setFunctionAccess(access[FORMATTED_APP_NAME][pageName])
  } else {
    setFunctionAccess('FORBIDDEN')
  }
}

/**
 * To check if we have retrieve access for the app
 */
export const isAppAccessSet = () => {
  // we need to get the access for the page
  const access = getAccess()

  if (access) {
    return access[FORMATTED_APP_NAME]
  }

  return false
}

/**
 * To get formatted access value
 * @returns {null|any}
 */
export const getAccess = () => {
  try {
    const access = getUserRole().access
    if (access === '') {
      return null
    }
    return JSON.parse(access)
  } catch (error) {
    console.log(error)
    const entryURL = getWebEntryUrl()
    localStorage.clear()
    if (entryURL) {
      setWebEntryUrl(entryURL)
    }
  }
}
