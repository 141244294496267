import React from 'react'
import {Grid, Typography} from '@mui/material'

import ApprovedPersonAndManualPaymentApprovers from 'components/InternalRequest/Forms/_Common/ApprovedPersonAndManualPaymentApprovers'
import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'

/**
 * To validate Manual Payment Update field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @returns {boolean}
 */
export const validateManualPaymentUpdateField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo
) => {
  const requiredFields = {
    mandatory: [
      {field: 'reason', label: 'reason'},
      {field: 'manual_payment_approvers_limit', label: 'Manual Payment'},
    ],
  }

  let isFormValid = true

  for (const [key, value] of Object.entries(requiredFields.mandatory)) {
    if (!formDetail[value.field] || formDetail[value.field].length === 0) {
      setFormArrayErrorValue(
        setBankAccountMaintenanceInfo,
        index,
        'form_details',
        value.field
      )
      isFormValid = false
    }
  }

  return isFormValid
}

/**
 * Manual Payment Update form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export const ManualPaymentUpdate = ({detail, formIndex, setFormArrayValue, disabled}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
          Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ApprovedPersonAndManualPaymentApprovers
          details={detail}
          setManual={(value) => {
            setFormArrayValue(
              formIndex,
              'form_details',
              'manual_payment_approvers_limit',
              value
            )
          }}
          manualOfflineApprovalKey={'manual_payment_approvers_limit'}
          disabled={disabled}
          showApprovedPerson={false}
          showManualApproval={false}
          showManualOfflineApproval={true}
        />
      </Grid>
    </Grid>
  )
}
