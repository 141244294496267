import React from 'react'
import {Tooltip, Typography} from '@mui/material'

type TooltipRendererProps = {
  contentField: string,
}

const TooltipRenderer = ({contentField, ...props}: TooltipRendererProps) => {
  const ref = React.createRef()
  const [showTooltip, setShowTooltip] = React.useState(false)

  React.useLayoutEffect(() => {
    //46 is padding
    if (ref.current.offsetWidth > ref.current.parentElement.offsetWidth - 46) {
      setShowTooltip(true)
    }
  }, [ref])

  const content = props.data[contentField]
    ?.replace(/<(?:.|\n)*?>/gm, '')
    .replace(/\&nbsp;/g, '')
  return (
    <Tooltip title={showTooltip ? content : ''} placement={'top-start'} followCursor>
      <Typography ref={ref} variant={'caption'}>
        {content}
      </Typography>
    </Tooltip>
  )
}

export default TooltipRenderer
