import {useQuery} from 'react-query'

import TSApi from 'api/TS/TSApi'
import type {Bank} from '_helper/_types'
import {queryKeys} from 'hoc/ReactQuery/_constants'

/**
 * To save bank
 * @return {Promise<any>}
 */
export async function getBank(id): Bank {
  const {data} = await TSApi.get(`bank_detail/${id}`)

  return data
}

export function useBank(id): Bank {
  const {data: bank = null} = useQuery([queryKeys.bank, id], () => getBank(id))

  return {bank}
}
