import React from 'react'

import Template from 'core/Template'

const NotFound = () => {
  return (
    <Template
      title="Error 404"
      content="Hmms, we couldnt find that page."
      desc={
        <>
          Double check the link is spelled correctly. Contact us @
          <a href="mailto: finsys-epm@sea.com">finsys-epm@sea.com</a>, if you still cant
          find what you need.
        </>
      }
    />
  )
}

export default NotFound
