import {SET_PENDING_REQUEST_TOTAL_COUNT} from '../actions/types'

const INITIAL_STATE = {
  totalCount: 0,
}

const internalRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PENDING_REQUEST_TOTAL_COUNT:
      return {...state, totalCount: action.total}
    default:
      return state
  }
}

export default internalRequestReducer
