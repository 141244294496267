import {SET_BREADCRUMB_RECORD_NAME, RESET_BREADCRUMB_RECORD_NAME} from '../actions/types'

const INITIAL_STATE = {
  crumbs: null,
}

const breadcrumbReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BREADCRUMB_RECORD_NAME:
      return {...state, crumbs: action.crumbs}
    case RESET_BREADCRUMB_RECORD_NAME:
      return {...state, crumbs: null}
    default:
      return state
  }
}

export default breadcrumbReducer
