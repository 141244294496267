import React from 'react'
import {Grid, Paper} from '@mui/material'
import {Input as InputWYSIWYG, Editor} from 'finsys-webcomponent'

interface ApprovedPersonAndManualPaymentApproversParams {
  details: {};
  setApprovers: () => void;
  setManual: () => void;
  disabled: boolean;
  showApprovedPerson: boolean;
  showManualApproval: boolean;
  approvedPersonKey?: string;
  manualApprovalKey?: string;
  manualOfflineApprovalKey?: string;
}

export default function ApprovedPersonAndManualPaymentApprovers({
  details,
  approvedPersonKey,
  manualApprovalKey,
  manualOfflineApprovalKey,
  setApprovers,
  setManual,
  disabled,
  showApprovedPerson = true,
  showManualApproval = true,
  showManualOfflineApproval = false,
}: ApprovedPersonAndManualPaymentApproversParams) {
  return (
    <>
      {showApprovedPerson && (
        <React.Fragment>
          <Grid
            container
            direction={'row'}
            columnSpacing={8}
            rowSpacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputWYSIWYG
                  label={'Approved Person'}
                  customEditor={Editor}
                  value={details[approvedPersonKey]}
                  handleOnChange={(value) => setApprovers(value)}
                  multiline
                  disabled={disabled}
                  required={true}
                  error={!!details[`error_${approvedPersonKey}`]}
                  helperText={details[`error_${approvedPersonKey}`]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper sx={{fontSize: '0.75em'}} elevation={0}>
                  <b>example</b>: <br />
                  <u>Group A</u>: Forrest Li Xiaodong (Group CEO) <br />
                  <u>Group B</u>: Tony Hou Tianyu (Group CFO), David Chen Jingye (Chief
                  Product Officer) <br />
                  <br />
                  Condition: Any 2 out of 3
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper sx={{p: 2, m: 2, fontSize: '0.75em'}}>
                <b>To note for Approved Person:</b>
                <br />
                - Authorised by Local Regulation/ Board Resolution/ Power of Attorney to
                sign-off account opening, closing and maintenance. Including signing-off
                on bank forms for operational activities <br />
                <br /> - To ensure dual approval <br />
                <br />
                - Please input based on following format: <br />
                1) Grouping & User <br /> 2) Condition for approval
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {showManualApproval && (
        <React.Fragment>
          <Grid
            container
            direction={'row'}
            columnSpacing={8}
            rowSpacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputWYSIWYG
                  label={'Manual Payment Approvers and Limits'}
                  customEditor={Editor}
                  value={details[manualApprovalKey]}
                  handleOnChange={(value) => setManual(value)}
                  multiline
                  disabled={disabled}
                  required={true}
                  error={!!details[`error_${manualApprovalKey}`]}
                  helperText={details[`error_${manualApprovalKey}`]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper sx={{fontSize: '0.75em'}} elevation={0}>
                  <b>example</b>: <br />
                  <u>Group A</u>: Forrest Li Xiaodong (Group CEO)
                  <br />
                  <u>Group B</u>: Tony Hou Tianyu (Group CFO), David Chen Jingye (Chief
                  Product Officer)
                  <br />
                  <br />
                  Condition: Unlimited - 1A+ 1B, {`<`} USD20mil - 1A + 1B/2B
                  <br />
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper sx={{p: 2, m: 2, fontSize: '0.75em'}}>
                <b>To note for Manual Payment Approvers and Limits:</b>
                <br />
                - To ensure dual approval. For unlimited amount, there must be at least 1
                HQ approver
                <br />
                <br />
                - Please state persons' name and title
                <br />
                <br />
                - User input based on following format:
                <br />
                <br />
                1) Grouping & User & title
                <br />
                <br />
                2) Condition for approval
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      {showManualOfflineApproval && (
        <React.Fragment>
          <Grid
            container
            direction={'row'}
            columnSpacing={8}
            rowSpacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputWYSIWYG
                  label={'Manual Payment/ Offline Users and Limits'}
                  customEditor={Editor}
                  value={details[manualOfflineApprovalKey]}
                  handleOnChange={(value) => setManual(value)}
                  multiline
                  disabled={disabled}
                  required={true}
                  error={!!details[`error_${manualOfflineApprovalKey}`]}
                  helperText={details[`error_${manualOfflineApprovalKey}`]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper sx={{fontSize: '0.75em'}} elevation={0}>
                  <b>example</b>: <br />
                  <u>Group A</u>: Forrest Li Xiaodong (Group CEO)
                  <br />
                  <u>Group B</u>: Tony Hou Tianyu (Group CFO), David Chen Jingye (Chief
                  Product Officer)
                  <br />
                  <br />
                  Offline verifier: XXX (designation)
                  <br />
                  Offline approver: YYY (designation)
                  <br />
                  <br />
                  Condition: Unlimited - 1A+ 1B, {`<`} USD20mil - 1A + 1B/2B
                  <br />
                  Process: maker > offline verifier > offline approver > online approver
                  <br />
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper sx={{p: 2, m: 2, fontSize: '0.75em'}}>
                <b>To note for Manual Payment Approvers and Limits:</b>
                <br />
                - To ensure dual approval. For unlimited amount, there must be at least 1
                HQ approver
                <br />
                <br />
                - Please state persons' name and title
                <br />
                <br />
                - User input based on following format:
                <br />
                <br />
                1) Grouping & User & title
                <br />
                2) Condition for approval
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </>
  )
}
