import React from 'react'
import {Grid, Stack} from '@mui/material'
import {IconButton, FormTable, Button, Autocomplete, Label} from 'finsys-webcomponent'
import DeleteIcon from '@mui/icons-material/Delete'

import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'

/**
 * To validate Approval Threshold Update field
 * @param formDetail
 * @param index
 * @param setBankAccountMaintenanceInfo
 * @param setFormErrorDetail
 * @returns {boolean}
 */
export const validateApprovalThresholdUpdateField = (
  formDetail,
  index,
  setBankAccountMaintenanceInfo,
  setFormErrorDetail
) => {
  const requiredFields = {
    mandatory: [{field: 'reason', label: 'reason'}],
    matrixCondition: [
      {field: 'matrix_threshold', label: 'Threshold'},
      {field: 'matrix_condition', label: 'Conditions'},
    ],
    bankDetails: [
      {field: 'bank_id', label: 'Bank Code'},
      {field: 'account_number', label: 'Account Number'},
      {field: 'acc_managed_by', label: 'Account Managed By'},
    ],
  }

  let isFormValid = true

  for (const [key, value] of Object.entries(requiredFields.mandatory)) {
    if (!formDetail[value.field] || formDetail[value.field].length === 0) {
      if (isFormValid) {
        setFormArrayErrorValue(
          setBankAccountMaintenanceInfo,
          index,
          'form_details',
          value.field
        )
        isFormValid = false
      }
    }
  }

  let isMatrixValid = true
  let isBankValid = true
  //lets validate form details
  if (formDetail.matrix_condition?.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'matrix_condition',
      'Please add at least 1 Matrix Detail'
    )
    isFormValid = false
  } else {
    for (const matrixIndex in formDetail.matrix_condition) {
      for (const [key, value] of Object.entries(requiredFields.matrixCondition)) {
        if (
          !formDetail.matrix_condition[matrixIndex][value.field] ||
          formDetail.matrix_condition[matrixIndex][value.field]?.length === 0
        ) {
          if (isMatrixValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'matrix_condition',
              'Please fill up all the required fields'
            )
            isMatrixValid = false
          }
          isFormValid = false
          setFormErrorDetail('matrix_condition', index, matrixIndex, value.field)
        }
      }
    }
  }

  if (formDetail.bank_details.length === 0) {
    setFormArrayErrorValue(
      setBankAccountMaintenanceInfo,
      index,
      'form_details',
      'bank_details',
      'Please add at least 1 Bank Account Detail'
    )
    isFormValid = false
  } else {
    for (const bankIndex in formDetail.bank_details) {
      for (const [key, value] of Object.entries(requiredFields.bankDetails)) {
        if (
          !formDetail.bank_details[bankIndex][value.field] ||
          formDetail.bank_details[bankIndex][value.field].length === 0
        ) {
          if (isBankValid) {
            setFormArrayErrorValue(
              setBankAccountMaintenanceInfo,
              index,
              'form_details',
              'bank_details',
              'Please fill up all the required fields'
            )
            isBankValid = false
          }
          isFormValid = false
          setFormErrorDetail('bank_details', index, bankIndex, value.field)
        }
      }
    }
  }

  return isFormValid
}

/**
 * Deletion form
 * @param detail
 * @param formIndex
 * @param setFormArrayValue
 * @param setArrayValue
 * @param disabled
 * @param reason
 * @param OBMbanks
 * @param bankAccount
 * @returns {JSX.Element}
 * @constructor
 */
export const ApprovalThresholdUpdate = ({
  detail,
  formIndex,
  setFormArrayValue,
  setArrayValue,
  disabled,
  matrixConditions,
  matrixThresholds,
  OBMbanks,
  bankAccount,
  accountMangedBy,
}) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label label={`Bank Account`} required={true} error={detail.error_bank_details} />
        <FormTable
          tableKey={'approval_threshold_update'}
          columns={[
            {
              name: 'Bank',
              field: 'bank_id',
              width: '30%',
              component: ({value, dataIndex, error}) => (
                <Autocomplete
                  options={OBMbanks}
                  getOptionLabel={(option) => option.text || ''}
                  handleOnChange={(value) => {
                    ;['account_number', 'acc_managed_by', 'currency_code'].forEach(
                      (fieldName) => {
                        setArrayValue(
                          formIndex,
                          'bank_details',
                          dataIndex,
                          fieldName,
                          null
                        )
                      }
                    )

                    setArrayValue(formIndex, 'bank_details', dataIndex, 'bank_id', value)
                  }}
                  value={value}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Bank Account Number',
              field: 'account_number',
              width: '20%',
              component: ({value, dataIndex, error, data}) => (
                <Autocomplete
                  options={
                    bankAccount[detail.bank_details[dataIndex].bank_id?.value]
                      ? [
                          {
                            acc_managed_by: null,
                            account_number: 'All Account',
                            currency_code: 'ALL',
                            account_type: null,
                            entity_id: 'ALL',
                            entity_name: null,
                          },
                          ...(bankAccount[
                            detail.bank_details[dataIndex].bank_id?.value
                          ] ?? []),
                        ]
                      : detail.bank_details[dataIndex].bank_id?.text === 'All Banks (ALL)'
                      ? [
                          {
                            acc_managed_by: null,
                            account_number: 'All Account',
                            currency_code: 'ALL',
                            account_type: null,
                            entity_id: null,
                            entity_name: null,
                          },
                        ]
                      : []
                  }
                  getOptionLabel={(option) => {
                    console.log('Approval Threshold CH Option: ', option)
                    // console.log(match)
                    return option.account_number &&
                      option.account_number !== 'All Account'
                      ? `${option.account_number} - ${option.entity_code}`
                      : option.account_number === 'All Account'
                      ? option.account_number
                      : ''
                  }}
                  handleOnChange={(value) => {
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'account_number',
                      value?.account_number
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'entity_code',
                      value?.entity_code
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'currency_code',
                      value?.currency_code
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'account_type',
                      value?.account_type
                    )
                    setArrayValue(
                      formIndex,
                      'bank_details',
                      dataIndex,
                      'acc_managed_by',
                      value?.acc_managed_by
                    )
                  }}
                  value={data}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Account Type',
              width: '5%',
              field: 'account_type',
            },
            {
              name: 'Currency',
              width: '5%',
              field: 'currency_code',
            },

            {
              name: 'Managed By',
              field: 'acc_managed_by',
              width: '12%',
              component: ({value, dataIndex, data, error}) => {
                const selectManagedBy = data?.account_number === 'All Account'

                if (selectManagedBy) {
                  return (
                    <Autocomplete
                      options={accountMangedBy}
                      getOptionLabel={(option) => option?.text ?? option}
                      handleOnChange={(value) => {
                        setArrayValue(
                          formIndex,
                          'bank_details',
                          dataIndex,
                          'acc_managed_by',
                          value?.value
                        )
                      }}
                      value={value ?? ''}
                      textFieldProps={{
                        error: !!error,
                        sx: {
                          '& label': {display: 'none'},
                          '& .MuiInput-root': {mt: 0},
                        },
                      }}
                      disabled={!selectManagedBy || disabled}
                    />
                  )
                } else {
                  return value ?? ''
                }
              },
            },
            ...(!disabled
              ? [
                  {
                    name: '',
                    disabled: disabled,
                    field: '',
                    width: '1%',
                    component: ({dataIndex}) => {
                      return (
                        <IconButton
                          title="delete"
                          handleOnClick={() => {
                            let newBankDetails = detail.bank_details
                            newBankDetails.splice(dataIndex, 1)
                            setFormArrayValue(
                              formIndex,
                              'form_details',
                              'bank_details',
                              newBankDetails
                            )
                          }}
                        >
                          <DeleteIcon style={{color: '#CE6B72'}} />
                        </IconButton>
                      )
                    },
                  },
                ]
              : []),
          ]}
          data={detail.bank_details}
        />
        {!disabled && (
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button
              label={'Add Bank Details'}
              type={'secondary'}
              size={'small'}
              onClick={() => {
                const newBankDetails = [
                  ...detail.bank_details,
                  {
                    role: '',
                    group: '',
                    bank_id: '',
                    entity_id: null,
                    currency_code: '',
                    account_number: '',
                    acc_managed_by: '',
                    account_type: '',
                  },
                ]
                setFormArrayValue(
                  formIndex,
                  'form_details',
                  'bank_details',
                  newBankDetails
                )
              }}
            />
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label label={`Matrix`} required={true} error={detail.error_matrix_condition} />
        <FormTable
          tableKey={'approval_threshold_update_matrix'}
          columns={[
            {
              name: 'Matrix Threshold',
              field: 'matrix_threshold',
              component: ({value, dataIndex, error}) => (
                <Autocomplete
                  options={matrixThresholds}
                  getOptionLabel={(option) => option?.text ?? option}
                  handleOnChange={(value) =>
                    setArrayValue(
                      formIndex,
                      'matrix_condition',
                      dataIndex,
                      'matrix_threshold',
                      value
                    )
                  }
                  value={value}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            {
              name: 'Matrix Condition',
              field: 'matrix_condition',
              component: ({value, dataIndex, error}) => (
                <Autocomplete
                  options={matrixConditions}
                  getOptionLabel={(option) => option?.text ?? option}
                  handleOnChange={(value) =>
                    setArrayValue(
                      formIndex,
                      'matrix_condition',
                      dataIndex,
                      'matrix_condition',
                      value
                    )
                  }
                  value={value}
                  textFieldProps={{
                    error: !!error,
                    sx: {
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                    },
                  }}
                  disabled={disabled}
                />
              ),
            },
            ...(!disabled
              ? [
                  {
                    name: '',
                    field: '',
                    disabled: disabled,
                    width: '1%',
                    component: ({dataIndex}) => {
                      return (
                        <IconButton
                          title="delete"
                          handleOnClick={() => {
                            let newMatrixDetails = detail.matrix_condition
                            newMatrixDetails.splice(dataIndex, 1)
                            setFormArrayValue(
                              formIndex,
                              'form_details',
                              'matrix_condition',
                              newMatrixDetails
                            )
                          }}
                        >
                          <DeleteIcon style={{color: '#CE6B72'}} />
                        </IconButton>
                      )
                    },
                  },
                ]
              : []),
          ]}
          data={detail.matrix_condition}
        />
        {!disabled && (
          <Stack mt={2} spacing={2} direction={'row'}>
            <Button
              label={'Add Matrix Details'}
              type={'secondary'}
              size={'small'}
              onClick={() => {
                const newMatrixDetails = [
                  ...detail.matrix_condition,
                  {
                    matrix_threshold: null,
                    matrix_condition: null,
                  },
                ]
                setFormArrayValue(
                  formIndex,
                  'form_details',
                  'matrix_condition',
                  newMatrixDetails
                )
              }}
            />
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
