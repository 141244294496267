import React from 'react'
import {Grid} from '@mui/material'
import {RadioButton} from 'finsys-webcomponent'

type FilterOptionsProps = {
  filterCondition: {
    temp: {
      currencies: [],
      segments: [],
      countries: [],
    },
    actual: {
      currency: [],
      segment: [],
      country: [],
    },
  },
  openFullScreenDialog: boolean,
  handleClose(): () => void,
  handleSetFilterCondition(): () => void,
  handleApplyFilter(): () => void,
  handleClearAllFilter(): () => void,
}
export const OBMFilterView = ({
  filterCondition,
  setFilterCondition,
}: FilterOptionsProps) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <RadioButton
          label="Flag Conflicted Designation"
          value={filterCondition.conflict ? 'Y' : 'N'}
          handleOnChange={(value) => {
            setFilterCondition((prevState) => {
              return {
                ...prevState,
                temp: {
                  ...prevState.temp,
                  conflict: value === 'Y',
                },
              }
            })
          }}
          options={['Y', 'N']}
        />
      </Grid>
    </Grid>
  )
}

export default OBMFilterView
