import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from 'hoc/AuthContext'
import {Stack, Typography} from '@mui/material'
import Container from '@mui/material/Container'
import {Button, FinanceLogoImage, Loading} from 'finsys-webcomponent'

type templateProps = {
  errorCode?: string,
  content: string,
  desc: React.node,
  tryAgain?: () => void,
}

/**
 * Generic error template
 * @param errorCode
 * @param content
 * @param desc
 * @param tryAgain
 * @param loadingIndicator
 * @return {JSX.Element}
 * @constructor
 */
const Template = ({
  errorCode,
  content,
  desc,
  tryAgain,
  loadingIndicator,
}: templateProps) => {
  const {user} = useAuth()
  const [imageLoading, setImageLoading] = useState(false)
  const navigate = useNavigate()

  if (loadingIndicator === 'progress') {
    return <Loading open={true} />
  }

  return (
    <Container
      maxWidth={false}
      sx={(theme) => ({
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
          top: '35px',
        },
        [theme.breakpoints.down('sm')]: {
          top: '40px',
        },
        textAlign: 'center',
      })}
    >
      <img
        onLoad={() => setImageLoading(true)}
        src={FinanceLogoImage}
        alt="finance mascot"
      />
      {imageLoading && (
        <>
          <Typography variant={'h6'}>
            {errorCode && `Error ${errorCode} -`} {content}
          </Typography>
          <Typography variant={'h6'}>{desc}</Typography>
          <div>
            <Stack direction={'row'} spacing={2} justifyContent={'center'} sx={{pt: 3}}>
              <>
                {!!user && (
                  <>
                    {tryAgain && (
                      <Button
                        type={'cancel'}
                        size={'large'}
                        onClick={() => window.location.reload()}
                        label={'Try Again'}
                      />
                    )}
                    <Button
                      type={'primary'}
                      size={'large'}
                      onClick={() => navigate('/')}
                      label={'Home'}
                    />
                  </>
                )}
                {!!!user && (
                  <>
                    <Button
                      type={'primary'}
                      size={'large'}
                      onClick={() => navigate('/Login')}
                      label={'Login'}
                    />
                  </>
                )}
              </>
            </Stack>
          </div>
        </>
      )}
    </Container>
  )
}

export default Template
