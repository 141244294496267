import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Typography, Grid, Stack, Menu, MenuItem} from '@mui/material'
import {
  AccessTime,
  MoreVert as MoreVertIcon,
  BookOnline,
  Approval as ApprovalIcon,
  Print as PrintIcon,
  History as HistoryIcon,
} from '@mui/icons-material'
import {useMutation} from 'react-query'
import {useDispatch} from 'react-redux'
import {
  TextField,
  Button,
  Loading,
  IconButton,
  DetailedLayout,
  Accordion,
  Comment,
  FileDeleteDialog,
  FilePreviewDialog,
  Dialog,
} from 'finsys-webcomponent'

import {useSnackBar} from 'hoc/SnackbarHandler'
import {setBreadcrumbRecordName} from 'store/actions'
import BankAccountOpening, {
  getBankAccountOpeningFormFields,
  validateBankAccountOpening,
} from 'components/InternalRequest/Forms/BankAccountOpening'
import BankAccountClosing, {
  getBankAccountClosingFormFields,
  validateBankAccountClosing,
} from 'components/InternalRequest/Forms/BankAccountClosing'
import BankAccountMaintenance, {
  getBankAccountMaintenanceFormFields,
  TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE,
  TYPE_OF_CHANGE_CREATION,
  TYPE_OF_CHANGE_DELETION,
  TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE,
  TYPE_OF_CHANGE_USER_ROLE_UPDATE,
  validateBankAccountMaintenance,
} from 'components/InternalRequest/Forms/BankAccountMaintanence'
import BankGuarantee, {
  getBankGuaranteeFormFields,
  validateBankGuarantee,
} from 'components/InternalRequest/Forms/BankGuarantee'
import DocumentSigningRequest, {
  getDocumentSigningFormFields,
  validateDocumentSigning,
} from 'components/InternalRequest/Forms/DocumentSigningRequest'
import useDataFetching from 'hoc/UseDataFetching'
import {
  deleteDraftInternalRequest,
  deleteEntities,
  fetchRequestForm,
  rejectInternalRequest,
  saveInternalRequest,
  submitInternalRequest,
  updateTicketSubCategory,
  withdrawnInternalRequest,
} from 'api/TS/query/InternalRequestQueries'
import {
  fetchComments,
  FetchCommentsType,
  saveComments,
} from 'api/TS/query/InternalRequestCommentQueries'
import type {FetchRequestFormType} from 'api/TS/query/InternalRequestQueries'
import {
  deleteAttachment,
  downloadAttachment,
  previewAttachment,
  uploadRequestAttachment,
} from 'api/TS/query/AttachmentQueries'
import {isDisabled} from 'components/InternalRequest/Forms/_Common'
import {Attachments} from 'components/InternalRequest/Forms/Attachments'
import {Reject} from 'components/ActionButtonDialog/Reject'
import {Withdraw} from 'components/ActionButtonDialog/Withdraw'
import {useAuth} from 'hoc/AuthContext'
import {queryClient} from 'hoc/ReactQuery'
import {
  FORM_BANK_ACCOUNT_OPENING,
  FORM_BANK_ACCOUNT_CLOSING,
  FORM_BANK_ACCOUNT_MAINTENANCE,
  FORM_BANK_GUARANTEE,
  FORM_DOCUMENT_SIGNING_REQUEST,
  HQ_ROLE_ID,
  dateTimeFormat,
  INTERNAL_REQUEST_PAGE_NAME,
} from '_helper/_constants'
import StatusChip, {
  approvedChipColor,
  notifiedChipColor,
  rejectedChipColor,
  submittedChipColor,
  verifiedChipColor,
} from 'components/InternalRequest/Forms/_Common/StatusChip'
import {getUserRole} from 'localstorage/UserRole'
import {
  useFetchPreviousRejectList,
  useFetchTicketHistory,
  useFetchTicketProgressIndicator,
} from 'components/_Utils/UseFetchFunctions'
import {Information} from 'components/InternalRequest/Forms/Information'
import {Details, validateDetails} from 'components/InternalRequest/Forms/Details'
import {TicketHistory} from 'components/ActionButtonDialog/TicketHistory'
import ProgressIndicator from 'components/InternalRequest/ProgressIndicator'
import {DeleteDraft} from 'components/ActionButtonDialog/DeleteDraft'
import {DeleteDialog} from 'components/ActionButtonDialog/DeleteEntity'
import {ModifySubCategory} from 'components/ActionButtonDialog/ModifySubCategory'
import PrintTicketTemplate from 'components/InternalRequest/PrintTicketTemplate'
import {retrievePageFunctionAccess} from '_helper/_authHelper'

/**
 * To retrieve request form
 * @param id
 * @return {{isError: false | true, data: FetchRequestFormType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
function useRetrieveTicket(id): FetchRequestFormType {
  return useDataFetching(`ticket-${id}`, () => fetchRequestForm(id), {
    refetchOnWindowFocus: false,
  })
}

/**
 * To retrieve account comments
 * @param id
 * @return {{isError: false | true, data: FetchCommentsType, isPreviousData: boolean, loading: boolean | undefined, error: unknown}}
 */
function useGetAccountComments(id: string): FetchCommentsType {
  return useDataFetching(`ticket-${id}-comments`, () => fetchComments(id))
}

/**
 * To return the approver status chips bg color
 * @param status
 * @return {string}
 */
export const renderApproverStatusBackgroundColor = (status) => {
  switch (status) {
    case 'Notified':
      return notifiedChipColor
    case 'Approved':
      return approvedChipColor
    case 'Verified':
      return verifiedChipColor
    case 'Submitted':
      return submittedChipColor
    case 'Rejected':
      return rejectedChipColor
    default:
      // draft
      return {color: '6b6b6b'}
  }
}

function Edit() {
  let {id} = useParams()
  const location = useLocation()
  const {setSnackBar} = useSnackBar()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {user} = useAuth()
  const {email: userEmail} = user
  const defaultRemoveEntityDialog = {
    open: false,
    type: null,
    value: [],
  }

  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState({
    open: false,
    index: null,
  })
  const [filePreviewDialog, setFilePreviewDialog] = useState({
    open: false,
    value: null,
  })
  const [actionDialog, setActionDialog] = useState({
    type: null,
    value: null,
    open: false,
    isDisabled: false,
    isLoading: false,
  })
  const [isCallingApi, setIsCallingApi] = useState({
    loading: false,
    type: null,
  })
  const [printDialog, setPrintDialog] = useState(false)
  const [printTicket, setPrintTicket] = useState({
    print: false,
    printComment: false,
  })
  const [formDetails, setFormDetails] = useState({
    able_to_withdraw: false,
    editable: '',
    request_by: '',
    current_order: null,
    ticket_id: '',
    entity_id: [],
    entity_code: '',
    country_id: [],
    category_type: '',
    sub_category_1: '',
    submission_date_format: '',
    last_update_date_format: '',
    ticket_number: '',
    ticket_status: '',
    status: '',
    request_by_fullname: '',
    request_summary: '',
    local_notify: [],
    local_approval: [],
    hq_approval: [],
    hq_verifier_1_fullname: '',
    hq_verifier_1_approve_date: '',
    hq_verifier_2: '',
    hq_verifier_2_approve_date: '',
    hq_final_approver: '',
    hq_final_approver_approve_date: '',
    reject_by: null,
    ticket_attachment: [],
    ticket_attachment_count: 0,
    details: null,
    reject_from_order: null,
  })
  const [comment, setComment] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [removeEntityDialog, setRemoveEntityDialog] = useState(defaultRemoveEntityDialog)
  const [changeSubCategoryDialog, setChangeSubCategoryDialog] = useState(
    defaultRemoveEntityDialog
  )
  const [functionAccess, setFunctionAccess] = useState([])

  const {loading: isCommentsLoading, data: comments} = useGetAccountComments(id)
  const {loading: isRequestLoading, data: internalRequestData} = useRetrieveTicket(id)
  const {loading: isPreviousStepLoading, data: userList} = useFetchPreviousRejectList(
    id,
    internalRequestData?.current_order
  )
  const {loading: isProgressIndicatorLoading, data: progressList} =
    useFetchTicketProgressIndicator(id)
  const {loading: isTicketHistoryLoading, data: ticketHistory} = useFetchTicketHistory(id)

  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(INTERNAL_REQUEST_PAGE_NAME, setFunctionAccess)
    }

    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * To populate subcategory 1 value based on the category user selected
   */
  useEffect(() => {
    if (!formDetails.category_type || !internalRequestData) {
      return
    }

    let formsData = null

    switch (formDetails.category_type?.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        formsData = getBankAccountOpeningFormFields(formDetails)
        break

      case FORM_BANK_ACCOUNT_CLOSING:
        formsData = getBankAccountClosingFormFields(formDetails)
        break

      case FORM_BANK_ACCOUNT_MAINTENANCE:
        formsData = getBankAccountMaintenanceFormFields(formDetails)
        break

      case FORM_BANK_GUARANTEE:
        formsData = getBankGuaranteeFormFields(formDetails)
        break

      case FORM_DOCUMENT_SIGNING_REQUEST:
        formsData = getDocumentSigningFormFields(formDetails)
        break

      default:
        break
    }
    setFormDetails((prevStats) => ({
      ...prevStats,
      ...formsData,
    }))
  }, [formDetails.category_type, formDetails.sub_category_1])

  /**
   * To trigger window.print()
   */
  useEffect(() => {
    if (!printTicket.print) {
      return
    }
    //to override the file name during print
    document.title = `${formDetails.ticket_number}_${moment().format('DD/MM/YYYY')}`
    window.print()
    let resetPrintTicket = window.setTimeout(() => {
      setPrintTicket((prevState) => ({
        print: false,
        printComment: false,
      }))
      document.title = `${process.env.REACT_APP_NAME}`
    }, 150)
    return () => {
      clearTimeout(resetPrintTicket)
    }
  }, [printTicket])

  /**
   * Initiate Form Input for Account after each update
   */
  useEffect(() => {
    if (!internalRequestData) {
      return
    }

    dispatch(
      setBreadcrumbRecordName({
        [location.pathname]: `${internalRequestData.category_type} - ${internalRequestData.ticket_number}`,
      })
    )

    let formsData = null

    switch (internalRequestData.category_type.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        formsData = getBankAccountOpeningFormFields(internalRequestData)
        break

      case FORM_BANK_ACCOUNT_CLOSING:
        formsData = getBankAccountClosingFormFields(internalRequestData)
        break

      case FORM_BANK_ACCOUNT_MAINTENANCE:
        formsData = getBankAccountMaintenanceFormFields(internalRequestData)
        break

      case FORM_BANK_GUARANTEE:
        formsData = getBankGuaranteeFormFields(internalRequestData)
        break

      case FORM_DOCUMENT_SIGNING_REQUEST:
        formsData = getDocumentSigningFormFields(internalRequestData)
        break

      default:
        break
    }
    setFormDetails((prevStats) => ({
      ...prevStats,
      able_to_withdraw:
        internalRequestData.local_approval.filter(
          ({approve_date}) => approve_date !== null
        ).length === 0,
      editable: internalRequestData.editable,
      request_by: internalRequestData.request_by,
      current_order: parseInt(internalRequestData.current_order),
      ticket_id: internalRequestData.ticket_id,
      entity_id: internalRequestData.entity ?? [],
      country_id: internalRequestData.country ?? [],
      category_type: internalRequestData.category_type,
      sub_category_1: internalRequestData.sub_category_1,
      submission_date_format: internalRequestData.submission_date_format,
      last_update_date_format: internalRequestData.last_update_date_format,
      ticket_number: internalRequestData.ticket_number,
      ticket_status: internalRequestData.ticket_status,
      request_by_fullname: internalRequestData.request_by_fullname,
      request_summary: internalRequestData.request_summary,
      status: internalRequestData.status,
      local_notify: internalRequestData.local_notify,
      local_approval: internalRequestData.local_approval,
      hq_approval: internalRequestData.hq_approval,
      hq_verifier_1_fullname: internalRequestData.hq_verifier_1_fullname
        ? `${internalRequestData.hq_verifier_1_fullname} (${internalRequestData.hq_verifier_1})`
        : undefined,
      hq_verifier_1_approve_date: internalRequestData.hq_verifier_1_approve_date,
      hq_verifier_2: internalRequestData.hq_verifier_2
        ? {
            email: internalRequestData.hq_verifier_2,
            full_name: `${internalRequestData.hq_verifier_2_fullname}`,
            approve_date: internalRequestData.hq_verifier_2_approve_date,
          }
        : undefined,
      hq_verifier_2_approve_date: internalRequestData.hq_verifier_2_approve_date,
      hq_final_approver: internalRequestData.hq_final_approver
        ? {
            email: internalRequestData.hq_final_approver,
            full_name: `${internalRequestData.hq_final_approver_fullname}`,
            approve_date: internalRequestData.hq_final_approver_approve_date,
          }
        : undefined,
      hq_final_approver_approve_date: internalRequestData.hq_final_approver_approve_date,
      reject_by: internalRequestData.reject_by,
      ticket_attachment: internalRequestData.ticket_attachment
        ? internalRequestData.ticket_attachment.map((file) => {
            return {
              docName: file.document_name,
              attachment_filename: file.attachment_filename,
              name: file.attachment_filename,
              attachment_id: file.attachment_id,
              file: {
                attachment_filename: file.attachment_filename,
                name: file.attachment_filename,
                attachment_id: file.attachment_id,
              },
              purpose: file.attachment_purpose,
              created_by: file.created_by,
            }
          })
        : null,
      ticket_attachment_count: internalRequestData.ticket_attachment_count,
      reject_from_order: internalRequestData.reject_from_order,
      ...formsData,
    }))
  }, [internalRequestData])

  /**
   * Check if the forms fields is disabled
   */
  useEffect(() => {
    if (internalRequestData && formDetails) {
      setDisabled(
        isDisabled(
          formDetails.current_order,
          formDetails.editable,
          formDetails.hq_verifier_1_approve_date,
          formDetails.hq_verifier_2_approve_date
        )
      )
    }
  }, [formDetails, internalRequestData])

  /**
   * To invalidate ticket so that it can re-fetch the updated data
   */
  const invalidateTicket = () => {
    queryClient.invalidateQueries(`ticket-${id}`)
    queryClient.invalidateQueries(`ticket-${id}-comments`)
    queryClient.invalidateQueries(`ticket-${id}-history`)
    queryClient.invalidateQueries(`ticket-${id}-progress-indicator`)
  }

  /**
   * To save form
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const saveInternalRequestForm = useMutation((request) => saveInternalRequest(request), {
    onSuccess: (result) => {
      setSnackBar({open: true, type: 'success', message: result.status})
    },
    onError: (err, variables, context) => {
      console.log(err)
      setSnackBar((prevState) => ({...prevState, open: true}))
    },
    onSettled: () => {
      invalidateTicket()
    },
  })

  /**
   * To submit form
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const submitInternalRequestForm = useMutation(
    (request) => submitInternalRequest(request),
    {
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        invalidateTicket()
      },
    }
  )

  /**
   * To reject internal request form
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleRejectTicket = useMutation(
    () => rejectInternalRequest(actionDialog, formDetails),
    {
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        invalidateTicket()
        setActionDialog({
          type: null,
          value: null,
          open: false,
          isDisabled: false,
          isLoading: false,
        })
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To withdrawn internal request form
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleWithdrawRequest = useMutation(
    () => withdrawnInternalRequest(actionDialog.value, formDetails),
    {
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        invalidateTicket()
        setActionDialog({
          type: null,
          value: null,
          open: false,
          isDisabled: false,
          isLoading: false,
        })
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To add new comment
   * @type {UseMutationResult<*, unknown, string, {previousComments: unknown}>}
   */
  const addNewCommentToAccount = useMutation(
    (comment) => saveComments(comment, formDetails),
    {
      onMutate: async (commentField: string) => {
        setComment(undefined)
        await queryClient.cancelQueries(`ticket-${id}-comments`)

        const previousComments = queryClient.getQueryData(`ticket-${id}-comments`)

        if (previousComments) {
          queryClient.setQueryData(`ticket-${id}-comments`, [
            {
              full_name: localStorage.getItem('username'),
              comment_date_format: moment(new Date()).format('YYYY-MM-DD HH:mm'),
              comments: commentField,
            },
            ...previousComments,
          ])
        }

        return {previousComments}
      },
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))

        if (context?.previousComments) {
          queryClient.setQueryData(`ticket-${id}-comments`, context.previousComments)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(`ticket-${id}-comments`)
      },
    }
  )

  /**
   * To validate form input based on current orders
   */
  const validateForm = (type = 'submit') => {
    let requiredFields = []
    let isGenericFormValid = true
    let mandatoryFieldValid = null
    //validate default mandatory field
    mandatoryFieldValid = validateDetails(formDetails, setFormDetails)

    // we need to validate the form during step 0 & 3 only
    switch (formDetails.current_order) {
      case 0: // draft
        requiredFields = [
          {field: 'local_notify', label: 'notifier'},
          {field: 'local_approval', label: 'local approver'},
        ]
        break
      case 3: // Verifier 1
        requiredFields = [
          {field: 'hq_verifier_2', label: 'HQ verifier 2'},
          {field: 'hq_approval', label: 'HQ approver'},
          {field: 'hq_final_approver', label: 'HQ final approver'},
        ]

        break
      default:
        break
    }

    for (const [key, value] of Object.entries(requiredFields)) {
      if (!formDetails[value.field] || formDetails[value.field].length === 0) {
        setFormDetails((prevState) => ({
          ...prevState,
          [`error_${value.field}`]: 'This is a required field',
        }))

        isGenericFormValid = false
      }
    }

    if (type === 'create') {
      if (!isGenericFormValid || mandatoryFieldValid?.type === 'error') {
        setSnackBar({
          open: true,
          type: 'error',
          message: `Incomplete Form! Please fill out the details.`,
        })

        return false
      }

      return true
    }

    for (const value in formDetails.ticket_attachment) {
      if (!formDetails.ticket_attachment[value].docName) {
        setFormDetails((prevState) => {
          const newAttachments = [...prevState.ticket_attachment]
          newAttachments[value].error_docName = 'This field is required'

          return {
            ...prevState,
            ticket_attachment: newAttachments,
            error_ticketAttachment: 'Document name is required!',
          }
        })

        isGenericFormValid = false
      }
    }

    let formValidation = null
    switch (formDetails.category_type.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        formValidation = validateBankAccountOpening({
          bankAccountOpeningInfo: formDetails,
          setBankAccountOpeningInfo: setFormDetails,
          subCategory: formDetails.sub_category_1,
          currentOrder: formDetails.current_order,
        })
        break
      case FORM_BANK_ACCOUNT_CLOSING:
        formValidation = validateBankAccountClosing({
          bankAccountClosingInfo: formDetails,
          setBankAccountClosingInfo: setFormDetails,
          currentOrder: formDetails.current_order,
        })
        break
      case FORM_BANK_ACCOUNT_MAINTENANCE:
        formValidation = validateBankAccountMaintenance({
          bankAccountMaintenanceInfo: formDetails,
          setBankAccountMaintenanceInfo: setFormDetails,
        })
        break
      case FORM_BANK_GUARANTEE:
        formValidation = validateBankGuarantee({
          bankGuaranteeInfo: formDetails.form_details,
          setBankGuaranteeInfo: setFormDetails,
          subCategory: formDetails.sub_category_1,
        })
        break
      case FORM_DOCUMENT_SIGNING_REQUEST:
        formValidation = validateDocumentSigning({
          documentSigningInfo: formDetails.form_details,
          setDocumentSigningRequestInfo: setFormDetails,
        })

        let isDocNameMissing = false
        let isPurposeMissing = false
        for (const value in formDetails.ticket_attachment) {
          ;['docName', 'purpose'].forEach((key) => {
            if (!formDetails.ticket_attachment[value][key]) {
              setFormDetails((prevState) => {
                const newAttachments = [...prevState.ticket_attachment]
                if (key === 'docName') {
                  isDocNameMissing = true
                }

                if (key === 'purpose') {
                  isPurposeMissing = true
                }
                newAttachments[value][`error_${key}`] = 'This field is required'

                let errorMessage = `${isDocNameMissing ? 'Document Name, ' : ''}${
                  isPurposeMissing ? 'Purpose' : ''
                } is required!`

                return {
                  ...prevState,
                  ticket_attachment: newAttachments,
                  error_ticketAttachment: errorMessage,
                }
              })
              isGenericFormValid = false
            }
          })
        }

        break
      default:
        break
    }

    if (formValidation?.type === 'error') {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Incomplete form! Please fill out all the required/ invalid fields',
      })

      return false
    }

    if (!isGenericFormValid) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Incomplete Approval Path/ Attachment  ! Please fill out the details`,
      })

      return false
    }

    return true
  }

  /**
   * Generic function to check if HQ fields are disabled
   * @return {boolean}
   */
  const isHQFieldDisabled = () => {
    if (formDetails.current_order === 3 && !disabled) {
      return false
    }

    return true
  }

  /**
   * To render the different form typef
   * @return {JSX.Element|string}
   */
  const renderFormTypeRequest = () => {
    switch (formDetails.category_type?.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        return (
          <BankAccountOpening
            bankAccountOpeningInfo={formDetails}
            setBankAccountOpeningInfo={setFormDetails}
            isDisabled={disabled}
          />
        )
      case FORM_BANK_ACCOUNT_CLOSING:
        return (
          <BankAccountClosing
            bankAccountClosingInfo={formDetails}
            setBankAccountClosingInfo={setFormDetails}
            isDisabled={disabled}
            invalidateTicket={() => invalidateTicket()}
            isCallingApi={isCallingApi}
            setIsCallingApi={setIsCallingApi}
            setOpenDeleteFileDialog={setOpenDeleteFileDialog}
            setFilePreviewDialog={setFilePreviewDialog}
          />
        )
      case FORM_BANK_ACCOUNT_MAINTENANCE:
        return (
          <BankAccountMaintenance
            bankAccountMaintenanceInfo={formDetails}
            setBankAccountMaintenanceInfo={setFormDetails}
            isDisabled={disabled}
          />
        )
      case FORM_BANK_GUARANTEE:
        return (
          <BankGuarantee
            bankGuaranteeInfo={formDetails}
            setBankGuaranteeInfo={setFormDetails}
            isDisabled={disabled}
            invalidateTicket={() => invalidateTicket()}
          />
        )
      case FORM_DOCUMENT_SIGNING_REQUEST:
        return (
          <DocumentSigningRequest
            documentSigningRequestInfo={formDetails}
            setDocumentSigningRequestInfo={setFormDetails}
            isDisabled={disabled}
            invalidateTicket={() => invalidateTicket()}
          />
        )
      default:
        return null
    }
  }

  /**
   * To render action submit button for the form
   * It could be save/ approve with reject or verify
   * @return {JSX.Element|null}
   */
  const renderSubmitButton = () => {
    let showRejectButton = false
    let buttonTittle = 'Submit'

    // we dont need to show any action button if the form is rejected
    if (formDetails.current_order === 99) {
      return null
    }

    //sanity check
    if (
      (formDetails.current_order === 0 || formDetails.current_order === 7) &&
      userEmail !== formDetails.request_by
    ) {
      return <></>
    }

    if (userEmail === formDetails.request_by) {
      if (![0, 7].includes(formDetails.current_order)) {
        return <></>
      }
    }

    if ([1, 2, 4, 5].includes(formDetails.current_order)) {
      if (formDetails.editable !== 'Y') {
        return <></>
      }
      showRejectButton = true
      buttonTittle = 'Approve'
    }

    if (formDetails.current_order === 3) {
      if (formDetails.editable !== 'Y') {
        return <></>
      }
      showRejectButton = true
      buttonTittle = 'Verify'
    }

    return (
      <>
        <Button
          label={buttonTittle}
          type={'primary'}
          onClick={() => {
            if (validateForm()) {
              submitInternalRequestForm.mutate(formDetails)
            }
          }}
          disabled={formDetails.editable === 'N'}
        />

        {showRejectButton && (
          <Button
            label={'Reject'}
            loading={isCallingApi.type === 'reject'}
            type={'danger'}
            onClick={() => {
              setIsCallingApi({
                loading: true,
                type: 'reject',
              })
              setActionDialog((prevState) => ({
                ...prevState,
                type: 'Reject',
                value: null,
                open: true,
              }))
            }}
            disabled={formDetails.editable === 'N' || isCallingApi.loading}
          />
        )}
      </>
    )
  }

  /**
   * To download file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDownloadAttachment = useMutation(({id}) => downloadAttachment({id}), {
    onError: (err) => {
      console.log(err)
      setSnackBar({
        open: true,
      })
    },
    onSettled: () => {
      setIsCallingApi({
        loading: false,
        type: null,
      })
    },
  })

  /**
   * To delete attachment
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDeleteAttachment = useMutation(({id, index}) => deleteAttachment({id}), {
    onSuccess: (result, variables) => {
      setFormDetails((prevState) => {
        const removeAttachment = [...prevState.ticket_attachment]
        removeAttachment.splice(variables.index, 1)

        return {
          ...prevState,
          ticket_attachment: removeAttachment,
        }
      })

      setSnackBar({
        open: true,
        type: 'success',
        message: 'Attachment deleted successfully',
      })
    },
    onError: (err, variables, context) => {
      setSnackBar((prevState) => ({...prevState, open: true}))
    },
    onSettled: () => {
      invalidateTicket()
    },
  })

  /**
   * To upload attachment
   * @type {UseMutationResult<unknown, unknown, void, unknown>}
   */
  const handleUploadFiles = useMutation(
    (file) =>
      uploadRequestAttachment({
        ticketId: formDetails.ticket_id,
        detailId: null,
        attachmentType: getCategory(),
        user: user.email,
        attachment: file.files,
      }),
    {
      onSuccess: (result) => {
        setFormDetails((prevState) => {
          const addAttachment = [...prevState.ticket_attachment]
          let value = {
            docName: null,
            attachment_filename: result.attachment_filename,
            name: result.attachment_filename,

            attachment_id: result.attachment_id,
            file: {
              attachment_filename: result.attachment_filename,
              name: result.attachment_filename,
              attachment_id: result.attachment_id,
            },
            purpose: null,
            created_by: user.email,
          }
          addAttachment.push(value)

          return {
            ...prevState,
            ticket_attachment: addAttachment,
          }
        })
        setSnackBar((prevState) => ({
          ...prevState,
          open: true,
          type: 'success',
          message: 'Attachment uploaded successfully!',
        }))
      },
      onError: (err, variables, context) => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To handle file preview
   * @type {UseMutationResult<unknown, unknown, void, unknown>}
   */
  const handlePreviewFile = useMutation(({id, name}) => previewAttachment({id, name}), {
    onSuccess: (result, {name}) => {
      setFilePreviewDialog({
        value: result,
        name: name,
        open: true,
      })
    },
  })

  /**
   * To get the category type by form type
   * @return {string}
   */
  const getCategory = () => {
    switch (formDetails.category_type.replaceAll(' ', '')) {
      case FORM_BANK_ACCOUNT_OPENING:
        return 'acc_opening'

      case FORM_BANK_ACCOUNT_CLOSING:
        return 'acc_closing'

      case FORM_BANK_ACCOUNT_MAINTENANCE:
        return 'acc_maintain'

      case FORM_BANK_GUARANTEE:
        return 'bg_request'

      case FORM_DOCUMENT_SIGNING_REQUEST:
        return 'doc_signing'

      default:
        break
    }
  }

  /**
   * To enable deletion of internal request form DRAFT only
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDeleteDraftRequest = useMutation(
    () => deleteDraftInternalRequest(actionDialog.value, formDetails),
    {
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
        navigate('/InternalRequest')
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        setActionDialog({
          type: null,
          value: null,
          open: false,
          isDisabled: false,
          isLoading: false,
        })
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )

  /**
   * To update sub-category in from
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleUpdateTicketSubCategory = useMutation(
    ({formDetails, sub_category_1}) =>
      updateTicketSubCategory({
        formDetails,
        sub_category_1: sub_category_1,
      }),
    {
      onSuccess: (result) => {
        setSnackBar({open: true, type: 'success', message: result.status})
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        setChangeSubCategoryDialog(defaultRemoveEntityDialog)
        setIsCallingApi({
          loading: false,
          type: null,
        })
        // invalidateTicket()
      },
    }
  )

  /**
   * To delete entities in form
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDeleteEntities = useMutation(
    ({removeEntityDialog, dbValue, formDetails}) =>
      deleteEntities({removeEntityDialog, dbValue, formDetails}),
    {
      onSuccess: (result, {removeEntityDialog, formDetails}) => {
        setSnackBar({open: true, type: 'success', message: result.status})
      },
      onError: (err) => {
        console.log(err)
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        setRemoveEntityDialog(defaultRemoveEntityDialog)
        setIsCallingApi({
          loading: false,
          type: null,
        })
        // invalidateTicket()
      },
    }
  )

  if (
    isRequestLoading ||
    isCommentsLoading ||
    !formDetails.ticket_id ||
    isPreviousStepLoading ||
    isProgressIndicatorLoading ||
    isTicketHistoryLoading
  ) {
    return <Loading open={true} />
  }

  return (
    <>
      <div className={'hide'} id={'hide'}>
        <FileDeleteDialog
          open={openDeleteFileDialog.open}
          onClose={() => setOpenDeleteFileDialog({open: false, index: null})}
          onSubmit={() => {
            if (openDeleteFileDialog.func) {
              openDeleteFileDialog.func()
            } else {
              handleDeleteAttachment.mutate({
                index: openDeleteFileDialog.index,
                id: formDetails.ticket_attachment[openDeleteFileDialog.index]
                  .attachment_id,
              })
            }

            setOpenDeleteFileDialog({open: false, index: null, func: null})
          }}
        />
        <FilePreviewDialog
          open={filePreviewDialog.open}
          value={{preview: filePreviewDialog.value, name: filePreviewDialog.name}}
          onClose={() =>
            setFilePreviewDialog({
              value: null,
              open: false,
            })
          }
        />
        {changeSubCategoryDialog.open && (
          <ModifySubCategory
            onSubmit={() => {
              setIsCallingApi({
                loading: true,
                type: 'delete-entity',
              })

              // need to get the type and remove the form
              let formCategory = formDetails.category_type.replaceAll(' ', '')

              if (formCategory === FORM_BANK_ACCOUNT_CLOSING) {
                setFormDetails((prevState) => {
                  let mutatedFormDetails = prevState.form_details
                  mutatedFormDetails = mutatedFormDetails.filter(
                    ({sub_category}) =>
                      !sub_category || sub_category === changeSubCategoryDialog.value
                  )
                  return {
                    ...prevState,
                    form_details: mutatedFormDetails,
                    sub_category_1: changeSubCategoryDialog.value,
                    ...(prevState.error_sub_category_1 && {
                      error_sub_category_1: undefined,
                    }),
                  }
                })
              }
              if (formCategory === FORM_BANK_ACCOUNT_MAINTENANCE) {
                setFormDetails((prevState) => {
                  let mutatedFormDetails = prevState.form_details

                  mutatedFormDetails = mutatedFormDetails.map((value, index) => {
                    let mutatedBankDetails = value.bank_details
                    if (changeSubCategoryDialog.value === undefined) {
                      mutatedBankDetails = []
                    } else {
                      //To remove bank_details
                      mutatedBankDetails = mutatedBankDetails?.filter(
                        ({account_type}) =>
                          !account_type || account_type === changeSubCategoryDialog.value
                      )
                    }
                    return {
                      ...value,
                      bank_details: mutatedBankDetails,
                    }
                  })
                  return {
                    ...prevState,
                    form_details: mutatedFormDetails,
                    sub_category_1: changeSubCategoryDialog.value,
                    ...(prevState.error_sub_category_1 && {
                      error_sub_category_1: undefined,
                    }),
                  }
                })
              }

              handleUpdateTicketSubCategory.mutate({
                formDetails,
                sub_category_1: changeSubCategoryDialog.value ?? null,
              })
            }}
            onClose={() => setChangeSubCategoryDialog(defaultRemoveEntityDialog)}
            content={`${
              changeSubCategoryDialog.value !== undefined
                ? `Banks not related to ${changeSubCategoryDialog.value} will be removed.
             Any unsaved changes will be reverted.`
                : `All banks will be removed and any unsaved changes will be reverted.`
            }`}
          />
        )}
        {removeEntityDialog.open && (
          <DeleteDialog
            // isDisabled={isCallingApi.loading}
            // isLoading={isCallingApi.loading}
            onSubmit={() => {
              setIsCallingApi({
                loading: true,
                type: 'delete-entity',
              })

              // to filter and remove accordingly those that have been added and not added
              let dbValue = internalRequestData[`${removeEntityDialog.type}`]
                ?.filter((value) => {
                  return removeEntityDialog[
                    `${removeEntityDialog.type === 'entity' ? 'entities' : 'countries'}`
                  ]
                    .map((value) => value[`${removeEntityDialog.type}_id`])
                    .includes(value[`${removeEntityDialog.type}_id`])
                })
                .map((value) => value[`${removeEntityDialog.type}_id`])

              // need to get the type and remove the form
              let formCategory = formDetails.category_type.replaceAll(' ', '')

              // We need to remove all affected forms for BANK_ACCOUNT_CLOSING and FORM_BANK_ACCOUNT_MAINTENANCE
              if (formCategory === FORM_BANK_ACCOUNT_CLOSING) {
                setFormDetails((prevState) => {
                  let mutatedFormDetails = prevState.form_details
                  mutatedFormDetails = mutatedFormDetails.filter(
                    ({entity_id}) =>
                      !entity_id ||
                      removeEntityDialog[
                        `${removeEntityDialog.type === 'entity' ? 'value' : 'entities'}`
                      ]
                        .map(({entity_id}) => entity_id)
                        .includes(entity_id)
                  )
                  return {
                    ...prevState,
                    form_details: mutatedFormDetails,
                    ...(removeEntityDialog.type === 'entity' && {
                      entity_id: removeEntityDialog.value,
                    }),
                    ...(removeEntityDialog.type === 'country' && {
                      country_id: removeEntityDialog.value,
                      entity_id: removeEntityDialog.entities,
                    }),
                  }
                })
              } else if (formCategory === FORM_BANK_ACCOUNT_MAINTENANCE) {
                setFormDetails((prevState) => {
                  let mutatedFormDetails = prevState.form_details
                  let deleteForms = []

                  mutatedFormDetails = mutatedFormDetails.map((value, index) => {
                    let mutatedBankDetails = value.bank_details
                    let mutatedEmpDetails = value.emp_details

                    if (
                      [
                        TYPE_OF_CHANGE_DELETION,
                        TYPE_OF_CHANGE_USER_ROLE_UPDATE,
                        TYPE_OF_CHANGE_CREATION,
                        TYPE_OF_CHANGE_APPROVAL_THRESHOLD_UPDATE,
                        TYPE_OF_CHANGE_SYSTEM_ADMIN_UPDATE,
                      ].includes(value.type_of_change)
                    ) {
                      //To remove bank_details
                      mutatedBankDetails = mutatedBankDetails.filter(
                        ({entity_id}) =>
                          !entity_id ||
                          removeEntityDialog[
                            `${
                              removeEntityDialog.type === 'entity' ? 'value' : 'entities'
                            }`
                          ]
                            .map(({entity_id}) => entity_id)
                            .includes(entity_id)
                      )

                      if (mutatedBankDetails.length === 0) {
                        deleteForms.push(index)
                      }
                    }

                    if (
                      [TYPE_OF_CHANGE_DELETION, TYPE_OF_CHANGE_USER_ROLE_UPDATE].includes(
                        value.type_of_change
                      )
                    ) {
                      //To remove emp_details
                      mutatedEmpDetails = mutatedEmpDetails.filter(({entity_id}) => {
                        let exists = removeEntityDialog[
                          `${removeEntityDialog.type === 'entity' ? 'value' : 'entities'}`
                        ]
                          .map(({entity_id}) => entity_id)
                          .filter((x) => entity_id.includes(x))

                        return !entity_id || exists.length !== 0
                      })
                    }

                    return {
                      ...value,
                      bank_details: mutatedBankDetails,
                      emp_details: mutatedEmpDetails,
                    }
                  })

                  deleteForms.forEach((value) => {
                    mutatedFormDetails.splice(value, 1)
                  })

                  return {
                    ...prevState,
                    form_details: mutatedFormDetails,
                    ...(removeEntityDialog.type === 'entity' && {
                      entity_id: removeEntityDialog.value,
                    }),
                    ...(removeEntityDialog.type === 'country' && {
                      country_id: removeEntityDialog.value,
                      entity_id: removeEntityDialog.entities,
                    }),
                  }
                })
              } else {
                setFormDetails((prevState) => {
                  return {
                    ...prevState,
                    ...(removeEntityDialog.type === 'entity' && {
                      entity_id: removeEntityDialog.value,
                    }),
                    ...(removeEntityDialog.type === 'country' && {
                      country_id: removeEntityDialog.value,
                      entity_id: removeEntityDialog.entities,
                    }),
                  }
                })
              }

              if (dbValue?.length === 0 || !dbValue) {
                setSnackBar({
                  open: true,
                  type: 'success',
                  message: 'Deleted Successfully',
                })
              }
              // To delete if it exists
              handleDeleteEntities.mutate({removeEntityDialog, dbValue, formDetails})
            }}
            onClose={() => setRemoveEntityDialog(defaultRemoveEntityDialog)}
            content={`${
              removeEntityDialog.type === 'country'
                ? `Entities linked to ${
                    removeEntityDialog.removeAll
                      ? 'the countries'
                      : removeEntityDialog.countries[0].country_name
                  } will be removed, including associated banks.`
                : ` Bank associated to ${
                    removeEntityDialog.removeAll || removeEntityDialog.type === 'country'
                      ? 'the entities'
                      : removeEntityDialog.entities[0].entity_name
                  } will be deleted.`
            } Any unsaved changes will be reverted.`}
          />
        )}
        {actionDialog.open && actionDialog.type === 'DeleteDraft' && (
          <DeleteDraft
            onSubmit={() => {
              setActionDialog((prevState) => ({
                ...prevState,
                isDisabled: true,
                isLoading: true,
              }))

              handleDeleteDraftRequest.mutate()
              setActionDialog({
                type: null,
                value: null,
                open: false,
                isDisabled: false,
                isLoading: false,
              })
            }}
            onClose={() => {
              setIsCallingApi({
                loading: false,
                type: null,
              })
              setActionDialog({
                type: null,
                value: null,
                open: false,
                isDisabled: false,
                isLoading: false,
              })
            }}
            isDisabled={actionDialog.isDisabled}
            isLoading={actionDialog.isLoading}
          />
        )}
        {actionDialog.open && actionDialog.type === 'TicketHistory' && (
          <TicketHistory
            onClose={() => {
              setActionDialog({
                type: null,
                value: null,
                open: false,
                isDisabled: false,
                isLoading: false,
              })
              setIsCallingApi({
                loading: false,
                type: null,
              })
            }}
            history={ticketHistory}
          />
        )}
        {actionDialog.open && actionDialog.type === 'Reject' && (
          <Reject
            userList={userList}
            value={actionDialog.value}
            onChange={setActionDialog}
            onSubmit={() => {
              setActionDialog((prevState) => ({
                ...prevState,
                isDisabled: true,
                isLoading: true,
              }))
              if (!actionDialog.value || !actionDialog?.user_email) {
                setSnackBar({
                  open: true,
                  type: 'error',
                  message: `Missing ${
                    !actionDialog.value ? 'reason' : 'steps'
                  } to reject`,
                })

                setActionDialog((prevState) => ({
                  ...prevState,
                  isDisabled: false,
                  isLoading: false,
                }))
                return
              }
              handleRejectTicket.mutate()
            }}
            onClose={() => {
              setIsCallingApi({
                loading: false,
                type: null,
              })
              setActionDialog((prevState) => ({
                type: null,
                value: null,
                open: false,
                isDisabled: false,
                isLoading: false,
              }))
            }}
            isDisabled={actionDialog.isDisabled}
            isLoading={actionDialog.isLoading}
          />
        )}
        {actionDialog.open && actionDialog.type === 'Withdraw' && (
          <Withdraw
            value={actionDialog.value}
            onChange={(value) =>
              setActionDialog((prevState) => ({
                ...prevState,
                value: value,
              }))
            }
            onSubmit={() => {
              setActionDialog((prevState) => ({
                ...prevState,
                isDisabled: true,
                isLoading: true,
              }))

              if (!actionDialog.value) {
                setSnackBar({
                  open: true,
                  type: 'error',
                  message: `Missing reason to withdraw`,
                })

                setActionDialog((prevState) => ({
                  ...prevState,
                  isDisabled: false,
                  isLoading: false,
                }))
                return
              }
              handleWithdrawRequest.mutate()
              setActionDialog({
                type: null,
                value: null,
                open: false,
                isDisabled: false,
                isLoading: false,
              })
            }}
            onClose={() => {
              setIsCallingApi({
                loading: false,
                type: null,
              })
              setActionDialog({
                type: null,
                value: null,
                open: false,
                isDisabled: false,
                isLoading: false,
              })
            }}
            isDisabled={actionDialog.isDisabled}
            isLoading={actionDialog.isLoading}
          />
        )}
        <DetailedLayout
          mainContent={
            <>
              <Details
                formDetails={formDetails}
                setFormDetails={setFormDetails}
                disabled={disabled}
                setRemoveEntityDialog={setRemoveEntityDialog}
                setChangeSubCategoryDialog={setChangeSubCategoryDialog}
              />
              <Information
                formDetails={formDetails}
                setFormDetails={setFormDetails}
                disabled={disabled}
                userEmail={userEmail}
                isLocalFieldDisabled={!(formDetails.current_order === 0 && !disabled)}
                isHQFieldDisabled={!(formDetails.current_order === 3 && !disabled)}
              />
              {renderFormTypeRequest()}
              <Attachments
                formDetails={formDetails}
                setFormDetails={setFormDetails}
                disabled={disabled}
                isCallingApi={isCallingApi}
                setIsCallingApi={setIsCallingApi}
                handleFileDownload={(index) =>
                  handleDownloadAttachment.mutate({
                    id: formDetails.ticket_attachment[index].attachment_id,
                  })
                }
                handleFileDelete={(index) => {
                  setOpenDeleteFileDialog({open: true, index: index})
                }}
                handleFileUpload={(files) => handleUploadFiles.mutate({files})}
                handleFilePreview={(index) => {
                  handlePreviewFile.mutate({
                    id: formDetails.ticket_attachment[index].attachment_id,
                    name: formDetails.ticket_attachment[index].name,
                  })
                }}
              />
              <Stack direction={'row'} spacing={2} sx={{pb: 2}}>
                <Button
                  label={'Cancel'}
                  type={'text'}
                  onClick={() => {
                    navigate('/InternalRequest')
                  }}
                />
                {/*only user and verifier 1 can save*/}
                {((userEmail === formDetails.request_by &&
                  formDetails.current_order !== 99) ||
                  !isHQFieldDisabled()) && (
                  <Button
                    type={'primary'}
                    label={'Save'}
                    onClick={() => {
                      if (validateForm('create')) {
                        saveInternalRequestForm.mutate(formDetails)
                      }
                    }}
                    disabled={
                      // we can override this if user is at step 6 because we need to save account
                      formDetails.current_order === 7
                        ? !userEmail === formDetails.current_order
                        : disabled
                    }
                  />
                )}
                {renderSubmitButton()}
                {userEmail === formDetails.request_by &&
                  formDetails.current_order === 0 && (
                    <Button
                      label={'Delete'}
                      disabled={isCallingApi.loading}
                      loading={isCallingApi.type === 'deletedraft'}
                      type={'danger'}
                      onClick={() => {
                        setIsCallingApi({
                          loading: true,
                          type: 'deletedraft',
                        })
                        setActionDialog((prevState) => ({
                          ...prevState,
                          type: 'DeleteDraft',
                          value: null,
                          open: true,
                        }))
                      }}
                    />
                  )}
                {(formDetails.able_to_withdraw === true ||
                  formDetails.editable === 'Y') &&
                  userEmail === formDetails.request_by &&
                  formDetails.current_order !== 101 && (
                    <Button
                      label={'Withdraw'}
                      disabled={isCallingApi.loading}
                      loading={isCallingApi.type === 'withdraw'}
                      type={'danger'}
                      onClick={() => {
                        setIsCallingApi({
                          loading: true,
                          type: 'withdraw',
                        })
                        setActionDialog((prevState) => ({
                          ...prevState,
                          type: 'Withdraw',
                          value: null,
                          open: true,
                        }))
                      }}
                    />
                  )}
              </Stack>
              {/* MARCH ENHANCEMENT 2023 TS Requested to allow comments section to be opened for all status and all users.*/}
              <Comment
                comments={comments}
                postComment={(value) => addNewCommentToAccount.mutate(value, formDetails)}
                value={comment}
                setValue={setComment}
                disabled={false}
              />
            </>
          }
          sideDrawerContent={
            <Grid container direction={'row'} rowSpacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={2}
                >
                  <Button
                    startIcon={<PrintIcon />}
                    label={'Print'}
                    type={'text'}
                    onClick={() => {
                      setPrintDialog((prevState) => ({
                        ...prevState,
                        open: true,
                      }))
                      setIsCallingApi({
                        loading: true,
                        type: 'print',
                      })
                    }}
                    disabled={isCallingApi.loading}
                    loading={isCallingApi.type === 'print'}
                  />
                  <Button
                    startIcon={<HistoryIcon />}
                    label={'Ticket History'}
                    type={'text'}
                    onClick={() => {
                      setActionDialog((prevState) => ({
                        ...prevState,
                        type: 'TicketHistory',
                        value: null,
                        open: true,
                      }))
                      setIsCallingApi({
                        loading: true,
                        type: 'ticket-history',
                      })
                    }}
                    disabled={isCallingApi.loading}
                    loading={isCallingApi.type === 'ticket-history'}
                  />
                  {getUserRole().role === HQ_ROLE_ID &&
                    ![99, 100, 101].includes(formDetails.current_order) && (
                      <>
                        <IconButton
                          title={'HQ Settings'}
                          handleOnClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="table-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null)
                              setIsCallingApi({
                                loading: true,
                                type: 'withdraw',
                              })
                              setActionDialog((prevState) => ({
                                ...prevState,
                                type: 'Withdraw',
                                value: null,
                                open: true,
                              }))
                            }}
                          >
                            Override and Withdraw Ticket
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align={'left'} sx={{color: '#6B6B6B'}}>
                  General
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={'Ticket Number'}
                  value={formDetails?.ticket_number}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={'Status'}
                  InputProps={{
                    startAdornment: StatusChip(
                      formDetails.status,
                      formDetails.ticket_status
                    ),
                  }}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={'Last Updated On'}
                  value={formDetails.last_update_date_format}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Accordion title={'Progress Indicator'} secondaryTitle={true}>
                  <Typography variant={'caption'}>
                    To Note: Local/HQ Approver happen concurrently
                  </Typography>
                  <ProgressIndicator
                    isWithdrawn={formDetails.ticket_status === 'Withdrawn'}
                    summary={progressList}
                    currentOrder={formDetails.current_order}
                    rejectFromOrder={formDetails.reject_from_order}
                    status={formDetails.status}
                  />
                </Accordion>
              </Grid>
            </Grid>
          }
          collapseSideDrawer={[
            {
              icon: BookOnline,
              iconStyle: {transform: 'rotate(45deg)'},
              label: 'Ticket Number:',
              value: formDetails.ticket_number,
            },
            {
              icon: ApprovalIcon,
              label: 'Status:',
              value: StatusChip(formDetails.ticket_status),
            },
            {
              icon: AccessTime,
              label: 'Last Updated On:',
              value: moment
                .utc(formDetails.last_update_date)
                .local()
                .format(dateTimeFormat),
            },
          ]}
        />
        {printDialog && (
          <Dialog
            disableBackdropClick={true}
            type={'warning'}
            title={'Select Print Confirmation dialog'}
            content={'Select printing options below'}
            buttons={[
              {
                label: 'Cancel',
                type: 'text',
                onClick: () => {
                  setPrintDialog(false)
                  setIsCallingApi({
                    loading: false,
                    type: null,
                  })
                },
              },
              {
                label: 'Print w Comment',
                type: 'primary',
                onClick: () => {
                  setPrintDialog(false)
                  setPrintTicket((prevState) => ({
                    ...prevState,
                    print: true,
                    printComment: prevState,
                  }))
                  setIsCallingApi({
                    loading: false,
                    type: null,
                  })
                },
              },
              {
                label: 'Print',
                type: 'primary',
                onClick: () => {
                  setPrintDialog(false)

                  setPrintTicket((prevState) => ({
                    ...prevState,
                    print: true,
                    printComment: false,
                  }))
                  setIsCallingApi({
                    loading: false,
                    type: null,
                  })
                },
              },
            ]}
          />
        )}
      </div>
      <PrintTicketTemplate
        ticketId={id}
        user={user}
        formDetails={formDetails}
        printComments={printTicket.printComment}
        comments={comments}
        formType={formDetails.category_type}
        progressIndicator={progressList}
        progressSummary={ticketHistory}
      />
    </>
  )
}

export default Edit
