import React, {createContext, useContext, useMemo, useState} from 'react'
import {Dialog} from 'finsys-webcomponent'

const DialogAlertContext = createContext()

export const DialogHandler = ({children}) => {
  const [dialog, setDialog] = useState({
    open: false,
    content: null,
    type: 'alert',
    title: null,
    buttons: [],
    disableBackdropClick: true,
  })

  const contextPayload = useMemo(() => ({setDialog}), [])

  return (
    <DialogAlertContext.Provider value={contextPayload}>
      {dialog.open && <Dialog {...dialog} />}

      {children}
    </DialogAlertContext.Provider>
  )
}

export const useDialog = () => useContext(DialogAlertContext)
