import React from 'react'
import {
  Accordion,
  Editor,
  FormTable,
  IconButton,
  Label,
  ServerSideAutocomplete,
  TextField,
  Input as InputWYSIWYG,
} from 'finsys-webcomponent'
import {Typography, Grid, Chip} from '@mui/material'
import {Delete as DeleteIcon} from '@mui/icons-material'
import moment from 'moment'

import {useSnackBar} from 'hoc/SnackbarHandler'
import {fetchUsers} from 'api/TS/query/_CommonQueries'
import {renderApproverStatusBackgroundColor} from 'pages/InternalRequest/Edit'
import {dateTimeFormatTable} from '_helper/_constants'

/**
 * To validate Initiator fields
 * @param formDetails
 * @param setFormDetails
 * @returns {boolean}
 */
export function validateInformation(formDetails, setFormDetails) {
  let isFormValid = true

  let requiredFields = [
    {field: 'local_notify', label: 'notifier'},
    {field: 'local_approval', label: 'local approver'},
  ]

  if (formDetails.current_order >= 3) {
    requiredFields = [
      ...requiredFields,
      {field: 'hq_verifier_2', label: 'HQ verifier 2'},
      {field: 'hq_approval', label: 'HQ approver'},
      {field: 'hq_final_approver', label: 'HQ final approver'},
    ]
  }

  for (const [key, value] of Object.entries(requiredFields)) {
    if (!formDetails[value.field] || formDetails[value.field].length === 0) {
      setFormDetails((prevState) => ({
        ...prevState,
        [`error_${value.field}`]: 'This is a required field',
      }))

      isFormValid = false
    }
  }

  if (!isFormValid) {
    return {
      type: 'error',
    }
  }

  return {
    type: 'success',
  }
}

export const Information = ({
  formDetails,
  setFormDetails,
  disabled,
  userEmail,
  isLocalFieldDisabled,
  isHQFieldDisabled,
}) => {
  const {setSnackBar} = useSnackBar()

  /**
   * To render the adding and removing of approver component
   * @param key
   * @return {JSX.Element}
   */
  const renderApproverComponent = (key) => {
    const isApproverDisabled =
      key === 'hq_approval' ? isHQFieldDisabled : isLocalFieldDisabled

    return (
      <React.Fragment>
        {!isApproverDisabled && (
          <Grid item xs={12} sm={12} md={12}>
            <ServerSideAutocomplete
              disabled={
                isApproverDisabled || formDetails[key].length > 9 // max 10 approver
              }
              textFieldProps={{
                required: true,
                placeholder: 'Search by name or email',
                error: !!formDetails[`error_${key}`],
                helperText: (
                  <>
                    {key === 'hq_approver'
                      ? 'Please select all HQ approvers applicable for the request e.g. HQ Treasury, GCT, HQ Tax. All HQ approvers will be able to approve concurrently.'
                      : 'Please select all local approvers applicable for the request e.g. Local FM, Local Tax. All local approvers will be able to approve concurrently.'}
                    {formDetails.error_notifier && (
                      <>
                        <br />
                        {formDetails[`error_${key}`]}
                      </>
                    )}
                  </>
                ),
              }}
              label={'Approvers (Only able to add up to 10)'}
              value={{email: '', full_name: ''}}
              handleOnChange={(value) => {
                if (!value) {
                  return
                }

                if (formDetails.request_by === value.email) {
                  setSnackBar({
                    type: 'error',
                    open: 'true',
                    message: `Cannot include ${
                      formDetails.request_by === userEmail ? 'yourself' : 'requester'
                    } in approver list!`,
                  })

                  return
                }

                if (formDetails[key].some((user) => user.email === value.email)) {
                  setSnackBar({
                    type: 'error',
                    open: 'true',
                    message: 'User exists in approver list!',
                  })

                  return
                }

                //HQ user cannot include local approver
                if (key === 'hq_approval') {
                  if (
                    formDetails.local_approval.some((user) => user.email === value.email)
                  ) {
                    setSnackBar({
                      type: 'error',
                      open: 'true',
                      message: 'User exists in local approver list!',
                    })
                    return
                  }
                }

                setFormDetails((prevState) => ({
                  ...prevState,
                  [`error_${key}`]: undefined,
                  [key]: [
                    ...prevState[key],
                    {
                      full_name: value.full_name,
                      email: value.email,
                      approve_date: null,
                      new_approver: true,
                    },
                  ],
                }))
              }}
              fetchOptions={(value) => fetchUsers(value)}
              getOptionLabel={(option) =>
                option.full_name ? `${option.full_name} (${option.email})` : ''
              }
              isOptionEqualToValue={(option, value) => option.email === value.email}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          {isApproverDisabled && (
            <Label label={`Approvers (${formDetails[key].length})`} />
          )}
          <FormTable
            noDataLabel={`No Approver ${
              !isApproverDisabled ? ', search above to add approver' : ''
            }`}
            columns={[
              {
                field: 'full_name',
                name: 'Name',
                width: '35%',
              },
              {
                field: 'email',
                name: 'Email',
                width: '35%',
              },
              {
                field: 'approve_date',
                name: 'Status',
                component: ({value, data}) => {
                  const label = value
                    ? 'Approved'
                    : formDetails.ticket_status === 'Rejected' &&
                      formDetails.status.includes('Approval Rejected')
                    ? formDetails.reject_by === data?.email
                      ? 'Rejected'
                      : data?.new_approver
                      ? 'Pending'
                      : 'Notified Rejected'
                    : 'Pending'
                  return (
                    <Chip
                      size={'small'}
                      sx={{
                        fontSize: '0.75rem',
                        ...renderApproverStatusBackgroundColor(label),
                      }}
                      label={label}
                    />
                  )
                },
              },
              {
                field: 'approve_date',
                width: '15%',
                name: 'TimeStamp',
                component: ({value}) => {
                  return value ? moment(value).format(dateTimeFormatTable) : ''
                },
              },
              {
                ...(!isApproverDisabled && {
                  field: '',
                  name: '',
                  width: '1%',
                  component: ({dataIndex}) => (
                    <IconButton
                      title={'Delete'}
                      handleOnClick={() => {
                        setFormDetails((prevState) => {
                          const approvers = [...prevState[key]]
                          approvers.splice(dataIndex, 1)

                          return {
                            ...prevState,
                            [key]: approvers,
                          }
                        })
                      }}
                    >
                      <DeleteIcon style={{color: '#CE6B72'}} />
                    </IconButton>
                  ),
                }),
              },
            ]}
            data={formDetails[key]}
          />
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <>
      <Accordion title={'Information'} expanded>
        <Grid container justifyContent={'flex-start'} columnSpacing={8} rowSpacing={2}>
          <Grid item xs={12}>
            <InputWYSIWYG
              label={'Request Summary'}
              customEditor={Editor}
              value={formDetails.request_summary}
              handleOnChange={(value) =>
                setFormDetails((prevState) => ({
                  ...prevState,
                  request_summary: value,
                }))
              }
              disabled={disabled}
              multiline
              {...(!disabled && {helperText: `Press 'enter' for new line`})}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{fontWeight: 600}} align={'left'}>
              Approval Path
            </Typography>
          </Grid>
        </Grid>
        <Accordion title={'Local'} secondaryTitle={true} expanded>
          <Grid container justifyContent={'flex-start'} columnSpacing={8} rowSpacing={2}>
            {!isLocalFieldDisabled && (
              <Grid item xs={12}>
                <ServerSideAutocomplete
                  disabled={isLocalFieldDisabled || formDetails.local_notify.length > 9}
                  textFieldProps={{
                    required: true,
                    placeholder: 'Search by name or email',
                    error: !!formDetails.error_local_notify,
                    helperText: (
                      <>
                        Please select all local finance PIC that you would like to notify.
                        HQTreasury@sea.com will automatically be notified.
                        {formDetails.error_local_notify && (
                          <>
                            <br />
                            {formDetails.error_local_notify}
                          </>
                        )}
                      </>
                    ),
                  }}
                  label={'Notifiers (Only able to add up to 10)'}
                  value={{email: '', full_name: ''}}
                  handleOnChange={(value) => {
                    if (!value) {
                      return
                    }

                    if (formDetails.request_by === value.email) {
                      setSnackBar({
                        type: 'error',
                        open: 'true',
                        message: 'You cant put yourself as notifier!',
                      })

                      return
                    }

                    if (
                      formDetails.local_notify.some((user) => user.email === value.email)
                    ) {
                      setSnackBar({
                        type: 'error',
                        open: 'true',
                        message: 'User exists in notifier list!',
                      })

                      return
                    }

                    setFormDetails((prevState) => ({
                      ...prevState,
                      error_local_notify: undefined,
                      local_notify: [
                        ...prevState.local_notify,
                        {
                          full_name: value.full_name,
                          email: value.email,
                          approve_date: null,
                        },
                      ],
                    }))
                  }}
                  fetchOptions={(value) => fetchUsers(value)}
                  getOptionLabel={(option) =>
                    option.full_name ? `${option.full_name} (${option.email})` : ''
                  }
                  isOptionEqualToValue={(option, value) => option.email === value.email}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              {isLocalFieldDisabled && (
                <Label label={`Notifiers (${formDetails.local_notify.length})`} />
              )}
              <FormTable
                noDataLabel={'No Notifier, search above to add notifier'}
                columns={[
                  {
                    field: 'full_name',
                    name: 'Name',
                    width: '35%',
                  },
                  {
                    field: 'email',
                    name: 'Email',
                    width: '35%',
                  },
                  {
                    field: 'approve_date',
                    name: 'Status',
                    component: ({value}) => {
                      return (
                        <Chip
                          size={'small'}
                          sx={{
                            fontSize: '0.75rem',
                            ...renderApproverStatusBackgroundColor(
                              value ? 'Notified' : 'Pending'
                            ),
                          }}
                          label={value ? 'Notified' : 'Pending'}
                        />
                      )
                    },
                  },
                  {
                    field: 'approve_date',
                    name: 'TimeStamp',
                    width: '15%',
                    component: ({value}) => {
                      return value ? moment(value).format(dateTimeFormatTable) : ''
                    },
                  },
                  {
                    ...(!isLocalFieldDisabled && {
                      field: '',
                      name: '',
                      width: '1%',
                      component: ({dataIndex}) => (
                        <IconButton
                          title={'Delete'}
                          handleOnClick={() => {
                            setFormDetails((prevState) => {
                              const notifiers = [...prevState.local_notify]
                              notifiers.splice(dataIndex, 1)

                              return {
                                ...prevState,
                                local_notify: notifiers,
                              }
                            })
                          }}
                        >
                          <DeleteIcon style={{color: '#CE6B72'}} />
                        </IconButton>
                      ),
                    }),
                  },
                ]}
                data={formDetails.local_notify}
              />
            </Grid>
            {renderApproverComponent('local_approval')}
          </Grid>
        </Accordion>
        <Accordion
          title={'HQ'}
          secondaryTitle={true}
          expanded={formDetails.current_order >= 3}
        >
          <Grid container justifyContent={'flex-start'} columnSpacing={8} rowSpacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label={'Verifier 1'}
                value={formDetails.hq_verifier_1_fullname ?? 'HQ Treasury'}
                disabled={true}
                {...(formDetails.hq_verifier_1_approve_date && {
                  InputProps: {
                    endAdornment: (
                      <Chip
                        size={'small'}
                        sx={{
                          ...renderApproverStatusBackgroundColor('Verified'),
                        }}
                        label={'Verified'}
                      />
                    ),
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ServerSideAutocomplete
                required={true}
                textFieldProps={{
                  required: true,
                  ...(!isHQFieldDisabled && {
                    placeholder: 'Search by name or email',
                  }),
                  error: !!formDetails.error_hq_verifier_2,
                  helperText: formDetails.error_hq_verifier_2,
                  ...(formDetails.hq_verifier_2_approve_date && {
                    InputProps: {
                      endAdornment: (
                        <Chip
                          size={'small'}
                          sx={{
                            ...renderApproverStatusBackgroundColor('Verified'),
                          }}
                          label={'Verified'}
                        />
                      ),
                    },
                  }),
                }}
                label={'Verifier 2'}
                value={
                  formDetails.hq_verifier_2 ?? {
                    email: '',
                    full_name: '',
                  }
                }
                handleOnChange={(value) => {
                  if (userEmail === value?.email) {
                    setSnackBar({
                      type: 'error',
                      open: 'true',
                      message: 'You cant put yourself as verifier 2!',
                    })
                    value = null
                  }

                  if (formDetails.request_by === value?.email) {
                    setSnackBar({
                      type: 'error',
                      open: 'true',
                      message: 'You cant put requester as verifier 2!',
                    })
                    value = null
                  }

                  setFormDetails((prevState) => ({
                    ...prevState,
                    hq_verifier_2: value,
                    error_hq_verifier_2: undefined,
                  }))
                }}
                fetchOptions={(value) => fetchUsers(value)}
                getOptionLabel={(option) =>
                  option.full_name ? `${option.full_name} (${option.email})` : ''
                }
                isOptionEqualToValue={(option, value) => option.email === value.email}
                disabled={isHQFieldDisabled}
              />
            </Grid>
            {renderApproverComponent('hq_approval')}
            <Grid item xs={12} sm={12} md={6}>
              <ServerSideAutocomplete
                disabled={isHQFieldDisabled}
                textFieldProps={{
                  required: true,
                  error: !!formDetails.error_hq_final_approver,
                  helperText: formDetails.error_hq_final_approver,
                  ...((formDetails.hq_final_approver?.approve_date ||
                    formDetails.reject_by === formDetails.hq_approval?.email) && {
                    InputProps: {
                      endAdornment: (
                        <Chip
                          size={'small'}
                          sx={{
                            ...renderApproverStatusBackgroundColor(
                              formDetails.hq_final_approver?.approve_date
                                ? 'Approved'
                                : formDetails.ticket_status === 'Rejected' &&
                                    formDetails.reject_by === userEmail &&
                                    'Rejected'
                            ),
                          }}
                          label={
                            formDetails.hq_final_approver?.approve_date
                              ? 'Approved'
                              : formDetails.ticket_status === 'Rejected' &&
                                formDetails.reject_by === userEmail &&
                                'Rejected'
                          }
                        />
                      ),
                    },
                  }),
                }}
                label={'Final Approver'}
                placeholder={'Search by name or email'}
                value={
                  formDetails.hq_final_approver ?? {
                    email: '',
                    full_name: '',
                  }
                }
                handleOnChange={(value) => {
                  if (formDetails.request_by === value?.email) {
                    setSnackBar({
                      type: 'error',
                      open: 'true',
                      message: 'Requester cant be final approver!',
                    })
                  }
                  setFormDetails((prevState) => ({
                    ...prevState,
                    hq_final_approver: value,
                    error_hq_final_approver: undefined,
                  }))
                }}
                fetchOptions={(value) => fetchUsers(value)}
                getOptionLabel={(option) =>
                  option.full_name ? `${option.full_name} (${option.email})` : ''
                }
                isOptionEqualToValue={(option, value) => option.email === value.email}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label={'Final Notification'}
                value={''}
                disabled={true}
                {...(formDetails.hq_final_approver_approve_date && {
                  InputProps: {
                    startAdornment: (
                      <Chip
                        size={'small'}
                        sx={{
                          ...renderApproverStatusBackgroundColor('Notified'),
                        }}
                        label={'Notified'}
                      />
                    ),
                  },
                })}
              />
            </Grid>
          </Grid>
        </Accordion>
      </Accordion>
    </>
  )
}
