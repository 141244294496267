import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {NumberDatePicker} from 'finsys-webcomponent'
import {makeStyles} from '@mui/styles'
import {PickersDay, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {Tooltip} from '@mui/material'

const useStyles = makeStyles((theme) => ({
  dayWithDotContainer: {
    position: 'relative',
  },
  dayWithDot: {
    position: 'relative',
    fontSize: '10px',
    padding: '2px 3px',
    borderRadius: '5px',
  },
}))

export function ApprovedDateTimePicker({
  approvedDates = [],
  approvedDate,
  setApprovedDate,
  loading,
}) {
  const classes = useStyles()
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [dateIndicator, setDateIndicator] = useState([])

  /**
   * To format the approved date for date picker
   */
  useEffect(() => {
    let dates = []
    if (approvedDates?.length > 0) {
      approvedDates.forEach((date) => {
        const d = moment(date)
        const dateFormat = d.format('YYYY-MM-DD')
        const timeFormat = d.format('HH:mm:ss')
        if (dates[dateFormat]) {
          dates[dateFormat].push(timeFormat)
        } else {
          dates[dateFormat] = [timeFormat]
        }
      })
    }
    setDateIndicator(dates)
  }, [approvedDates])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NumberDatePicker
        loading={loading}
        variant={'outlined'}
        open={openDatePicker}
        onOpen={() => setOpenDatePicker(true)}
        disableToolbar
        orientation="landscape"
        placeholder="yyyy-mm-DD hh:mm:ss"
        inputFormat="yyyy-MM-DD HH:mm:ss"
        label={'Approved Date'}
        value={approvedDate !== '' ? approvedDate : null}
        setSelectedDate={(date) => {
          const selectedDate = moment(`${date}`).format('YYYY-MM-DD HH:mm:ss')
          setApprovedDate(selectedDate === 'Invalid date' ? null : selectedDate)
        }}
        onClose={() => setOpenDatePicker(false)}
        inputVariant="outlined"
        renderDay={(day, selectedDate, dayComponent) => {
          const pickerDay = moment(day)

          const pickerDayFormat = pickerDay.format('YYYY-MM-DD')
          if (dateIndicator[pickerDayFormat]) {
            return (
              <Tooltip title={'Select the time below to filter'} placement={'top'}>
                <div
                  key={`date-${pickerDayFormat}`}
                  className={classes.dayWithDotContainer}
                >
                  <PickersDay
                    {...dayComponent}
                    sx={{
                      '&:hover': {
                        cursor: 'not-allowed!important',
                      },
                      background: '#6b6b6b!important',
                      color: 'white!important',
                      opacity: '1!important',
                    }}
                    disabled={true}
                  />
                  <div className={classes.dayWithDot}>
                    {dateIndicator[pickerDayFormat].map((dateTime, key) => {
                      return (
                        <React.Fragment key={`${pickerDayFormat}-${key}`}>
                          <small
                            onClick={() => {
                              const selectedDate = moment(
                                `${pickerDayFormat} ${dateTime}`
                              ).format('YYYY-MM-DD HH:mm:ss')
                              setApprovedDate(
                                selectedDate === 'Invalid date' ? null : selectedDate
                              )
                              setOpenDatePicker(false)
                            }}
                            className={'dateHover'}
                            style={{
                              color: '#2196f3',
                              fontSize: '9.6px',
                              cursor: 'pointer',
                            }}
                          >
                            {dateTime}
                          </small>
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              </Tooltip>
            )
          }

          return <PickersDay {...dayComponent} />
        }}
        disableFuture={approvedDates.length === 0}
        disablePast={approvedDates.length === 0}
        {...(approvedDates.length > 0 && {
          shouldDisableDate: (day) => {
            return !!!dateIndicator[moment(day).format('YYYY-MM-DD')]
          },
        })}
        onError={null}
        okLabel={null}
      />
    </LocalizationProvider>
  )
}
