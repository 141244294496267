import React from 'react'
import {
  RadioButton,
  TextField,
  Autocomplete,
  Button,
  Accordion,
  Label,
  FormTable,
  IconButton,
} from 'finsys-webcomponent'
import {Card, CardActions, CardContent, Grid, Stack, Typography} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import ApprovedPersonAndManualPaymentApprovers from 'components/InternalRequest/Forms/_Common/ApprovedPersonAndManualPaymentApprovers'
import {AddButton, RemoveButton} from 'components/_Common'
import {
  useFetchAccountManagedBy,
  useFetchBankNames,
  useFetchCurrencies,
  useFetchEmployeeGroup,
  useFetchEmployeeTitle,
  useFetchMatrixConditions,
  useFetchMatrixThresholds,
} from 'components/_Utils/UseFetchFunctions'
import {useDialog} from 'hoc/DialogHandler'
import {setFormArrayErrorValue} from 'components/InternalRequest/Forms/_Common'
import EmployeeFreeSoloCreateOptionDialog from 'components/InternalRequest/Forms/_Common/EmployeeFreeSoloCreateOptionDialog'
import {SectionLoading} from 'components/InternalRequest/Forms/_Common/SectionLoading'

type BankAccountOpeningFormType = {
  acc_opening_approved_person: string,
  acc_opening_manual_payment_approvers_limit: string,
  acc_opening_online_banking: string,
  acc_opening_matrix_currency: string,
  matrix_condition: Array<{
    matrix_condition: string,
    matrix_threshold: string,
  }>,
  reject_by: string,
  acc_opening_form_details: Array<{
    acc_managed_by: string,
    acc_managed_by_explain: string,
    acc_opening_id: number,
    applying_MT940: string,
    applying_MT940_Explanation: string,
    bank_id: string,
    bank_description: string,
    cheque_book_required: string,
    cheque_book_required_explanation: string,
    sub_category: string,
    number_of_account_to_open: string,
    open_with_preferred_bank: string,
    open_with_preferred_bank_explanation: string,
    purpose_of_bank_account: string,
  }>,
  ob_maker: Array<{
    acc_online_banking_id: number,
    name: string,
    title: string,
  }>,
  ob_verifier: Array<{
    acc_online_banking_id: number,
    name: string,
    title: string,
  }>,
  ob_approver: Array<{
    acc_online_banking_id: number,
    name: string,
    title: string,
    group: string,
  }>,
  system_admin: Array<{
    acc_online_banking_id: number,
    name: string,
    title: string,
  }>,
  view_only: Array<{
    acc_online_banking_id: number,
    name: string,
    title: string,
  }>,
  acc_opening_status: Array<{
    acc_managed_by: string,
    account_number: string,
    bank_id: string,
    bank_code: string,
    bank_description: string,
    currency_code: string,
    opening_status_id: number,
    status: string,
    entity_id: string,
    entity_desc: string,
    acc_type: string,
  }>,
}
/**
 * Bank Account Opening form details
 * @type {{bank: null, openWithPreferredBank: null, numberOfAccountToOpen: null, chequeBookRequiredExplanation: null, applyingMT940Explanation: null, accountManagedByExplanation: null, applyingMT940: null, purposeOfBankAccount: null, openWithPreferredBankExplanation: null, chequeBookRequired: null, accountManagedBy: null}}
 */
export function getBankFormDetails(subCategory) {
  return {
    bank: null,
    number_of_account_to_open: null,
    purpose_of_bank_account: null,
    open_with_preferred_bank: null,
    open_with_preferred_bank_explanation: null,
    applying_MT940: null,
    applying_MT940_Explanation: null,
    cheque_book_required: null,
    cheque_book_required_explanation: null,
    account_managed_by: null,
    acc_managed_by_explain: null,
    sub_category: subCategory,
  }
}

/**
 * To return all the fields for Bank Account opening
 * @param data
 * @return {{approver: {accOnlineBankingId: *, name: *, title: {text: *, value: *}|*, group: {text: *, value: *}|*}[], approvedPerson: (string|undefined), systemAdmin, matrixThreshold: (string|undefined), matrixConditions: ({text: (string|*), value: (string|*)}|undefined), verifier: {accOnlineBankingId: *, name: *, title: {text: *, value: *}|*}[], bankAccountStatus: ([]|*|undefined), manualPaymentApproversAndLimits: (string|undefined), maker: {accOnlineBankingId: *, name: *, title: {text: *, value: *}|*}[], currency: ({text: (string|*), value: (string|*)}|undefined), formDetails: ({bank: {text: *, value: *}|*, openWithPreferredBank: *, numberOfAccountToOpen: *, chequeBookRequiredExplanation: *, applyingMT940Explanation: *, accountManagedByExplanation: *, applyingMT940: *, purposeOfBankAccount: *, openWithPreferredBankExplanation: *, chequeBookRequired: *, accountManagedBy: {text: *, value: *}|*}[]|{bank: null, openWithPreferredBank: null, numberOfAccountToOpen: null, chequeBookRequiredExplanation: null, applyingMT940Explanation: null, accountManagedByExplanation: null, applyingMT940: null, purposeOfBankAccount: null, openWithPreferredBankExplanation: null, chequeBookRequired: null, accountManagedBy: null}[]), onlineBanking: (string|undefined)}}
 */
export function getBankAccountOpeningFormFields(formData: BankAccountOpeningFormType) {
  let details = []

  const getData = (data) => {
    return {
      bank_id: data.bank_description
        ? {value: data.bank_id, text: data.bank_description}
        : data.bank_id,
      number_of_account_to_open: data.number_of_account_to_open ?? null,
      purpose_of_bank_account: data.purpose_of_bank_account ?? null,
      open_with_preferred_bank: data.open_with_preferred_bank ?? null,
      open_with_preferred_bank_explanation:
        data.open_with_preferred_bank_explanation ?? null,
      applying_MT940: data.applying_MT940 ?? null,
      applying_MT940_Explanation: data.applying_MT940_Explanation ?? null,
      cheque_book_required: data.cheque_book_required ?? null,
      cheque_book_required_explanation: data.cheque_book_required_explanation ?? null,
      sub_category:
        formData.sub_category_1 === data.sub_category
          ? data.sub_category
          : formData.sub_category_1 === 'WC + ESCROW'
          ? data.sub_category ?? null
          : formData.sub_category_1,
      acc_managed_by: data.acc_managed_by ?? null,
      acc_managed_by_explain: data.acc_managed_by_explain ?? null,
    }
  }

  if (formData?.form_details?.length > 0) {
    details = formData.form_details.map((data) => {
      return getData(data)
    })
  } else if (formData?.acc_opening_form_details?.length > 0) {
    details = formData.acc_opening_form_details.map((data) => {
      return getData(data)
    })
  } else {
    details = [
      getBankFormDetails(
        formData.sub_category_1 === 'WC + ESCROW' ? null : formData.sub_category_1
      ),
    ]
  }

  let currency = formData?.acc_opening_matrix_currency ?? formData.currency?.value

  return {
    currency: currency
      ? {
          value: currency,
          text: currency,
        }
      : null,
    form_details: details,
    acc_opening_approved_person: formData?.acc_opening_approved_person ?? undefined,
    acc_opening_manual_payment_approvers_limit:
      formData?.acc_opening_manual_payment_approvers_limit ?? undefined,
    acc_opening_online_banking: formData?.acc_opening_online_banking ?? 'N',
    ob_maker:
      formData?.ob_maker?.map((data) => {
        return {
          accOnlineBankingId: formData.acc_online_banking_id,
          name: typeof data.name === 'string' ? JSON.parse(data?.name) : data?.name,
          title: data.title,
        }
      }) ?? [],
    ob_verifier:
      formData?.ob_verifier?.map((data) => {
        return {
          acc_online_banking_id: formData.acc_online_banking_id,
          name: typeof data.name === 'string' ? JSON.parse(data?.name) : data?.name,
          title: data.title,
        }
      }) ?? [],
    ob_approver:
      formData?.ob_approver?.map((data) => {
        return {
          acc_online_banking_id: formData.acc_online_banking_id,
          name: typeof data.name === 'string' ? JSON.parse(data?.name) : data?.name,
          title: data.title,
          group: data.group,
        }
      }) ?? [],
    system_admin:
      formData?.system_admin?.map((data) => {
        return {
          acc_online_banking_id: formData.acc_online_banking_id,
          name: typeof data.name === 'string' ? JSON.parse(data?.name) : data?.name,
          title: data.title,
        }
      }) ?? [],
    view_only:
      formData?.view_only?.map((data) => {
        return {
          acc_online_banking_id: formData.acc_online_banking_id,
          name: typeof data.name === 'string' ? JSON.parse(data?.name) : data?.name,
          title: data.title,
        }
      }) ?? [],
    matrix_condition:
      formData?.matrix_condition?.map(({matrix_condition, matrix_threshold}) => {
        return {
          matrix_condition: matrix_condition,
          matrix_threshold: matrix_threshold,
        }
      }) ?? [],
    acc_opening_status:
      formData?.acc_opening_status?.length > 0
        ? formData.acc_opening_status.map((acc) => {
            return {
              opening_status_id: acc.opening_status_id,
              acc_managed_by: acc.acc_managed_by,
              account_number: acc.account_number,
              bank_description: acc.bank_description,
              bank_id: acc.bank_id,
              status: acc.status,
              entity_id: acc.entity_id,
              entity_desc: acc.entity_desc,
              acc_type: acc.acc_type,
            }
          })
        : formData?.acc_opening_form_details
            ?.map(
              ({
                bank_id,
                bank_description,
                number_of_account_to_open,
                acc_managed_by,
                sub_category,
              }) => {
                let totalNumberOfAccountNo = Array.from(
                  Array(number_of_account_to_open < 1 ? 0 : number_of_account_to_open),
                  (e, i) => {
                    return {
                      opening_status_id: null,
                      account_number: '',
                      bank_description: bank_description,
                      bank_id: bank_id,
                      acc_managed_by: acc_managed_by,
                      status: `Pending opening, requested on ${formData.submission_date_format}`,
                      entity_id: null,
                      entity_desc: null,
                      acc_type: sub_category,
                    }
                  }
                )

                return totalNumberOfAccountNo
                  .map((value) => {
                    return formData.entity.map(
                      ({entity_id, entity_code, entity_name}) => {
                        return {
                          ...value,
                          entity_id: entity_id,
                          entity_desc: `${entity_name} (${entity_code})`,
                        }
                      }
                    )
                  })
                  .flat()
              }
            )
            .flat(),
  }
}
//form_details
type BankAccountOpeningInfoType = {
  bankAccountOpeningInfo: {
    currency: {text: string, value: string},
    form_details: Array<{
      bank_id: {text: string, value: string},
      number_of_account_to_open: string | null,
      purpose_of_bank_account: string | null,
      open_with_preferred_bank: string | null,
      open_with_preferred_bank_explanation: string | null,
      applying_MT940: string | null,
      applying_MT940_Explanation: string | null,
      cheque_book_required: string | null,
      cheque_book_required_explanation: string | null,
      acc_managed_by: string | null,
      acc_managed_by_explain: string | null,
      sub_category: string,
    }>,
    acc_opening_approved_person: string,
    acc_opening_manual_payment_approvers_limit: string,
    acc_opening_online_banking: string,
    ob_maker: Array<{
      acc_online_banking_id: number,
      name: string,
      title: string,
    }>,
    ob_verifier: Array<{
      acc_online_banking_id: number,
      name: string,
      title: string,
    }>,
    ob_approver: Array<{
      acc_online_banking_id: number,
      name: string,
      title: string,
      group: string,
    }>,
    system_admin: Array<{
      acc_online_banking_id: number,
      name: string,
      title: string,
    }>,
    view_only: Array<{
      acc_online_banking_id: number,
      name: string,
      title: string,
    }>,
    matrix_condition: Array<{
      matrix_condition: number,
      matrix_threshold: string,
    }>,
    acc_opening_status: Array<{
      opening_status_id: number,
      acc_opening_status: string,
      acc_managed_by: string | null,
      account_number: string,
      acc_type: string,
      bank: {
        text: string,
        value: string,
      },
      status: string,
      entity_id: string,
      entity_desc: string,
    }>,
  },
  setBankAccountOpeningInfo: () => void,
  current_order: number,
}

/**
 * To validate the fields for bank account opening
 * @param bankAccountOpeningInfo
 * @param subcategory
 * @return {{type: string, message: *}|{type: string}}
 */
export function validateBankAccountOpening({
  bankAccountOpeningInfo,
  setBankAccountOpeningInfo,
  subCategory,
  currentOrder,
}): BankAccountOpeningInfoType {
  const requiredFields = {
    form: [
      {field: 'bank_id', label: 'bank'},
      {field: 'number_of_account_to_open', label: 'number of account to open'},
      {field: 'purpose_of_bank_account', label: 'purpose of bank'},
      {field: 'open_with_preferred_bank', label: 'open with preferred bank'},
      {field: 'applying_MT940', label: 'applying MT940'},
      {field: 'cheque_book_required', label: 'cheque book required'},
      {field: 'acc_managed_by', label: 'account managed by'},
      {field: 'sub_category', label: 'sub_category'},
    ],
    details: [
      {field: 'currency', label: 'currency'},
      {field: 'acc_opening_approved_person', label: 'approved person'},
      {
        field: 'acc_opening_manual_payment_approvers_limit',
        label: 'manual payment approvers and limit',
      },
      {field: 'acc_opening_online_banking', label: 'online banking'},
    ],
    conditionalOnlineBanking: [
      //if online banking is Y below is mandatory
      {
        field: 'matrix_condition',
        label: 'matrix',
        others: ['matrix_threshold', 'matrix_condition'],
        required: true,
      },
      {field: 'ob_maker', label: 'ob_maker', others: ['name', 'title'], required: false},
      {
        field: 'ob_approver',
        label: 'approver',
        others: ['name', 'title', 'group'],
        required: false,
      },
      {
        field: 'ob_verifier',
        label: 'verifier',
        others: ['name', 'title'],
        required: false,
      },
      {
        field: 'system_admin',
        label: 'system admin',
        others: ['name', 'title'],
        required: false,
      },
      {
        field: 'view_only',
        label: 'view only',
        others: ['name', 'title'],
        required: false,
      },
    ],
  }

  if (bankAccountOpeningInfo.form_details.length === 0) {
    return {
      type: 'error',
      message: `Need to have at least 1 bank account opening form`,
    }
  }

  let isFormValid = true
  // lets validate form details first
  let formIndex = 0
  for (const details of bankAccountOpeningInfo.form_details) {
    formIndex++
    for (const [key, value] of Object.entries(requiredFields.form)) {
      if (!details[value.field] || details[value.field].length === 0) {
        setFormArrayErrorValue(
          setBankAccountOpeningInfo,
          formIndex,
          'form_details',
          value.field
        )
        isFormValid = false
      }

      //extra condition for validation
      if (value.field === 'number_of_account_to_open' && details[value.field] < 1) {
        setFormArrayErrorValue(
          setBankAccountOpeningInfo,
          formIndex,
          'form_details',
          value.field,
          details[value.field]
            ? `Value have to be greater than 0`
            : 'This field is required'
        )
        isFormValid = false
      }

      //extra condition for validation
      if (value.field === 'number_of_account_to_open' && details[value.field] > 100) {
        setFormArrayErrorValue(
          setBankAccountOpeningInfo,
          formIndex,
          'form_details',
          value.field,
          details[value.field]
            ? `Value cannot be more than 100`
            : 'This field is required'
        )
        isFormValid = false
      }

      if (
        value.field === 'open_with_preferred_bank' &&
        details[value.field]?.toLowerCase() === 'n'
      ) {
        if (!details.open_with_preferred_bank_explanation) {
          setFormArrayErrorValue(
            setBankAccountOpeningInfo,
            formIndex,
            'form_details',
            'open_with_preferred_bank_explanation'
          )
          isFormValid = false
        }
      }

      if (
        value.field === 'applying_MT940' &&
        details[value.field]?.toLowerCase() === 'n'
      ) {
        if (!details.applying_MT940_Explanation) {
          setFormArrayErrorValue(
            setBankAccountOpeningInfo,
            formIndex,
            'form_details',
            'applying_MT940_Explanation'
          )
          isFormValid = false
        }
      }

      if (
        value.field === 'cheque_book_required' &&
        details[value.field]?.toLowerCase() === 'y'
      ) {
        if (!details.cheque_book_required_explanation) {
          setFormArrayErrorValue(
            setBankAccountOpeningInfo,
            formIndex,
            'form_details',
            'cheque_book_required_explanation'
          )
          isFormValid = false
        }
      }

      if (
        value.field === 'acc_managed_by' &&
        ((details[value.field]?.toLowerCase() === 'finops' && subCategory === 'WC') ||
          (details[value.field]?.toLowerCase() === 'finance' && subCategory === 'ESCROW'))
      ) {
        if (!details.acc_managed_by_explain) {
          setFormArrayErrorValue(
            setBankAccountOpeningInfo,
            formIndex,
            'form_details',
            'acc_managed_by_explain'
          )
          isFormValid = false
        }
      }
    }
  }

  // next lets validate the extra fields
  for (const [key, value] of Object.entries(requiredFields.details)) {
    if (
      !bankAccountOpeningInfo[value.field] ||
      bankAccountOpeningInfo[value.field].length === 0
    ) {
      setBankAccountOpeningInfo((prevState) => ({
        ...prevState,
        [`error_${value.field}`]: `This field is required`,
      }))
      isFormValid = false
    }
  }

  // lastly validate conditional fields
  if (bankAccountOpeningInfo.acc_opening_online_banking?.toLowerCase() === 'y') {
    // next lets validate the extra fields
    for (const [key, value] of Object.entries(requiredFields.conditionalOnlineBanking)) {
      if (
        value.required &&
        (!bankAccountOpeningInfo[value.field] ||
          bankAccountOpeningInfo[value.field].length === 0)
      ) {
        setBankAccountOpeningInfo((prevState) => ({
          ...prevState,
          [`error_${value.field}`]: `This field is required`,
        }))
        isFormValid = false
      }

      // validate the other fields like name, title and group
      if (value.others) {
        for (const [subKey, subValue] of Object.entries(value.others)) {
          for (const index in bankAccountOpeningInfo[value.field]) {
            if (!bankAccountOpeningInfo[value.field][index][subValue]) {
              setFormArrayErrorValue(
                setBankAccountOpeningInfo,
                parseInt(index) + 1,
                value.field,
                subValue
              )

              if (!bankAccountOpeningInfo[`error_${value.field}`]) {
                setBankAccountOpeningInfo((prevState) => ({
                  ...prevState,
                  [`error_${value.field}`]: `This field is required`,
                }))
              }

              isFormValid = false
            }
          }
        }
      }
    }
  }

  // if current step === 7 ( pending account number )
  // we need to validate the bank account number is filled up
  let bankAccount = 0
  if (currentOrder === 7 && bankAccountOpeningInfo.acc_opening_status?.length > 0) {
    for (const details of bankAccountOpeningInfo.acc_opening_status) {
      bankAccount++
      if (!details.account_number) {
        setFormArrayErrorValue(
          setBankAccountOpeningInfo,
          bankAccount,
          'acc_opening_status',
          'account_number'
        )
        isFormValid = false

        setBankAccountOpeningInfo((prevState) => ({
          ...prevState,
          [`error_account_status`]: `Account Number is required`,
        }))
      }

      if (details.account_number === '0' || details.account_number < 0) {
        setFormArrayErrorValue(
          setBankAccountOpeningInfo,
          bankAccount,
          'acc_opening_status',
          'account_number',
          `Invalid number`
        )
        setBankAccountOpeningInfo((prevState) => ({
          ...prevState,
          [`error_account_status`]: `Account Number is invalid`,
        }))
        isFormValid = false
      }
    }
  }

  if (!isFormValid) {
    return {
      type: 'error',
    }
  }

  return {
    type: 'success',
  }
}

export default function BankAccountOpening({
  bankAccountOpeningInfo,
  setBankAccountOpeningInfo,
  isDisabled,
}) {
  const {loading: isCurrenciesLoading, data: currencies} = useFetchCurrencies()
  const {loading: isBanksLoading, data: banks} = useFetchBankNames()
  const {loading: isMatrixConditionsLoading, data: matrixConditions} =
    useFetchMatrixConditions()
  const {loading: isMatrixThresholdsLoading, data: matrixThresholds} =
    useFetchMatrixThresholds()

  const {loading: isEmployeeTitleLoading, data: title} = useFetchEmployeeTitle()
  const {loading: isEmployeeGroupLoading, data: group} = useFetchEmployeeGroup()
  const {loading: isAccountMangedByLoading, data: accountMangedBy} =
    useFetchAccountManagedBy()

  const {form_details} = bankAccountOpeningInfo
  const disabled = isDisabled
  const {setDialog} = useDialog()

  /**
   * To reset online banking related field if we change from Y -> N
   */
  const resetRelatedFieldsForOnlineBanking = (value) => {
    if (value.toLowerCase() === 'n') {
      const fields = [
        'ob_maker',
        'ob_verifier',
        'ob_approver',
        'system_admin',
        'view_only',
        'matrix_threshold',
        'matrix_condition',
      ]
      fields.forEach((field) =>
        setBankAccountOpeningInfo((prevState) => ({
          ...prevState,
          [field]: undefined,
        }))
      )
    }
  }

  /**
   * To set form value
   *
   * @param index
   * @param key
   * @param subKey
   * @param value
   */
  const setFormArrayValue = (index, key, subKey, value, error = true) => {
    setBankAccountOpeningInfo((prevState) => {
      const handleChangeDetails = [...prevState[key]]
      handleChangeDetails[index][subKey] = value
      if (error) {
        handleChangeDetails[index][`error_${subKey}`] = undefined
      }
      return {
        ...prevState,
        [key]: handleChangeDetails,
      }
    })
  }

  /**
   * To add user input value
   *
   * @param key
   * @param group
   */
  const addUserToOperations = (key, group) => {
    setBankAccountOpeningInfo((prevState) => {
      const addUser = prevState[key] ? [...prevState[key]] : []

      let value = {
        acc_online_banking_id: null,
        name: null,
        title: null,
        new: true,
      }

      if (group) {
        value.group = null
      }
      addUser.push(value)

      return {
        ...prevState,
        [key]: addUser,
        [`error_${key}`]: undefined,
      }
    })
  }

  /**
   * To render bank account status for user to input
   * @return {unknown[]}
   */
  const renderAccountStatus = () => {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormTable
          tableKey={'bank-account-status'}
          columns={[
            {
              name: 'Account Number',
              width: '25%',
              field: 'account_number',
              component: ({value, dataIndex, error, ...props}) => {
                if (
                  bankAccountOpeningInfo.editable !== 'Y' ||
                  bankAccountOpeningInfo.current_order !== 7
                ) {
                  return value
                }
                return (
                  <TextField
                    label={''}
                    value={value ?? ''}
                    onChange={(value) => {
                      setFormArrayValue(
                        dataIndex,
                        'acc_opening_status',
                        'account_number',
                        value.target.value
                      )
                    }}
                    sx={{
                      '& label': {display: 'none'},
                      '& .MuiInput-root': {mt: 0},
                      '& .Mui-error': {borderBottomColor: '#d32f2f!important'},
                    }}
                    error={error}
                  />
                )
              },
            },
            {
              name: 'Entity',
              field: 'entity_desc',
            },
            {
              name: 'Account Type',
              width: '80px',
              field: 'acc_type',
            },
            {
              name: 'Bank',
              field: 'bank_description',
            },
            {
              name: 'Account Managed By',
              field: 'acc_managed_by',
            },
            {
              name: 'Status',
              width: '150px',
              field: 'status',
              dependentValue: 'account_number',
            },
          ]}
          data={bankAccountOpeningInfo.acc_opening_status?.sort((a, b) => {
            const bankA = a.bank?.text?.toUpperCase() // ignore upper and lowercase
            const bankB = b.bank?.text?.toUpperCase() // ignore upper and lowercase

            const entityA = a.entity_desc?.toUpperCase() // ignore upper and lowercase
            const entityB = b.entity_desc?.toUpperCase() // ignore upper and lowercase

            if (bankA > bankB || entityA > entityB) {
              return 1
            }

            if (bankA < bankB || entityA < entityB) {
              return -1
            }
          })}
        />
      </Grid>
    )
  }

  if (
    isCurrenciesLoading ||
    isBanksLoading ||
    isMatrixConditionsLoading ||
    isMatrixThresholdsLoading ||
    isEmployeeTitleLoading ||
    isEmployeeGroupLoading ||
    isAccountMangedByLoading
  ) {
    return <SectionLoading label={'Bank Account Opening'} />
  }

  return (
    <React.Fragment>
      <Accordion title={'Bank Account Opening Form'} expanded>
        <div style={{marginBottom: '10px'}}>
          {Array.isArray(form_details) &&
            form_details?.map((detail, index) => {
              return (
                <Accordion
                  title={`Form ${index + 1}`}
                  secondaryTitle={true}
                  expanded
                  key={index}
                  id={`form-${index}`}
                >
                  <Card variant="outlined" id={`form-card-${index}`}>
                    <CardContent>
                      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Autocomplete
                            options={banks}
                            getOptionLabel={(option) => {
                              return option.text || ''
                            }}
                            handleOnChange={(value) =>
                              setFormArrayValue(index, 'form_details', 'bank_id', value)
                            }
                            value={detail.bank_id ?? ''}
                            label={'Select Bank'}
                            textFieldProps={{
                              required: true,
                              error: !!detail.error_bank_id,
                              helperText: detail.error_bank_id,
                            }}
                            disabled={disabled}
                            isOptionEqualToValue={(option, value) => {
                              return option?.value === value?.value
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Autocomplete
                            id={`currency - ${index}`}
                            options={currencies}
                            noOptionsText="Select Currency"
                            getOptionLabel={(option) => {
                              return option.text || option
                            }}
                            label={'Select Currency'}
                            handleOnChange={(value) => {
                              if (
                                form_details?.length > 1 &&
                                bankAccountOpeningInfo.currency &&
                                bankAccountOpeningInfo.currency !== value.value
                              ) {
                                setDialog((prevState) => ({
                                  ...prevState,
                                  type: 'info',
                                  open: true,
                                  buttons: [
                                    {
                                      label: 'Cancel',
                                      onClick: () => {
                                        setDialog((prevState) => ({
                                          ...prevState,
                                          open: false,
                                        }))
                                        setBankAccountOpeningInfo((prevState) => ({
                                          ...prevState,
                                          currency: bankAccountOpeningInfo.currency,
                                        }))
                                      },
                                      type: 'text',
                                    },
                                    {
                                      label: 'Continue',
                                      onClick: () => {
                                        setDialog((prevState) => ({
                                          ...prevState,
                                          open: false,
                                        }))
                                        setBankAccountOpeningInfo((prevState) => ({
                                          ...prevState,
                                          currency: value,
                                        }))
                                      },
                                    },
                                  ],
                                  title: 'Are you sure?',
                                  content: (
                                    <>
                                      <span style={{padding: '0 9px', display: 'block'}}>
                                        Are you sure you want to change? Each Bank Account
                                        Opening Request allows one currency only. We will
                                        default all the other forms currency to the
                                        selected currency
                                      </span>
                                    </>
                                  ),
                                }))
                              } else {
                                setBankAccountOpeningInfo((prevState) => ({
                                  ...prevState,
                                  currency: value,
                                  ...(bankAccountOpeningInfo?.error_currency && {
                                    error_currency: null,
                                  }),
                                }))
                              }
                            }}
                            value={bankAccountOpeningInfo.currency}
                            isOptionEqualToValue={(option, value) => {
                              return option?.value === value?.value
                            }}
                            textFieldProps={{
                              required: true,
                              error: !!bankAccountOpeningInfo.error_currency,
                              helperText: bankAccountOpeningInfo.error_currency,
                            }}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            type={'number'}
                            label={'Number of account to open'}
                            value={detail.number_of_account_to_open}
                            onChange={(value) => {
                              setFormArrayValue(
                                index,
                                'form_details',
                                'number_of_account_to_open',
                                value.target.value
                              )
                            }}
                            disabled={disabled}
                            required={true}
                            error={!!detail.error_number_of_account_to_open}
                            helperText={detail.error_number_of_account_to_open}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            label={'Purpose of bank account'}
                            required={true}
                            value={detail.purpose_of_bank_account}
                            multiline
                            maxRows={2}
                            onChange={(value) =>
                              setFormArrayValue(
                                index,
                                'form_details',
                                'purpose_of_bank_account',
                                value.target.value
                              )
                            }
                            disabled={disabled}
                            error={!!detail.error_purpose_of_bank_account}
                            helperText={
                              detail.error_purpose_of_bank_account ?? !disabled
                                ? `Press 'enter' for new line`
                                : ''
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={
                            detail.open_with_preferred_bank?.toUpperCase() === 'N'
                              ? 12
                              : 3
                          }
                          lg={
                            detail.open_with_preferred_bank?.toUpperCase() === 'N'
                              ? 12
                              : 3
                          }
                        >
                          <Grid
                            container
                            alignContent={'center'}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={
                                detail.open_with_preferred_bank?.toUpperCase() === 'N'
                                  ? 3
                                  : 12
                              }
                              lg={
                                detail.open_with_preferred_bank?.toUpperCase() === 'N'
                                  ? 3
                                  : 12
                              }
                            >
                              <RadioButton
                                label="Open with preferred bank"
                                value={detail.open_with_preferred_bank?.toUpperCase()}
                                options={['Y', 'N']}
                                handleOnChange={(value) => {
                                  setFormArrayValue(
                                    index,
                                    'form_details',
                                    'open_with_preferred_bank',
                                    value
                                  )
                                }}
                                disabled={disabled}
                                required={true}
                                error={!!detail.error_open_with_preferred_bank}
                                helperText={detail.error_open_with_preferred_bank}
                              />
                            </Grid>
                            {detail.open_with_preferred_bank?.toUpperCase() === 'N' && (
                              <Grid item xs={12} sm={12} md={9} lg={9}>
                                <TextField
                                  label={'Please Explain'}
                                  required={true}
                                  value={detail.open_with_preferred_bank_explanation}
                                  multiline
                                  maxRows={2}
                                  onChange={(value) =>
                                    setFormArrayValue(
                                      index,
                                      'form_details',
                                      'open_with_preferred_bank_explanation',
                                      value.target.value
                                    )
                                  }
                                  disabled={disabled}
                                  error={
                                    !!bankAccountOpeningInfo.error_open_with_preferred_bank_explanation
                                  }
                                  helperText={
                                    bankAccountOpeningInfo.error_open_with_preferred_bank_explanation
                                      ? bankAccountOpeningInfo.error_open_with_preferred_bank_explanation
                                      : !disabled
                                      ? `Press 'enter' for new line`
                                      : ''
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={detail.applying_MT940?.toUpperCase() === 'N' ? 12 : 3}
                          lg={detail.applying_MT940?.toUpperCase() === 'N' ? 12 : 3}
                        >
                          <Grid
                            container
                            alignContent={'center'}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={detail.applying_MT940?.toUpperCase() === 'N' ? 3 : 12}
                              lg={detail.applying_MT940?.toUpperCase() === 'N' ? 3 : 12}
                            >
                              <RadioButton
                                label="Applying MT940?"
                                value={detail.applying_MT940?.toUpperCase()}
                                options={['Y', 'N']}
                                handleOnChange={(value) => {
                                  setFormArrayValue(
                                    index,
                                    'form_details',
                                    'applying_MT940',
                                    value
                                  )
                                }}
                                disabled={disabled}
                                required={true}
                                error={!!detail.error_applying_MT940}
                                helperText={detail.error_applying_MT940}
                              />
                            </Grid>
                            {detail.applying_MT940?.toUpperCase() === 'N' && (
                              <Grid item xs={12} sm={12} md={9} lg={9}>
                                <TextField
                                  label={'Please Explain'}
                                  multiline
                                  maxRows={2}
                                  value={detail.applying_MT940_Explanation}
                                  onChange={(value) =>
                                    setFormArrayValue(
                                      index,
                                      'form_details',
                                      'applying_MT940_Explanation',
                                      value.target.value
                                    )
                                  }
                                  disabled={disabled}
                                  required={true}
                                  error={!!detail.error_applying_MT940_Explanation}
                                  helperText={
                                    detail.error_applying_MT940_Explanation
                                      ? detail.error_applying_MT940_Explanation
                                      : !disabled
                                      ? `Press 'enter' for new line`
                                      : ''
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={detail.cheque_book_required === 'Y' ? 12 : 3}
                          lg={detail.cheque_book_required === 'Y' ? 12 : 3}
                        >
                          <Grid
                            container
                            alignContent={'center'}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={
                                detail.cheque_book_required?.toUpperCase() === 'Y'
                                  ? 3
                                  : 12
                              }
                              lg={
                                detail.cheque_book_required?.toUpperCase() === 'Y'
                                  ? 3
                                  : 12
                              }
                            >
                              <RadioButton
                                label="Cheque Book Required?"
                                value={detail.cheque_book_required?.toUpperCase()}
                                options={['Y', 'N']}
                                handleOnChange={(value) => {
                                  setFormArrayValue(
                                    index,
                                    'form_details',
                                    'cheque_book_required',
                                    value
                                  )
                                }}
                                disabled={disabled}
                                required={true}
                                error={!!detail.error_cheque_book_required}
                                helperText={detail.error_cheque_book_required}
                              />
                            </Grid>
                            {detail.cheque_book_required?.toUpperCase() === 'Y' && (
                              <Grid item xs={12} sm={12} md={9} lg={9}>
                                <TextField
                                  label={'Please Explain'}
                                  multiline
                                  maxRows={2}
                                  value={detail.cheque_book_required_explanation}
                                  onChange={(value) =>
                                    setFormArrayValue(
                                      index,
                                      'form_details',
                                      'cheque_book_required_explanation',
                                      value.target.value
                                    )
                                  }
                                  disabled={disabled}
                                  required={true}
                                  error={!!detail.error_cheque_book_required_explanation}
                                  helperText={
                                    detail.error_cheque_book_required_explanation
                                      ? detail.error_cheque_book_required_explanation
                                      : !disabled
                                      ? `Press 'enter' for new line`
                                      : ''
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid
                            container
                            direction={'row'}
                            columnSpacing={8}
                            rowSpacing={2}
                          >
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Autocomplete
                                options={accountMangedBy}
                                getOptionLabel={(option) => option.text || option}
                                handleOnChange={(value) =>
                                  setFormArrayValue(
                                    index,
                                    'form_details',
                                    'acc_managed_by',
                                    value?.value
                                  )
                                }
                                value={detail.acc_managed_by ?? ''}
                                label="Select Account managed by"
                                textFieldProps={{
                                  required: true,
                                  error: !!detail.error_acc_managed_by,
                                  helperText: detail.error_acc_managed_by,
                                }}
                                disabled={disabled}
                                isOptionEqualToValue={(option, value) => {
                                  return option?.value === value
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Autocomplete
                                id={`subcategory-${index}`}
                                options={
                                  bankAccountOpeningInfo.sub_category_1 === 'WC + ESCROW'
                                    ? ['WC', 'ESCROW']
                                    : []
                                }
                                handleOnChange={(value) => {
                                  setFormArrayValue(
                                    index,
                                    'form_details',
                                    'sub_category',
                                    value
                                  )
                                }}
                                value={detail.sub_category}
                                label="Select Sub Category"
                                textFieldProps={{
                                  required: true,
                                  error: !!detail.error_sub_category,
                                  helperText: detail.error_sub_category,
                                }}
                                disabled={
                                  disabled ||
                                  bankAccountOpeningInfo.sub_category_1 !== 'WC + ESCROW'
                                }
                                isOptionEqualToValue={(option, value) => {
                                  return option === value
                                }}
                              />
                            </Grid>
                            {((detail.acc_managed_by?.toLowerCase() === 'finops' &&
                              bankAccountOpeningInfo.sub_category_1 === 'WC') ||
                              (detail.acc_managed_by?.toLowerCase() === 'finance' &&
                                bankAccountOpeningInfo.sub_category_1 === 'ESCROW')) && (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                  label={'Please Explain'}
                                  maxRows={3}
                                  value={detail.acc_managed_by_explain}
                                  multiline
                                  onChange={(value) =>
                                    setFormArrayValue(
                                      index,
                                      'form_details',
                                      'acc_managed_by_explain',
                                      value.target.value
                                    )
                                  }
                                  disabled={disabled}
                                  required={true}
                                  error={!!detail.error_acc_managed_by_explain}
                                  helperText={
                                    detail.error_acc_managed_by_explain
                                      ? detail.error_acc_managed_by_explain
                                      : !disabled
                                      ? `Press 'enter' for new line`
                                      : ''
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>

                    {!disabled && (
                      <CardActions sx={{ml: 1, mb: 1}}>
                        <RemoveButton
                          onClick={() => {
                            setBankAccountOpeningInfo((prevState) => {
                              const addFormDetails = [...prevState.form_details]
                              addFormDetails.splice(index, 1)

                              return {
                                ...prevState,
                                form_details: addFormDetails,
                              }
                            })
                          }}
                        />
                      </CardActions>
                    )}
                  </Card>
                </Accordion>
              )
            })}
        </div>
        {!disabled && (
          <>
            <AddButton
              onClick={() =>
                setBankAccountOpeningInfo((prevState) => {
                  const addFormDetails = [...prevState.form_details]
                  addFormDetails.push(
                    getBankFormDetails(
                      bankAccountOpeningInfo.sub_category_1 === 'WC + ESCROW'
                        ? null
                        : bankAccountOpeningInfo.sub_category_1
                    )
                  )

                  return {
                    ...prevState,
                    form_details: addFormDetails,
                  }
                })
              }
            />
          </>
        )}
      </Accordion>
      <Accordion title={'Operation Details'} expanded>
        <Grid container>
          <ApprovedPersonAndManualPaymentApprovers
            details={bankAccountOpeningInfo}
            approvedPersonKey={'acc_opening_approved_person'}
            setApprovers={(value) => {
              setBankAccountOpeningInfo((prevState) => ({
                ...prevState,
                acc_opening_approved_person: value,
                error_acc_opening_approved_person: undefined,
              }))
            }}
            manualApprovalKey={'acc_opening_manual_payment_approvers_limit'}
            setManual={(value) => {
              setBankAccountOpeningInfo((prevState) => ({
                ...prevState,
                acc_opening_manual_payment_approvers_limit: value,
                error_acc_opening_manual_payment_approvers_limit: undefined,
              }))
            }}
            disabled={disabled}
          />

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <RadioButton
              label="Online Banking"
              value={bankAccountOpeningInfo.acc_opening_online_banking}
              options={['Y', 'N']}
              style={{float: 'left'}}
              handleOnChange={(value) => {
                resetRelatedFieldsForOnlineBanking(value)
                setBankAccountOpeningInfo((prevState) => ({
                  ...prevState,
                  acc_opening_online_banking: value,
                  error_acc_opening_online_banking: undefined,
                }))
              }}
              disabled={disabled}
              required={true}
              error={!!bankAccountOpeningInfo.error_acc_opening_online_banking}
              helperText={bankAccountOpeningInfo.error_acc_opening_online_banking}
            />
          </Grid>
        </Grid>
        {bankAccountOpeningInfo.acc_opening_online_banking?.toLowerCase() === 'y' && (
          <Accordion
            title={'Additional Information for Online Banking'}
            secondaryTitle={true}
            expanded
          >
            <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
              <Grid item xs={12} md={12}>
                <Label
                  label={'Matrix'}
                  required={true}
                  error={
                    bankAccountOpeningInfo.error_matrix_condition
                      ? `${bankAccountOpeningInfo.error_matrix_condition}
              ${
                bankAccountOpeningInfo.matrix_condition.length === 0 &&
                ', please add matrix'
              }`
                      : ''
                  }
                />
                <FormTable
                  tableKey={'bank_acc_opening_matrix'}
                  columns={[
                    {
                      name: 'Currency',
                      width: '6%',
                      component: () => bankAccountOpeningInfo.currency?.value,
                    },
                    {
                      name: 'Matrix Threshold',
                      field: 'matrix_threshold',
                      component: ({value, dataIndex, error}) => {
                        return (
                          <Autocomplete
                            id={`matrix-th-${dataIndex}`}
                            options={matrixThresholds}
                            getOptionLabel={(option) => option.value ?? option}
                            handleOnChange={(value) =>
                              setFormArrayValue(
                                dataIndex,
                                'matrix_condition',
                                'matrix_threshold',
                                value?.value
                              )
                            }
                            value={value ?? ''}
                            label={null}
                            textFieldProps={{
                              error: !!error,
                              sx: {
                                '& label': {display: 'none'},
                                '& .MuiInput-root': {mt: 0},
                              },
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value
                            }
                            disabled={disabled}
                          />
                        )
                      },
                    },
                    {
                      name: 'Matrix Condition',
                      field: 'matrix_condition',
                      component: ({value, dataIndex, error}) => {
                        return (
                          <Autocomplete
                            id={`matrix-con-${dataIndex}`}
                            options={matrixConditions}
                            getOptionLabel={(option) => option.value ?? option}
                            handleOnChange={(value) => {
                              setFormArrayValue(
                                dataIndex,
                                'matrix_condition',
                                'matrix_condition',
                                value?.value
                              )
                            }}
                            value={value ?? ''}
                            label={null}
                            textFieldProps={{
                              error: !!error,
                              sx: {
                                '& label': {display: 'none'},
                                '& .MuiInput-root': {mt: 0},
                              },
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value
                            }
                            disabled={disabled}
                          />
                        )
                      },
                    },
                    {
                      ...(!disabled && {
                        name: '',
                        field: '',
                        width: '1%',
                        component: ({dataIndex}) => {
                          return (
                            <IconButton
                              title={'Delete'}
                              handleOnClick={() => {
                                setBankAccountOpeningInfo((prevState) => {
                                  const removeMatrix = [...prevState.matrix_condition]
                                  removeMatrix.splice(dataIndex, 1)

                                  return {
                                    ...prevState,
                                    matrix_condition: removeMatrix,
                                  }
                                })
                              }}
                            >
                              <DeleteIcon style={{color: '#CE6B72'}} />
                            </IconButton>
                          )
                        },
                      }),
                    },
                  ]}
                  noDataLabel={
                    disabled ? (
                      'No Matrix'
                    ) : (
                      <Typography
                        color={'secondary'}
                        sx={{cursor: 'pointer'}}
                        // @ts-ignore
                        onClick={() =>
                          setBankAccountOpeningInfo((prevState) => {
                            const addMatrix = [...prevState.matrix_condition]

                            let value = {
                              matrix_threshold: null,
                              matrix_condition: null,
                            }

                            addMatrix.push(value)

                            return {
                              ...prevState,
                              matrix_condition: addMatrix,
                              [`error_matrix_condition`]: undefined,
                            }
                          })
                        }
                      >
                        No Matrix is being selected, click here to add Matrix now!
                      </Typography>
                    )
                  }
                  data={bankAccountOpeningInfo.matrix_condition}
                />
                {!disabled && (
                  <Stack sx={{mt: 1, mb: 2}} direction={'row'}>
                    <Button
                      label={'Add Matrix'}
                      type={'text'}
                      size={'small'}
                      onClick={() =>
                        setBankAccountOpeningInfo((prevState) => {
                          const addMatrix = [...prevState.matrix_condition]

                          let value = {
                            matrix_threshold: null,
                            matrix_condition: null,
                          }

                          addMatrix.push(value)

                          return {
                            ...prevState,
                            matrix_condition: addMatrix,
                            [`error_matrix_condition`]: undefined,
                          }
                        })
                      }
                    />
                  </Stack>
                )}
              </Grid>

              {[
                {label: 'Approver', field: 'ob_approver', required: false},
                {label: 'Maker', field: 'ob_maker', required: false},
                {label: 'Verifier', field: 'ob_verifier', required: false},
                {label: 'System Admin', field: 'system_admin', required: false},
                {label: 'View Only', field: 'view_only', required: false},
              ].map(({label, field, required}, key) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12} key={`${key}-${field}`}>
                    <Label
                      label={label}
                      required={required}
                      error={bankAccountOpeningInfo[`error_${field}`]}
                    />
                    {!disabled && (
                      <Typography variant="body2">
                        <small style={{opacity: '0.8'}}>
                          Select existing user or key in new user email and name
                        </small>
                      </Typography>
                    )}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormTable
                        tableKey={`${label}-${field}`}
                        columns={[
                          {
                            name: 'Name',
                            field: 'name',
                            width: '40%',
                            component: ({value, dataIndex, error, ...props}) => (
                              <EmployeeFreeSoloCreateOptionDialog
                                disabled={disabled}
                                data={value}
                                index={dataIndex}
                                setFormArrayValue={setFormArrayValue}
                                keyName={field}
                                subKeyName={'name'}
                              />
                            ),
                          },
                          {
                            name: 'Title',
                            field: 'title',
                            width: '40%',
                            component: ({value, dataIndex, error, ...props}) => {
                              return (
                                <Autocomplete
                                  options={title}
                                  getOptionLabel={(option) => option.text || option}
                                  handleOnChange={(value) =>
                                    setFormArrayValue(
                                      dataIndex,
                                      field,
                                      'title',
                                      value?.value
                                    )
                                  }
                                  value={value}
                                  label={null}
                                  textFieldProps={{
                                    error: !!error,
                                    sx: {
                                      '& label': {display: 'none'},
                                      '& .MuiInput-root': {mt: 0},
                                    },
                                  }}
                                  disabled={disabled}
                                />
                              )
                            },
                          },
                          {
                            ...(field === 'ob_approver' && {
                              name: 'Group',
                              field: 'group',
                              disabled: field !== 'ob_approver',
                              component: ({value, dataIndex, error, ...props}) => {
                                return (
                                  <Autocomplete
                                    options={group}
                                    getOptionLabel={(option) => option.text || option}
                                    handleOnChange={(value) =>
                                      setFormArrayValue(
                                        dataIndex,
                                        field,
                                        'group',
                                        value?.value
                                      )
                                    }
                                    value={value}
                                    label={null}
                                    textFieldProps={{
                                      error: !!error,
                                      sx: {
                                        '& label': {display: 'none'},
                                        '& .MuiInput-root': {mt: 0},
                                      },
                                    }}
                                    disabled={disabled}
                                  />
                                )
                              },
                            }),
                          },
                          {
                            ...(!disabled && {
                              name: '',
                              field: '',
                              width: '1%',
                              component: ({value, dataIndex, ...props}) => {
                                return (
                                  <IconButton
                                    title={'Delete'}
                                    handleOnClick={() => {
                                      setBankAccountOpeningInfo((prevState) => {
                                        const removeUser = [...prevState[field]]
                                        removeUser.splice(dataIndex, 1)

                                        return {
                                          ...prevState,
                                          [field]: removeUser,
                                        }
                                      })
                                    }}
                                  >
                                    <DeleteIcon style={{color: '#CE6B72'}} />
                                  </IconButton>
                                )
                              },
                            }),
                          },
                        ]}
                        data={bankAccountOpeningInfo[field]}
                        noDataLabel={
                          disabled ? (
                            'No user is being selected'
                          ) : (
                            <Typography
                              color={'secondary'}
                              sx={{cursor: 'pointer'}}
                              // @ts-ignore
                              onClick={() =>
                                addUserToOperations(field, field === 'approver')
                              }
                            >
                              No user is being selected, click here to add user now!
                            </Typography>
                          )
                        }
                      />
                      {!disabled && (
                        <Stack sx={{mt: 1, mb: 2}} direction={'row'}>
                          <Button
                            label={'Add User'}
                            type={'text'}
                            size={'small'}
                            onClick={() =>
                              addUserToOperations(field, field === 'approver')
                            }
                            style={{marginTop: '10px'}}
                          />
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Accordion>
        )}
        {!['Draft', 'Withdrawn'].includes(bankAccountOpeningInfo.ticket_status) && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Accordion
              title={
                <>
                  Account Status
                  <span
                    aria-hidden="true"
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                  >
                    &thinsp;*
                  </span>
                </>
              }
              secondaryTitle={true}
              expanded={bankAccountOpeningInfo.current_order >= 6}
            >
              {bankAccountOpeningInfo.error_account_status && (
                <Label label={''} error={bankAccountOpeningInfo.error_account_status} />
              )}
              {bankAccountOpeningInfo.current_order === 7 && (
                <Typography variant={'caption'}>
                  Please click on Submit button to close the ticket after you filled up
                  all the details
                </Typography>
              )}
              {renderAccountStatus()}
            </Accordion>
          </Grid>
        )}
      </Accordion>
    </React.Fragment>
  )
}
