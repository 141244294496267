import {Outlet, Navigate} from 'react-router-dom'

import {Breadcrumbs} from 'hoc/Breadcrumbs'
import {ErrorHandler} from 'hoc/Error/ErrorHandler'
import {AxiosInterceptor} from 'api/TS/AxiosInterceptor'

export const PrivateRoutes = ({authenticated, breadCrumbs = true}) => {
  return authenticated ? (
    breadCrumbs ? (
      <>
        <Breadcrumbs />
        <ErrorHandler>
          <AxiosInterceptor>
            <Outlet />
          </AxiosInterceptor>
        </ErrorHandler>
      </>
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={'/login'} />
  )
}
