import React from 'react'
import {Typography, Grid, Stack} from '@mui/material'
import {NumberDatePicker, FullScreenDialog, Button} from 'finsys-webcomponent'
import moment from 'moment'

import MultipleSelect from 'components/InternalRequest/Summary/FilterOptions/MultipleSelect'

type FilterOptionsProps = {
  filterCondition: {
    temp: {
      submissionstartdate: string,
      submissionenddate: string,
      ticketstatus: string,
    },
    actual: {
      submissionstartdate: string,
      submissionenddate: string,
      ticketstatus: string,
    },
  },
  openFullScreenDialog: boolean,
  handleClose(): () => void,
  handleSetFilterCondition(): () => void,
  handleApplyFilter(): () => void,
  handleClearAllFilter(): () => void,
}
export const FilterOptions = ({
  filterCondition,
  filtersData,
  openFullScreenDialog,
  handleClose,
  setFilterCondition,
  handleSetFilterCondition,
  handleApplyFilter,
  handleClearAllFilter,
}: FilterOptionsProps) => {
  return (
    <FullScreenDialog
      open={openFullScreenDialog}
      title={'Filters'}
      handleClose={handleClose}
      footer={
        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
          <Button
            type={'danger'}
            label={'Clear All Filters'}
            onClick={handleClearAllFilter}
          />
          <Button type={'primary'} label={'Apply'} onClick={handleApplyFilter} />
        </Stack>
      }
    >
      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'} gutterBottom>
            General
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MultipleSelect
            isAll={true}
            selections={[
              {
                country_id: 'All',
                country_name: 'All',
              },
              ...filtersData.countries,
            ]}
            limitTags={5}
            label="Select Countries"
            onChange={(event, value) => {
              if (value[value.length - 1]?.country_id === 'All') {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      country:
                        filterCondition.country.length === filtersData.countries.length
                          ? []
                          : filtersData.countries,
                    },
                  }
                })
              } else {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      country: value,
                    },
                  }
                })
              }
            }}
            value={filterCondition.country}
            getOptionLabel={(option) => {
              return `${option.country_name} (${option.country_id})`
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MultipleSelect
            isAll={true}
            selections={[
              {
                entity_id: 'All',
                entity_code: 'All',
                entity_name: 'All',
              },
              ...filtersData.entities,
            ]}
            limitTags={5}
            label="Select Entities"
            onChange={(event, value) => {
              if (value[value.length - 1]?.entity_id === 'All') {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      entity:
                        filterCondition.entity.length === filtersData.entities.length
                          ? []
                          : filtersData.entities,
                    },
                  }
                })
              } else {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      entity: value,
                    },
                  }
                })
              }
            }}
            value={filterCondition.entity}
            getOptionLabel={(option) => {
              return `${option.entity_name} (${option.entity_code})`
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MultipleSelect
            isAll={true}
            selections={['All', ...filtersData.category]}
            limitTags={5}
            label="Select Category"
            onChange={(event, value) => {
              if (value[value.length - 1] === 'All') {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      category:
                        filterCondition.category.length === filtersData.category.length
                          ? []
                          : filtersData.category,
                    },
                  }
                })
              } else {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      category: value,
                    },
                  }
                })
              }
            }}
            value={filterCondition.category}
            getOptionLabel={(option) => {
              return option
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MultipleSelect
            isAll={true}
            selections={['All', ...filtersData.sub_category]}
            limitTags={5}
            label="Select Sub Category"
            onChange={(event, value) => {
              if (value[value.length - 1] === 'All') {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      sub_category:
                        filterCondition.sub_category.length ===
                        filtersData.sub_category.length
                          ? []
                          : filtersData.sub_category,
                    },
                  }
                })
              } else {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      sub_category: value,
                    },
                  }
                })
              }
            }}
            value={filterCondition.sub_category}
            getOptionLabel={(option) => {
              return option
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MultipleSelect
            isAll={true}
            selections={['All', ...filtersData.status]}
            limitTags={5}
            label="Select Ticket Status"
            onChange={(event, value) => {
              if (value[value.length - 1] === 'All') {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      ticketstatus:
                        filterCondition.ticketstatus.length === filtersData.status.length
                          ? []
                          : filtersData.status,
                    },
                  }
                })
              } else {
                setFilterCondition((prevState) => {
                  return {
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      ticketstatus: value,
                    },
                  }
                })
              }
            }}
            value={filterCondition.ticketstatus}
            getOptionLabel={(option) => {
              return option
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'} gutterBottom>
            Submission Dates
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <NumberDatePicker
            inputFormat={'MM/DD/yyyy'}
            setSelectedDate={(value) => {
              handleSetFilterCondition(
                'submissionstartdate',
                value ? moment(value).format('MM/DD/yyyy') : null
              )
            }}
            value={filterCondition.submissionstartdate ?? null}
            label={'Select Start Date'}
            {...(filterCondition.submissionenddate && {
              maxDate: moment(filterCondition.submissionenddate),
            })}
            handleOnError={(error) => {
              handleSetFilterCondition('error_submissionstartdate', error)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <NumberDatePicker
            inputFormat={'MM/DD/yyyy'}
            setSelectedDate={(value) => {
              handleSetFilterCondition(
                'submissionenddate',
                value ? moment(value).format('MM/DD/yyyy') : null
              )
            }}
            value={filterCondition.submissionenddate ?? null}
            label={'Select End Date'}
            {...(filterCondition.submissionstartdate && {
              minDate: moment(filterCondition.submissionstartdate),
            })}
            handleOnError={(error) => {
              handleSetFilterCondition('error_submissionstartdate', error)
            }}
          />
        </Grid>
      </Grid>
    </FullScreenDialog>
  )
}

export default FilterOptions
