import NewTabLinkRenderer from 'components/AgGrid/NewTabLinkRenderer'

export const columnDefs = [
  {
    headerName: 'Entity Code',
    field: 'entity_code',
    suppressMenu: true,
    floatingFilter: false,
    cellRenderer: 'newTabLinkRenderer',
    cellRendererParams: {
      to: '/Metadata/Entity',
      linkIdentification: 'entity_id',
      contentField: 'entity_code',
    },
  },
  {
    headerName: 'Entity Name',
    field: 'entity_name',
    suppressMenu: true,
    floatingFilter: false,
  },
  {
    headerName: 'Currency',
    field: 'currency',
    suppressMenu: true,
    floatingFilter: false,
  },
  {
    headerName: 'Segment',
    field: 'segment_id',
    suppressMenu: true,
    floatingFilter: false,
  },
  {
    headerName: 'Country',
    field: 'country_id',
    suppressMenu: true,
    floatingFilter: false,
  },
]

/**
 * This is for react component, the component content will not be wrapped in a cell
 * @type {{newTabLinkRenderer}}
 */
export const frameworkComponents = {
  newTabLinkRenderer: NewTabLinkRenderer,
}

/**
 * If you need your component to be wrapped in a cell, you need to use component
 */
export const components = {}
