import StatusChip from 'components/AgGrid/StatusChip'
import NewTabLinkRenderer from 'components/AgGrid/NewTabLinkRenderer'
import TooltipRenderer from 'components/AgGrid/TooltipRenderer'

export const columnDefs = [
  {
    headerName: 'Request ID',
    field: 'ticket_number',
    suppressMenu: true,
    floatingFilter: false,
    cellRenderer: 'newTabLinkRenderer',
    cellRendererParams: {
      to: '/InternalRequest',
      endToIdentification: 'category_type',
      linkIdentification: 'ticket_id',
      contentField: 'ticket_number',
    },
  },
  {
    headerName: 'Summary',
    field: 'request_summary',
    width: 400,
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    },
    cellRenderer: 'tooltipRenderer',
    cellRendererParams: {
      contentField: 'request_summary',
    },
  },
  {
    headerName: 'Country',
    minWidth: 175,
    field: 'country_id',
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    },
    cellRenderer: 'tooltipRenderer',
    cellRendererParams: {
      contentField: 'country_id',
    },
  },
  {
    headerName: 'Entity',
    minWidth: 175,
    field: 'entity_code',
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    },
    cellRenderer: 'tooltipRenderer',
    cellRendererParams: {
      contentField: 'entity_code',
    },
  },
  {
    headerName: 'Category',
    width: 280,
    field: 'category_type',
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    },
    cellRenderer: 'tooltipRenderer',
    cellRendererParams: {
      contentField: 'category_type',
    },
  },
  {
    headerName: 'Sub Category',
    width: 205,
    field: 'sub_category_1',
    suppressMenu: true,
    floatingFilter: false,
  },
  {
    headerName: 'Submission Date',
    width: 140,
    resizable: false,
    field: 'submission_date_format',
    suppressMenu: true,
    floatingFilter: false,
  },
  {
    headerName: 'Status',
    field: 'status',
    suppressMenu: true,
    floatingFilter: false,
    cellRenderer: 'statusChip',
    cellRendererParams: {
      contentField: 'status',
    },
    minWidth: 150,
  },
]

/**
 * This is for react component, the component content will not be wrapped in a cell
 * @type {{newTabLinkRenderer}}
 */
export const frameworkComponents = {
  newTabLinkRenderer: NewTabLinkRenderer,
  statusChip: StatusChip,
  tooltipRenderer: TooltipRenderer,
}

/**
 * If you need your component to be wrapped in a cell, you need to use component
 */
export const components = {}
