import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Loading} from 'finsys-webcomponent'

import ServerError from 'core/Template/ServerError'
import Forbidden from 'core/Template/Forbidden'
import ResourceNotFound from 'core/Template/ResourceNotFound'
import ApiError from 'core/Template/ApiError'
import NetworkError from 'core/Template/NetworkError'

/**
 * To use this upon accessing the page
 * @param error
 * @param retry
 * @return {*}
 * @constructor
 */
export function RenderErrorPage({error, retry}) {
  const navigate = useNavigate()
  const [errorType, setErrorType] = useState(null)

  useEffect(() => {
    if ([401].includes(error)) {
      setErrorType('SESSION-EXPIRED')
      return
    }

    if ([403].includes(error)) {
      setErrorType('FORBIDDEN')
      return
    }

    if ([404].includes(error)) {
      setErrorType('NOT-FOUND')
      return
    }

    if ([500, 405].includes(error)) {
      setErrorType('API-ERROR')
      return
    }

    if ([1000].includes(error)) {
      setErrorType('SERVER-ERROR')
      return
    }

    setErrorType('NETWORK-ERROR')
  }, [])

  function renderErrorView() {
    switch (errorType) {
      case 'SESSION-EXPIRED':
        // sign out google auth
        window.gapi.auth2?.getAuthInstance().signOut()
        window.gapi.auth2?.getAuthInstance().disconnect()
        localStorage.clear()

        navigate('/Logout')
        return null

      case 'NOT-FOUND':
        return <ResourceNotFound />

      case 'FORBIDDEN':
        return <Forbidden />

      case 'API-ERROR':
        return (
          <ApiError
            tryAgain={
              retry
                ? () => {
                    retry()
                  }
                : null
            }
          />
        )

      case 'SERVER-ERROR':
        return (
          <ServerError
            tryAgain={
              retry
                ? () => {
                    retry()
                  }
                : null
            }
          />
        )

      case 'NETWORK-ERROR':
        return (
          <NetworkError
            tryAgain={
              retry
                ? () => {
                    retry()
                  }
                : null
            }
          />
        )

      default:
        return <Loading open={true} />
    }
  }

  return renderErrorView()
}
