import SecureLS from 'secure-ls'

/**
 * To encrypt localstorage data
 * @type {SecureLS}
 */
export const securedLocalStorage = new SecureLS({
  encodingType: 'aes',
  isCompression: true,
})
