import React, {useEffect, useState} from 'react'
import {rowsPerPage} from 'components/AgGrid/constant'

export function Pagination({
  serverPaginationResult,
  page,
  isLoading,
  onClickToFirstPage,
  onClickToPreviousPage,
  onClickToNextPage,
  onClickToLastPage,
}) {
  const {totalPages, totalCount} = serverPaginationResult

  const [startRow, setStartRow] = useState(0)
  const [endRow, setEndRow] = useState(0)

  /**
   * To handle AG-Gird pagination field
   */
  useEffect(() => {
    setStartRow(page === 1 || page === null ? 1 : (page - 1) * rowsPerPage + 1)
    setEndRow(page * rowsPerPage > totalCount ? totalCount : page * rowsPerPage)
  }, [page, totalCount])

  return (
    <div className="ag-grid-pagination">
      {totalCount > 0 ? (
        //<!--AG-PAGINATION-->
        <div className="ag-paging-panel">
          <span className="ag-paging-row-summary-panel">
            <span className="ag-paging-row-summary-panel-number">{startRow}</span>
            <span>to</span>
            <span className="ag-paging-row-summary-panel-number">{endRow}</span>
            {totalPages !== page && (
              <>
                <span>of</span>
                <span className="ag-paging-row-summary-panel-number">{totalCount}</span>
              </>
            )}
          </span>
          <span className="ag-paging-page-summary-panel">
            <button
              className={`ag-paging-button`}
              onClick={onClickToFirstPage}
              disabled={page === 1 || isLoading}
            >
              <span className="ag-icon ag-icon-first" unselectable="on" role="button" />
            </button>
            <button
              className={`ag-paging-button`}
              onClick={onClickToPreviousPage}
              disabled={page === 1 || isLoading}
            >
              <span
                className="ag-icon ag-icon-previous"
                unselectable="on"
                role="presentation"
              />
            </button>
            <span className="ag-paging-description" aria-hidden="true">
              <span>Page</span>
              <span className="ag-paging-number">{page}</span>
              <span>of</span>
              <span className="ag-paging-number">
                {totalPages === page ? page : totalPages}
              </span>
            </span>
            <button
              className={`ag-paging-button`}
              onClick={onClickToNextPage}
              disabled={totalPages === page || isLoading}
            >
              <span className="ag-icon ag-icon-next" unselectable="on" />
            </button>
            <button
              className={`ag-paging-button`}
              onClick={onClickToLastPage}
              disabled={totalPages === page || isLoading}
            >
              <span className="ag-icon ag-icon-last" unselectable="on" role="button" />
            </button>
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
