import React from 'react'

import {AuthProvider} from 'hoc/AuthContext'
import BaseApp from 'core/App'

function AuthApp() {
  return (
    <AuthProvider>
      <BaseApp />
    </AuthProvider>
  )
}

export default AuthApp
