import React from 'react'

import Template from 'core/Template'

/**
 * To be shown if user does not have permission to the application
 * @returns {JSX.Element}
 * @constructor
 */
const Forbidden = () => {
  return (
    <>
      <Template
        errorCode="403"
        content="Uh oh! You are not authorized to access this resource."
        desc={
          <>
            You do not have permissions to access this resource.
            <br />
            Please go to <a href="https://tiny.one/systems">
              https://tiny.one/systems
            </a>{' '}
            for guides on how to get access or contact us @{' '}
            <a href="mailto: finsys-epm@sea.com">finsys-epm@sea.com</a>.
          </>
        }
      />
    </>
  )
}

export default Forbidden
