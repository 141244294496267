import React, {useEffect, useState} from 'react'
import {Autocomplete} from 'finsys-webcomponent'
import {retrieveOBMEmployee} from 'api/TS/query/InternalRequestQueries'
import {
  useFetchOBMEmployee,
  useFetchUserOBMAccess,
} from '../../../../_Utils/UseFetchFunctions'

export default function EmployeeListForBankAccount({
  disabled,
  value,
  setEmployeeName,
  setApproveTypeOptions = () => {},
  bankAccount,
  entityId,
}) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])

  const {loading: isUserOBMAccessLoading, data: OBMEmployee = []} = useFetchOBMEmployee(
    bankAccount,
    entityId
  )

  useEffect(() => {
    if (OBMEmployee.length < 1) {
      return
    }
    setOptions(OBMEmployee)

    if (value) {
      let editEmployee = OBMEmployee.find((employee) => employee.email === value.email)
      setApproveTypeOptions(editEmployee)
    }
  }, [OBMEmployee])

  return (
    <Autocomplete
      loading={isUserOBMAccessLoading}
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      getOptionLabel={(option) => {
        return option?.full_name
          ? `${option.full_name} ${option.email ? `(${option.email})` : ''}`
          : ''
      }}
      handleOnChange={(value) => setEmployeeName(value)}
      options={options}
      textFieldProps={{
        placeholder: 'Search by name or email',
        error: !!value?.error_employeeName,
        sx: {
          '& label': {display: 'none'},
          '& .MuiInput-root': {mt: 0},
        },
      }}
    />
  )
}
