import CardRequestIcon from 'assets/request_icon.png'
import CardMatrixIcon from 'assets/matrix_icon.png'
import CardPOAIcon from 'assets/poa_icon.png'
import CardGCRIcon from 'assets/gcr_icon.png'
import CardLibraryIcon from 'assets/library_icon.png'

export const HQ_ROLE_ID = 'UGTS_HQ-TRE'
export const IT_ROLE_ID = 'UGTS-IT'
export const INTERNAL_REQUEST_PAGE_NAME = 'InternalRequest'
export const METADATA_PAGE_NAME = 'Metadata'
export const APPROVAL_MATRIX_PAGE_NAME = 'BankApprovalMatrix'
export const POA_PAGE_NAME = 'POA'
export const GCR_PAGE_NAME = 'GCR'

export const FORM_BANK_ACCOUNT_OPENING = 'BankAccountOpening'
export const FORM_BANK_ACCOUNT_CLOSING = 'BankAccountClosing'
export const FORM_BANK_ACCOUNT_MAINTENANCE = 'BankAccountMaintenance'
export const FORM_BANK_GUARANTEE = 'BankGuarantee'
export const FORM_DOCUMENT_SIGNING_REQUEST = 'DocumentSigningRequest'

export const STATUS_APPROVAL_IN_PROGRESS = 'Approval In Progress'
export const STATUS_APPROVED = 'Approved'
export const STATUS_PENDING_SECOND_APPROVED = 'Pending 2nd Approver'
export const STATUS_REJECTED = 'Rejected'
export const STATUS_SUBMITTED = 'Submitted'
export const STATUS_PENDING_ACCOUNT_NUMBER = 'Pending Account Number'
export const STATUS_PENDING_ACCOUNT_CLOSING = 'Pending Account Closing'
export const STATUS_COMPLETED = 'Completed'
export const STATUS_WITHDRAWN = 'Withdrawn'
export const STATUS_DRAFT = 'Draft'
export const STATUS_VERIFIED = 'Verified'

/**
 * To render the form status chip bg color
 * @param status
 * @return {string}
 */
export const renderFormStatusBackgroundColor = (status) => {
  switch (status) {
    case STATUS_APPROVAL_IN_PROGRESS: //OK IM PROGRESS
      return '#59b7ff'
    case STATUS_APPROVED: // OK
      return '#F346A4'
    case STATUS_REJECTED: // ok
      return '#D02A05'
    case STATUS_SUBMITTED: //OK
      return '#ffc964'
    case STATUS_PENDING_ACCOUNT_NUMBER: // OK
    case STATUS_PENDING_ACCOUNT_CLOSING: // OK
      return '#a19cdc'
    case STATUS_COMPLETED: // OK
      return '#64cd9c'
    case STATUS_WITHDRAWN: //OK
      return '#ecbdbd'
    default:
      // draft
      return '#ACACAC'
  }
}

/**
 * Generic zone format
 * @type {string}
 */
export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
export const dateTimeFormat = 'MMM Do YYYY, H:mm [GMT]ZZ'
export const dateTimeFormatTable = 'yyyy-MM-DD H:mm'

export const sessionTTL = 604800000 // 1 week in milliseconds
//TO NOTE: PRODUCTION SETTING IS 200MB, TEST & DEV IS 20MB, we -2 to have some allowance
export const maxFileSizeForServer =
  (process.env.REACT_APP_NODE_ENV === 'PROD' ? 200 : 20) - 2
export const APP_NAME = `${process.env.REACT_APP_NAME}`
export const FORMATTED_APP_NAME = `${process.env.REACT_APP_NAME.replaceAll(' ', '')}`

/**
 *
 * @type {[{cardSrc, drawerSrc, backgroundColor: string, name: string, alt: string, link: string, pageName: string, desc: string}, {cardSrc, drawerSrc, backgroundColor: string, name: string, alt: string, link: string, pageName: string, desc: string}, {cardSrc, drawerSrc, backgroundColor: string, name: string, alt: string, link: string, pageName: string, desc: string}, {cardSrc, drawerSrc, backgroundColor: string, name: string, alt: string, link: string, pageName: string, desc: string}]}
 */

type MenuListType = {
  name: string,
  desc: string,
  backgroundColor: string,
  alt: string,
  cardSrc: string,
  drawerSrc: string,
  link: string,
  pageName: string,
}

const menuListBg = ['#fababa', '#ffd391', '#8dd5d5', '#97d3a4', '#c8c4ed']

export const menuList: MenuListType = [
  {
    name: 'Internal Request',
    desc: 'For submission, approval, and storage for all requests',
    backgroundColor: menuListBg[0],
    alt: 'Request',
    cardSrc: CardRequestIcon,
    link: '/InternalRequest',
    pageName: INTERNAL_REQUEST_PAGE_NAME,
  },
  {
    name: 'Bank Approval Matrix',
    desc: 'To view and modify bank approval matrix and viewing of online banking matrix',
    backgroundColor: menuListBg[1],
    alt: 'BankApprovalMatrix',
    cardSrc: CardMatrixIcon,
    link: '/BankApprovalMatrix',
    pageName: APPROVAL_MATRIX_PAGE_NAME,
  },
  {
    name: 'POA',
    desc: 'To view and modify POA',
    backgroundColor: menuListBg[2],
    alt: 'POA',
    cardSrc: CardPOAIcon,
    link: '/Poa',
    pageName: POA_PAGE_NAME,
  },
  {
    name: 'GCR',
    desc: 'Group Cash Reporting system to identify cash fluxes, and to perform cash analysis',
    backgroundColor: menuListBg[3],
    alt: 'GCR',
    cardSrc: CardGCRIcon,
    link: `${process.env.REACT_APP_GRC_URL}`,
    pageName: GCR_PAGE_NAME,
    redirect: true,
  },
  {
    name: 'Metadata',
    desc: 'To managed Entities, Employees and Banks',
    backgroundColor: menuListBg[4],
    alt: 'metadata',
    cardSrc: CardLibraryIcon,
    link: '/Metadata',
    pageName: METADATA_PAGE_NAME,
  },
]

/**
 * To check if value only contain string
 * @param str
 * @returns {boolean}
 */
export function onlySpaces(str) {
  return /<\s*p[^>]*>(?:&nbsp;)+<\s*\/\s*p\s*>/.test(str)
}
