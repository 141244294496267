import React, {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid, Menu, MenuItem, Stack, Typography} from '@mui/material'
import {
  Autocomplete,
  Loading,
  Button,
  FullScreenDialog,
  IconButton,
  FilePreviewDialog,
} from 'finsys-webcomponent'
import EditIcon from '@mui/icons-material/Edit'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SettingsIcon from '@mui/icons-material/Settings'

import useDataFetching from 'hoc/UseDataFetching'
import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {setBreadcrumbRecordName, setFilter} from 'store/actions'
import {formatCellValue, renderCellComponent} from 'components/Tables/_helper'
import {POATable} from 'components/Tables/POATable'
import {FileUpload} from 'components/_Common/FileUpload'
import {
  exportPOASummary,
  fetchApprovedDate,
  fetchEntitiesAndRequestId,
  fetchPOASummary,
  fetchSegmentsForPOA,
  fetchSupportingDocs,
  notifyEmail,
} from 'api/TS/query/POAQueries'
import {downloadAttachment, previewAttachment} from 'api/TS/query/AttachmentQueries'
import {useFetchCountry} from 'components/_Utils/UseFetchFunctions'
import {getDate} from 'components/_Common'
import {HQ_ROLE_ID, POA_PAGE_NAME} from '_helper/_constants'
import {toMergeField} from 'pages/POA/_constants'
import {EmailNotify} from 'components/_Common/EmailNotify'
import {ApprovedDateTimePicker} from 'components/_Common/ApprovedDateTimePicker'
import './index.scss'

function POA() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {setSnackBar} = useSnackBar()
  const {country, segment, entity, approvedDate} = useParams()
  const filterState = useSelector((state) => state.filter.state)
  const [openSettings, setOpenSettings] = useState(null)
  const [openMore, setOpenMore] = useState(null)
  const [filterQuery, setFilterQuery] = useState({
    country: country ? {country_id: country.toUpperCase()} : null,
    segment: segment
      ? {value: segment, text: segment, pending_poa_request_id: null}
      : null,
    entity: entity ? {value: entity, text: entity} : null,
    approvedDate: getDate(approvedDate),
  })
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    toNextPage: '',
  })
  const [pendingApprovalRequestId, setPendingApprovalRequestId] = useState(null)
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState({
    open: false,
    type: null,
  })
  const [functionAccess, setFunctionAccess] = useState([])
  const [emailNotifyUserList, setEmailNotifyUserList] = useState({
    toNotify: [],
    notified: [],
    isNotified: false,
  })
  const [isCallingApi, setIsCallingApi] = useState({
    loading: false,
    type: null,
  })
  const [filePreviewDialog, setFilePreviewDialog] = useState({
    open: false,
    value: null,
  })
  const [isTransformingData, setIsTransformingData] = useState(false)

  /**
   * To set breadcrumb nav only if we re viewing from email
   */
  useEffect(() => {
    if (country) {
      dispatch(
        setBreadcrumbRecordName({
          [location.pathname]: `View From Email ${country.toUpperCase()}`,
        })
      )
    }
  }, [])

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(POA_PAGE_NAME, setFunctionAccess)
    }

    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * Apply filter state
   */
  useEffect(() => {
    //to retain filter value
    if (filterState) {
      setFilterQuery(filterState.filterQuery ?? filterQuery)
      if (filterState.filterQuery.entity) {
        setPendingApprovalRequestId(filterState.filterQuery.entity.pending_poa_request_id)
      }
    }
  }, [])

  /**
   * Set filter state
   */
  useEffect(() => {
    dispatch(
      setFilter('Poa', 'index', {
        filterQuery,
      })
    )
  }, [filterQuery])

  /**
   * To retrieve countries list
   */
  const {loading: isCountryLoading, data: countries} = useFetchCountry()

  /**
   * To retrieve entities
   */
  const {loading: isEntitiesLoading, data: entities} = useDataFetching(
    ['POA-entities', filterQuery.country, filterQuery.segment],
    () => fetchEntitiesAndRequestId(filterQuery.country, filterQuery.segment)
  )
  /**
   * To retrieve segment
   */
  const {loading: isSegmentLoading, data: segments} = useDataFetching(
    ['POA-segment', filterQuery.country, filterQuery.entity],
    () => fetchSegmentsForPOA(filterQuery.country, filterQuery.entity)
  )

  /**
   * To retrieve approved dates
   */
  const {loading: isApprovedDateLoading, data: approvedDates = []} = useDataFetching(
    ['POA-approved-dates', filterQuery.entity],
    () => fetchApprovedDate(filterQuery.entity)
  )

  /**
   * To retrieve POA summary
   */
  const {
    loading: isSummaryLoading,
    data: summaryData,
    isPreviousData,
  } = useDataFetching(['POA', filterQuery], () => fetchPOASummary(filterQuery), {
    refetchOnWindowFocus: true,
  })

  /**
   * To retrieve supporting doc for POA
   */
  const {loading: isSupportDocLoading, data: supportDocs} = useDataFetching(
    ['supporting-doc', filterQuery.entity, filterQuery.approvedDate],
    () =>
      fetchSupportingDocs({
        entity: filterQuery.entity?.entity_id,
        approvedDate: filterQuery.approvedDate,
      })
  )

  /**
   * We need to update the poa request in progress if any for the entity
   */
  useEffect(() => {
    if (entities && filterState?.filterQuery?.entity?.entity_id) {
      entities.forEach(({value, pending_poa_request_id}) => {
        if (value === filterState.filterQuery.entity.entity_id) {
          setPendingApprovalRequestId(pending_poa_request_id)
        }
      })
    }
  }, [entities])

  /**
   * To retain initial filter state and subsequently mutate the entity selection according to country and segment input
   */
  useEffect(() => {
    if (filterState?.entity?.entity_id !== filterQuery?.entity?.entity_id && entities) {
      const entityObj = entities.find(
        (obj) => obj.entity_id == filterQuery.entity?.entity_id
      )

      setFilterQuery((prevState) => ({
        ...prevState,
        entity: entityObj
          ? {
              entity_id: entityObj.entity_id,
              entity_name: entityObj.entity_name,
              entity_code: entityObj.entity_code,
              pending_poa_request_id: entityObj.pending_poa_request_id,
            }
          : null,
      }))
    }
  }, [filterQuery.country?.country_id, filterQuery.segment?.value, entities])

  /**
   * To retain initial filter state and subsequently mutate the segment selection according to country and entity input
   */
  useEffect(() => {
    if (filterState?.segment?.value !== filterQuery.segment?.value && segments) {
      const entityObj = segments.find(
        (obj) => obj.value === filterQuery.segment?.value?.toUpperCase()
      )

      setFilterQuery((prevState) => ({
        ...prevState,
        segment: entityObj
          ? {
              value: entityObj.value,
              text: entityObj.text,
            }
          : null,
      }))
    }
  }, [filterQuery.country?.country_id, filterQuery.entity?.entity_id, segments])

  /**
   * This is to retrieve the segment/ entity text based on its value from the params
   */
  useEffect(() => {
    if (entity && segment) {
      if (entities) {
        const entityObj = entities.find((obj) => obj.entity_id == entity.toUpperCase())

        // if we cant find entity we jus set it to null
        setFilterQuery((prevState) => ({
          ...prevState,
          entity: {
            entity_id: entityObj.entity_id,
            entity_name: entityObj.entity_name,
            entity_code: entityObj.entity_code,
            pending_poa_request_id: prevState.entity?.pending_poa_request_id ?? null,
          },
        }))
      }

      if (segments) {
        const segmentObj = segments.find((obj) => obj.value === segment.toUpperCase())

        // if we cant find entity we jus set it to null
        setFilterQuery((prevState) => ({
          ...prevState,
          segment: {
            value: segmentObj ? segmentObj.value : null,
            text: segmentObj ? segmentObj.text : null,
          },
        }))
      }
    }
  }, [entities, segments])

  useEffect(() => {
    if (isSummaryLoading && !isTransformingData) {
      setIsTransformingData(true)
    }
  }, [isSummaryLoading])

  useEffect(() => {
    const approvedDateLength = approvedDates.length
    if (approvedDateLength > 0) {
      setFilterQuery((prevState) => ({
        ...prevState,
        approvedDate: getDate(approvedDates[approvedDateLength - 1]),
      }))
    } else {
      setFilterQuery((prevState) => ({
        ...prevState,
        approvedDate: null,
      }))
    }
  }, [approvedDates])

  /**
   * To update the table data and format the data for the table to consume
   */
  useEffect(() => {
    if (summaryData && !isPreviousData) {
      // we need to extract threshold and matrix first
      let tempSummaryData = []
      let currentEntity = null
      let currentEntityIndex = 0
      summaryData.forEach((row) => {
        // whatever it is we need to group it based by entity
        let newSummaryData = {...tempSummaryData}

        if (!newSummaryData[row.entity_id]) {
          newSummaryData[row.entity_id] = {
            data: [],
            name: row.entity_name,
          }
        }

        // the code below is executed if its not a header
        // we will merge it only if they got the same entity as the previous index
        let ableToMerge = undefined
        if (currentEntity !== row.entity_id) {
          currentEntity = row.entity_id
          currentEntityIndex = 0
          ableToMerge = false
        } else {
          ableToMerge = true
          currentEntityIndex++
        }

        // we will loop the field to set its rowSpan and index and compare if its merge-able
        toMergeField.forEach((fieldName) => {
          let tempArray = [...newSummaryData[row.entity_id].data]
          let rowSpanIndex = currentEntityIndex
          let rowSpan = 1

          if (ableToMerge) {
            const prevIndex = currentEntityIndex - 1

            if (
              formatCellValue(tempArray[prevIndex][fieldName]).localeCompare(
                formatCellValue(row[fieldName])
              ) === 0
            ) {
              // we will add +1 to the previous rowSpan based on the row span index
              tempArray[tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]][
                `${[fieldName]}RowSpan`
              ]++

              // update the current rowSpanIndex & rowSpan to the previous row index
              rowSpanIndex = tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]
              rowSpan = 0
            }
          }

          // if this is the first record we shall instantiate the rowSpan & rowIndex for the field
          if (!tempArray[currentEntityIndex]) {
            tempArray[currentEntityIndex] = {
              ...row,
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
            }
          } else {
            tempArray[currentEntityIndex] = {
              ...tempArray[currentEntityIndex],
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
            }
          }

          newSummaryData[row.entity_id] = {
            ...newSummaryData[row.entity_id],
            data: tempArray,
          }
          tempSummaryData = newSummaryData
        })
      })

      setServerPaginationResult({
        rowData: tempSummaryData,
        totalPages: summaryData?.total_pages,
        totalCount: summaryData?.count,
        toNextPage: summaryData?.next,
      })
    }
  }, [summaryData])

  useEffect(() => {
    if (isTransformingData) {
      setIsTransformingData(false)
    }
  }, [serverPaginationResult.rowData])

  const columns = [
    {
      name: 'Category',
      fieldName: 'category',
      width: 100,
    },
    {
      name: 'Sub Category',
      fieldName: 'sub_category',
      component: ({value}) => renderCellComponent(value),
      width: 110,
    },
    {
      name: 'Forms To Be Signed',
      fieldName: 'forms_to_be_signed',
      component: ({value}) => renderCellComponent(value),
      width: 150,
    },
    {
      name: 'Internal Approval(*)',
      fieldName: 'internal_approval',
      component: ({value}) => renderCellComponent(value),
      width: 150,
    },
    {
      name: 'Principal',
      fieldName: 'principal',
      component: ({value}) => renderCellComponent(value),
      width: 100,
    },
    {
      name: 'Agent',
      fieldName: 'agent',
      component: ({value}) => renderCellComponent(value),
      width: 100,
    },
    {
      name: 'Remarks',
      fieldName: 'remarks',
      component: ({value}) => renderCellComponent(value),
      width: 100,
    },
  ]

  /**
   * To download file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleDownloadFile = useMutation((id) => downloadAttachment({id}), {
    onSuccess: () => {
      setSnackBar({
        open: true,
        type: 'success',
        message: 'Attachment downloaded successfully',
      })
    },
    onError: (err, variables, context) => {
      setSnackBar((prevState) => ({...prevState, open: true}))
    },
  })

  /**
   * To preview file
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handlePreviewFile = useMutation(({id, name}) => previewAttachment({id}), {
    onSuccess: (result, {name}) => {
      setFilePreviewDialog({
        value: result,
        name: name,
        open: true,
      })
    },
  })

  /**
   * To export excel file
   */
  const handleExportRecords = () => {
    if (serverPaginationResult.rowData.length === 0) {
      setSnackBar((prevState) => ({
        ...prevState,
        open: true,
        message: 'No data to export!',
      }))
      return
    }

    exportPOASummary(filterQuery).then((response) => {
      setSnackBar((prevState) => ({
        ...prevState,
        open: true,
        type: 'success',
        message: 'Excel download successfully!',
      }))
    })
  }

  /**
   * To handle email notification
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleNotifyUserEmail = useMutation(
    (emails) => notifyEmail({emails, filterQuery}),
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          type: 'success',
          message: 'Sent email notification to user successfully',
        })
        setEmailNotifyUserList({
          toNotify: [],
          isNotified: true,
          notified: [...emailNotifyUserList.notified, ...emailNotifyUserList.toNotify],
        })
      },
      onError: () => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        setIsCallingApi({
          loading: false,
          type: null,
        })
      },
    }
  )
  if (isSupportDocLoading || isCountryLoading || isSegmentLoading || isEntitiesLoading) {
    return <Loading open={true} />
  }
  return (
    <>
      <FilePreviewDialog
        open={filePreviewDialog.open}
        value={{preview: filePreviewDialog.value, name: filePreviewDialog.name}}
        onClose={() =>
          setFilePreviewDialog({
            value: null,
            open: false,
          })
        }
      />
      <FullScreenDialog
        title={
          openFullScreenDialog.type === 'supportingDocs'
            ? `Supporting docs`
            : `Email and Notify User to view Country [${
                filterQuery.country?.country_id
              }] POA for Segment [${filterQuery.segment?.value}] and Entity [${
                filterQuery.entity?.entity_code
              }] ${filterQuery.approvedDate ? `on ${filterQuery.approvedDate}` : ''}`
        }
        open={openFullScreenDialog.open}
        handleClose={() => {
          if (isCallingApi.loading && isCallingApi.type === 'notify') {
            setSnackBar({
              open: true,
              type: 'info',
              message: 'Notifying user in progress, unable to close',
            })
            return
          }
          setOpenFullScreenDialog({open: false, type: null})
          setEmailNotifyUserList({
            toNotify: [],
            notified: [],
            isNotified: false,
          })
        }}
      >
        {openFullScreenDialog.type === 'supportingDocs' && (
          <FileUpload
            disabled={true}
            columns={[
              {
                name: 'File Name',
                fieldName: 'attachment_filename',
              },
              {
                name: 'Upload By',
                fieldName: 'created_by',
              },
              {
                name: 'Date',
                fieldName: 'creation_date_format',
              },
            ]}
            formDetails={supportDocs}
            handleFilePreview={(index) => {
              const file = supportDocs[index]
              handlePreviewFile.mutate({
                id: file.attachment_id,
                name: file.attachment_filename,
              })
            }}
            handleFileDownload={(index) =>
              handleDownloadFile.mutate(supportDocs[index].attachment_id)
            }
            handleClose={() => setOpenFullScreenDialog({open: false, type: null})}
            open={true}
            hideDeleteButton={false}
          />
        )}
        {openFullScreenDialog.type === 'notify' && (
          <EmailNotify
            emailNotifyUserList={emailNotifyUserList}
            setEmailNotifyUserList={setEmailNotifyUserList}
            handleNotifyUserEmail={(emails) => {
              setIsCallingApi({
                loading: true,
                type: 'notify',
              })
              handleNotifyUserEmail.mutate(emails)
            }}
            isCallingApi={isCallingApi.type === 'notify'}
          />
        )}
      </FullScreenDialog>
      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid
            container
            direction={'row'}
            columnSpacing={5}
            justifyContent={'space-between'}
            rowSpacing={2}
          >
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Autocomplete
                disabled={country}
                options={countries ?? []}
                getOptionLabel={(option) => option.country_id || ''}
                handleOnChange={(value) => {
                  setFilterQuery((prevState) => ({
                    ...prevState,
                    country: value,
                  }))
                }}
                value={filterQuery.country}
                label={'Country'}
                textFieldProps={{
                  placeholder: 'Please select country',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Autocomplete
                disabled={segment}
                options={segments ?? []}
                getOptionLabel={(option) => option.text || ''}
                handleOnChange={(value) => {
                  setFilterQuery((prevState) => ({
                    ...prevState,
                    segment: value,
                  }))
                }}
                value={filterQuery.segment}
                label={'Segment'}
                textFieldProps={{
                  placeholder: 'Please select segment',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Autocomplete
                disabled={entity}
                options={entities ?? []}
                getOptionLabel={(option) => {
                  return `${option.entity_name} (${option.entity_code})`
                }}
                handleOnChange={(value) => {
                  setFilterQuery((prevState) => ({
                    ...prevState,
                    entity: value,
                    approvedDate: null,
                  }))
                  setPendingApprovalRequestId(value?.pending_poa_request_id)
                }}
                value={filterQuery.entity}
                label={'Entity'}
                textFieldProps={{
                  placeholder: 'Please select entity',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <ApprovedDateTimePicker
                loading={isApprovedDateLoading}
                approvedDates={approvedDates}
                approvedDate={filterQuery.approvedDate}
                setApprovedDate={(date) => {
                  setFilterQuery((prevState) => ({
                    ...prevState,
                    approvedDate: date,
                  }))
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Stack
                direction={'row'}
                spacing={2}
                alignItems={'center'}
                justifyContent={'end'}
              >
                {functionAccess === HQ_ROLE_ID && (
                  <>
                    <Button
                      sx={{mt: '8px', mb: '8px'}}
                      label={'Request in progress'}
                      type={'danger'}
                      disabled={
                        !filterQuery.entity?.entity_id ||
                        !filterQuery.country?.country_id ||
                        !filterQuery.segment?.value ||
                        !pendingApprovalRequestId
                      }
                      onClick={() => {
                        navigate(
                          `/Poa/Edit/${filterQuery.country.country_id}/${filterQuery.segment.value}/${filterQuery.entity?.entity_id}/${pendingApprovalRequestId}`
                        )
                      }}
                    />

                    <IconButton
                      title={`${
                        !filterQuery.entity?.entity_id ||
                        !filterQuery.country?.country_id ||
                        !filterQuery.segment?.value
                          ? 'Please select country, segment and entity to edit'
                          : 'Edit'
                      }`}
                      disabled={
                        !filterQuery.entity?.entity_id ||
                        !filterQuery.country?.country_id ||
                        !filterQuery.segment?.value ||
                        !!pendingApprovalRequestId
                      }
                      handleOnClick={() =>
                        navigate(
                          `/Poa/Create/${filterQuery.country.country_id}/${filterQuery.segment.value}/${filterQuery.entity?.entity_id}`
                        )
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
                <IconButton
                  title={'More'}
                  handleOnClick={(event) => setOpenMore(event.currentTarget)}
                >
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
                <IconButton
                  title={'Settings'}
                  handleOnClick={(event) => setOpenSettings(event.currentTarget)}
                >
                  <SettingsIcon fontSize="small" />
                </IconButton>
                <Menu
                  style={{marginTop: '40px'}}
                  anchorEl={openSettings}
                  anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  keepMounted
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  open={!!openSettings}
                  onClose={() => setOpenSettings(null)}
                >
                  <MenuItem
                    onClick={() => {
                      handleExportRecords()
                    }}
                  >
                    <Typography variant="overline">Export</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      if (
                        !filterQuery.entity?.entity_id ||
                        !filterQuery.country?.country_id ||
                        !filterQuery.segment?.value
                      ) {
                        setSnackBar((prevState) => ({
                          ...prevState,
                          open: true,
                          message:
                            'Please select a country, segment and entity to proceed',
                        }))

                        return
                      }

                      setOpenFullScreenDialog({
                        open: true,
                        type: 'notify',
                      })
                    }}
                  >
                    <Typography variant="overline">Email / Notify</Typography>
                  </MenuItem>
                </Menu>
                <Menu
                  style={{marginTop: '40px'}}
                  anchorEl={openMore}
                  anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  keepMounted
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  open={!!openMore}
                  onClose={() => setOpenMore(null)}
                >
                  <MenuItem
                    onClick={() => {
                      if (!filterQuery.entity?.entity_id || !filterQuery.approvedDate) {
                        setSnackBar((prevState) => ({
                          ...prevState,
                          open: true,
                          message: 'Please select entity & approved date first',
                        }))

                        return
                      }

                      setOpenFullScreenDialog({
                        open: true,
                        type: 'supportingDocs',
                      })
                    }}
                  >
                    <Typography variant="overline">Supporting Doc</Typography>
                  </MenuItem>
                </Menu>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <POATable
            isLoading={isSummaryLoading || isTransformingData}
            columns={columns}
            serverPaginationResults={serverPaginationResult}
            country={filterQuery.country}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default POA
