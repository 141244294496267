import React from 'react'
import {Stack, Tooltip} from '@mui/material'
import {DataIllustration} from 'finsys-webcomponent'

export const formatValueWithEmptyPToBr = (value) => {
  return value?.replace(/(<p><\/p>)+|\n/g, '<br/>')
}

/**
 * To render table header
 * @return {JSX.Element}
 */
export const TableFixHeader = ({columns}) => {
  return (
    <thead>
      <tr>
        {columns.map(({fieldName, name, width}) => {
          return (
            <th key={`${fieldName}-header`} {...(width && {width: width})}>
              {name}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

function areEqual(prevProps, nextProps) {
  const keys1 = Object.keys(prevProps)
  const keys2 = Object.keys(nextProps)
  if (keys1.length !== keys2.length) {
    return false
  }
  return true
}

/**
 * To prevent header from being re-rendered
 * Header will always be the same - the columns wont change
 * @type {React.NamedExoticComponent<{readonly columns?: *}>}
 */
export const MemoizedTableFixHeader = React.memo(TableFixHeader, areEqual)

/**
 * To render table row
 * @return {JSX.Element}
 */
export const BAMTableRow = ({
  columns,
  editableHeaderColumns,
  rows,
  type,
  editable,
  toEditCell,
  selectedCell,
  handleOnMouseDown,
}) => {
  return (
    <tbody>
      {Object.keys(rows).map((keyName) => {
        const value = rows[keyName]
        const matrixAndThresholdType = value.request_type
        return (
          <React.Fragment key={`${keyName}-cell-wrapper`}>
            {editableHeaderColumns.map(
              ({name, fieldName, component: Component, menu: Menu}, index) => {
                return (
                  <tr
                    key={`${keyName}-${fieldName}-sub-header`}
                    className={`editableCell ${type}`}
                  >
                    {type === 'edit' && index === 0 && (
                      <td
                        rowSpan={2}
                        className={`${
                          matrixAndThresholdType === 'delete' && 'delete-cell'
                        } ${matrixAndThresholdType === 'add' && 'add-cell'}`}
                      >
                        {editable
                          ? Menu({entityId: keyName})
                          : matrixAndThresholdType === 'edit'
                          ? ''
                          : matrixAndThresholdType?.toUpperCase()}
                      </td>
                    )}
                    <th className={`editableCell-header ${type} `}>{name}</th>
                    {index === 0 && (
                      <td
                        className={`fixed-cell ${
                          matrixAndThresholdType === 'delete' && 'disabled-cell'
                        }`}
                        rowSpan={value.data.length + 2}
                      >
                        {value.name}
                      </td>
                    )}

                    {['authorized_person', 'manual_payments', 'system_admin'].map(
                      (editableFieldName) => {
                        // entity - sub header - fieldName
                        const selector = `${keyName}-${fieldName}-${editableFieldName}`

                        let props = {
                          key: `${keyName}-${fieldName}-${editableFieldName}`,
                          selector: selector,
                          entityId: keyName,
                          fieldName: editableFieldName,
                          disabled: toEditCell ? !(toEditCell === selector) : true,
                          value: value[fieldName]?.[editableFieldName],
                        }

                        // we remove the HTML tag and compare value only
                        let previousFieldValue = formatCellValue(
                          value[fieldName]?.[`previous_${editableFieldName}`] ?? ''
                        )

                        let currentFieldValue = formatCellValue(
                          value[fieldName]?.[editableFieldName] ?? ''
                        )

                        const isModifiedCell =
                          type === 'edit' &&
                          (value.request_type === 'add'
                            ? false
                            : (previousFieldValue || currentFieldValue) &&
                              previousFieldValue.localeCompare(currentFieldValue) !== 0)

                        const className = `${isModifiedCell && 'modified-cell'} ${
                          selectedCell?.includes(selector) ? 'cell-focused' : ''
                        }`
                        return (
                          <MemoizedHeaderTableRow
                            {...props}
                            isModifiedCell={isModifiedCell}
                            row={value[fieldName]}
                            fieldName={editableFieldName}
                            selector={selector}
                            keyName={`${fieldName}-header-${keyName}-${editableFieldName}`}
                            editable={editable}
                            matrixAndThresholdType={matrixAndThresholdType}
                            handleOnMouseDown={handleOnMouseDown}
                            className={className}
                            Component={
                              Component
                                ? Component({...props})
                                : value[fieldName]?.[editableFieldName]
                            }
                          />
                        )
                      }
                    )}

                    {type === 'statusUpdate' && index === 0 && (
                      <td style={{background: '#9f9f9f'}} rowSpan={2} colSpan={2} />
                    )}
                  </tr>
                )
              }
            )}

            {value.data.map((row, index) => {
              const id = index
              return (
                <tr key={`${keyName}-${id}-row`}>
                  {columns.map(({component: Component, fieldName}) => {
                    if (fieldName === 'entity_name' || row[`${fieldName}RowSpan`] === 0) {
                      return null
                    }

                    const key = `${keyName}-${fieldName}-${id}`

                    // entity - index - fieldname - rowspan
                    const selector = `${keyName}-${index}-${fieldName}-${
                      row[`${fieldName}RowSpan`]
                    }`

                    let props = {
                      key: `${key}-cell`,
                      selector: selector,
                      dataIndex: index,
                      entityId: row.entity_id,
                      rowSpanName: `${fieldName}RowSpan`,
                      fieldName: fieldName,
                      disabled: toEditCell ? !(toEditCell === selector) : true,
                    }

                    if (fieldName && fieldName === 'bank_description') {
                      props = {...props, value: row}
                    } else {
                      props = {...props, value: row[fieldName]}
                    }

                    // we remove the HTML tag and compare value only
                    const previousFieldValue = formatCellValue(
                      row[`previous_${fieldName}`] ?? ''
                    )

                    const currentFieldValue = formatCellValue(row[fieldName] ?? '')

                    const isModifiedCell =
                      type === 'edit' &&
                      (row.request_type === 'add'
                        ? false
                        : (currentFieldValue || previousFieldValue) &&
                          previousFieldValue.localeCompare(currentFieldValue) !== 0)

                    const className = `${isModifiedCell && 'modified-cell'} ${
                      fieldName === 'tableMenu' && row.request_type === 'delete'
                        ? 'delete-cell'
                        : fieldName === 'tableMenu' &&
                          row.request_type === 'add' &&
                          'add-cell'
                    } ${
                      row.request_type === 'delete' &&
                      fieldName !== 'tableMenu' &&
                      ' disabled-cell'
                    } ${selectedCell?.includes(selector) ? 'cell-focused' : ''}`
                    return (
                      <MemoizedTableCell
                        {...props}
                        keyValue={key}
                        isModifiedCell={isModifiedCell}
                        row={row}
                        fieldName={fieldName}
                        selector={selector}
                        className={className}
                        editable={editable}
                        selectedCell={selectedCell}
                        Component={Component ? Component({...props}) : row[fieldName]}
                        handleOnMouseDown={handleOnMouseDown}
                      />
                    )
                  })}
                </tr>
              )
            })}
          </React.Fragment>
        )
      })}
    </tbody>
  )
}

/**
 * To render table row
 * @return {JSX.Element}
 */
export const POATableRow = ({
  columns,
  rows,
  type,
  editable,
  toEditCell,
  selectedCell,
  handleEntityMenu,
  handleOnMouseDown,
}) => {
  return (
    <tbody>
      {Object.keys(rows).map((keyName) => {
        const value = rows[keyName]
        const matrixAndThresholdType = value.request_type
        return (
          <React.Fragment key={`${keyName}-cell-wrapper`}>
            <tr
              key={`${keyName}-${value.name}-sub-header`}
              className={`editableCell ${type}`}
            >
              {handleEntityMenu && (
                <td
                  className={`fixed-cell ${
                    matrixAndThresholdType === 'delete' && 'disabled-cell'
                  }`}
                >
                  {editable && handleEntityMenu()}
                </td>
              )}

              <td
                className={`fixed-cell ${
                  matrixAndThresholdType === 'delete' && 'disabled-cell'
                }`}
                colSpan={handleEntityMenu ? 7 : 8}
              >
                {value.name}
              </td>
            </tr>
            {value.data.map((row, index) => {
              const id = index
              return (
                <tr key={`${keyName}-${id}-row`}>
                  {columns.map(({component: Component, fieldName}) => {
                    if (row[`${fieldName}RowSpan`] === 0) {
                      return null
                    }

                    const key = `${keyName}-${fieldName}-${id}`

                    // entity - index - fieldname - rowspan
                    const selector = `${keyName}-${index}-${fieldName}-${
                      row[`${fieldName}RowSpan`]
                    }`

                    let props = {
                      key: `${key}-cell`,
                      selector: selector,
                      dataIndex: index,
                      entityId: row.entity_id,
                      rowSpanName: `${fieldName}RowSpan`,
                      fieldName: fieldName,
                      disabled: toEditCell ? !(toEditCell === selector) : true,
                    }

                    if (fieldName && fieldName === 'category') {
                      props = {...props, value: row}
                    } else {
                      props = {...props, value: row[fieldName]}
                    }

                    // we remove the HTML tag and compare value only
                    const previousFieldValue = formatCellValue(
                      row[`previous_${fieldName}`] ?? ''
                    )

                    const currentFieldValue = formatCellValue(row[fieldName] ?? '')

                    const isModifiedCell =
                      type === 'edit' &&
                      (row.request_type === 'add'
                        ? false
                        : (currentFieldValue || previousFieldValue) &&
                          previousFieldValue.localeCompare(currentFieldValue) !== 0)

                    const className = `${isModifiedCell && 'modified-cell'} ${
                      fieldName === 'tableMenu' && row.request_type === 'delete'
                        ? 'delete-cell'
                        : fieldName === 'tableMenu' &&
                          row.request_type === 'add' &&
                          'add-cell'
                    } ${
                      row.request_type === 'delete' &&
                      fieldName !== 'tableMenu' &&
                      ' disabled-cell'
                    } ${selectedCell?.includes(selector) ? 'cell-focused' : ''}`
                    return (
                      <MemoizedTableCell
                        {...props}
                        keyValue={key}
                        isModifiedCell={isModifiedCell}
                        row={row}
                        fieldName={fieldName}
                        selector={selector}
                        className={className}
                        editable={editable}
                        selectedCell={selectedCell}
                        Component={Component ? Component({...props}) : row[fieldName]}
                        handleOnMouseDown={handleOnMouseDown}
                      />
                    )
                  })}
                </tr>
              )
            })}
          </React.Fragment>
        )
      })}
    </tbody>
  )
}

const HeaderTableCell = ({
  isModifiedCell,
  row,
  fieldName,
  selector,
  keyName,
  editable,
  matrixAndThresholdType,
  handleOnMouseDown,
  className,
  Component,
}) => {
  return (
    <Tooltip
      arrow
      title={
        isModifiedCell ? (
          <>
            <h4>Previous Value:</h4>
            <div
              className={'wrap-text'}
              dangerouslySetInnerHTML={{
                __html: row[`previous_${fieldName}`],
              }}
            />
          </>
        ) : (
          ''
        )
      }
      placement="top-start"
    >
      <td
        id={selector}
        key={keyName}
        className={className}
        onMouseDown={(event) => {
          // if its not editable we dont need to invoke on mouse down function
          if (!editable || matrixAndThresholdType === 'delete') {
            return
          }

          handleOnMouseDown(event, event.target.closest('td').id)
        }}
      >
        {Component ? Component : row[fieldName]}
      </td>
    </Tooltip>
  )
}

/**
 * To prevent header from being re-rendered
 * Header will always be the same - the columns wont change
 * @type {React.NamedExoticComponent<{readonly columns?: *}>}
 */
export const MemoizedHeaderTableRow = React.memo(
  HeaderTableCell,
  shouldWeReRenderedHeaderCell
)

function shouldWeReRenderedHeaderCell(prevValue, currentValue) {
  if (prevValue.value !== currentValue.value) {
    return false
  }

  if (prevValue.className !== currentValue.className) {
    return false
  }

  if (prevValue.disabled !== currentValue.disabled) {
    return false
  }

  if (prevValue.isModifiedCell !== currentValue.isModifiedCell) {
    return false
  }

  if (prevValue.editable !== currentValue.editable) {
    return false
  }

  return true
}

/**
 * To prevent header from being re-rendered
 * Header will always be the same - the columns wont change
 * @type {React.NamedExoticComponent<{readonly columns?: *}>}
 */
export const MemoizedTableRow = React.memo(BAMTableRow, () => false)

/**
 * To prevent header from being re-rendered
 * Header will always be the same - the columns wont change
 * @type {React.NamedExoticComponent<{readonly columns?: *}>}
 */
export const MemoizedPOATableRow = React.memo(POATableRow, () => false)

const TableCell = ({
  keyValue,
  isModifiedCell,
  row,
  fieldName,
  selector,
  className,
  editable,
  Component,
  handleOnMouseDown,
  selectedCell,
}) => {
  const key = keyValue

  return (
    <Tooltip
      key={`${key}-tooltip`}
      arrow
      title={
        isModifiedCell ? (
          <>
            <h4>Previous Value:</h4>
            <div
              className={'wrap-text'}
              dangerouslySetInnerHTML={{
                __html: row[`previous_${fieldName}`],
              }}
            />
          </>
        ) : (
          ''
        )
      }
      placement="top-start"
    >
      <td
        key={`${key}-cell`}
        id={selector}
        rowSpan={row[`${fieldName}RowSpan`]}
        className={className}
        onMouseDown={(event) => {
          // if its delete we dont do anything
          if (fieldName === 'tableMenu' || ['delete'].includes(row.request_type)) {
            return
          }

          // if its not editable we dont need to invoke on mouse down function
          if (!editable) {
            return
          }

          // if its select we dont do anything
          if (event.target.classList.contains('MuiSelect-root')) {
            return
          }

          if (event.target.closest('td')?.id) {
            handleOnMouseDown(event, event.target.closest('td').id, selectedCell)
          }
        }}
      >
        {fieldName === 'tableMenu' && !editable
          ? row.request_type === 'edit'
            ? ''
            : row.request_type?.toUpperCase()
          : Component}
      </td>
    </Tooltip>
  )
}

function shouldWeReRenderedCell(prevValue, currentValue) {
  if (
    currentValue.fieldName === 'bank_description' ||
    currentValue.fieldName === 'category'
  ) {
    if (currentValue.Component !== prevValue.Component) {
      return false
    }
  }

  if (prevValue.value !== currentValue.value) {
    return false
  }

  if (
    currentValue.fieldName !== 'tableMenu' &&
    (prevValue.selector !== currentValue.selector ||
      prevValue.row[`${prevValue.fieldName}RowSpanIndex`] !==
        currentValue.row[`${currentValue.fieldName}RowSpanIndex`])
  ) {
    return false
  }

  if (prevValue.className !== currentValue.className) {
    return false
  }

  if (prevValue.disabled !== currentValue.disabled) {
    return false
  }

  if (prevValue.isModifiedCell !== currentValue.isModifiedCell) {
    return false
  }

  if (prevValue.editable !== currentValue.editable) {
    return false
  }

  return true
}
/**
 * To prevent header from being re-rendered
 * Header will always be the same - the columns wont change
 * @type {React.NamedExoticComponent<{readonly columns?: *}>}
 */
export const MemoizedTableCell = React.memo(TableCell, shouldWeReRenderedCell)

/**
 * To remove html value
 * @param value
 * @return {*}
 */
export const formatCellValue = (value) => {
  if (typeof value === 'number') {
    return value
  }
  let formatValue = value
  //remove tags
  formatValue = formatValue?.replaceAll(/(<([^>]+)>)|\r?\n|\r/gi, '')
  //remove <
  formatValue = formatValue?.replaceAll('<', '&lt;')
  formatValue = formatValue?.replaceAll('>', '&gt;')
  //remove &
  formatValue = formatValue?.replaceAll('&', '&amp;')
  //remove spaces
  formatValue = formatValue?.replaceAll(/ /g, '')

  if (!formatValue || formatValue === '' || formatValue === null) {
    formatValue = ''
  }

  //remove new line
  return formatValue
}

/**
 * To render cell component
 * @param value
 */
export const renderCellComponent = (value) => {
  return (
    <div
      className={'wrap-text'}
      dangerouslySetInnerHTML={{__html: formatValueWithEmptyPToBr(value)}}
    />
  )
}

/**
 * To render no row to show for tables
 * @param children
 * @return {JSX.Element}
 */
export const NoRowToShow = ({helperText}) => {
  return (
    <Stack sx={{margin: '0 auto', position: 'relative'}} alignItems={'center'}>
      <DataIllustration
        isCaughtUp={false}
        {...(helperText && {helperText: helperText})}
      />
    </Stack>
  )
}
