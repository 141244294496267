import TSApi from 'api/TS/TSApi'

export type fetchBankApprovalMatrixSummaryType = Array<{
  id: number,
  approve_date_format: string,
  authorized_person: string,
  bank_approval_matrix_id?: number,
  bank_code?: string,
  bank_description: string,
  country_id: string,
  entity_id: string,
  entity_name: string,
  escrow_related_online: string,
  header_type: string,
  is_header: string,
  manual_payments: string,
  remarks: string,
  request_detail_id?: number,
  request_id?: number,
  status: string,
  status_update?: string,
  system_admin: string,
  trust_online: string,
  working_capital_online: string,
}>

/**
 * To retrieve the bank approval matrix summary
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchBankApprovalMatrixSummary(
  filter
): fetchBankApprovalMatrixSummaryType {
  let filterQueryString = ''
  if (filter.country) {
    filterQueryString = filterQueryString.concat(
      '&country_id',
      '=',
      filter.country.country_id
    )
  }

  if (filter.approvedDate) {
    filterQueryString = filterQueryString.concat(
      '&approved_date',
      '=',
      filter.approvedDate
    )
  }

  if (filter.entity) {
    filter.entity.map((arrayValue) => {
      filterQueryString = filterQueryString.concat(
        '&entity_id',
        '=',
        arrayValue?.entity_id
      )
    })
  }

  const {data} = await TSApi.get(
    `/approval_matrix_summary/?tz=Asia/Singapore&page=summary${filterQueryString}`
  )

  return data
}

export type supportingDocsType = Array<{
  attachment_filename: string,
  attachment_id: number,
  attachment_type: string,
  created_by: string,
  creation_date_format: string,
  request_id: number,
}>

/**
 * To retrieve the bank approval matrix summary document
 * @return {Promise<DefaultSelectType>}
 */
export async function fetchSupportingDocs({country, approvedDate}): supportingDocsType {
  if (!country || !approvedDate) {
    return []
  }

  const {data} = await TSApi.get(
    `/get_bam_supporting_docs/?tz=Asia/Singapore&country_id=${country}&approved_date=${approvedDate}`
  )

  return data
}

/**
 * To retrieve the bank approval matrix summary
 * @return {Promise<DefaultSelectType>}
 */
export async function exportBankApprovalMatrixSummary(filter) {
  let filterQueryString = ''
  if (filter.country) {
    filterQueryString = filterQueryString.concat(
      '&country_id',
      '=',
      filter.country.country_id
    )
  }

  if (filter.approvedDate) {
    filterQueryString = filterQueryString.concat(
      '&approved_date',
      '=',
      filter.approvedDate
    )
  }

  if (filter.entity) {
    filter.entity.map((arrayValue) => {
      filterQueryString = filterQueryString.concat(
        '&entity_id',
        '=',
        arrayValue?.entity_id
      )
    })
  }

  const response = await TSApi.get(`/export_bam/?tz=Asia/Singapore${filterQueryString}`, {
    responseType: 'blob',
  })

  const fileName = response.headers['content-disposition']
    .split('filename=')[1]
    .replaceAll('"', '')

  const attachmentUrl = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = attachmentUrl
  link.setAttribute('download', fileName) //or any other extension
  document.body.appendChild(link)
  link.click()

  return true
}

/**
 * To send notification to user
 * @param emails
 * @return {Promise<any>}
 */
export async function notifyEmail({emails, filterQuery}) {
  const {data} = await TSApi.put(`bam_notify_email/`, {
    recipients: emails,
    country_id: filterQuery.country.country_id,
    approve_date: filterQuery.approve_date ?? 'ALL',
  })

  return data
}

/**
 * To retrieve bank approval matrix approved date
 * @param emails
 * @return {Promise<any>}
 */
export async function fetchApprovedDate(country): Array {
  if (!country) {
    return []
  }

  const {data} = await TSApi.get(
    `bam_approve_date_list/?country_id=${country.country_id}&tz=Asia/Singapore`
  )
  return data
}
