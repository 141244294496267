import React, {useState, useEffect} from 'react'
import {Stack} from '@mui/material'
import {Button} from 'finsys-webcomponent'
import {useNavigate} from 'react-router-dom'

import {useSnackBar} from 'hoc/SnackbarHandler'
import {Details} from 'components/Metadata/Bank/Details'
import {useCreateBank} from 'components/Metadata/_hooks/useCreateBank'
import {HQ_ROLE_ID, METADATA_PAGE_NAME} from '_helper/_constants'
import {getUserRole} from 'localstorage/UserRole'
import {retrievePageFunctionAccess} from '_helper/_authHelper'

/**
 * Create page for Bank
 * @return {JSX.Element}
 * @constructor
 */
const Create = () => {
  const navigate = useNavigate()
  const {setSnackBar} = useSnackBar()

  const [formDetails, setFormDetails] = useState({
    bank_code: null,
    bank_description: null,
  })
  const [functionAccess, setFunctionAccess] = useState([])

  const createBank = useCreateBank()

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(METADATA_PAGE_NAME, setFunctionAccess)
    }

    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * To redirect non hq user to forbidden page
   */
  useEffect(() => {
    if (getUserRole().role !== HQ_ROLE_ID) {
      navigate('/Forbidden')
    }
  }, [])

  /**
   * To validate form
   */
  const validateForm = () => {
    let isGenericFieldsValid = true

    const requiredFields = ['bank_code', 'bank_description']

    requiredFields.forEach((field) => {
      if (!formDetails[field] || formDetails[field].length === 0) {
        setFormDetails((prevState) => ({
          ...prevState,
          [`error_${field}`]: 'This is a required field',
        }))

        isGenericFieldsValid = false
      }
    })

    if (!isGenericFieldsValid) {
      setSnackBar({
        open: true,
        type: 'error',
        message: `Missing input! Please fill up all the required input`,
      })
    } else {
      createBank.mutate(formDetails)
    }
  }

  return (
    <>
      <Details
        isDisabled={false}
        errors={createBank.errors}
        setFormDetails={setFormDetails}
        formDetails={formDetails}
      />
      <Stack direction="row" spacing={2} sx={{pt: 3, pb: 3}}>
        <Button
          disabled={createBank.isLoading}
          label={'Cancel'}
          type={'text'}
          onClick={() => navigate('/Metadata')}
        />
        {getUserRole().role === HQ_ROLE_ID && (
          <Button
            label={'Create'}
            onClick={() => validateForm()}
            disabled={createBank.isLoading}
            loading={createBank.isLoading}
          />
        )}
      </Stack>
    </>
  )
}

export default Create
