import React from 'react'
import {useNavigate} from 'react-router-dom'

import Template from 'core/Template'

/**
 * To be shown if user is not connected to VPN or any network error
 * @TODO to check again
 * @returns {JSX.Element}
 * @constructor
 */
const NetworkError = () => {
  let navigate = useNavigate()

  return (
    <Template
      title="Error 502"
      content="Server connection could not be established."
      desc={
        <>
          The server may be down or there is a connection problem. <br />
          <br />
          Check your connection (Wifi/ VPN) and try again, if the issue persists, please
          contact us at @ <a href="mailto: finsys-epm@sea.com">finsys-epm@sea.com</a>.
        </>
      }
      tryAgain={() => navigate(-1)}
    />
  )
}

export default NetworkError
