import {useEffect} from 'react'
import {useQuery} from 'react-query'

import {useErrorStatus} from 'hoc/Error/ErrorHandler'

export const useDataFetching = (key, queryFn, opt = {}) => {
  /**
   * We need to init google client before calling any API
   * so that we can have valid auth token or redirect to any error page
   * @type {boolean|*}
   */
  const {setError, setRetry} = useErrorStatus()
  const {
    isIdle,
    isLoading,
    isError,
    error,
    data,
    isPreviousData,
    isFetching,
    refetch,
    isRefetching,
  } = useQuery(key, queryFn, opt)

  useEffect(() => {
    // we only wanna refetch if we dont have any data
    if (!data && !isIdle) {
      refetch()
    }
  }, [data])

  if (isError) {
    setError(error ?? 'not-connected-to-vpn')
    setRetry('ji')
  }

  return {
    isIdle,
    loading: isLoading,
    isError,
    error,
    data,
    isPreviousData,
    isRefetching,
    isFetching,
  }
}

export default useDataFetching
