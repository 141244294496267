import React, {useEffect, useState} from 'react'
import Grid from '@mui/material/Grid'
import {Link, useNavigate} from 'react-router-dom'
import {MenuCard} from 'finsys-webcomponent'

import {FORMATTED_APP_NAME, menuList} from '_helper/_constants.js'
import {getAccess} from '_helper/_authHelper'

/**
 * Default Home page from FinSys-react-boiler-plate
 * @return {JSX.Element}
 * @constructor
 */
const Home = () => {
  const [pages, setPages] = useState([])
  const navigate = useNavigate()
  // we need to handle permission from page level
  useEffect(() => {
    // for permission
    const access = getAccess()

    if (access) {
      const pageList = menuList.filter((key, value) => {
        return access[FORMATTED_APP_NAME][key.pageName]
      })

      setPages(pageList)
    } else {
      navigate('/Forbidden')
    }
  }, [])

  const renderCardContent = ({name, desc, backgroundColor, cardSrc}) => {
    return (
      <MenuCard name={name} desc={desc} backgroundColor={backgroundColor} src={cardSrc} />
    )
  }

  return (
    <Grid container justifyContent={'flex-start'} spacing={6}>
      {pages.map(({name, desc, backgroundColor, cardSrc, link, redirect}, index) => (
        <Grid item xs={12} sm={12} md={6} lg={3} sx={{textAlign: 'center'}} key={index}>
          {redirect ? (
            <a
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              href={link}
              target={'_blank'}
              rel="noreferrer"
            >
              {renderCardContent({name, desc, backgroundColor, cardSrc})}
            </a>
          ) : (
            <Link to={link} style={{textDecoration: 'unset'}}>
              {renderCardContent({name, desc, backgroundColor, cardSrc})}
            </Link>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default Home
