import React from 'react'
import {Grid} from '@mui/material'

import MultipleSelect from 'components/Metadata/Summary/Entity/FilterOptions/MultipleSelect'

type FilterOptionsProps = {
  filterCondition: {
    temp: {
      currencies: [],
      segments: [],
      countries: [],
    },
    actual: {
      currency: [],
      segment: [],
      country: [],
    },
  },
  openFullScreenDialog: boolean,
  handleClose(): () => void,
  handleSetFilterCondition(): () => void,
  handleApplyFilter(): () => void,
  handleClearAllFilter(): () => void,
}
export const EntityFilterView = ({
  filterCondition,
  filtersData,
  setFilterCondition,
}: FilterOptionsProps) => {
  return (
    <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <MultipleSelect
          isAll={true}
          selections={[
            {
              country_id: 'All',
              country_name: 'All',
            },
            ...filtersData.countries,
          ]}
          limitTags={5}
          label="Select Countries"
          onChange={(event, value) => {
            if (value[value.length - 1]?.country_id === 'All') {
              setFilterCondition((prevState) => {
                return {
                  ...prevState,
                  temp: {
                    ...prevState.temp,
                    countries:
                      filterCondition.countries.length === filtersData.countries.length
                        ? []
                        : filtersData.countries,
                  },
                }
              })
            } else {
              setFilterCondition((prevState) => {
                return {
                  ...prevState,
                  temp: {
                    ...prevState.temp,
                    countries: value,
                  },
                }
              })
            }
          }}
          value={filterCondition.countries}
          getOptionLabel={(option) => {
            return `${option.country_name} (${option.country_id})`
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <MultipleSelect
          isAll={true}
          selections={[
            {
              segment_id: 'All',
              segment_name: 'All',
            },
            ...filtersData.segments,
          ]}
          limitTags={5}
          label="Select Segment"
          onChange={(event, value) => {
            if (value[value.length - 1]?.segment_id === 'All') {
              setFilterCondition((prevState) => {
                return {
                  ...prevState,
                  temp: {
                    ...prevState.temp,
                    segments:
                      filterCondition.segments.length === filtersData.segments.length
                        ? []
                        : filtersData.segments,
                  },
                }
              })
            } else {
              setFilterCondition((prevState) => {
                return {
                  ...prevState,
                  temp: {
                    ...prevState.temp,
                    segments: value,
                  },
                }
              })
            }
          }}
          value={filterCondition.segments}
          getOptionLabel={(option) => {
            return `${option.segment_name}`
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <MultipleSelect
          isAll={true}
          selections={[
            {
              currency_code: 'All',
              currency_description: 'All',
            },
            ...filtersData.currencies,
          ]}
          limitTags={5}
          label="Select Currencies"
          onChange={(event, value) => {
            if (value[value.length - 1]?.currency_code === 'All') {
              setFilterCondition((prevState) => {
                return {
                  ...prevState,
                  temp: {
                    ...prevState.temp,
                    currencies:
                      filterCondition.currencies.length === filtersData.currencies.length
                        ? []
                        : filtersData.currencies,
                  },
                }
              })
            } else {
              setFilterCondition((prevState) => {
                return {
                  ...prevState,
                  temp: {
                    ...prevState.temp,
                    currencies: value,
                  },
                }
              })
            }
          }}
          value={filterCondition.currencies}
          getOptionLabel={(option) => {
            return `${option.currency_description}`
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EntityFilterView
