import {useState} from 'react'
import {useMutation, UseMutateFunction} from 'react-query'

import AIApi from 'api/TS/TSApi'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {queryClient} from 'hoc/ReactQuery'
import {queryKeys} from 'hoc/ReactQuery/_constants'
import {FormErrorPayload, FormSuccessPayload} from '_helper/_types'
import {_getError} from '_helper/_getError'

export type BankPayload = {
  ...FormSuccessPayload,
}

/**
 * To save obm employee
 * @return {Promise<any>}
 */
export async function saveObmEmployee(
  obmEmployee,
  formDetails
): BankPayload | FormErrorPayload {
  const {data} = await AIApi.put(`obm_employee_details/`, {
    email: obmEmployee.email,
    fullname: obmEmployee.fullname,
    updated_email:
      obmEmployee.email === formDetails.email ? undefined : formDetails.email,
    updated_fullname:
      obmEmployee.fullname === formDetails.fullname ? undefined : formDetails.fullname,
    designation: formDetails?.updated_designation?.value,
  })

  return data
}

interface UseSaveObmEmployee {
  mutate: UseMutateFunction;
  isLoading: boolean;
  errors: [];
}

export function useSaveObmEmployee(): UseSaveObmEmployee {
  const {setSnackBar} = useSnackBar()
  const [errors, setErrors] = useState(null)
  const [updatedEmail, setUpdatedEmail] = useState(null)
  const {mutate, isLoading} = useMutation(
    ({obmEmployee, formDetails}) => saveObmEmployee(obmEmployee, formDetails),
    {
      onSuccess: (result: BankPayload | FormErrorPayload, {obmEmployee, formDetails}) => {
        setSnackBar({
          open: true,
          type: result.status === 'SUCCESS' ? 'success' : 'error',
          message:
            result.status === 'SUCCESS'
              ? result.message
              : 'Validation failed! Please check those fields that are in red.',
        })

        if (result.status === 'SUCCESS') {
          queryClient.invalidateQueries([queryKeys.obmEmployee, result.email])

          if (formDetails.email !== obmEmployee.email) {
            setUpdatedEmail(formDetails.email)
          }

          if (errors) {
            setErrors(null)
          }
        } else {
          setErrors(_getError(result.errors))
        }
      },
    }
  )

  return {mutate, isLoading, errors, updatedEmail}
}
