import React from 'react'
import {Stack, Typography} from '@mui/material'
import {
  CloudDownload as CloudDownloadIcon,
  DeleteForever as DeleteForeverIcon,
  Preview as PreviewIcon,
} from '@mui/icons-material'
import {red} from '@mui/material/colors'
import {blue, FormTable, IconButton} from 'finsys-webcomponent'

import OutlineInput from 'components/OutlineInput'

/**
 * Standardized File Table
 */
const FileTable = ({
  handleFileDownload,
  handleFilePreview,
  handleFileDelete,
  data,
  setFileUpload,
  hideDeleteButton = false,
  hideDeleteButtonValue,
  hideDeleteButtonField,
  disabled,
  setFormDetails,
  categoryType,
  ...props
}) => {
  return (
    <FormTable
      tableKey={'attachment-file-upload'}
      columns={[
        {
          name: 'File',
          width: '20%',
          field: 'file',
          component: ({value}) => value.attachment_filename,
        },
        {
          name: 'Document Name*',
          width: '20%',
          field: 'docName',
          component: ({value, dataIndex, error}) => (
            <OutlineInput
              disabled={disabled}
              style={{fontSize: '0.9em'}}
              minRows={1}
              maxRows={2}
              error={!!error}
              value={value ?? ''}
              onChange={(value) => {
                setFormDetails((prevState) => {
                  const newAttachments = [...prevState.ticket_attachment]
                  newAttachments[dataIndex].docName = value.target.value
                  newAttachments[dataIndex].error_docName = undefined

                  return {
                    ...prevState,
                    ticketAttachment: newAttachments,
                    error_ticketAttachment: undefined,
                  }
                })
              }}
            />
          ),
        },
        {
          name: `Purpose  ${categoryType === 'Document Signing Request' ? '*' : ''}`,
          field: 'purpose',
          width: '30%',
          component: ({value, dataIndex, error}) => (
            <OutlineInput
              disabled={disabled}
              style={{fontSize: '0.9em'}}
              error={!!error}
              multiline
              minRows={1}
              maxRows={2}
              value={value ?? ''}
              onChange={(value) => {
                setFormDetails((prevState) => {
                  const newAttachments = [...prevState.ticket_attachment]
                  newAttachments[dataIndex].purpose = value.target.value
                  newAttachments[dataIndex].error_purpose = undefined

                  return {
                    ...prevState,
                    ticket_attachment: newAttachments,
                    error_ticket_attachment: undefined,
                  }
                })
              }}
            />
          ),
        },
        {
          name: 'Action',
          field: 'action',
          width: '20%',
          // @ts-ignore
          component: ({dataIndex}) => (
            <Stack
              direction={{xs: 'column', sm: 'row', md: 'row'}}
              justifyContent="left"
              alignItems="left"
              spacing={1}
            >
              {/*@ts-ignore*/}
              <IconButton
                title={'Download'}
                handleOnClick={() => handleFileDownload(dataIndex)}
              >
                <CloudDownloadIcon sx={{color: blue.primary}} fontSize={'small'} />
              </IconButton>
              {/*@ts-ignore*/}
              <IconButton
                title={'Preview'}
                handleOnClick={() => handleFilePreview(dataIndex)}
              >
                <PreviewIcon sx={{color: blue.primary}} fontSize={'small'} />
              </IconButton>
              {(!hideDeleteButton ||
                (hideDeleteButtonValue &&
                  hideDeleteButtonField &&
                  data[dataIndex][hideDeleteButtonField] === hideDeleteButtonValue)) && (
                // @ts-ignore
                <IconButton
                  title={'Delete'}
                  handleOnClick={() => handleFileDelete(dataIndex)}
                >
                  <DeleteForeverIcon sx={{color: red[300]}} fontSize={'small'} />
                </IconButton>
              )}
            </Stack>
          ),
        },
      ]}
      data={data}
      noDataLabel={
        disabled ? (
          'No File'
        ) : (
          <Typography
            color={'secondary'}
            sx={{cursor: 'pointer'}}
            // @ts-ignore
            onClick={setFileUpload}
          >
            No File, click here to upload file now!
          </Typography>
        )
      }
      style={{
        minHeight: '75px',
        maxHeight: '300px',
        overflow: 'auto',
      }}
      {...props}
    />
  )
}

export default FileTable
