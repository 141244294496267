// to format errors to column value
export const _getError = (errors) =>
  errors.reduce((obj, item) => {
    let matches = [...item.field_name.matchAll('\\[[-?\\d+]+\\]')]

    if (matches.length === 1) {
      const column = item.field_name.substring(matches[0].index, -1)

      const newIndex = item.field_name.substring(
        matches[0].index + 1,
        item.field_name.length - 1
      )

      return {
        ...obj,
        [column]: {...obj[column], [newIndex]: item.message},
      }
    }

    // we only have up to 2 dimension
    if (matches.length === 2) {
      const nestedArray = []
      const column = item.field_name.substring(matches[0].index, -1)
      let nestedColumnValue = obj[column] ? {...obj[column]} : {}

      matches.forEach((matchValue) => {
        const index = matchValue[0].match(`-?\\d+`)
        nestedArray.push(parseInt(index[0]))
      })
      nestedColumnValue[nestedArray[0]] = nestedColumnValue?.[nestedArray[0]] ?? {}
      nestedColumnValue[nestedArray[0]][nestedArray[1]] = item.message

      return {
        ...obj,
        [column]: nestedColumnValue,
      }
    }

    return {
      ...obj,
      [item.field_name]: item.message,
    }
  }, {})
