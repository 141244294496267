/**
 * To get the pagination settings for now its only rowPerPage
 * @returns {{email: string, username: string}}
 */

export function getPagination(): {rowPerPage: number} {
  return {
    rowPerPage: localStorage.getItem('rowsPerPage') ?? 10,
  }
}

/**
 * To remove pagination settings
 */
export function removeUserDetails(): void {
  localStorage.removeItem('rowsPerPage')
}
