import React from 'react'
import {Stack, Typography} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const SectionLoading = ({label}) => {
  return (
    <>
      <Typography variant={'h5'} sx={{fontWeight: 600, p: 2}} align={'left'}>
        <Stack alignItems={'center'} direction={'row'}>
          {label} Section is loading <CircularProgress sx={{pl: 1}} />
        </Stack>
      </Typography>
      <Typography variant={'caption'} sx={{p: 2}} align={'left'} gutterBottom>
        Please be patience as it will take a while if it contain alot of entity and
        country.
      </Typography>
    </>
  )
}
