import React, {useEffect, useState} from 'react'
import {Grid, MenuItem, Select, Stack} from '@mui/material'
import {Loading, Button, CustomEditorContent, Editor} from 'finsys-webcomponent'
import {useDispatch} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useMutation} from 'react-query'

import useDataFetching from 'hoc/UseDataFetching'
import {BAMTable} from 'components/Tables/BAMTable'
import {
  fetchBankApprovalMatrixSummary,
  saveStatusUpdate,
} from 'api/TS/query/BankApprovalMatrixEditQueries'
import {queryClient} from 'hoc/ReactQuery'
import {useSnackBar} from 'hoc/SnackbarHandler'
import {setBreadcrumbRecordName} from 'store/actions'
import {APPROVAL_MATRIX_PAGE_NAME, HQ_ROLE_ID} from '_helper/_constants'
import {retrievePageFunctionAccess} from '_helper/_authHelper'
import {fetchApprovedDate} from 'api/TS/query/BankApprovalMatrixQueries'
import {ApprovedDateTimePicker} from 'components/_Common/ApprovedDateTimePicker'

function BankApprovalMatrixStatusUpdate() {
  const {country, approvedDate} = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const {setSnackBar} = useSnackBar()
  const navigate = useNavigate()
  const [functionAccess, setFunctionAccess] = useState([])
  const [approvedDateFormat, setApprovedDate] = useState(approvedDate)
  const [selectedCell, setSelectedCell] = useState([])
  const [toEditCell, setToEditCell] = useState(null)
  const [serverPaginationResult, setServerPaginationResult] = useState({
    rowData: [],
    totalPages: 0,
    totalCount: 0,
    toNextPage: '',
  })
  const [editable, setEditable] = useState(false)
  const [cellMutation, setCellMutation] = useState(null)
  const [refreshDone, setRefreshDone] = useState(false)

  /**
   * Determine which function does user have access to
   */
  useEffect(() => {
    if (functionAccess.length === 0) {
      retrievePageFunctionAccess(APPROVAL_MATRIX_PAGE_NAME, setFunctionAccess)
    }
    if (functionAccess === 'FORBIDDEN') {
      navigate('/Forbidden')
    }
  }, [functionAccess])

  /**
   * To retrieve approved dates
   */
  const {loading: isApprovedDateLoading, data: approvedDates} = useDataFetching(
    ['bam-approved-dates', country],
    () => fetchApprovedDate({country_id: country})
  )

  /**
   * To set breadcrumb nav
   */
  useEffect(() => {
    dispatch(
      setBreadcrumbRecordName({
        [location.pathname]: `Status Update for ${country.toUpperCase()}`,
      })
    )
  }, [])

  const editableHeaderColumns = [
    {
      name: 'Matrix',
      fieldName: 'matrix',
      component: ({value}) => (
        <div className={'wrap-text'} dangerouslySetInnerHTML={{__html: value}} />
      ),
    },
    {
      name: 'Threshold',
      fieldName: 'threshold',
      component: ({value}) => (
        <div className={'wrap-text'} dangerouslySetInnerHTML={{__html: value}} />
      ),
    },
  ]

  const columns = [
    {
      name: 'Bank',
      fieldName: 'bank_description',
    },
    {
      name: 'Entity',
      fieldName: 'entity_name',
    },
    {
      name: 'Authorised Person',
      fieldName: 'authorized_person',
      component: ({value}) => (
        <div className={'wrap-text'} dangerouslySetInnerHTML={{__html: value}} />
      ),
    },
    {
      name: 'Manual Payments',
      fieldName: 'manual_payments',
      component: ({value}) => (
        <div className={'wrap-text'} dangerouslySetInnerHTML={{__html: value}} />
      ),
    },
    {
      name: 'System Admin',
      fieldName: 'system_admin',
      component: ({value}) => (
        <div className={'wrap-text'} dangerouslySetInnerHTML={{__html: value}} />
      ),
    },
    {
      name: 'Status',
      fieldName: 'status_update',
      component: ({value, entityId, dataIndex, rowSpanName, fieldName}) => {
        if (!editable) {
          return value
        }

        return (
          <Select
            value={value}
            displayEmpty
            variant={'outlined'}
            style={{width: '100%', fontSize: '0.9em'}}
            inputProps={{'aria-label': 'Without label'}}
          >
            {['Pending completion with Bank', 'Completed'].map((value, index) => {
              return (
                <MenuItem
                  style={{fontSize: '0.9em'}}
                  key={index}
                  value={value}
                  onClick={() =>
                    setCellMutation({
                      value,
                      entityId,
                      dataIndex,
                      rowSpanName,
                      fieldName,
                    })
                  }
                >
                  {value}
                </MenuItem>
              )
            })}
          </Select>
        )
      },
    },
    {
      name: 'Remarks',
      fieldName: 'remarks',
      component: ({value, entityId, dataIndex, rowSpanName, fieldName}) => {
        return (
          <CustomEditorContent
            disabled={!editable}
            customEditor={Editor}
            value={value}
            handleOnChange={(value) => {
              setCellMutation({
                value,
                entityId,
                dataIndex,
                rowSpanName,
                fieldName,
              })
            }}
          />
        )
      },
    },
  ]

  /**
   * To retrieve bank approval matrix summary by request id else country
   */
  const {
    loading: isSummaryLoading,
    data: summaryData,
    isPreviousData,
  } = useDataFetching(
    [`bankApprovalMatrixStatusUpdate-${country}`, country, approvedDateFormat],
    () => fetchBankApprovalMatrixSummary(country, approvedDateFormat, 'status_update')
  )

  /**
   * To set the row value after cell mutation
   */
  useEffect(() => {
    if (cellMutation) {
      const entityId = cellMutation.entityId
      const value = cellMutation.value
      const dataIndex = cellMutation.dataIndex
      const fieldName = cellMutation.fieldName

      let modifiedRowData = [...serverPaginationResult.rowData[entityId].data]

      modifiedRowData[dataIndex][fieldName] = value

      setServerPaginationResult((prevState) => ({
        ...prevState,
        rowData: {
          ...prevState.rowData,
          [entityId]: {
            ...prevState.rowData[entityId],
            data: modifiedRowData,
          },
        },
      }))
    }
  }, [cellMutation])

  /**
   * To update the table data and format the data for the table to consume
   */
  useEffect(() => {
    if ((summaryData && !isPreviousData) || refreshDone) {
      // we need to extract threshold and matrix first
      let tempThresholdAndMatrix = []
      let currentEntity = null
      let currentEntityIndex = 0
      summaryData.forEach((row) => {
        // whatever it is we need to group it based by entity
        let newThresholdAndMatrix = {...tempThresholdAndMatrix}

        if (!newThresholdAndMatrix[row.entity_id]) {
          newThresholdAndMatrix[row.entity_id] = {
            data: [],
            type: null,
            name: row.entity_name,
            matrix: {
              authorized_person: null,
              bank_approval_matrix_id: null,
              manual_payments: null,
              system_admin: null,
            },
            threshold: {
              authorized_person: null,
              bank_approval_matrix_id: null,
              manual_payments: null,
              system_admin: null,
            },
          }
        }

        // lets check if the data is a threshold and matrix header
        if (row.is_header === 'Y') {
          // lets retrieve entity name
          const thresholdAndMatrixObject = {
            bank_approval_matrix_id: row.bank_approval_matrix_id,
            authorized_person: row.authorized_person ?? '',
            manual_payments: row.manual_payments ?? '',
            system_admin: row.system_admin ?? '',
          }

          newThresholdAndMatrix[row.entity_id] = {
            ...newThresholdAndMatrix[row.entity_id],
            [row.header_type]: thresholdAndMatrixObject,
          }

          tempThresholdAndMatrix = newThresholdAndMatrix
          return
        }

        // the code below is executed if its not a header
        // we will merge it only if they got the same entity as the previous index
        let ableToMerge = undefined
        if (currentEntity !== row.entity_id) {
          currentEntity = row.entity_id
          currentEntityIndex = 0
          ableToMerge = false
        } else {
          ableToMerge = true
          currentEntityIndex++
        }

        // we will loop the field to set its rowSpan and index and compare if its merge-able
        ;[
          'bank_description',
          'entity_name',
          'authorized_person',
          'manual_payments',
          'system_admin',
        ].forEach((fieldName) => {
          let tempArray = [...newThresholdAndMatrix[row.entity_id].data]
          let rowSpanIndex = currentEntityIndex
          let rowSpan = 1

          if (ableToMerge) {
            const prevIndex = currentEntityIndex - 1

            if (tempArray[prevIndex][fieldName] === row[fieldName]) {
              // we will add +1 to the previous rowSpan based on the row span index
              tempArray[tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]][
                `${[fieldName]}RowSpan`
              ]++

              // update the current rowSpanIndex & rowSpan to the previous row index
              rowSpanIndex = tempArray[prevIndex][`${[fieldName]}RowSpanIndex`]
              rowSpan = 0
            }
          }

          // if this is the first record we shall instantiate the rowSpan & rowIndex for the field
          if (!tempArray[currentEntityIndex]) {
            tempArray[currentEntityIndex] = {
              ...row,
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
            }
          } else {
            tempArray[currentEntityIndex] = {
              ...tempArray[currentEntityIndex],
              [`${[fieldName]}RowSpan`]: rowSpan,
              [`${[fieldName]}RowSpanIndex`]: rowSpanIndex,
            }
          }

          newThresholdAndMatrix[row.entity_id] = {
            ...newThresholdAndMatrix[row.entity_id],
            data: tempArray,
          }
          tempThresholdAndMatrix = newThresholdAndMatrix
        })
      })

      setServerPaginationResult({
        rowData: tempThresholdAndMatrix,
        totalPages: summaryData?.total_pages,
        totalCount: summaryData?.count,
        toNextPage: summaryData?.next,
      })
      setRefreshDone(false)
    }
  }, [summaryData, refreshDone])

  /**
   * To handle on save for status and remark
   * @type {UseMutationResult<*, unknown, void, unknown>}
   */
  const handleOnSave = useMutation(
    () => saveStatusUpdate(serverPaginationResult.rowData),
    {
      onSuccess: (result) => {
        setSnackBar({
          open: true,
          type: 'success',
          message: result?.status,
        })
      },
      onError: () => {
        setSnackBar((prevState) => ({...prevState, open: true}))
      },
      onSettled: () => {
        queryClient.invalidateQueries(`bankApprovalMatrixStatusUpdate-${country}`)
      },
    }
  )

  if (isSummaryLoading || isApprovedDateLoading) {
    return <Loading open={true} />
  }
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container>
          <Grid item xs={7} sm={5} md={4} lg={4}>
            <ApprovedDateTimePicker
              approvedDates={approvedDates}
              approvedDate={approvedDateFormat}
              setApprovedDate={setApprovedDate}
            />
          </Grid>
          {functionAccess === HQ_ROLE_ID && (
            <Grid item xs={5} sm={7} md={8} lg={8}>
              <div style={{float: 'right'}}>
                {!editable && (
                  <Button
                    label={'Edit'}
                    onClick={() => {
                      setEditable(true)
                    }}
                    disabled={serverPaginationResult.rowData.length === 0}
                  />
                )}
                {editable && (
                  <Stack direction={'row'} spacing={2}>
                    <Button
                      label={'Cancel'}
                      type={'text'}
                      onClick={() => {
                        setRefreshDone(true)
                        setEditable(false)
                        setEditable(null)
                        setSelectedCell([])
                      }}
                    />
                    <Button
                      label={'Save'}
                      onClick={() => {
                        handleOnSave.mutate()
                      }}
                    />
                  </Stack>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BAMTable
          columns={columns}
          editableHeaderColumns={editableHeaderColumns}
          serverPaginationResults={serverPaginationResult}
          country={country}
          type={'statusUpdate'}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          toEditCell={toEditCell}
          setToEditCell={setToEditCell}
          editable={editable}
          style={{top: '175px'}}
        />
      </Grid>
    </Grid>
  )
}

export default BankApprovalMatrixStatusUpdate
