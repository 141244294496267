import React from 'react'

import Template from 'core/Template'

const ServerError = ({tryAgain, loadingIndicator}) => {
  return (
    <>
      <Template
        title="Error 500"
        content="Sorry, we are having problem with our system."
        desc={
          <>
            We will have things fixed in no time. If you need help while the system is
            down, please contact us @{' '}
            <a href="mailto: finsys-epm@sea.com">finsys-epm@sea.com</a>
          </>
        }
        tryAgain={tryAgain}
        loadingIndicator={loadingIndicator}
      />
    </>
  )
}

export default ServerError
