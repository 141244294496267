import React from 'react'
import {Accordion, TextField} from 'finsys-webcomponent'
import Grid from '@mui/material/Grid'

/**
 * Component for Bank - Detail Section
 * @param formDetails
 * @param setFormDetails
 * @param errors
 * @param isDisabled
 * @returns {JSX.Element}
 * @constructor
 */
export function Details({formDetails, setFormDetails, errors, isDisabled}) {
  return (
    <Accordion title={'Bank Details'} expanded>
      <Grid container direction={'row'} columnSpacing={8} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label={'Bank Code'}
            onChange={(value) => {
              setFormDetails((prevState) => {
                return {
                  ...prevState,
                  bank_code: value.target.value?.toUpperCase(),
                  error_bank_code: null,
                }
              })
            }}
            value={formDetails.bank_code}
            required
            error={!!formDetails.error_bank_code || errors?.bank_code}
            helperText={formDetails.error_bank_code || errors?.bank_code}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label={'Bank Name'}
            onChange={(value) => {
              setFormDetails((prevState) => {
                return {
                  ...prevState,
                  bank_description: value.target.value,
                  error_bank_description: null,
                }
              })
            }}
            value={formDetails.bank_description}
            required
            error={!!formDetails.error_bank_description || errors?.bank_description}
            helperText={formDetails.error_bank_description || errors?.bank_description}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </Accordion>
  )
}
