import {useQuery} from 'react-query'

import TSApi from 'api/TS/TSApi'
import type {Entity} from '_helper/_types'
import {queryKeys} from 'hoc/ReactQuery/_constants'

/**
 * To save bank
 * @return {Promise<any>}
 */
export async function getEntity(id): Entity {
  const {data} = await TSApi.get(`entity_detail/${id}`)

  return data
}

export function useEntity(id): Entity {
  const {data: entity = null} = useQuery([queryKeys.entity, id], () => getEntity(id))

  return {entity}
}
