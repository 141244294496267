import React, {useEffect} from 'react'

import Template from 'core/Template'

/**
 * To be shown if user does not have access to the module
 * @returns {JSX.Element}
 * @constructor
 */
const NoAccess = () => {
  useEffect(() => {
    if (window.gapi.auth2?.getAuthInstance()) {
      // sign out google auth
      window.gapi.auth2?.getAuthInstance().signOut()
      window.gapi.auth2?.getAuthInstance().disconnect()
      localStorage.clear()
    }
  }, [])

  return (
    <>
      <Template
        errorCode={null}
        desc={
          <>
            Please go to{' '}
            <a href={`${process.env.REACT_APP_USER_GUIDE_URL}`}>
              {process.env.REACT_APP_USER_GUIDE_URL}
            </a>{' '}
            for guides on how to get access or contact us @{' '}
            <a href="mailto: finsys-epm@sea.com">finsys-epm@sea.com</a>.
          </>
        }
        content={'Uh oh! You are not authorized to access this resource.'}
      />
    </>
  )
}

export default NoAccess
