import React from 'react'
import {Box} from '@mui/material'

import {TableWrapper} from 'components/Tables/TableWrapper'
import {NoRowToShow} from 'components/Tables/_helper'
import {Loading} from 'finsys-webcomponent'

export function OBMTable({
  type,
  columns,
  page,
  serverPaginationResults,
  onClickToNextPage,
  onClickToPreviousPage,
  onClickToFirstPage,
  onClickToLastPage,
  isLoading,
}) {
  /**
   * Render table rows
   * @return {JSX.Element}
   */
  const renderTableRows = () => {
    const rows = serverPaginationResults.rowData

    return (
      <>
        <tbody>
          {rows?.map((value) => {
            return (
              <tr key={`${value.obm_account_id}`}>
                {columns.map(({fieldName, component: Component}, index) => {
                  if (fieldName === 'condition_threshold') {
                    if (value.condition_thresholdRowSpan === 0) {
                      return null
                    }
                    return (
                      <td
                        key={`${fieldName}-${index}`}
                        rowSpan={value.condition_thresholdRowSpan}
                      >
                        {Component
                          ? Component({
                              keyIndex: `${fieldName}-${index}-value`,
                              value: value[fieldName],
                            })
                          : value[fieldName]}
                      </td>
                    )
                  }
                  return (
                    <td key={`${fieldName}-${index}`}>
                      {Component
                        ? Component({
                            keyIndex: `${fieldName}-${index}-value`,
                            value: value[fieldName],
                          })
                        : value[fieldName]}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </>
    )
  }

  /**
   * To render table header
   * @return {JSX.Element}
   */
  const renderTableFixHeader = (columns) => {
    return (
      <thead>
        <tr style={{background: 'white', zIndex: '1'}}>
          <th
            style={{padding: 'unset', borderRight: 'unset', background: '#999999'}}
            colSpan={4}
          />
          <th
            style={{padding: 'unset', borderLeft: 'unset', background: '#999999'}}
            colSpan={3}
          />
          <th
            style={{padding: '5px 23px', background: '#999999', color: 'white'}}
            colSpan={2}
          >
            Matrix
          </th>
          <th
            style={{padding: '5px 23px', background: '#999999', color: 'white'}}
            colSpan={2}
          >
            Payment Maker
          </th>
          <th
            style={{padding: '5px 23px', background: '#999999', color: 'white'}}
            colSpan={3}
          >
            Payment Approver
          </th>
          <th
            style={{padding: '5px 23px', background: '#999999', color: 'white'}}
            colSpan={2}
          >
            Payment Verifier
          </th>
        </tr>
        <tr>
          {columns.map(({fieldName, name}, key) => {
            return <th key={`${fieldName}-header-${key}`}>{name}</th>
          })}
        </tr>
      </thead>
    )
  }

  return (
    <TableWrapper
      type={type}
      serverPaginationResults={serverPaginationResults}
      page={page}
      onClickToNextPage={onClickToNextPage}
      onClickToPreviousPage={onClickToPreviousPage}
      onClickToFirstPage={onClickToFirstPage}
      onClickToLastPage={onClickToLastPage}
    >
      <div className={`obm-table no-border`}>
        {isLoading ? (
          <Loading open={true} fullScreen={false} />
        ) : (
          <>
            <table>
              {renderTableFixHeader(columns)}
              {renderTableRows()}
            </table>

            {serverPaginationResults.rowData.length === 0 && (
              <Box>
                <NoRowToShow />
              </Box>
            )}
          </>
        )}
      </div>
    </TableWrapper>
  )
}
