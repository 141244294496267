import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {Snackbar} from 'finsys-webcomponent'

const SnackbarAlertContext = createContext()

export const SnackbarHandler = ({children}) => {
  const message = 'Opps! Something went wrong. Please try again later'
  const [snackbar, setSnackBar] = useState({
    open: false,
    type: 'error',
    message: message,
  })

  useEffect(() => {
    if (!snackbar.open) {
      let timer1 = window.setTimeout(() => {
        setSnackBar((prevState) => ({
          ...prevState,
          type: 'error',
          message: message,
        }))
      }, 150)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [snackbar.open])

  const contextPayload = useMemo(() => ({setSnackBar}), [])

  return (
    <SnackbarAlertContext.Provider value={contextPayload}>
      <Snackbar
        open={snackbar.open}
        type={snackbar.type ?? 'error'}
        message={snackbar.message ?? message}
        handleOpenClose={() => setSnackBar((prevState) => ({...prevState, open: false}))}
      />

      {children}
    </SnackbarAlertContext.Provider>
  )
}

export const useSnackBar = () => useContext(SnackbarAlertContext)
